import React from 'react';
import PropTypes from 'prop-types';


const propTypes = {
  handleClickOutside: PropTypes.func.isRequired,
};

class ClickOutside extends React.Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }


  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.handleClickOutside();
    }
  }

  render() {
    const {props} = this;

    return (
      <div
        className={props.className}
        ref={(inst) => (this.wrapperRef = inst)}>
        {props.children}
      </div>
    );
  }
};

ClickOutside.propTypes = propTypes;

export default ClickOutside;
