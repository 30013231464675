import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import Helpers from '../../../utils/Helpers';

const propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
};


class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
    Helpers.hashLinkScroll(0, 300, -100);
  }

  componentWillMount() {
    Helpers.hashLinkScroll(0, 300, -100);
  }

  componentDidMount() {
    Helpers.hashLinkScroll(0, 300, -100);
  }

  render() {
    return this.props.children
  }
}

ScrollToTop.propTypes = propTypes;

export default withRouter(ScrollToTop)
