import {
  extendObservable,
  action,
} from 'mobx';

import uuid from 'uuid-v4';

import {
  mergeWith,
} from 'lodash';

import GameListModel from './GameListModel';
import { orderStatuses } from '../constants/orderStatuses';

class OrderItemModel {
  constructor(data) {
    extendObservable(this, {
      data: mergeWith({}, this.orderItemInitialState),
      box: new GameListModel(),
      address: mergeWith({}, this.addressInitialState),

      get statusName() {
        const index = orderStatuses.map(i => i.id).indexOf(this.data.status);

        return index >= 0 ? orderStatuses[index].name : null;
      },
    });

    if (data) {
      this.updateOrderItem(data);
    }
  }

  id = uuid();

  orderItemInitialState = {
    id: '',
    status: 0,
    orderNumber: 0,
    detailsAvailable: false,
    transaction: '',
    isSendTogether: false
  };

  addressInitialState = {
    id: '',
    fullName: '', // string
    businessName: '', // string
    zip: '', // number
    city: '', // string
    state: '', // string
    address: '', // string
    phone: '', // string
    country: '', // object
  };

  clearOrderItem = action(
    'clear order item', () => {
    this.data = mergeWith({}, this.orderItemInitialState);
    this.box = new GameListModel();
    this.address = mergeWith({}, this.addressInitialState);
  });

  changeOrderAddress = action('change order address', newAddr => {
    Object.keys(newAddr).forEach(key => {
      if (this.address[key] !== newAddr[key]) {
        this.address[key] = newAddr[key];
      }
    });
  });

  updateOrderItem = action('update order item', newData => {
    newData.box.forEach(item => {
      item.platform = item.game && item.game.platforms[0];
      this.box.addItemToGameList(item, 'id');
    });

    this.box.updateTotal(newData.transaction ? newData.transaction.amount : newData.amount);
    this.box.updateDeliveryServicePrice(newData.deliveryServicePrice);
    this.box.updateDeliveryService(newData.deliveryService);
    if (!!newData.promoCode && !!newData.promoPercent) {
      this.box.updateDiscount({code: newData.promoCode, percent: newData.promoPercent});
    }

    if (newData.address) {
      this.changeOrderAddress(newData.address);
      delete newData.address;
    }

    delete newData.box;

    Object.keys(newData).forEach(key => {
      if (this.data[key] !== newData[key]) {
        this.data[key] = newData[key];
      }
    });

    this.data.detailsAvailable = (newData.status === 7 || newData.status === 5) ? true :
      ["select","turbo","speedRun","warpZone","proStandard","proExtensive"].some(
        sl => { return (newData[sl+"Status"] > 3); }
    );
  });
}

export default OrderItemModel;
