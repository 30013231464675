import React from 'react';

import {Tooltip} from 'react-tippy-ie11-fix';

import {
  tippyConfig,
} from '../../../../constants/common';

import './styles/Size.css';

const Size = (props) => {
  return (
    <div className="sizel">
      <div className="container-fluid">
        <div className="row-fluid">

          <div className="size__wrapper section section--gradient section--ytr">
            <div className="size__content">

              <h2 className="section__line-through section__line-through--white">
                <span>WATA Legacy Cases</span>
              </h2>

              <p className="size__subtitle">
              Whether you need to complete your almost-done collection, prefer the look of the legacy case, or any other reason, we still have the goods. However there’s limited availability! 
              </p>

              <div className="size__items">

                <div className="size__item">
                  <h3 className="size__title">Four Case Sizes:</h3>

                  <ul className="size__list">

                    <li className="size__list-item">

                      <div className="square square--gradient">
                        <div className="square__content">
                          <span className="size__value">
                            9.35”
                          </span>
                        </div>
                      </div>

                      <div className="size__list-content">
                        <div className="size__label">
                          <b>9.35” Display Case</b>
                        </div>
                        <p className="size__list-text">
                          Currently holds: Sega CD, Sega Saturn, PS1 Longbox
                        </p>
                      </div>
                    </li>

                    <li className="size__list-item">

                      <div className="square square--gradient">
                        <div className="square__content">
                          <span className="size__value">
                            8.0”
                          </span>
                        </div>
                      </div>

                      <div className="size__list-content">
                        <div className="size__label">
                          <b>8.0” Display Case</b>
                        </div>
                        <p className="size__list-text">
                          Currently holds: NES Boxes, SNES Boxes, N64 Boxes,
                          Genesis and 32X boxes, Gamecube, PS2, Xbox, Xbox
                          360, Wii, Wii U
                        </p>
                      </div>
                    </li>

                    <li className="size__list-item">

                      <div className="square square--gradient">
                        <div className="square__content">
                          <span className="size__value">
                            6.3”
                          </span>
                        </div>
                      </div>

                      <div className="size__list-content">
                        <div className="size__label">
                          <b>6.3” Display Case</b>
                        </div>
                        <p className="size__list-text">
                          Currently holds: NES Cartridges
                        </p>
                      </div>
                    </li>

                    <li className="size__list-item">

                      <div className="square square--gradient">
                        <div className="square__content">
                          <span className="size__value">
                            4.45”
                          </span>
                        </div>
                      </div>

                      <div className="size__list-content">
                        <div className="size__label">
                          <b>4.45” Case Body</b>
                        </div>
                        <p className="size__list-text">
                          Currently holds: SNES Cartridges
                        </p>
                      </div>
                    </li>

                  </ul>
                </div>

                <div className="size__item">
                  <h3 className="size__title">One Case Style:</h3>
                  <div className="size__cases">

                    <div className="size__case">
                      <span className="size__case-icon icon-case-3d"/>
                      <div className="size__case-title">
                        3D / Banner
                        <Tooltip
                          {...tippyConfig}
                          html={(
                            <div>
                              The 3D/Banner Label is applicable to any one of the four heights.
                            </div>
                          )}>
                          <span className="icon-question-circle text-gradient size__tooltip" />
                        </Tooltip>
                      </div>
                      <ul className="arrow-list arrow-list--white">
                        <li className="arrow-list__item">View pertinent information on 4 different sides.</li>
                        <li className="arrow-list__item">See all sides of your media without any obscuring.</li>
                      </ul>
                    </div>

                  
                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  );
}


export default Size;
