import React from 'react';


const FooterContacts = (props) => {
  return (
    <div className="footer__contacts">
      <a
        href="mailto:info@WataGames.com"
        className="footer__contacts-item">
        <span className="footer__contacts-icon icon-envelope" /> info @ <b>WataGames</b> .com
      </a>
    </div>
  );
}


export default FooterContacts;
