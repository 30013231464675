import React from 'react';
import PropTypes from 'prop-types';
import ReactAlert from 'react-s-alert';

import AvatarEditor from 'react-avatar-editor';

import {
  mergeWith,
  debounce,
  isEqual,
} from 'lodash';

import uuid from 'uuid-v4';

import Helpers from '../../../utils/Helpers';

import Formsy from 'formsy-react';

import {inject, observer} from 'mobx-react';
import { translate } from 'react-i18next';

import FormModel from '../../../models/FormModel';

import File from '../../shared/form-elements/File';
import Input from '../../shared/form-elements/Input';
import Invader from '../../shared/Invader/Invader';


import '../../../utils/FormsyCustomValidationRules';

import {
  errorMessages as err,
  serverConstants,
  alertConfig,
  placeholders,
  imageSizes,
} from '../../../constants/common';

import { apiURLs } from '../../../configs/apiURLs';

import API from '../../../utils/API';
import Logger from '../../../utils/Logger';

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
};

class ProfileImage extends React.Component {
  constructor(props) {
    super(props);
    this.debounceOnPositionChange = debounce((pos, cb) => {
      this.onPositionChange(pos)
    }, 300);

    this.store = new FormModel({
      thumbnail: '',
    });
  }

  state = {
    key: uuid(),
    img: '',
    imgSize: {},
    cropData: {},
  };

  handleFileChange = (dataURI, image, file) => {
    const {store} = this;

    store.update({
      thumbnail: file
    });
    store.updateLoadingStatus(false);

    this.setState(currentState => {
      return mergeWith({},
        currentState,
        {
          img: dataURI,
          imgSize: {
            width: image.width,
            height: image.height,
          },
        },
        Helpers.customizer);
    });
  }

  updateInputsWithError = (data) => {
    const {store} = this;

    if (!!store.refToForm) return;

    store.refToForm.updateInputsWithError(data);
  }

  uploadHandler = (name, file) => {
    const handleFileChange = this.handleFileChange;
    const reader = new FileReader();

    if (!file) return;

    this.store.updateLoadingStatus();

    reader.onload = img => {
      let image = new Image();

      image.src = img.target.result;
      image.onload = () => {
        handleFileChange(img.target.result, image, file);
      }
    };

    reader.readAsDataURL(file);
  }

  getCropData = (imgSize, pos) => {
    const {state} = this;
    const previewSize = imageSizes.profileImage;
    const centerPosition = previewSize/2;
    let posRel = pos.x;
    let smallSide = state.imgSize.height;
    let bigSide = state.imgSize.width;
    let scale;
    let scaledBigSide;
    let scaledInPx;
    let resData = {};

    if (state.imgSize.height > state.imgSize.width) {
      smallSide = state.imgSize.width;
      bigSide = state.imgSize.height;
      posRel = pos.y;
    }

    scale = smallSide/previewSize;
    scaledBigSide = bigSide/scale;

    scaledInPx = Math.round((scaledBigSide*posRel-centerPosition)*100)/100;

    if (scaledInPx <= 0) {
      scaledInPx = +0;
    } else if (scaledInPx + previewSize > scaledBigSide) {
      scaledInPx = Math.round((scaledBigSide - previewSize)*100)/100;
    }

    resData = {
      x: scaledInPx,
      y: 0,
      scale,
      size: previewSize,
    };

    if (state.imgSize.height > state.imgSize.width) {
      resData.x = 0;
      resData.y = scaledInPx;
    }

    return resData;
  }

  onPositionChange = (pos) => {
    const {state} = this;
    const cropData = this.getCropData(state.imgSize, pos);

    if (isEqual(cropData, state.cropData)) return;

    this.setState(currentState => {
      return mergeWith({},
        currentState,
        {
          cropData,
        },
        Helpers.customizer);
      });
  }

  onLoadSuccess = (imgInfo) => {
    this.setState(currentState => {
      return mergeWith({},
        currentState,
        {
          cropData: this.getCropData(this.state.imgSize, imgInfo),
        },
        Helpers.customizer);
    });
  }

  onValidSubmit = async (model, resetForm, invalidateForm) => {
    let formData = new FormData();
    const {cropData} = this.state;
    const {store} = this;
    const query = {
      offsetX: cropData.x,
      offsetY: cropData.y,
      cornerSize: cropData.size,
      scaleIndex: cropData.scale,
    };

    const objToQuery = (obj = {}) => {
      let str = '?';

      Object.keys(obj).forEach(key => {
        str = `${str}&${key}=${obj[key]}`;
      });

      return str;
    };

    const stringifyQuery = objToQuery(query);

    store.updateLoadingStatus();

    formData.append('file', store.elements.get('thumbnail'));

    try {
      const response = await API.postData(apiURLs.file.crop(stringifyQuery), formData);
      const respUpdateImage = await API.patchData(apiURLs.user.avatar, {
        avatar: `//${response.data.cloudFront}`
      });

      this.props.appStore.updateUserData({
        profile: respUpdateImage.data,
      });

      this.resetForm();

    } catch (error) {
      Logger.error(error);
      ReactAlert.error(error.message, alertConfig);

      store.updateLoadingStatus(false);
    }
  }

  resetForm = () => {
    const {store} = this;

    store.resetForm();
    store.updateLoadingStatus(false);

    this.setState(currentState => {
      return mergeWith({},
        currentState,
        {
          key: uuid(),
          img: '',
        },
        Helpers.customizer);
    });
  }

  removePhotoImage = async () => {
    const {store} = this;
    store.updateLoadingStatus();

    try {
      const respUpdateImage = await API.patchData(apiURLs.user.avatar, {
        avatar: ''
      });

      this.props.appStore.updateUserData({
        profile: respUpdateImage.data,
      });

      store.updateLoadingStatus(false);

    } catch (error) {

      store.updateLoadingStatus(false);

      Logger.error(error);
      ReactAlert.error(error.message, alertConfig);
    }
  }

  render() {
    const {
      t,
      appStore,
    } = this.props;

    const {
      state,
      store,
    } = this;

    const thumbnail = store.elements.get('thumbnail');

    return (
      <Formsy
        ref={(component) => {
          store.updateRefToForm(component);
        }}
        className="profile__photo-wrapper"
        onValidSubmit={this.onValidSubmit}>
          <p className="profile__label">
            {t('Profile picture')}:
          </p>

          <Input
            name="message"
            type="hidden"
            className="form-field--no-margin" />

          <File
            key={state.key}
            name="thumbnail"
            value={''}
            accept={serverConstants.availableTypesOfAttachments.image.join(',')}
            uploadHandler={this.uploadHandler}
            id="profile_thumbnail"
            updateInputsWithError={this.updateInputsWithError}
            inputClassName="profile__photo-input"

            customValidation={{
              maxFileSize: 10,
            }}
            customValidationErrors={{
              maxFileSize: err.maxFileSize(10),
            }}>
            <div className="profile__photo-content">
              {
                store.isLoading.get() && (
                  <div className="image-loader-wrapper">
                    <Invader className="image-loader" />
                  </div>
                )
              }
              <AvatarEditor
                image={state.img || appStore.profile.avatar || placeholders.userImage}
                width={245}
                height={245}
                disableDrop={true}
                border={0}
                onLoadSuccess={this.onLoadSuccess}
                onPositionChange={this.debounceOnPositionChange}
                // color={[255, 255, 255, 0.6]}
                scale={1}
                rotate={0} />
              <div className="profile__photo-controls">
                {
                  (!!appStore.profile.avatar && !thumbnail) && (
                    <button
                      title={t('Remove photo')}
                      onClick={this.removePhotoImage}
                      className="profile__photo-icon"
                      type="button">
                      <span className="icon-trash-alt" aria-hidden="true" />
                    </button>
                  )
                }

                {
                  !!thumbnail && (
                    <button
                      onClick={this.resetForm}
                      title={t('Clean the input')}
                      className="profile__photo-icon"
                      type="button">
                      &times;
                    </button>
                  )
                }
                {
                  !!thumbnail && (
                    <button
                    title={t('Upload photo')}
                    className="profile__photo-icon"
                    type="submit">
                      <span className="icon-check" aria-hidden="true" />
                    </button>
                  )
                }
                {
                  !thumbnail && (
                    <label
                      title={t('Add photo')}
                      className="profile__photo-icon"
                      htmlFor="profile_thumbnail">
                      <span className="icon-camera" aria-hidden="true" />
                    </label>
                  )
                }
              </div>
            </div>
          </File>
      </Formsy>
    );
  }

}

const WrappedProfileImage = inject('appStore')(observer(ProfileImage));
const TranslateProfileImage = translate()(WrappedProfileImage);
TranslateProfileImage.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateProfileImage;
