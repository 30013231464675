import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles/Invader.css';

const propTypes = {
  className: PropTypes.string,
};

const Invader = (props) => {
  return (
    <div className={classnames(
        'invader-container',
        props.className,
      )}>
      <div className="space-invader" />
    </div>
  );
}

Invader.propTypes = propTypes;

export default Invader;
