import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';

import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import Newsletter from './Newsletter/Newsletter';
import Events from './Events/Events';

import { routeURLs as URL } from '../../configs/routeURLs';

import withTracker from '../shared/AnalyticsTracker/withTracker';

import './styles/Learn.css';

const propTypes = {
  t: PropTypes.func.isRequired,
};

const TITLE = 'Learn + Connect';

class Learn extends React.Component {
  render() {
    /* It makes redirect from /learn to /learn/newsletter */
    const redirectToNewsletter = props => {
      const {from} = this.props.location.state || {
        from: { pathname: URL.learn.child.newsletter.link }
      }
      return (
        <Redirect to={from} />
      )
    };
    return (
      <div className="page-wrapper">

        <Switch>
          <Route
            exact
            path={URL.learn.link}
            render={redirectToNewsletter} />

          <Route
            exact
            path={URL.learn.child.newsletter.link}
            render={props => {
              return <Newsletter {...props} />
            }}
          />

          {/*
            <Route
              path={URL.learn.child.howTo.link}
              render={props => {
                return <HowTo {...props} />
              }}
            />
          */}

          <Route
            path={URL.learn.child.events.link}
            render={props => {
              return <Events {...props} />
            }}
          />

          {/* <Route
            path={URL.learn.child.database.link}
            render={props => {
              return <Database {...props} />
            }}
          />
          <Route
            path={URL.learn.child.registry.link}
            render={props => {
              return <Registry {...props} />
            }}
          />
          <Route
            path={URL.learn.child.census.link}
            render={props => {
              return <Census {...props} />
            }}
          />
          <Route
            path={URL.learn.child.ebay.link}
            render={props => {
              return <Ebay {...props} />
            }}
          /> */}
          <Route
            path={'/learn/blog/'}
            component={() => { 
              window.location.href = URL.learn.child.blog.link; 
              return null;
            }}
          />

          {/* 404 */}
          <Redirect to={{ state: { error: true } }} />
        </Switch>

      </div>
    );
  }
}


Learn.propTypes = propTypes;
const TranslateLearn = translate()(Learn);

export default withTracker(TranslateLearn, {title: TITLE});
