import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { translate } from 'react-i18next';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import Collectors from './Collectors/Collectors';

import withTracker from '../shared/AnalyticsTracker/withTracker';


const propTypes = {
  t: PropTypes.func.isRequired,
};

const TITLE = 'Who We Are';


class WhoWeAre extends React.Component {
  render() {
    const {t} = this.props;

    return (
      <div className="page-wrapper">
        <Helmet
          title={t(TITLE)}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />

        <Header />

        <main className="main">
          <Collectors />
        </main>

        <Footer />
      </div>
    );
  }
}


WhoWeAre.propTypes = propTypes;
const TranslateWhoWeAre = translate()(WhoWeAre);

export default withTracker(TranslateWhoWeAre, {title: TITLE});
