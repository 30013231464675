import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  cloneDeep,
} from 'lodash';

import {
  headerStickyTrigger,
} from '../../constants/common';

import HeaderTop from './HeaderTop/HeaderTop';
import HeaderNav from './HeaderNav/HeaderNav';

import './styles/Header.css';

const propTypes = {
  className: PropTypes.string
};


class Header extends React.Component {
  state = {
    isSticky: false,
  }

  componentDidMount() {
    const w = window;

    w.addEventListener('scroll', this.handleSticky);
    w.addEventListener('resize', this.handleSticky);
  }

  componentWillUnmount() {
    const w = window;

    w.removeEventListener('scroll', this.handleSticky);
    w.removeEventListener('resize', this.handleSticky);
  }

  handleSticky = () => {
    let isSticky = cloneDeep(this.state.isSticky);

    const {top} = document.body.getBoundingClientRect();

    if (isSticky !== (top <= headerStickyTrigger)) {
      this.setState({
        isSticky: top <= headerStickyTrigger
      });
    }
  }

  render() {
    const {props} = this;
    return (
      <header
        className={classnames(
          'header',
          {
            'header--sticky': this.state.isSticky
          },
          props.className
        )}>
        <HeaderTop />

        <HeaderNav isSticky={this.state.isSticky}/>
      </header>
    );
  }
}

Header.propTypes = propTypes;

export default Header;
