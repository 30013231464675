import React from 'react';

import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import AuthSession from '../../utils/AuthSession';
import { routeURLs as URL } from '../../configs/routeURLs';

import JoinView from './JoinView';
import UsThemView from './UsThemView';

import withTracker from '../shared/AnalyticsTracker/withTracker';

const TITLE = 'Join us';

class Join extends React.Component {
  render() {
    // const {props} = this;
    // const {history} = props;
    const {from} = this.props.location.state || { from: { pathname: URL.dashboard.link } }

    if (AuthSession.isTokenSet()) {
      return (
        <Redirect to={from} />
      )
    }

    return (
      <div className="page-wrapper">

        <Switch>
          <Route
            exact
            path={URL.join.link}
            render={props => {
              return <JoinView {...props} />
            }} />

          <Route
            path={URL.join.child.usThem.link}
            render={props => {
              return <UsThemView {...props} />
            }} />
        </Switch>

      </div>
    );
  }
}


// Join.propTypes = propTypes;

export default withTracker(Join, {title: TITLE});
