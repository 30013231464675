import {
    extendObservable,
    action,
  } from 'mobx';
    
  class CertDetailsStore {
    constructor() {
      extendObservable(this, {
        certDetailsByLabel: new Map(),
      });
    }
  
    addCertDetails = action(
      'add the cert details for a label',
      (label, details) => {
        if (!this.certDetailsByLabel.has(label)){
          this.certDetailsByLabel.set(label,details);
        }
      }
    );
  
    getCertDetails = action(
      'provide the cert details for a label -- null is provided if there are no relevant details in the store',
      (label) => {
        if (this.certDetailsByLabel.has(label)){
          return this.certDetailsByLabel.get(label);
        } else {
          return null;
        }
      }
    );
  }
  
  const store = new CertDetailsStore();
  
  export default store;
  