export const displayTypes = [
  {
    id: 0,
    name: "3D",
    icon: "case-3d",
  },
  {
    id: 1,
    name: "2D",
    icon: "case-2d",
  },
  {
    id: 2,
    name: "3D",
    icon: "case-3d",
  },
];
