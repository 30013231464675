import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { translate } from 'react-i18next';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import UsThem from './UsThem/UsThem';

import './styles/Join.css';

const propTypes = {
  t: PropTypes.func.isRequired,
};

class UsThemView extends React.Component {
  render() {
    const {t} = this.props;

    return (
      <div className="page-wrapper">

        <Helmet
          title={t('Us > Them')}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />

        <Header className="header--gradient-btp" />

        <main className="main">
          <UsThem sectionClassName="section--top"/>
        </main>

        <Footer />
      </div>
    );
  }
}


UsThemView.propTypes = propTypes;

const TranslateUsThemView = translate()(UsThemView);

export default TranslateUsThemView;
