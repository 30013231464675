import React from "react";
import PropTypes from "prop-types";

import { inject, observer } from "mobx-react";

import ReactAlert from "react-s-alert";

import Helmet from "react-helmet";

import { translate, Trans } from "react-i18next";

import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

import EventsList from "../EventsList/EventsList";

import { alertConfig, errorMessages as err } from "../../../constants/common";

import { apiURLs } from "../../../configs/apiURLs";

import API from "../../../utils/API";

import Logger from "../../../utils/Logger";

import withTracker from "../../shared/AnalyticsTracker/withTracker";

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
};

const TITLE = "Events";

class Events extends React.Component {
  state = {
    gaming: [],
    comic: [],
  };

  componentDidMount() {
    this.fetchEventsData();
  }

  fetchEventsData = async () => {
    const { appStore } = this.props;

    appStore.updateProgressData();

    try {
      const response = await API.getData(apiURLs.events);

      const { data } = response;

      this.setState({
        gaming: data.gaming,
        comic: data.comic,
      });

      appStore.updateProgressData(false);
    } catch (error) {
      Logger.error(error);
      if (!!error.response) {
        ReactAlert.error(error.response.data.message, alertConfig);
      } else {
        ReactAlert.error(err.somethingWentWrong, alertConfig);
      }
      appStore.updateProgressData(false);
    }
  };

  render() {
    const { t } = this.props;

    const { state } = this;

    return (
      <div>
        <Helmet
          title={t(TITLE)}
          meta={[
            {
              name: "description",
              content: "",
            },
          ]}
        />

        <Header className="header--gradient-btp" />

        <main className="main">
          <div className="learn">
            <div className="container-fluid">
              <div className="row-fluid">
                <div className="learn__content">
                  <div className="learn__section section section--top section--gradient section--btp">
                    <div className="events">
                      <div className="container-fluid">
                        <h2 className="section__title">
                          <Trans i18nKey={"WATA Events (come hang out!)"}>
                            WATA Events <span>(come hang out!)</span>
                          </Trans>
                          :
                        </h2>
                        <p className="section__subtitle">
                          {t(
                            "We’d love to meet you, hear what you’re thinking, and answer any questions about WATA or collecting video games! Meeting you in person is a real treat for us, so slip on your power glove and come on down! We’ll take game submissions in person at many of the events (noted below), and offer exclusive promotions and other benefits to anyone who makes the trek out to say howdy!"
                          )}
                        </p>
                        <div className="events__info">
                          <h3 className="section__line-through">
                            <span>
                              <b>{t("Gaming Events")}</b>
                            </span>
                          </h3>
                          <EventsList data={state.gaming} white={false} />
                        </div>

                        <div className="events__info">
                          <h3 className="section__line-through">
                            <span>
                              <b>{t("Comic Events")}</b>
                            </span>
                          </h3>
                          <EventsList data={state.comic} white={false}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    );
  }
}

const WrappedEvents = inject("appStore")(observer(Events));
const TranslateEvents = translate()(WrappedEvents);
TranslateEvents.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslateEvents, { title: TITLE });
