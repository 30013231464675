import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Helmet from 'react-helmet';
import uuid from 'uuid-v4';
import BigNumber from 'bignumber.js';

import Formsy from 'formsy-react';

import {
  mergeWith,
} from 'lodash';

import { inject, observer } from 'mobx-react';

import { translate } from 'react-i18next';

import { ttCibPlus, ttGenDesignation, ttLegacyHolder, ttLightCleaning, ttPhotoServices, ttReholder, ttStickerRemoval, ttAutographAuth } from '../../../constants/tooltips';

import { routeURLs as URL } from '../../../configs/routeURLs';
import { Link } from "react-router-dom";

import { serviceLevels } from '../../../constants/serviceLevels';
import { prices } from '../../../constants/prices';

import Helpers from '../../../utils/Helpers';
import Storage from '../../../utils/Storage';

import FormModel from '../../../models/FormModel';

import {
  errorMessages as err,
  tippyConfig,
  tippyConfigLeft
} from '../../../constants/common';

import Checkbox from '../../shared/form-elements/Checkbox';
import Input from '../../shared/form-elements/Input';
import Radio from '../../shared/form-elements/Radio';

import './styles/AddOnsForm.css';
import { Tooltip } from 'react-tippy-ie11-fix';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  submitGameStore: PropTypes.object.isRequired,
  queueStore: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  updateSucceed: PropTypes.func.isRequired,
};

class AddOnsForm extends React.Component {
  state = {
    isAgree: false,
  };

  onCheck = (e) => {
    const { target } = e;

    const useVar = target.name === "agreement" ? "isAgree" : "isNewsletter";

    if (target.checked !== this.state[useVar]) {
      const objVar = {};
      objVar[useVar] = target.checked;
      this.setState(objVar);
    }
  };

  constructor(props) {
    super(props);

    this.store = new FormModel({
      displayType: 0,
      serviceLevel: 0,
      badge: false,
      cleaning: false,
      heavyCleaning: false,
      photoServices: false,
      report: false,
      testing: false,
      boardPhotos: false,
      reholder: false,
      certificationNumber: '',
      cibPlus: false,
      legacyHolder: false,
      stickerRemoval: false,
      genDesignation: false,
      autographAuth: false,
    });
  }

  componentDidMount() {
    this.updateFormState();
  }

  componentWillReceiveProps(props) {
    this.updateFormState();
  }

  onCheck = (e) => {
    const { target } = e;

    if (target.checked !== this.state.isAgree) {
      this.setState({isAgree: target.checked});
    }
  };
  
  updateFormState = () => {
    const {gameItem} = this.props.submitGameStore;

    const firstAvailableServiceLevel = gameItem.serviceLevelPrices
      .map(e => e.enabled).indexOf(true);

    this.store.update({
      displayType: gameItem.data.displayType,
      serviceLevel: gameItem.data.serviceLevel || firstAvailableServiceLevel,
      cleaning: gameItem.data.cleaning,
      heavyCleaning: gameItem.data.heavyCleaning,
      photoServices: gameItem.data.photoServices,
      report: gameItem.data.report,
      testing: gameItem.data.testing,
      reholder: gameItem.data.reholder,
      certificationNumber: gameItem.data.certificationNumber,
      boardPhotos: gameItem.data.boardPhotos,
      cibPlus: gameItem.data.cibPlus,
      legacyHolder: gameItem.data.legacyHolder,
      stickerRemoval: gameItem.data.stickerRemoval,
      genDesignation: gameItem.data.genDesignation,
      autographAuth: gameItem.data.autographAuth,
    });
  }

  onValidSubmit = (model, resetForm, invalidateForm) => {
    const {props} = this;
    const {elements} = this.store;
    const {
      submitGameStore,
      queueStore,
    } = props;

    submitGameStore.gameItem.updateGameItem({
      displayType: elements.get('displayType'),
      serviceLevel: elements.get('serviceLevel'),
      badge: elements.get('badge'),
      cleaning: elements.get('cleaning'),
      heavyCleaning: elements.get('heavyCleaning'),
      photoServices: elements.get('photoServices'),
      report: elements.get('report'),
      testing: elements.get('testing'),
      reholder: elements.get('reholder'),
      certificationNumber: elements.get('certificationNumber'),
      boardPhotos: elements.get('boardPhotos'),
      queueId: uuid(),
      cibPlus: elements.get('cibPlus'),
      legacyHolder: elements.get('legacyHolder'),
      stickerRemoval: elements.get('stickerRemoval'),
      genDesignation: elements.get('genDesignation'),
      autographAuth: elements.get('autographAuth'),
    });

    props.queueStore.addItemToGameList(submitGameStore.gameItem.data, 'queueId');

    const queueDataToStorage = queueStore.gameList.ids.map(id => {
      return queueStore.gameList.dataMap[id].data
    });

    let dataToAdd = {};
    let storage = Storage.get('watagamesQueueList');

    if (props.appStore.user.id) {
      dataToAdd[props.appStore.user.id] = queueDataToStorage;
    } else {
      dataToAdd['unauth'] = queueDataToStorage;
    }

    const dataToStorage = mergeWith({},
      storage,
      dataToAdd,
      Helpers.customizer);

    Storage.set('watagamesQueueList', dataToStorage);

    submitGameStore.gameItem.clearGameItem();
    props.updateSucceed();
  }

  goBack = () => {
    const {
      router,
      submitGameStore,
    } = this.props;

    submitGameStore.updateCurrentStep(1);
    router.push(URL.submitGame.child.stepTwo.link);
  }

  render() {
    const {
      store,
      props,
    } = this;

    const {elements} = store;
    const {t} = props;
    const {round} = Helpers;
    const { gameItem } = props.submitGameStore;
    const tooltipConfig = window.innerWidth < 1000 ? tippyConfig : tippyConfigLeft;

    const declaredValue = (gameItem.data.declaredValueType === 6)
      ? gameItem.data.declaredValue
      : prices.getDeclaredValueOptionsArray()[gameItem.data.declaredValueType];

    const serviceLevelPrice = (status, price) => {
      return status ? price : t('N/A');
    };

    const addOnsPrice = () => {
      const arrAddOns = [
        'cleaning',
        'heavyCleaning',
        'testing',
        'boardPhotos',
        'photoServices',
        'report',
        'reholder',
        'cibPlus',
        'stickerRemoval',
        'genDesignation',
        // 'legacyHolder',
        'autographAuth',
      ];

      let priceToRes;

      const {
        gameState
      } = gameItem.data;

      return arrAddOns.reduce((previousValue, currentValue) => {
        switch (true) {
          case (
            currentValue === 'cleaning'
            && gameState === 1
          ):
            priceToRes = prices.serviceLevels.cib.addOns[currentValue];
            break;

          case (
            currentValue === 'cleaning'
            && (gameState === 0 || gameState === 2)
          ):
            priceToRes = prices.serviceLevels.sealedLoose.addOns[currentValue];
            break;

          default:
            priceToRes = prices.addOns[currentValue];
            break;
        }

        const withAddOnValue = BigNumber(previousValue)
          .plus(priceToRes)
          .toNumber();

        return elements.get(currentValue) ? withAddOnValue : previousValue
      }, 0);

    };

    const serviceLevelSubmissionPrice = elements.get('reholder')
      ? 0
      : gameItem.serviceLevelPrices[elements.get('serviceLevel')].price;

    const totalPrice = () => {
      let price_bn = BigNumber(serviceLevelSubmissionPrice);

      price_bn = price_bn.plus(
        elements.get('reholder')
          ? prices.addOns.reholder
          : addOnsPrice()
      );

      return price_bn.times(gameItem.data.count).toNumber();
    };

    const isCertificationNumberRequired = !!elements.get('reholder');

    const certificationNumberValidation = isCertificationNumberRequired ? {
      isCertificationNumber: true,
    } : {};

    const makeShipEstimate = (days,flavor) => {
      return (
        <p className="choice-option__info-text">
          <small>{t('Turnaround ')}<b>{t('estimate')}</b></small><br />
          <b>{days}</b>{t(' business days')}
          <span>{t(flavor)}</span>
        </p>
      );
    };

    return (
      <div className="add-ons">
        <Helmet
          title={`${t('Submit Here')} - ${t('Add-ons')}`}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />
        <h1 className="section__title">
          {t('Choose options & add-ons')}:
        </h1>
        <Formsy
          className="container-fluid"
          ref={(component) => {
            store.updateRefToForm(component);
          }}
          onValidSubmit={this.onValidSubmit}>
          <div className="row add-ons__row">
            <div className="col-xs-12 col-md-5 submit-game__item">
              <div className="select-game__label">
                {t('Service Level')}:
              </div>
              <ul className="choice-option choice-option--content">
                <li className="choice-option__item">
                  <div className="choice-option__item-container">
                    <Radio
                      id="serviceLevel_turbo"
                      checked={elements.get('serviceLevel') === 1}
                      value="1"
                      disabled={!gameItem.serviceLevelPrices[1].enabled}
                      updateStateData={store.updateFormItem}
                      className="choice-option__radio"
                      isIndicatorIcon={false}
                      name="serviceLevel"
                    />
                    <label
                      htmlFor="serviceLevel_turbo"
                      className={classnames(
                        'choice-option__content',
                        {
                          'checked': elements.get('serviceLevel') === 1,
                          'disabled': !gameItem.serviceLevelPrices[1].enabled,
                        },
                      )}>
                      <span className="choice-option__icon icon-sl-turbo" />
                      <p className="choice-option__text">
                        {t('Turbo')}
                      </p>
                    </label>
                    <label
                      htmlFor="serviceLevel_turbo"
                      className={classnames(
                        'choice-option__info',
                        {
                          'checked': elements.get('serviceLevel') === 1,
                          'disabled': !gameItem.serviceLevelPrices[1].enabled,
                        },
                      )}>
                      {makeShipEstimate(serviceLevels[1].turnaroundEstimate,serviceLevels[1].shortDescription)}
                      <p className="choice-option__info-price choice-option__info-price-block">
                        {
                          serviceLevelPrice(
                            gameItem.serviceLevelPrices[1].enabled,
                            `$${round(gameItem.serviceLevelPrices[1].price).toFixed(2)}`
                          )
                        }
                      </p>
                    </label>
                  </div>
                </li>
                <li className="choice-option__item">
                  <div className="choice-option__item-container">
                    <Radio
                      id="serviceLevel_speedrun"
                      checked={elements.get('serviceLevel') === 2}
                      value="2"
                      disabled={!gameItem.serviceLevelPrices[2].enabled}
                      updateStateData={store.updateFormItem}
                      className="choice-option__radio"
                      isIndicatorIcon={false}
                      name="serviceLevel"
                    />
                    <label
                      htmlFor="serviceLevel_speedrun"
                      className={classnames(
                        'choice-option__content',
                        {
                          'checked': elements.get('serviceLevel') === 2,
                          'disabled': !gameItem.serviceLevelPrices[2].enabled,
                        },
                      )}>
                      <span className="choice-option__icon icon-sl-speedrun" />
                      <p className="choice-option__text">
                        Speed-Run
                      </p>
                    </label>
                    <label
                      htmlFor="serviceLevel_speedrun"
                      className={classnames(
                        'choice-option__info',
                        {
                          'checked': elements.get('serviceLevel') === 2,
                          'disabled': !gameItem.serviceLevelPrices[2].enabled,
                        },
                      )}>
                      {makeShipEstimate(serviceLevels[2].turnaroundEstimate,serviceLevels[2].shortDescription)}
                      <p className="choice-option__info-price-block">
                        <span className="choice-option__info-price">
                          {
                            serviceLevelPrice(
                              gameItem.serviceLevelPrices[2].enabled,
                              `$${round(gameItem.serviceLevelPrices[2].price).toFixed(2)}`
                            )
                          }
                        </span>
                      </p>
                    </label>
                  </div>
                </li>
                <li className="choice-option__item">
                  <div className="choice-option__item-container">
                    <Radio
                      id="serviceLevel_warpzone"
                      checked={elements.get('serviceLevel') === 3}
                      value="3"
                      disabled={!gameItem.serviceLevelPrices[3].enabled}
                      updateStateData={store.updateFormItem}
                      className="choice-option__radio"
                      isIndicatorIcon={false}
                      name="serviceLevel" />
                    <label
                      htmlFor="serviceLevel_warpzone"
                      className={classnames(
                        'choice-option__content',
                        {
                          'checked': elements.get('serviceLevel') === 3,
                          'disabled': !gameItem.serviceLevelPrices[3].enabled,
                        },
                      )}>
                      <span className="choice-option__icon icon-sl-warpzone" />
                      <p className="choice-option__text">
                        Warp-Zone
                      </p>
                    </label>
                    <label
                      htmlFor="serviceLevel_warpzone"
                      className={classnames(
                        'choice-option__info',
                        {
                          'checked': elements.get('serviceLevel') === 3,
                          'disabled': !gameItem.serviceLevelPrices[3].enabled,
                        },
                      )}>
                      {makeShipEstimate(serviceLevels[3].turnaroundEstimate,serviceLevels[3].shortDescription)}
                      <p className="choice-option__info-price choice-option__info-price-block">
                        {
                          serviceLevelPrice(
                            gameItem.serviceLevelPrices[3].enabled,
                            `$${round(gameItem.serviceLevelPrices[3].price).toFixed(2)}`
                          )
                        }
                      </p>
                    </label>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-xs-12 col-md-offset-1 col-md-6 submit-game__item add-ons__services">
              <div className="select-game__label">
                {t('Add-On Services')}:
              </div>
              <ul className="add-ons__list">
                <li className="add-ons__item">
                  <Tooltip {...tooltipConfig} html={<div>{ttAutographAuth}</div>}>
                    <div className="add-ons__service-info">
                      <span
                        className="add-ons__service-icon icon-process-qc"
                        aria-hidden="true"
                      />
                      <Checkbox
                        name="autographAuth"
                        checked={elements.get('autographAuth')}
                        disabled={elements.get('reholder')}
                        id="autographAuth"
                        checkboxClassName="form-checkbox--black"
                        updateStateData={store.updateFormItem} />
                      <label
                        htmlFor="autographAuth"
                        className="add-ons__service-title">
                        PSA/DNA Autograph Authentication (additional estimated 3-5 business days)
                      </label>
                    </div>
                  </Tooltip>
                  <p className="add-ons__service-price">
                    ${round(prices.addOns.autographAuth).toFixed(2)}
                  </p>
                </li>

                <li className="add-ons__item">
                  <Tooltip {...tooltipConfig} html={<div>{ttLightCleaning}</div>}>
                    <div className="add-ons__service-info">
                      <span className="add-ons__service-icon icon-cleaning" />
                      <Checkbox
                        name="cleaning"
                        checked={elements.get('cleaning')}
                        id="cleaning"
                        disabled={elements.get('reholder')}
                        checkboxClassName="form-checkbox--black"
                        updateStateData={store.updateFormItem} />
                      <label
                        htmlFor="cleaning"
                        className="add-ons__service-title">
                        {t('Light Cleaning')}
                      </label>
                    </div>
                  </Tooltip>
                  <p className="add-ons__service-price">
                    {
                      gameItem.data.gameState === 1 ? (
                        `$${round(prices.serviceLevels.cib.addOns.cleaning).toFixed(2)}`
                      ) : (
                        `$${round(prices.serviceLevels.sealedLoose.addOns.cleaning).toFixed(2)}`                      )
                    }
                  </p>
                </li>

                <li className="add-ons__item">
                  <Tooltip {...tooltipConfig} html={<div>{ttReholder}</div>}>
                    <div className="add-ons__service-info">
                      <span className="add-ons__service-icon icon-add-reholder" />
                      <Checkbox
                        name="reholder"
                        checked={elements.get('reholder')}
                        id="reholder"
                        // disabled={true}
                        checkboxClassName="form-checkbox--black"
                        updateStateData={store.updateFormItem}
                      />
                      <label
                        htmlFor="reholder"
                        className="add-ons__service-title">
                        {t('Reholder')}
                      </label>
                    </div>
                  </Tooltip>
                  <p className="add-ons__service-price">
                    ${round(prices.addOns.reholder).toFixed(2)}
                  </p>
                </li>

                <li className="add-ons__item">
                  <Tooltip {...tooltipConfig} html={<div>{ttPhotoServices}</div>}>
                    <div className="add-ons__service-info">
                      <span className="add-ons__service-icon icon-camera" />
                      <Checkbox
                        name="photoServices"
                        checked={elements.get('photoServices')}
                        disabled={(!!elements.get('reholder'))}
                        id="photoServices"
                        checkboxClassName="form-checkbox--black"
                        updateStateData={store.updateFormItem} />
                      <label
                        htmlFor="photoServices"
                        className="add-ons__service-title">
                        {t('Post-Grade Photos')}
                      </label>
                    </div>
                  </Tooltip>
                  <p className="add-ons__service-price">
                    ${round(prices.addOns.photoServices).toFixed(2)}
                  </p>
                </li>

                <li className="add-ons__item">
                  <Tooltip {...tooltipConfig} html={<div>{ttCibPlus}</div>}>
                    <div className="add-ons__service-info">
                      <span className="add-ons__service-icon icon-cib" />
                      <Checkbox
                        name="cibPlus"
                        checked={elements.get('cibPlus')}
                        disabled={(gameItem.data.gameState != 1 || !!elements.get('reholder'))}
                        id="cibPlus"
                        checkboxClassName="form-checkbox--black"
                        updateStateData={store.updateFormItem} />
                      <label
                        htmlFor="cibPlus"
                        className="add-ons__service-title">
                        CIB Plus
                      </label>
                    </div>
                  </Tooltip>
                  <p className="add-ons__service-price">
                    ${round(prices.addOns.cibPlus).toFixed(2)}
                  </p>
                </li>

                <li className="add-ons__item">
                  <Tooltip {...tooltipConfig} html={<div>{ttStickerRemoval}</div>}>
                    <div className="add-ons__service-info">
                      <span className="add-ons__service-icon icon-cleaning" />
                      <Checkbox
                        name="stickerRemoval"
                        checked={elements.get('stickerRemoval')}
                        disabled={gameItem.data.gameState != 0 || (!!elements.get('reholder'))}
                        id="stickerRemoval"
                        checkboxClassName="form-checkbox--black"
                        updateStateData={store.updateFormItem} />
                      <label
                        htmlFor="stickerRemoval"
                        className="add-ons__service-title">
                        Sticker Removal
                      </label>
                    </div>
                  </Tooltip>
                  <p className="add-ons__service-price">
                    ${round(prices.addOns.stickerRemoval).toFixed(2)}
                  </p>
                </li>

                <li className="add-ons__item">
                  <Tooltip {...tooltipConfig} html={<div>{ttGenDesignation}</div>}>
                    <div className="add-ons__service-info">
                      <span className="add-ons__service-icon icon-process-grade" />
                      <Checkbox
                        name="genDesignation"
                        checked={elements.get('genDesignation')}
                        disabled={(!!elements.get('reholder'))}
                        id="genDesignation"
                        checkboxClassName="form-checkbox--black"
                        updateStateData={store.updateFormItem} />
                      <label
                        htmlFor="genDesignation"
                        className="add-ons__service-title">
                        Genuine “GEN” Designation
                      </label>
                    </div>
                  </Tooltip>
                  <p className="add-ons__service-price">
                    ${round(prices.addOns.genDesignation).toFixed(2)}
                  </p>
                </li>

                {false &&
                <li className="add-ons__item">
                  <Tooltip {...tooltipConfig} html={<div>{ttLegacyHolder}</div>}>
                    <div className="add-ons__service-info">
                      <span className="add-ons__service-icon icon-case-3d" />
                      <Checkbox
                        name="legacyHolder"
                        checked={elements.get('legacyHolder')}
                        disabled={(!!elements.get('reholder'))}
                        id="legacyHolder"
                        checkboxClassName="form-checkbox--black"
                        updateStateData={store.updateFormItem} />
                      <label
                        htmlFor="legacyHolder"
                        className="add-ons__service-title">
                        WATA Legacy Holder
                      </label>
                    </div>
                  </Tooltip>
                  <p className="add-ons__service-price">
                    ${round(prices.addOns.legacyHolder).toFixed(2)}
                  </p>
                </li>}
              </ul>
              {
                isCertificationNumberRequired && (
                  <Input
                    id="certification_number"
                    name="certificationNumber"
                    type="text"
                    placeholder={t('Certification Number')}
                    value={elements.get('certificationNumber')}
                    updateStateData={store.updateFormItem}
                    required={isCertificationNumberRequired}
                    validations={certificationNumberValidation}
                    validationErrors={{
                      isCertificationNumber: err.isCertificationNumber,
                      isDefaultRequiredValue: err.isDefaultRequiredValue,
                    }}
                  />
                )
              }
              {
                (!!elements.get('stickerRemoval') || !!elements.get('autographAuth')) && (
                  <div className="form-field form-field--checkbox">
                    <label className="form-checkbox form-checkbox--black">
                      <input
                        type="checkbox"
                        name="agreement"
                        checked={this.state.isAgree}
                        onChange={this.onCheck}
                        id="review_agreement"
                      />
                      <i className="icon-check" />
                    </label>
                    <div htmlFor="review_agreement" className="form-checkbox__label">
                      <p className="shipping-form__save">
                        I have reviewed WATA’s {" "}
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to={URL.faq.link}
                        >
                          FAQs
                        </Link>
                        , and I acknowledge and understand that there are inherent risks associated with the Sticker Removal and the PSA/DNA Autograph Authentication add-on services (collectively, the "add-on services"), including that an add-on service may damage games or uncover pre-existing damage, which can possibly lead to grade reductions. Nonetheless, I accept and voluntarily assume all risks associated with my decision to proceed with such add-on(s). In addition to agreeing to the{" "}
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to={URL.services.link}
                        >
                          WATA Services Agreement
                        </Link>
                        {" "}(the "WATA terms") and, if I select the PSA/DNA Autograph Authentication add-on as part of the services for my item, the PSA grading terms & conditions included within the{" "}
                        <a
                            target="_blank"
                            href="https://www.psacard.com/Content/downloads/PSADNA-Autograph-Submission-Form.pdf"
                        >
                            Autograph Submission Agreement
                        </a>
                        {" "}(the "DNA terms"), by clicking “Add to Queue,” I agree to release from liability and waive my right to sue WATA, Collectors Universe, Inc. and their respective employees, officers, agents, and affiliates from any and all claims, including claims for breach of contract, negligence, physical damage to a game, or economic loss, which may arise during or result from any applicable add-on service.  I also acknowledge that all turnaround times are estimated and are not guaranteed and not a basis of liability by Collectors Universe, Inc. and its entities. To the extent there is loss or damage to an item and if the PSA/DNA Autograph Authentication add-on service is selected, the WATA terms shall govern and control in the event of an express conflict with the DNA terms.
                      </p>
                    </div>
                  </div>
                )
              }

              <div className="add-ons__box box">
                <div className="add-ons__box-header add-ons__box-item">
                  <div className="select-game__label">
                    {t('Declared Value')}:
                  </div>
                  <span className="add-ons__declared-price">
                    ( ${declaredValue} )
                  </span>
                </div>

                <ul className="price-list">
                  <li className="price-list__item">

                    <span className="price-list__label">{t('Quantity')}</span>

                    <div className="price-list__info">
                      <span className="price-list__price">
                        &times;{gameItem.data.count}
                      </span>
                    </div>

                  </li>
                  <li className="price-list__item">

                    <span className="price-list__label">
                      {t('Service Level')}: ‘{serviceLevels[elements.get('serviceLevel')].name}’
                    </span>

                    <div className="price-list__info">
                      <span className="price-list__price">
                        ${round(serviceLevelSubmissionPrice).toFixed(2)}
                      </span>
                    </div>

                  </li>
                  <li className="price-list__item">

                    <span className="price-list__label price-list__label--border">
                      {t('Add-ons')}
                    </span>

                    <div className="price-list__info">
                      <span className="price-list__price price-list__price--border">
                        +${round(
                          elements.get('reholder')
                            ? prices.addOns.reholder
                            : addOnsPrice()
                        ).toFixed(2)}
                      </span>
                    </div>

                  </li>
                </ul>

                <ul className="price-list price-list--total price-list--no-margin">
                  <li className="price-list__item">
                    <span className="price-list__label price-list__label--total">
                      {t('Media Total')}:
                    </span>
                    <div className="price-list__info">
                      <span className="price-list__price price-list__price--total price-list__price--pink">
                        ${round(totalPrice()).toFixed(2)}
                      </span>
                    </div>
                  </li>
                </ul>

              </div>

            </div>
          </div>
          <div className="submit-game__footer horizontal-space-between">
            <button
              type="button"
              onClick={this.goBack}
              className="btn btn--high btn--lg btn--black btn--icon-left">
              <span className="btn__text">
                {t('Back')}
                <i className="btn__icon icon-pix-arrow-lt" />
              </span>
            </button>
            <button
              type="submit"
              disabled={(!!elements.get('stickerRemoval') || !!elements.get('autographAuth')) && !this.state.isAgree}
              className="btn btn--high btn--lg btn--black btn--icon-right">
              <span className="btn__text">
                {t('Add to queue')}
                <i className="btn__icon icon-pix-arrow-rt" />
              </span>
            </button>
          </div>
        </Formsy>
      </div>
    );
  }
}


const WrappedAddOnsForm = inject(
  'router',
  'appStore',
  'submitGameStore',
  'queueStore'
)(observer(AddOnsForm));
const TranslateAddOnsForm = translate()(WrappedAddOnsForm);
TranslateAddOnsForm.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateAddOnsForm;
