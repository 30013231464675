import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  translate,
} from 'react-i18next';

import {inject, observer} from 'mobx-react';

const wrappedPropTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  status: PropTypes.string.isRequired,
}


class OrderAddOn extends React.Component {
  render() {
    const {
      status,
      title,
      className,
    } = this.props;

    return (
      <div className={
        classnames(
          'order-item order-photos-list',
          className
        )}
      >
      
        <div className="order-item__header order-photos__header">
          <b>{title}</b>
          {status}
        </div>
      </div>
    );
  }
}

const WrappedOrderAddOn = inject('orderDetailsStore')(observer(OrderAddOn));
const TranslateOrderAddOn = translate()(WrappedOrderAddOn);
TranslateOrderAddOn.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateOrderAddOn;