import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { translate } from "react-i18next";

import { inject, observer } from "mobx-react";

import Helpers from "../../../../../utils/Helpers";

import { apiURLs } from "../../../../../configs/apiURLs";
import { baseAPIurl } from "../../../../../constants/common";
import { gradeLabels } from "../../../../../constants/grades";
import {
  gameStates,
  attachmentSets,
  gradeLists,
} from "../../../../../constants/collectibleEnumsAndDefs";
import { displayTypes } from "../../../../../constants/displayTypes";

import OrderPhotos from "../OrderPhotos/OrderPhotos";
import OrderAddOn from "../OrderAddOn/OrderAddOn";
import OrderReport from "../OrderReport/OrderReport";
import OrderInserts from "../OrderInserts/OrderInserts";

const wrappedPropTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
};

class LabelDetails extends React.Component {
  render() {
    const { t, className, collectible, orderId, itemId, order } = this.props;

    const boxItem = false;
    //orderId = itemId = "needed for report";

    const dtFromKeyLabelAndDd = (key, label, dd) => {
      return dd.length > 0 ? (
        <div key={collectible.label + "-" + key} style={{ clear: "left" }}>
          <dt
            style={{
              fontSize: "80%",
              float: "left",
              marginRight: "1rem",
              width: "6rem",
            }}
          >
            {label}
          </dt>
          <dd>{dd}</dd>
        </div>
      ) : (
        ""
      );
    };
    const gameStateDl = (def) => {
      var stateName = !!def && def.length > 0 ? def[0].name : "";
      return dtFromKeyLabelAndDd("gameState", "State", stateName);
    };

    const gradeBreakdown = gradeLabels
      .filter((value) => Helpers.notNil(collectible.grade[value.jsonKey]))
      .map((gradeKeys) =>
        dtFromKeyLabelAndDd(
          "grade-" + gradeKeys.jsonKey,
          t(gradeKeys.translationId),
          collectible.grade[gradeKeys.jsonKey]
        )
      );

    // TODO also reference order.data.turboStatus or whatever service level applies
    const showGradeNumbers =
      order.data.status > 6 ||
      (collectible.status > 3 && collectible.grade.overallGrade);

    const gameStateDef = gameStates.filter(
      (value) => value.id === collectible.gameState
    );

    gradeBreakdown.unshift(gameStateDl(gameStateDef));

    if (collectible.displayType) {
      const displayTypeDef = displayTypes.filter(
        (value) => value.id === collectible.displayType
      );
      //TODO link to case options for those that are not familiar or at least use displayTypeDef[0].icon
      gradeBreakdown.push(
        dtFromKeyLabelAndDd("case-style", "WATA label", displayTypeDef[0].name)
      );
    }

    const makeDetailsBlock = (bootstrapCols, iteratorKey, content) => (
      <div
        key={collectible.label + "-" + iteratorKey}
        className={bootstrapCols + " order-details__block p-1"}
      >
        {content}
      </div>
    );

    const reactivePhotoAreas = "col-xs-12 col-xl-6";
    const labelPhotos = attachmentSets
      .filter((attachmentSetsDef) =>
        collectible.attachments.some(
          (i) => i.attachmentTypeId === attachmentSetsDef.attachmentTypeId
        )
      )
      .sort((a, b) => b.attachmentTypeId - a.attachmentTypeId)
      .map((attachmentSetsDef) =>
        makeDetailsBlock(
          reactivePhotoAreas,
          attachmentSetsDef.translationId,
          <OrderPhotos
            title={t(
              "attachments." + attachmentSetsDef.translationId + ".Title"
            )}
            alt={t("attachments." + attachmentSetsDef.translationId + ".Desc")}
            data={collectible.attachments.filter(
              (i) => i.attachmentTypeId === attachmentSetsDef.attachmentTypeId
            )}
          />
        )
      );

    const labelInfoLists = gradeLists
      .filter(
        (infoListDef) =>
          collectible.grade.hasOwnProperty(infoListDef.jsonKey) &&
          collectible.grade[infoListDef.jsonKey].length > 0
      )
      .map((infoListDef) =>
        makeDetailsBlock(
          infoListDef.suggestedCols,
          infoListDef.jsonKey,
          <OrderInserts
            title={t("Grade." + infoListDef.translationId + ".Title")}
            data={collectible.grade[infoListDef.jsonKey]}
          />
        )
      );

    return (
      <article>
        <div className={classnames("order-item order-item--margin", className)}>
          <input
            id={"order-item_" + collectible.label}
            type="checkbox"
            className="order-item__toggle"
          />
          <label
            className="order-item__header"
            htmlFor={"order-item_" + collectible.label}
          >
            <div className="order-item__info" style={{ width: "100%" }}>
              <span className="order-item__toggle-status" />
              <p
                className="order-item__number"
                style={{
                  width: "calc(100% - 40px)",
                  "justify-content": "space-between",
                }}
              >
                <span className="order-item__order">
                  {t("Certification")} #<b>{collectible.label}</b>
                </span>
                <span
                  className="order-item__order"
                  style={{
                    "flex-grow": "1",
                    "margin-left": "20px",
                    "max-width": "100%",
                    color: "white",
                  }}
                >
                  {collectible.game.platforms}
                  <b>{collectible.game.name}</b>
                </span>
                <span
                  className="order-item__order"
                  style={{ "font-size": "30px" }}
                >
                  <b>{showGradeNumbers && collectible.grade.overallGrade}</b>
                </span>
              </p>
            </div>
          </label>
          <div className="order-item__content-wrapper">
            <div className="order-item__content">
              <div className="row">
                <div className="col-xs-12 order-details__block">
                  <h4>{t("Grade details")}</h4>
                  <dl>{showGradeNumbers && gradeBreakdown}</dl>
                </div>
                {labelInfoLists}
                {labelPhotos}
                <div className="col-xs-12 col-sm-6 col-md-4 order-details__block">
                  {boxItem && collectible.cleaning && (
                    <OrderAddOn
                      className="order-item--margin"
                      title={t("Gentle Cleaning")}
                      status={collectible.cleaningStatus}
                    />
                  )}
                  {boxItem && boxItem.data.heavyCleaning && (
                    <OrderAddOn
                      className="order-item--margin"
                      title={t("Heavy Cleaning")}
                      status={collectible.heavyCleaningStatus}
                    />
                  )}
                  {boxItem && boxItem.data.testing && (
                    <OrderAddOn
                      className="order-item--margin"
                      title={t("Testing")}
                      status={collectible.testingStatus}
                    />
                  )}
                  {boxItem && boxItem.data.reholder && (
                    <OrderAddOn
                      className="order-item--margin"
                      title={t("Reholder")}
                      status={collectible.reholderStatus}
                    />
                  )}
                  {boxItem && boxItem.data.cibPlus && (
                    <OrderAddOn
                      className="order-item--margin"
                      title="CIB Plus"
                      status={collectible.cibPlusStatus}
                    />
                  )}
                  {boxItem && boxItem.data.legacyHolder && (
                    <OrderAddOn
                      className="order-item--margin"
                      title="Legacy Holder"
                      status={collectible.legacyHolderStatus}
                    />
                  )}
                  {boxItem && boxItem.data.stickerRemoval && (
                    <OrderAddOn
                      className="order-item--margin"
                      title="Sticker Removal"
                      status={collectible.stickerRemovalStatus}
                    />
                  )}
                  {boxItem && boxItem.data.autographAuth && (
                    <OrderAddOn
                      className="order-item--margin"
                      title="CIB Plus"
                      status={collectible.autographAuthStatus}
                    />
                  )}
                  {collectible && collectible.graderReport && (
                    <OrderReport
                      className="order-item--margin"
                      status={true}
                      downloadLink={
                        baseAPIurl() + apiURLs.order.report(orderId, itemId)
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

const WrappedLabelDetails = inject("orderDetailsStore")(observer(LabelDetails));
const TranslateLabelDetails = translate()(WrappedLabelDetails);
TranslateLabelDetails.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateLabelDetails;
