import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import { routeURLs as URL } from '../../../configs/routeURLs';

const propTypes = {
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string.isRequired,
  linkClassName: PropTypes.string,
  imageClassName: PropTypes.string,
};

const defaultProps = {
  isSquare: false,
};

const Logo = (props) => {
  return (
    <Link to={URL.home.link} className={props.linkClassName}>
      <img
        src={`${process.env.PUBLIC_URL}/images/${props.src}`}
        srcSet={`${process.env.PUBLIC_URL}/images/${props.srcSet} 2x`}
        alt="WataGames"
        className={props.imageClassName} />
    </Link>
  );
}

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;


export default Logo;
