import React from "react";

import "./styles/Collectors.css";

class Collectors extends React.Component {
  render() {
    return (
      <div className="collectors section section--top">
        <div className="container-fluid">
          <h1 className="collectors__title">Collectors. Experts. Gamers.</h1>
          <div className="editable__content">
            <p>
            WATA is the industry leading video game and media authentication and grading company built by collectors, for collectors. We grade Sealed and Complete-In-Box (CIB) media for most consoles and platforms, and cartridges for NES and Super NES. We help you preserve your most important gaming and media memories, recognizing them for the art and history that they are. Whether you’re looking to continue building your high value media collection or are just getting started, we can help. Founded and operated by experts and collectors in the collectible industry, WATA Games offers the true confidence in collecting video games and media that is recognized and respected by serious collectors and gamers alike.
            </p>
          </div>
          <div className="collectors__img editable__content">
            <img
              src={`${process.env.PUBLIC_URL}/images/wata-timeline-mario.png`}
              alt="img"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Collectors;
