import React from 'react';
import ReactDOM from 'react-dom';

import createBrowserHistory from 'history/createBrowserHistory';
import { syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router';

import { Provider } from 'mobx-react';
import { unregister } from './registerServiceWorker';

import ScrollToTop from './components/shared/routes/ScrollToTop';

import App from './components/App/App';

import appStore from './stores/AppStore';
import modalsStore from './stores/ModalsStore';
import submitGameStore from './stores/SubmitGameStore';
import queueStore from './stores/QueueStore';
import summaryOrderStore from './stores/SummaryOrderStore';
import cartStore from './stores/CartStore';
import savedGamesStore from './stores/SavedGamesStore';
import openOrdersStore from './stores/OpenOrdersStore';
import completedOrdersStore from './stores/CompletedOrdersStore';
import orderDetailsStore from './stores/OrderDetailsStore';
import { RoutingStore } from './stores/RoutingStore';
import certDetailsStore from './stores/CertDetailsStore';

import './i18n';

const browserHistory = createBrowserHistory();

const appStores = {
  appStore,
  modalsStore,
  submitGameStore,
  queueStore,
  cartStore,
  savedGamesStore,
  summaryOrderStore,
  openOrdersStore,
  completedOrdersStore,
  orderDetailsStore,
  router: RoutingStore,
  certDetailsStore,
};

const history = syncHistoryWithStore(browserHistory, RoutingStore);

ReactDOM.render(
  <Provider {...appStores}>
    <Router history={history}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// registerServiceWorker();
unregister();
