export const newShipping = [
  {
    games: 1,
    "230": 19.99,
    "39": 59.99,
    other: 69.99,
  },
  {
    games: 2,
    "230": 24.99,
    "39": 69.99,
    other: 79.99,
  },
  {
    games: 3,
    "230": 39.99,
    "39": 89.99,
    other: 99.99,
  },
  {
    games: 7,
    "230": 59.99,
    "39": 109.99,
    other: 139.99,
  },
  {
    games: 11,
    "230": 89.99,
    "39": 139.99,
    other: 169.99,
  },
  {
    games: 16,
    "230": 99.99,
    "39": 169.99,
    other: 199.99,
  },
];

export const shippingMaxGames = 20;
export const perGameCost = {
  "230": 2.99,
  "39": 4.99,
  other: 6.99,
};
