import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { inject, observer } from 'mobx-react';
import ReactAlert from 'react-s-alert';

import { translate } from 'react-i18next';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import JoinForm from './JoinForm/JoinForm';
import Members from './Members/Members';
import UsThem from './UsThem/UsThem';

import withTracker from '../shared/AnalyticsTracker/withTracker';
import API from '../../utils/API';
import Logger from '../../utils/Logger';
import { apiURLs } from '../../configs/apiURLs';
import { alertConfig } from '../../constants/common';

import './styles/Join.css';


const propTypes = {
  t: PropTypes.func.isRequired,
};

const TITLE = 'Join';


class JoinView extends React.Component {
  state = {
    review: [],
  };

  componentDidMount() {
    this.fetchReviewData();
  }

  fetchReviewData = async () => {
    const {appStore} = this.props;
    appStore.updateProgressData();
    try {
      const response = await API.getData(apiURLs.cms.any('joinUs'));

      this.setState({
        review: response.data.value.review,
      });

      appStore.updateProgressData(false);

    } catch (error) {
      Logger.error(error);
      ReactAlert.error(error.message, alertConfig);
      appStore.updateProgressData(false);
    }
  }

  render() {
    const {t} = this.props;

    return (
      <div className="page-wrapper">

        <Helmet
          title={t(TITLE)}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />

        <Header />

        <main className="main">
          <JoinForm />
          <Members
            data={this.state.review}
          />
          <UsThem />
        </main>

        <Footer />
      </div>
    );
  }
}


JoinView.propTypes = propTypes;

const WrappedJoinView = inject(
  'appStore',
)(observer(JoinView));

const TranslateJoinView = translate()(WrappedJoinView);

export default withTracker(TranslateJoinView, {title: TITLE});
