import React from "react";
import Helmet from "react-helmet";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import "./styles/Services.css";

import withTracker from "../shared/AnalyticsTracker/withTracker";

class Services extends React.Component {
  render() {
    return (
      <div className="page-wrapper">
        <Helmet
          title="WATA Services Agreement"
          meta={[
            {
              name: "description",
              content: "",
            },
          ]}
        />

        <Header className="header--gradient-btp" />

        <main className="main">
          <div className="legal info-page">
            <div className="container-fluid">
              <div className="row-fluid">
                <div className="legal__wrapper info-page__wrapper section section--top section--gradient section--btp">
                  <div className="legal__content info-page__content">
                    <h2 className="section__title">WATA SERVICES AGREEMENT</h2>

                    <p className="legal__text">
                      Wata, Inc. (dba WATA Games), a Colorado corporation ("
                      <b>Wata</b>"), agrees to provide the Services (as defined
                      below) to you on the terms and conditions set forth in
                      this WATA Services Agreement (this <b>"Agreement"</b>) so
                      long as you accept this Agreement by selecting the
                      “Accept” button at the bottom of this page. Please read
                      this Agreement carefully before selecting the “Accept” or
                      “Decline” button. By selecting the “Accept” button, you
                      acknowledge that (1) you have read and understand all of
                      the terms and conditions of this Agreement; (2) you agree
                      to be bound by all of the terms and conditions of this
                      Agreement; and (3) if you are acting on behalf of an
                      entity, you have full authority to bind your entity to all
                      of the terms and conditions of this Agreement and by
                      clicking “Accept” you do so bind your entity to this
                      agreement. If you are not willing to be bound by all of
                      the terms and conditions of this Agreement, select the
                      ‘Decline’ button at the bottom of this page and WATA will
                      not provide the Services to you and your credit card will
                      not be charged.
                    </p>
                    <ol>
                      <li>
                        <p className="legal__text">
                          <b>Services.</b> WATA will use its proprietary grading
                          system and techniques to provide the services set
                          forth below (collectively, the &ldquo;<b>Services</b>
                          &rdquo;).
                        </p>
                        <ol>
                          <li>
                            <p className="legal__text">
                              <b>Contents</b>. When your video game arrives at
                              Wata&rsquo;s facility, WATA will open the
                              packaging and compare the contents against the
                              general description of the items that you listed
                              and paid for when you submitted your order (e.g.,
                              number of games, game title, game system, etc.).
                              If WATA believes any objects were damaged during
                              transit, then WATA will notify you and ask whether
                              you would like WATA to return the objects to you
                              or perform the Services regardless of the damage.
                              If WATA believes any objects are not in a
                              condition that allows WATA to perform the
                              Services, then WATA will notify you, and in
                              Wata&rsquo;s discretion, WATA may return the
                              objects to you without performing the Services. If
                              WATA discovers, at any time while performing the
                              Services, any discrepancies between your order and
                              the contents received, then WATA will either
                              return the items that were not properly sent or
                              contact you to resolve the discrepancies.
                            </p>
                          </li>
                          <li>
                            <p className="legal__text">
                              <b>Insurance</b>. During Wata&rsquo;s review of
                              your games, WATA will determine whether the
                              insured value that you selected is sufficient to
                              cover Wata&rsquo;s estimation of the value of the
                              games. In rare instances, WATA may determine that
                              a greater insured value is necessary, and if so,
                              then WATA will contact you to explain the increase
                              in insured value and obtain your authorization to
                              charge your credit card for the additional fees
                              required based on the increased insured value.
                            </p>
                          </li>
                          <li>
                            <p className="legal__text">
                              <b>Grading</b>. WATA will analyze each of your
                              video games using Wata&rsquo;s proprietary
                              methodology and rating system and perform any
                              additional services you have requested and paid
                              for, such as cleaning. Once Wata&rsquo;s analysis
                              and other services are complete, WATA will then
                              encase each video game in Wata&rsquo;s
                              proprietary, tamper-evident case. WATA will return
                              each encased game to you at the address you
                              provided in your order.{" "}
                            </p>
                          </li>
                          <li>
                            <p className="legal__text">
                              <b>Photos</b>. While your video game is in
                              Wata&rsquo;s possession, WATA may photograph the
                              game at different stages of Wata&rsquo;s grading
                              process. You hereby irrevocably assign to WATA all
                              ownership rights in any photographs or other
                              recordings WATA may make of your video games and
                              any other work product WATA may create in relation
                              to your video games. You understand and agree that
                              WATA may use, display and disseminate Wata&rsquo;s
                              photographs and other information that identifies
                              or describes your games (but in all cases
                              anonymized to exclude, wherever applicable, your
                              personal information, such as your name or
                              address) online on Wata&rsquo;s website or other
                              websites, through social media, in private or
                              public databases, in articles or other promotional
                              materials or advertisements, and in any other
                              medium or for any other purpose.{" "}
                            </p>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <p className="legal__text">
                          <b>Fees and Insurance</b>. In consideration of the
                          Services, you agree to pay the following amounts to
                          WATA (collectively, the &ldquo;<b>Fees</b>&rdquo;).
                        </p>
                        <ol>
                          <li>
                            <p className="legal__text">
                              When you submit your order to Wata, your credit
                              card will be charged in the amounts displayed on
                              your screen, including, without limitation, fees
                              for the Services, insurance costs, and shipping
                              and handling expenses. The amount that WATA
                              charges for each video game depends in part on the
                              insured value that you estimate for the game. If,
                              as occurs in rare instances, WATA determines that
                              your video game has a higher value than your
                              original estimate, then you agree to pay the
                              applicable increase in fees upon Wata&rsquo;s
                              request. If you do not pay the additional fees (or
                              if your video games are not in a condition that
                              allows WATA to perform the Services), then WATA
                              will not perform the Services and will return your
                              video game to you and refund any applicable
                              payments, minus Wata&rsquo;s standard order
                              rejection fees, insurance costs, and shipping and
                              handling expenses.
                            </p>
                          </li>
                          <li>
                            <p className="legal__text">
                              If any of your video games are damaged, lost or
                              destroyed at any point after WATA receives the
                              games, while WATA is performing the Services, or
                              during transit from Wata&rsquo;s facility back to
                              you, your sole remedy is, and Wata&rsquo;s maximum
                              liability will be equal to and not exceed under
                              any circumstances, the lesser of the (a) insured
                              value of each game and (b) the fair-market value
                              replacement cost of each game. You select the
                              insured value of each game when you send the game
                              to Wata, and the amount you select is your
                              responsibility. The insurance you purchase for
                              your games covers the games while they are in
                              Wata&rsquo;s possession, and during shipment back
                              to the address you provide in your order. If WATA
                              determines a higher insured value is necessary,
                              then WATA will charge your credit card for the
                              additional fees required (as described above) and
                              will obtain insurance coverage in the appropriate
                              amounts for each of your games while the games are
                              in Wata&rsquo;s possession and while the games are
                              transported back to you.
                            </p>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <p className="legal__text">
                          <b>Limited Warranty</b>. WATA provides a limited
                          warranty on the Services and the proprietary,
                          tamper-evident case covering product defects or
                          workmanship defects for a period of one year after the
                          date WATA completes the Services. If a workmanship or
                          product defect is revealed during the warranty period
                          in the tamper-evident case that you receive, then WATA
                          will encase the video game in a new case, free of
                          charge, after you return the original case to WATA
                          with the video game still inside the case; provided,
                          that Wata&rsquo;s limited warranty is voided in full
                          if the game was removed from the case at any time or
                          if the damage to the case arose from the way the case
                          was handled or stored. Handling encased video games
                          with care and storing them properly in a dark,
                          immobile and temperature- and humidity-controlled
                          location is necessary to maintain the condition of the
                          cases and the games. Exposing an encased game to
                          extreme temperatures or other environmental factors
                          (such as moisture, light, movement or other hazards)
                          or pressuring, throwing, sliding, dropping, hitting or
                          attempting to open an encased video game can
                          negatively impact the game and the case and void
                          Wata&rsquo;s limited warranty.{" "}
                        </p>
                      </li>
                      <li>
                        <p className="legal__text">
                          <b>Disclaimer</b>. OTHER THAN THE LIMITED WARRANTY SET
                          FORTH ABOVE, WATA DOES NOT MAKE ANY OTHER WARRANTY,
                          EXPRESS OR IMPLIED, WITH RESPECT TO THIS AGREEMENT,
                          THE SERVICES OR THE TAMPER-EVIDENT CASE, INCLUDING,
                          WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF
                          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                          TITLE OR NON-INFRINGEMENT AND/OR ANY WARRANTIES
                          ARISING OUT OF A COURSE OF DEALING OR COURSE OF
                          PERFORMANCE. WITHOUT LIMITING THE FOREGOING, WATA DOES
                          NOT WARRANT THAT THE SERVICES OR THE TAMPER-EVIDENT
                          CASE WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS OR
                          THAT YOU WILL OBTAIN ANY PARTICULAR RESULT BY USE OF
                          THE SERVICES OR THE TAMPER-EVIDENT CASE.
                        </p>
                      </li>
                      <li>
                        <p className="legal__text">
                          <b>Intellectual Property</b>. You acknowledge that
                          WATA is and will remain the sole and exclusive owner
                          of all right, title and interest in and to
                          Wata&rsquo;s intellectual property, including, without
                          limitation, Wata&rsquo;s proprietary methodologies,
                          tamper-evident case design and concept, materials,
                          information, processes, technology and any and all
                          improvements, enhancements, updates, upgrades and
                          modifications to any of the preceding (whether or not
                          made in conjunction with this Agreement), and all
                          patent, trade secret, copyright, trademark and other
                          proprietary rights worldwide embodied in each of the
                          preceding. You agree not to disassemble, reverse
                          engineer, decompile, copy or otherwise use any of
                          Wata&rsquo;s intellectual property. In no event will
                          you take any action that might encumber or expose
                          Wata&rsquo;s intellectual property to any claims,
                          liens or other forms of encumbrance. At Wata&rsquo;s
                          request, you agree to execute and deliver all
                          documents that WATA deems reasonably necessary to
                          secure or maintain any registrations for Wata&rsquo;s
                          intellectual property or to protect Wata&rsquo;s
                          ownership of Wata&rsquo;s intellectual property.{" "}
                        </p>
                      </li>
                      <li>
                        <p className="legal__text">
                          <b>Limitations of Liability</b>. WITH THE EXCEPTION OF
                          DAMAGES ARISING FROM EITHER PARTY&rsquo;S WILLFUL
                          MISCONDUCT OR YOUR BREACH OF SECTION 5 (INTELLECTUAL
                          PROPERTY), (1) IN NO EVENT WILL EITHER PARTY BE LIABLE
                          FOR ANY CONSEQUENTIAL, INDIRECT, EXEMPLARY, SPECIAL,
                          PUNITIVE OR INCIDENTAL DAMAGES, INCLUDING, WITHOUT
                          LIMITATION, ANY LOST DATA, LOST VIDEO GAMES OR OTHER
                          MERCHANDISE, LOST PROFITS, OR COSTS OF PROCUREMENT OF
                          SUBSTITUTE GOODS OR SERVICES, ARISING FROM OR RELATING
                          TO THIS AGREEMENT, THE USE OF OR INABILITY TO USE THE
                          SERVICES, HOWEVER CAUSED AND UNDER ANY THEORY OF
                          LIABILITY (INCLUDING CONTRACT, STATUTE, TORT OR
                          NEGLIGENCE), EVEN IF SUCH PARTY HAS BEEN ADVISED OF
                          THE POSSIBILITY OF SUCH DAMAGES; AND (2)
                          NOTWITHSTANDING ANYTHING HEREIN TO THE CONTRARY, THE
                          TOTAL CUMULATIVE LIABILITY OF WATA IN CONNECTION WITH
                          THIS AGREEMENT WILL IN NO EVENT EXCEED THE AMOUNT OF
                          ANY FEES YOU PAID FOR USE OF THE SERVICES. EACH PARTY
                          ACKNOWLEDGES THAT THE DISCLAIMERS AND LIMITATIONS
                          HEREIN REFLECT A FAIR ALLOCATION OF RISK AND THAT
                          NEITHER PARTY WOULD ENTER INTO THIS AGREEMENT WITHOUT
                          SUCH LIMITATIONS ON EACH PARTY&rsquo;S LIABILITY, AND
                          EACH PARTY AGREES THAT THE FOREGOING DISCLAIMERS AND
                          LIMITATIONS WILL APPLY NOTWITHSTANDING ANY FAILURE OF
                          ESSENTIAL PURPOSE. BECAUSE SOME JURISDICTIONS DO NOT
                          ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN
                          CATEGORIES OF DAMAGES, IN SUCH JURISDICTIONS, THE
                          PARTIES AGREE THAT THE LIABILITY OF EACH PARTY WILL BE
                          LIMITED TO THE FULLEST EXTENT PERMITTED BY SUCH
                          JURISDICTION.{" "}
                        </p>
                      </li>
                      <li>
                        <p className="legal__text">
                          <b>Term</b>. The term of this Agreement commences on
                          the date on which you submit your order and terminates
                          on the date WATA completes the Services; provided,
                          that Sections 3 (Limited Warranty), 4 (Disclaimer), 5
                          (Intellectual Property), 6 (Limitations of Liability),
                          7 (Term) and 8 (Miscellaneous) shall survive
                          termination of this Agreement.
                        </p>
                      </li>
                      <li>
                        <p className="legal__text">
                          <b>Miscellaneous</b>.
                        </p>
                        <ol>
                          <li>
                            <p className="legal__text">
                              <b>Agreement</b>. This Agreement sets forth the
                              entire understanding and agreement between you and
                              WATA and supersedes any and all oral or written
                              agreements or understandings between you and Wata,
                              as to the subject matter of this Agreement. This
                              Agreement may be changed only by a written
                              amendment signed by you and Wata.{" "}
                            </p>
                          </li>
                          <li>
                            <p className="legal__text">
                              <b>Governing Law</b>. This Agreement will be
                              governed by the laws of the State of Colorado as
                              applied to agreements made, entered into and
                              performed entirely in Colorado by Colorado
                              residents. All claims under, or otherwise with
                              respect to, this Agreement will be brought and
                              maintained in the State and Federal courts located
                              in the City and County of Denver, Colorado, and
                              the parties hereby expressly consent to the
                              exclusive venue and jurisdiction of such courts
                              and waive any and all objections to such venue and
                              jurisdiction, including with respect to forum non
                              conveniens.
                            </p>
                          </li>
                          <li>
                            <p className="legal__text">
                              <b>Force Majeure</b>. Notwithstanding anything in
                              this Agreement to the contrary, you and WATA will
                              be excused from performance hereunder for any
                              period and to the extent that either party is
                              prevented from performing such party&rsquo;s
                              obligations under this Agreement, in whole or in
                              part, as a result of delays caused by the other
                              party or a force majeure event, such as an act of
                              God, war (whether or not actually declared), riot
                              or other act of civil disobedience, governmental
                              act, judicial action, explosion, act of terrorism
                              or threat thereof (including cyberterrorism),
                              natural disaster, destruction by lightning, fire,
                              earthquake, tsunami, flood, strike or lockout or
                              labor dispute, prolonged internet outage,
                              communications line failure or power failure.
                            </p>
                          </li>
                          <li>
                            <p className="legal__text">
                              <b>General</b>. No agency, partnership, franchise,
                              joint venture or employment relationship is
                              intended or created by this Agreement. If any
                              provision herein is held to be invalid or
                              unenforceable for any reason, the remaining
                              provisions will continue in full force without
                              being impaired or invalidated in any way. You and
                              WATA agree to replace any invalid provision with a
                              valid provision that most closely approximates the
                              intent and economic effect of the invalid
                              provision. The waiver of a breach of any provision
                              of this Agreement will not operate or be
                              interpreted as a waiver of any other or subsequent
                              breach.
                            </p>
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    );
  }
}

export default withTracker(Services);
