import React from 'react';
import { Link } from 'react-router-dom';

import { routeURLs as URL } from '../../../configs/routeURLs';

import './styles/Case.css';

const Case = (props) => {
  return (
    <div className="case section section--gradient section--btp">
      <div className="container-fluid">
        <div className="case__content">

          <div className="case__info">
            <h2 className="home__title home__title--white">Defining the industry standard. <span>(again)</span></h2>

            <ul className="case__list arrow-list">
              <li className="arrow-list__item diamond">The toughest, most durable display case on the market:</li>
              <li className="arrow-list__item x">Horizontal and portrait viewing in 6 different sizes:</li>
              <li className="arrow-list__item triangle">Interlocking and stacking capability in a slim profile:</li>
              <li className="arrow-list__item circle">Enhanced security features for a tamper-evident case:</li>
            </ul>

            <div className="case__btn-wrapper">
              <Link
                className="btn btn--md"
                to={`${URL.whatWeDo.child.ourTechnology.link}/#case`}>
                <span className="btn__text">LEARN MORE</span>
              </Link>
            </div>

          </div>

          <div className="case__img-wrapper">
            <img className="case__img" src={`${process.env.PUBLIC_URL}/images/case.png`} alt="case"/>
          </div>

        </div>
      </div>
    </div>
  );
}


export default Case;
