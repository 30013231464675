import React from 'react';
import classnames from 'classnames';

import { Link } from 'react-router-dom';

import { routeURLs as URL } from '../../../configs/routeURLs';

import './styles/Process.css';


class Process extends React.Component {
  state = {
    processTab: 'process-submission',
  }

  changeTab = (e) => {
    this.setState({
      processTab: e.target.value
    });
  }

  render() {
    /* TODO: Add a translations */
    return (
      <div className="process">
        <div className="container-fluid process__wrapper">

          <div className="section">
            <div className="home__title-wrapper">
              <h2 className="home__title">Our Process. <span>(at a glance)</span></h2>
              <p className="home__subtitle">Click each step below for more information on our <span>iron-clad</span>, road-tested grading process:</p>
            </div>

            <div className="process__nav">
              <div className="process__labels-wrapper">
                <ul className="process__nav-labels">
                  <li>
                    <label
                      htmlFor="process-submission">
                      SUBMISSION
                    </label>
                  </li>
                  <li>
                    <label
                      htmlFor="process-receiving">
                      RECEIVING
                    </label>
                  </li>
                  <li>
                    <label
                      htmlFor="process-grading">
                      GRADING
                    </label>
                  </li>
                  <li>
                    <label
                      htmlFor="process-encapsulation">
                        ENCAPSULATION
                    </label>
                  </li>
                  <li>
                    <label
                      htmlFor="process-quality">
                      QUALITY  CHECK
                    </label>
                  </li>
                  <li>
                    <label
                      htmlFor="process-shipping">
                      SHIPPING
                    </label>
                  </li>
                </ul>
              </div>

              <div className="process__icons-wrapper">
                <ul className="process__nav-icons">
                  <li>
                    <label
                      className={classnames({
                          'active': this.state.processTab === 'process-submission',
                        },
                      )}
                      htmlFor="process-submission">
                      <span className="icon-process-submit" />
                    </label>
                  </li>
                  <li>
                    <label
                      className={classnames({
                          'active': this.state.processTab === 'process-receiving',
                        },
                      )}
                      htmlFor="process-receiving">
                      <span className="icon-process-receive" />
                    </label>
                  </li>
                  <li>
                    <label
                      className={classnames({
                          'active': this.state.processTab === 'process-grading',
                        },
                      )}
                      htmlFor="process-grading">
                      <span className="icon-process-grade" />
                    </label>
                  </li>
                  <li>
                    <label
                      className={classnames({
                          'active': this.state.processTab === 'process-encapsulation',
                        },
                      )}
                      htmlFor="process-encapsulation">
                      <span className="icon-process-encapsulate" />
                    </label>
                  </li>
                  <li>
                    <label
                      className={classnames({
                          'active': this.state.processTab === 'process-quality',
                        },
                      )}
                      htmlFor="process-quality">
                      <span className="icon-process-qc" />
                    </label>
                  </li>
                  <li>
                    <label
                      className={classnames({
                          'active': this.state.processTab === 'process-shipping',
                        },
                      )}
                      htmlFor="process-shipping">
                      <span className="icon-process-ship" />
                    </label>
                  </li>
                </ul>
              </div>
            </div>

            <div className="process__items">

              <div className="process__item">
                <input
                  type="radio"
                  name="processTab"
                  value="process-submission"
                  id="process-submission"
                  className="process__radio"
                  checked={this.state.processTab === 'process-submission'}
                  onChange={this.changeTab}
                  />
                <label
                  className="process__label"
                  htmlFor="process-submission">
                  SUBMISSION
                </label>
                <div className="process__content">

                    <div className="process__info">
                      <h3 className="process__title">
                        <span>Step 1</span>
                        | Submission.
                      </h3>
                      <ul className="arrow-list">
                        <li className="arrow-list__item">
                          Give us a few details by selecting your media’s Platform, Title, and declared value.
                          Choose your service level and let us do the rest!
                        </li>
                        <li className="arrow-list__item">
                          Choose from a variety of services to enhance your submission and receive the most
                          value for your media, such as Cleaning and more.
                        </li>
                      </ul>
                    </div>
                    <div className="process__btn-wrapper">
                      <Link
                        className="btn btn--md"
                        to={URL.whatWeDo.child.submissionProcess.link}>
                        <span className="btn__text btn__text--black">LEARN MORE</span>
                      </Link>
                    </div>

                </div>
              </div>

              <div className="process__item">
                <input
                  type="radio"
                  name="processTab"
                  checked={this.state.processTab === 'process-receiving'}
                  onChange={this.changeTab}
                  value="process-receiving"
                  id="process-receiving"
                  className="process__radio"/>
                <label
                  className="process__label"
                  htmlFor="process-receiving">
                  RECEIVING
                </label>
                <div className="process__content">

                    <div className="process__info">
                      <h3 className="process__title">
                        <span>Step 2</span>
                      | Receiving.
                      </h3>
                      <ul className="arrow-list">
                        <li className="arrow-list__item">Contents are carefully unpacked and verified. Media travels in a bag with unique barcode and label to identify and keep contents secure throughout the process.</li>
                        <li className="arrow-list__item">Additional features and services applied, if selected.</li>
                      </ul>
                    </div>

                </div>
              </div>

              <div className="process__item">
                <input
                  type="radio"
                  name="processTab"

                  onChange={this.changeTab}
                  value="process-grading"
                  id="process-grading"
                  checked={this.state.processTab === 'process-grading'}
                  className="process__radio"/>
                <label
                  className="process__label"
                  htmlFor="process-grading">
                  GRADING
                </label>
                <div className="process__content">

                    <div className="process__info">
                      <h3 className="process__title">
                        <span>Step 3</span>
                        | Grading.
                      </h3>
                      <ul className="arrow-list">
                        <li className="arrow-list__item">Two graders independently assess contents, authenticity, & condition and consider all relevant media information. A third expert Final Grader independently assesses grades, then reviews other graders’ work before assigning a final grade. </li>
                        <li className="arrow-list__item">Graders may consult expert Advisory Board</li>
                      </ul>
                    </div>
                    <div className="process__btn-wrapper">
                      <Link
                        className="btn btn--md"
                        to={URL.whatWeDo.child.wataScale.link}>
                        <span className="btn__text btn__text--black">LEARN MORE</span>
                      </Link>
                    </div>

                </div>
              </div>

              <div className="process__item">
                <input
                  type="radio"
                  name="processTab"

                  onChange={this.changeTab}
                  value="process-encapsulation"
                  id="process-encapsulation"
                  checked={this.state.processTab === 'process-encapsulation'}
                  className="process__radio"/>
                <label
                  className="process__label"
                  htmlFor="process-encapsulation">
                    ENCAPSULATION
                </label>
                <div className="process__content">

                    <div className="process__info">
                      <h3 className="process__title">
                        <span>Step 4</span>
                        | Encapsulation.
                      </h3>
                      <ul className="arrow-list">
                        <li className="arrow-list__item">High-tech secure label is applied. Next, Matrix technology is applied for security and restricted information access. </li>
                        <li className="arrow-list__item">Unique identifiers for web reference and internal use are assigned to Matrix.</li>
                      </ul>
                    </div>

                </div>
              </div>

              <div className="process__item">
                <input
                  type="radio"
                  name="processTab"

                  onChange={this.changeTab}
                  value="process-quality"
                  id="process-quality"
                  checked={this.state.processTab === 'process-quality'}
                  className="process__radio"/>
                <label
                  className="process__label"
                  htmlFor="process-quality">
                  QUALITY  CHECK
                </label>
                <div className="process__content">

                    <div className="process__info">
                      <h3 className="process__title">
                        <span>Step 5</span>
                        | Quality check.
                      </h3>
                      <ul className="arrow-list">
                        <li className="arrow-list__item">A careful review to detect errors or defects takes place.</li>
                        <li className="arrow-list__item">Final accountability check of certification process.</li>
                        <li className="arrow-list__item">Case is secured with six locking clips.</li>
                      </ul>
                    </div>

                </div>
              </div>

              <div className="process__item">
                <input
                  type="radio"
                  name="processTab"
                  onChange={this.changeTab}
                  value="process-shipping"
                  id="process-shipping"
                  checked={this.state.processTab === 'process-shipping'}
                  className="process__radio"/>
                <label
                  className="process__label"
                  htmlFor="process-shipping">
                  SHIPPING
                </label>
                <div className="process__content">

                    <div className="process__info">
                      <h3 className="process__title">
                        <span>Step 6</span>
                        | Shipping.
                      </h3>
                      <ul className="arrow-list">
                        <li className="arrow-list__item">Media is carefully packed, shipping label is printed and applied, and the now-certified games ship to customers or authorized dealers.</li>
                      </ul>
                    </div>

                </div>
              </div>

            </div>


          </div>
        </div>
      </div>
    );
  }
}

export default Process;
