import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {withFormsy} from 'formsy-react';

import './styles/form.css';

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  id: PropTypes.string,
  updateStateData: PropTypes.func,
  isPristine: PropTypes.func,
  getErrorMessage: PropTypes.func,
  step: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  min: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  max: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

const defaultProps = {
  className: 'form-control',
  updateStateData: () => {},
  value: '',
  step: 1
};

const parseNumber = (value, step, max, min) => {
  if (value === '') return '';
  if (value) {
    value = parseFloat(value);
    if (isNaN(value)) return '';
  }

  if (typeof max === 'number' && value > max) return max;
  if (typeof min === 'number' && value < min) return min;

  if (step) {
    const p = (step.toString().split('.')[1] || []).length;
    if (p) return parseFloat(value.toFixed(p));
  }

  return value;
};

const KEY_UP = 38;
const KEY_DOWN = 40;
const KEY_ENTER = 13;

class InputNumber extends React.Component {
  // constructor(props) {
  //   super(props);
  //   // this.defaultValue = props.getValue();
  // }

  parse = (val) => {
    const {props} = this;
    return parseNumber(val, props.step, props.max, props.min);
  }

  change = (value) => {
    const {props} = this;
    const {name} = props;

    props.setValue(this.parse(value));
    props.updateStateData(name, value);

  }

  up = () => {
    const {props} = this;
    const value = parseFloat(props.getValue()) || 0;

    this.change(value + props.step);
  }

  down = () => {
    const {props} = this;
    const value = parseFloat(props.getValue()) || 0;

    this.change(value - props.step);
  }

  handleKeyDown = (e) => {
    switch (e.keyCode) {
      case KEY_UP:
        e.preventDefault();
        this.up();
        break;
      case KEY_DOWN:
        e.preventDefault();
        this.down();
        break;

      default:
        break;
    }
  }

  handleKeyUp = (e) => {
    if (e.keyCode === KEY_ENTER) {
      this.change(this.props.getValue());
    }
  }

  onChange = (e) => {
    const {props} = this;
    const {name} = props;
    let {value} = e.target;

    props.setValue(value);

    props.updateStateData(name, value);
  }

  render() {
    const {props} = this;
    const {isPristine, getErrorMessage} = props;
    return (
      <div
        className={classnames({
            'form-field': true,
            'has-error': !isPristine() ? !!getErrorMessage() : false,
          },
          props.className
        )}
      >
        {props.children}
        <div className={classnames(
          'form-field__container',
          'form-field__container--number'
        )}>
          <input
            type="number"
            name={props.name}
            disabled={props.disabled}
            readOnly={props.readOnly}
            value={props.getValue()}
            onKeyUp={e => this.handleKeyUp(e)}
            onKeyDown={e => this.handleKeyDown(e)}
            onChange={this.onChange}
            id={props.id}
            placeholder={props.placeholder}
            className={classnames(
              'form-field__item',
              props.inputClassName
            )}
            step={props.step}
            min={props.min}
            max={props.max}
          />
          <div className="form-field__icon form-field__icon--number">
            <button
              type="button"
              className={classnames(
                'form-field__button',
                'form-field__button--up',
                props.buttonClassName
              )}
              onMouseDown={e => e.preventDefault()}
              onClick={e => {
                e.preventDefault();
                this.up();
              }}
              tabIndex={-1}
              >
              <i className="icon-caret-up" aria-hidden="true" />
            </button>
            <button
              type="button"
              className={classnames(
                'form-field__button',
                'form-field__button--down',
                props.buttonClassName
              )}
              onMouseDown={e => e.preventDefault()}
              onClick={e => {
                e.preventDefault();
                this.down();
              }}
              tabIndex={-1}>
              <i className="icon-caret-down" aria-hidden="true" />
            </button>
          </div>
        </div>
        {(!isPristine() && getErrorMessage())
          ? <div className="help-block text-danger">{getErrorMessage()}</div>
          : null
        }
      </div>
    );
  }
}

InputNumber.propTypes = propTypes;

InputNumber.defaultProps = defaultProps;

export default withFormsy(InputNumber);
