import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { BrowserNavLink } from "../../shared/BrowserNavLink";

import Logo from "../../shared/Logo/Logo";

import { I18n, Trans } from "react-i18next";

import { routeURLs as URL } from "../../../configs/routeURLs";

import "./styles/UsThem.css";

import withTracker from "../../shared/AnalyticsTracker/withTracker";

const propTypes = {
  sectionClassName: PropTypes.string,
};

const TITLE = "Us > Them";

const UsThem = (props) => {
  return (
    <I18n ns="translations">
      {(t, { i18n }) => (
        <div id="us-them" className="us-them">
          <div className="container-fluid us-them__container">
            <div
              className={classnames(
                "us-them__content",
                "section",
                props.sectionClassName
              )}
            >
              <h2 className="section__title">
                <Trans i18nKey={"Us > Them. (hint: it’s almost unfair)"}>
                  Us > Them. <span>(hint: it’s almost unfair)</span>
                </Trans>
              </h2>
              <p className="section__subtitle">
                {t(
                  "We run laps around our competition. Skeptical? See for yourself how we stack up:"
                )}
              </p>

              <div className="us-them__table">
                <div className="us-them__table-head">
                  <div className="us-them__head-title">
                    <Logo
                      src="logo-dot.png"
                      srcSet="logo-dot@2x.png"
                      linkClassName="us-them__logo"
                      imageClassName="us-them__logo-img"
                    />
                  </div>
                  <div className="us-them__head-us">
                    <span className="icon-compare-us us-them__head-icon"></span>
                    <p className="us-them__head-text">( {t("Us")} )</p>
                  </div>
                  <div className="us-them__head-them">
                    <span className="icon-compare-them us-them__head-icon"></span>
                    <p className="us-them__head-text">( {t("Them")} )</p>
                  </div>
                </div>

                <div className="us-them__table-content">
                  <div className="us-them__row">
                    <div className="us-them__col us-them__title">
                      <h3>{t("ALL GAMES CONSIDERED")}</h3>
                      <p>{t("We grade: Sealed, CIB,  and Cart")}</p>
                    </div>
                    <div
                      className="us-them__col us-them__us us-have"
                      title={t("Us")}
                    >
                      <span className="icon-seal text-gradient us-them__icon" />
                    </div>
                    <div
                      className="us-them__col us-them__them"
                      title={t("Them")}
                    />
                  </div>

                  <div className="us-them__row">
                    <div className="us-them__col us-them__title">
                      <h3>{t("ITEMIZED GRADING")}</h3>
                      <p>
                        {t(
                          "CIB piece grades included & list of components on label"
                        )}
                      </p>
                    </div>
                    <div
                      className="us-them__col us-them__us us-have"
                      title={t("Us")}
                    >
                      <span className="icon-seal text-gradient us-them__icon" />
                    </div>
                    <div
                      className="us-them__col us-them__them"
                      title={t("Them")}
                    />
                  </div>

                  <div className="us-them__row">
                    <div className="us-them__col us-them__title">
                      <h3>{t("COLLECTOR-OPERATED")}</h3>
                      <p>
                        {t(
                          "Run & graded by industry experts & experienced video game collectors"
                        )}
                      </p>
                    </div>
                    <div
                      className="us-them__col us-them__us us-have"
                      title={t("Us")}
                    >
                      <span className="icon-seal text-gradient us-them__icon" />
                    </div>
                    <div
                      className="us-them__col us-them__them"
                      title={t("Them")}
                    />
                  </div>

                  <div className="us-them__row">
                    <div className="us-them__col us-them__title">
                      <h3>{t("ADVISORY BOARD")}</h3>
                      <p>
                        {t(
                          "Championed by Advisory Board of recognized industry leaders"
                        )}
                      </p>
                    </div>
                    <div
                      className="us-them__col us-them__us us-have"
                      title={t("Us")}
                    >
                      <span className="icon-seal text-gradient us-them__icon" />
                    </div>
                    <div
                      className="us-them__col us-them__them"
                      title={t("Them")}
                    />
                  </div>

                  <div className="us-them__row">
                    <div className="us-them__col us-them__title">
                      <h3>{t("TRANSPARENT GRADING SYSTEM")}</h3>
                      <p>
                        {t(
                          "0-to-10 grading scale; easily understood, regardless of collecting experience"
                        )}
                      </p>
                    </div>
                    <div
                      className="us-them__col us-them__us us-have"
                      title={t("Us")}
                    >
                      <span className="icon-seal text-gradient us-them__icon" />
                    </div>
                    <div
                      className="us-them__col us-them__them"
                      title={t("Them")}
                    />
                  </div>

                  <div className="us-them__row">
                    <div className="us-them__col us-them__title">
                      <h3>{t("ARCHIVAL-GRADE CASE")}</h3>
                      <p>
                        {t(
                          "UV-protected, tamper-evident case w/multi-stage locking"
                        )}
                      </p>
                    </div>
                    <div
                      className="us-them__col us-them__us us-have"
                      title={t("Us")}
                    >
                      <span className="icon-seal text-gradient us-them__icon" />
                    </div>
                    <div
                      className="us-them__col us-them__them"
                      title={t("Them")}
                    />
                  </div>

                  <div className="us-them__row">
                    <div className="us-them__col us-them__title">
                      <h3>{t("TOP-TIER SECURITY")}</h3>
                      <p>
                        {t(
                          "CDT-based security features protect your investment"
                        )}
                      </p>
                    </div>
                    <div
                      className="us-them__col us-them__us us-have"
                      title={t("Us")}
                    >
                      <span className="icon-seal text-gradient us-them__icon" />
                    </div>
                    <div
                      className="us-them__col us-them__them"
                      title={t("Them")}
                    />
                  </div>

                  <div className="us-them__row">
                    <div className="us-them__col us-them__title">
                      <h3>{t("BRAGGING RIGHTS")}</h3>
                      <p>
                        {t(
                          "Optional extras (e.g. “WATA Badge”) for games in exceptional condition"
                        )}
                      </p>
                    </div>
                    <div
                      className="us-them__col us-them__us us-have"
                      title={t("Us")}
                    >
                      <span className="icon-seal text-gradient us-them__icon" />
                    </div>
                    <div
                      className="us-them__col us-them__them"
                      title={t("Them")}
                    />
                  </div>

                  <div className="us-them__row">
                    <div className="us-them__col us-them__title">
                      <h3>{t("MATRIX CODE")}</h3>
                      <p>
                        {t(
                          "Scannable technology provides photos & secure information access"
                        )}
                      </p>
                    </div>
                    <div
                      className="us-them__col us-them__us us-have"
                      title={t("Us")}
                    >
                      <span className="icon-seal text-gradient us-them__icon" />
                    </div>
                    <div
                      className="us-them__col us-them__them"
                      title={t("Them")}
                    />
                  </div>

                  <div className="us-them__row">
                    <div className="us-them__col us-them__title">
                      <h3>{t("CONVENTION PRESENCE")}</h3>
                      <p>
                        {t(
                          "Strong committment to convention & trade-show presence"
                        )}
                      </p>
                    </div>
                    <div
                      className="us-them__col us-them__us us-have"
                      title={t("Us")}
                    >
                      <span className="icon-seal text-gradient us-them__icon" />
                    </div>
                    <div
                      className="us-them__col us-them__them"
                      title={t("Them")}
                    />
                  </div>

                  <div className="us-them__row">
                    <div className="us-them__col us-them__title">
                      <h3>{t("DETAILED NOTES")}</h3>
                      <p>
                        {t(
                          "Accurate variance, significance, & key notes on label"
                        )}
                      </p>
                    </div>
                    <div
                      className="us-them__col us-them__us us-have"
                      title={t("Us")}
                    >
                      <span className="icon-seal text-gradient us-them__icon" />
                    </div>
                    <div
                      className="us-them__col us-them__them"
                      title={t("Them")}
                    />
                  </div>

                  <div className="us-them__row">
                    <div className="us-them__col us-them__title">
                      <h3>{t("2 LABEL TYPES")}</h3>
                      <p>
                        {t(
                          "Two label varieties to fit your display & info preferences: Flat, (or) 3D"
                        )}
                      </p>
                    </div>
                    <div
                      className="us-them__col us-them__us us-have"
                      title={t("Us")}
                    >
                      <span className="icon-seal text-gradient us-them__icon" />
                    </div>
                    <div
                      className="us-them__col us-them__them"
                      title={t("Them")}
                    />
                  </div>

                  <div className="us-them__row">
                    <div className="us-them__col us-them__title">
                      <h3>{t("ONLINE SUBMISSION")}</h3>
                      <p>
                        {t("Seamless & intuitive online submission process")}
                      </p>
                    </div>
                    <div
                      className="us-them__col us-them__us us-have"
                      title={t("Us")}
                    >
                      <span className="icon-seal text-gradient us-them__icon" />
                    </div>
                    <div
                      className="us-them__col us-them__them"
                      title={t("Them")}
                    />
                  </div>

                  <div className="us-them__row">
                    <div className="us-them__col us-them__title">
                      <h3>{t("ENCAPSULATION")}</h3>
                      <p>{t("Physical game encapsulation")}</p>
                    </div>
                    <div
                      className="us-them__col us-them__us us-have"
                      title={t("Us")}
                    >
                      <span className="icon-seal text-gradient us-them__icon" />
                    </div>
                    <div
                      className="us-them__col us-them__them them-have"
                      title={t("Them")}
                    >
                      <span className="icon-seal us-them__icon" />
                    </div>
                  </div>

                  <div className="us-them__row">
                    <div className="us-them__col us-them__title">
                      <h3>{t("BASIC GRADING")}</h3>
                      <p>{t("Grade & description assignment")}</p>
                    </div>
                    <div
                      className="us-them__col us-them__us us-have"
                      title={t("Us")}
                    >
                      <span className="icon-seal text-gradient us-them__icon" />
                    </div>
                    <div
                      className="us-them__col us-them__them them-have"
                      title={t("Them")}
                    >
                      <span className="icon-seal us-them__icon" />
                    </div>
                  </div>

                  <div className="us-them__row">
                    <div className="us-them__col us-them__title">
                      <h3>{t("DISPLAY CASE")}</h3>
                      <p>{t("Acrylic handmade case")}</p>
                    </div>
                    <div className="us-them__col us-them__us" title={t("Us")} />
                    <div
                      className="us-them__col us-them__them them-have"
                      title={t("Them")}
                    >
                      <span className="icon-seal us-them__icon" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <BrowserNavLink
                  className="btn btn--high btn--lg btn--white"
                  to={URL.submitGame.link}
                >
                  <span className="btn__text btn__text--white">
                    {t("Start Grading!")}
                  </span>
                </BrowserNavLink>
              </div>
            </div>
          </div>
        </div>
      )}
    </I18n>
  );
};

UsThem.propTypes = propTypes;

export default withTracker(UsThem, { title: TITLE });
