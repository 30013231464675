import React from 'react';
import {Link} from 'react-router-dom';

import {socialsURL} from '../../../constants/common';

import './styles/Socials.css';


const Socials = (props) => {
  return (
    <ul className="social">
      <li className="social__item">
        <Link
          to={{ pathname: socialsURL.facebook}}
          target="_blank"
          title="Facebook"
          rel="noopener noreferrer"
          className="social__link">
          <span className="icon-facebook" />
        </Link>
      </li>
      <li className="social__item">
        <Link
          to={{ pathname: socialsURL.instagram}}
          target="_blank"
          title="Instagram"
          rel="noopener noreferrer"
          className="social__link">
          <span className="icon-instagram" />
        </Link>
      </li>
      <li className="social__item">
        <Link
          to={{ pathname: socialsURL.twitter}}
          target="_blank"
          title="Twitter"
          rel="noopener noreferrer"
          className="social__link">
          <span className="icon-twitter" />
        </Link>
      </li>
    </ul>
  );
}


export default Socials;
