import Helpers from "../utils/Helpers";

const calcLiability = decValue => {
  if (decValue >= 2000) return Helpers.roundTo(0.02 * decValue, 2);
  if (decValue >= 1000) return Helpers.roundTo(0.01 * decValue, 2);
  return 0;
};

export const prices = {
  gameLiability: {
    getLiabilityCost: decValue => calcLiability(decValue)
  },
  serviceLevels: {
    cib: {
      gameStates: [1],
      base: {
        select: 0, 
        turbo: 49.99,
        speedrun: 79.99,
        warpzone: 149.99
      },
      addOns: {
        cleaning: 19.99
      }
    },
    sealedLoose: {
      gameStates: [0, 2],
      base: {
        select: 0,
        turbo: 49.99,
        speedrun: 79.99,
        warpzone: 149.99
      },
      addOns: {
        cleaning: 9.99
      }
    }
  },
  addOns: {
    badge: 7.99,
    heavyCleaning: 23.99, //
    reholder: 24.99, //
    testing: 4.99,
    boardPhotos: 9.99,
    photoServices: 19.99,
    report: 9.99,
    cibPlus: 39.99,
    legacyHolder: 4.99,
    stickerRemoval: 39.99,
    genDesignation: 0,
    autographAuth: 29.99,
  },
  addOnsForPrototypes: {
    cleaning: 15,
    heavyCleaning: 25, //
    boardPhotos: 15,
    photoServices: 10
  },
  serviceLevelsForPrototypes: {
    proStandard: {
      serviceLevelId: 4,
      enabled: true,
      cost: 249.99
    },
    proExtensive: {
      serviceLevelId: 5,
      enabled: false,
      cost: 300
    }
  },
  getDeclaredValueOptionsArray: () => {
    return [99, 199, 349, 499, 749, 999];
  }
};
