import React from 'react';
import PropTypes from 'prop-types';

import { findDOMNode } from 'react-dom';

import { inject, observer } from 'mobx-react';

import {
  translate,
  // Trans
} from 'react-i18next';

import Helmet from 'react-helmet';
import RangeSlider from 'rc-slider';

import Modal from 'react-modal';

import { modalOptions } from '../../../constants/common';
import { scaleConfig } from '../../../constants/scaleConfig';

// default styles
import 'rc-slider/assets/index.css';
import './styles/WataScale.css';

import Banner from './Banner/Banner';
import ScaleInfo from './ScaleInfo/ScaleInfo';
import WataScaleTabContent from '../WataScaleTabContent/WataScaleTabContent';
import ScaleShrink from './ScaleShrink/ScaleShrink';

import withTracker from '../../shared/AnalyticsTracker/withTracker';


const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
  modalsStore: PropTypes.object.isRequired,
};

const TITLE = 'The WATA Scale';


class WataScale extends React.Component {
  constructor(props) {
    super(props);

    this.pointerInst = null;
    this.rangeInst = null;
    this.posInst = null;
  }

  state = {
    currentTab: 10,
    imageToModal: null,
    imageToModalAlt: '',
  }

  componentDidMount() {
    // this.handlePointerHeight();

    window.addEventListener('resize', this.handlePointerHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlePointerHeight);
  }

  setPointerHeight = (value, max, posInst) => {
    if (!this.pointerInst || !this.posInst || !this.rangeInst) return;

    const pointer = findDOMNode(this.pointerInst);
    const content = findDOMNode(this.posInst);
    const handler = findDOMNode(this.rangeInst.handlesRefs[0].handle);

    const slider = findDOMNode(this.rangeInst);
    const sliderOffsetHeight = slider.offsetHeight;
    const sliderOffsetTop = slider.getBoundingClientRect().top;

    const handlerPosition = sliderOffsetHeight - value*(sliderOffsetHeight/max);

    const handlerOffset = handler.offsetHeight/2;


    const contentOffsetTop = content.getBoundingClientRect().top;

    let height = (sliderOffsetTop + handlerPosition) - contentOffsetTop - handlerOffset;

    let transform = 'translateY(0) scaleX(1)';

    if (height < 0) {
      height = Math.abs(height);
      transform = 'translateY(-100%) scaleX(-1)';
    }

    pointer.setAttribute("style",`height: ${height}px; transform: ${transform};`);
  }

  handlePointerHeight = () => {
    this.setPointerHeight(this.state.currentTab, scaleConfig.max);
  }


  onChange = (value) => {
    // INFO: Block all positions < 0.5
    if (value < 0.5) {
      value = 0.5;
    }
    this.setPointerHeight(value, scaleConfig.max);
    this.setState({
      currentTab: value,
    });
  }

  onAfterChange = (value) => {
    // TODO: fetch data here
  }

  setPosInst = (posRef) => {
    this.posInst = posRef;
  }

  toggleModal = () => {
    this.props.modalsStore.toggleModal('scaleImage');
  }

  setImageToModal = (imageUrl, imageAlt) => {
    this.setState({
      imageToModal: imageUrl,
      imageToModalAlt: imageAlt,
    });
    this.toggleModal();
  }

  render() {
    const {props} = this;
    const {t} = props;

    const d = document;
    const {modalsStore} = props;

    const {state} = this;

    const helperLine = () => (
      <div
        ref={(inst) => (this.pointerInst = inst)}
        className="helper-line">
        <div className="helper-line__arrow helper-line__arrow--top" />
        <div className="helper-line__arrow helper-line__arrow--bottom" />
      </div>
    );

    return (
      <div className="page-wrapper"> {/* TODO: rename className */}
        <Helmet
          title={`${t('What We Do')} - ${t(TITLE)}`}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />

        <Banner />
        <div className="scale">
          <div className="container-fluid">
            <div className="row-fluid">
              <div className="scale__wrapper section section--gradient section--ytr">
                <h2 className="scale__title">THE <img src={`${process.env.PUBLIC_URL}/images/wata.png`} alt="img"/> SCALE</h2>
                <div className="scale__content">
                  <div className="slider-vertical">
                    <RangeSlider
                      min={scaleConfig.min}
                      max={scaleConfig.max}
                      marks={scaleConfig.marks}
                      step={null}
                      ref={(inst) => (this.rangeInst = inst)}
                      className="rangeslider-vertical--fluid"
                      vertical={true}
                      onChange={this.onChange}
                      onAfterChange={this.onAfterChange}
                      value={state.currentTab} />
                  </div>

                  <div className="scale__info">
                    <ScaleInfo
                      onMount={this.handlePointerHeight} />
                    <div
                      // ref={(inst) => (this.posInst = inst)}
                      className="pos">

                      <WataScaleTabContent
                        helperLine={helperLine}
                        setPosInst={this.setPosInst}
                        currentTabValue={state.currentTab}
                        toggleModal={this.toggleModal}
                        setImageToModal={this.setImageToModal}
                        onMount={this.handlePointerHeight} />
                    </div>
                    <ScaleShrink
                      toggleModal={this.toggleModal}
                      setImageToModal={this.setImageToModal} />
                    <Modal
                      onRequestClose={this.toggleModal}
                      shouldCloseOnOverlayClick={true}
                      bodyOpenClassName={modalOptions.modalBodyOpenClassName}
                      className={modalOptions.modalContainer('modal--max')}
                      overlayClassName={modalOptions.modalOverlayContainer()}
                      parentSelector={() => d.body}
                      appElement={d.getElementById('root')}
                      shouldCloseOnEsc={true}
                      isOpen={modalsStore.modals.scaleImage}>
                      <button
                        onClick={this.toggleModal}
                        type="button"
                        className="modal__btn-close">
                        &times;
                      </button>
                      {
                        !!this.state.imageToModal && (
                          <div className="modal-body">
                            <img
                              src={this.state.imageToModal}
                              className="scale-content__item-img"
                              alt={this.state.imageToModalAlt} />
                          </div>
                        )
                      }
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedWataScale = inject('appStore', 'modalsStore')(observer(WataScale));
const TranslateWataScale = translate()(WrappedWataScale);
TranslateWataScale.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslateWataScale, {title: TITLE});
