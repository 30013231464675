import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Storage from '../../../utils/Storage';
import { routeURLs as URL } from '../../../configs/routeURLs';

class AuthRoute extends React.Component {
  componentDidMount() {
    this.props.onMount();
  }
  render () {
    const {
      component: Component,
      authenticated,
      redirectTo,
      ...rest
    } = this.props;

    if (!authenticated){
      Storage.set('watagamesNextRedirect', this.props.location.pathname);
    }

    return (
       <Route
         {...rest}
         render={props => (
           authenticated ? (
             <Component {...props} />
           ) : (
             <Redirect to={
                 {
                   pathname: URL.login.link,
                   state: { from: props.location },
                 }
               }
             />
           )
         )}
       />
    )
  }
};

AuthRoute.propTypes = {
  authenticated: PropTypes.bool,
  redirectTo: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  onMount: PropTypes.func,
};

AuthRoute.defaultProps = {
  authenticated: false,
  onMount: () => {},
};

export default AuthRoute;
