import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import { translate } from 'react-i18next';


import '../../shared/styles/slick-slider.css';
import './styles/LogoSlider.css';

import {
  sliderSettings,
} from '../../../constants/sliderSettings';

import Helpers from '../../../utils/Helpers';

const propTypes = {
  t: PropTypes.func.isRequired,
};

/* TODO: Add a real data. Should be a map from the data array */

const data = [
  {
    id: 0,
    logo: `${process.env.PUBLIC_URL}/images/companies/atari.svg`,
    name: 'Atari',
  },
  {
    id: 1,
    logo: `${process.env.PUBLIC_URL}/images/companies/coleco.svg`,
    name: 'Coleco',
  },
  // {
  //   id: 2,
  //   logo: `${process.env.PUBLIC_URL}/images/companies/gce.svg`,
  //   name: 'Gce',
  // },
  {
    id: 3,
    logo: `${process.env.PUBLIC_URL}/images/companies/magnavox.svg`,
    name: 'Magnavox',
  },
  {
    id: 4,
    logo: `${process.env.PUBLIC_URL}/images/companies/mattel.svg`,
    name: 'Mattel',
  },
  {
    id: 5,
    logo: `${process.env.PUBLIC_URL}/images/companies/memorex.svg`,
    name: 'Memorex',
  },
  {
    id: 6,
    logo: `${process.env.PUBLIC_URL}/images/companies/microsoft.svg`,
    name: 'Nintendo 64',
  },
  {
    id: 7,
    logo: `${process.env.PUBLIC_URL}/images/companies/nec.svg`,
    name: 'Nintendo 64',
  },
  {
    id: 8,
    logo: `${process.env.PUBLIC_URL}/images/companies/nintendo.svg`,
    name: 'Nintendo 64',
  },
  {
    id: 9,
    logo: `${process.env.PUBLIC_URL}/images/companies/panasonic.svg`,
    name: 'Nintendo 64',
  },
  {
    id: 10,
    logo: `${process.env.PUBLIC_URL}/images/companies/philips.svg`,
    name: 'Nintendo 64',
  },
  {
    id: 11,
    logo: `${process.env.PUBLIC_URL}/images/companies/sega.svg`,
    name: 'Nintendo 64',
  },
  {
    id: 12,
    logo: `${process.env.PUBLIC_URL}/images/companies/snk.svg`,
    name: 'Nintendo 64',
  },
  {
    id: 13,
    logo: `${process.env.PUBLIC_URL}/images/companies/sony.svg`,
    name: 'Nintendo 64',
  },
];

class LogoSlider extends React.Component {
  render() {
    const {t} = this.props;
    const responsiveOptions = Helpers.updateSliderOptions(sliderSettings.logoSlider, data);

    const sliderItem = data.map(item => {
      return (
        <div
          key={item.id}
          className="logo-slider__item">
          <img
            src={item.logo}
            alt={item.name}
            className="logo-slider__img" />
        </div>
      )
    });

    return (
      <section className="logo-slider__wrapper">
        <div className="container-fluid">
          <div className="row-fluid logo-slider__content">
            <div className="section section--gradient section--btp">
              <h2 className="logo-slider__title">
                {t('We’ve got you covered.')}
              </h2>
              <Slider
                className="logo-slider slick-slider--visible"
                {...responsiveOptions}>
                {sliderItem}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    );
  }
}


LogoSlider.propTypes = propTypes;

const TranslateLogoSlider = translate()(LogoSlider);

export default TranslateLogoSlider;
