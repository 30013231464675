import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';
import ReactAlert from 'react-s-alert';

import { translate } from 'react-i18next';

import {
  Redirect,
} from 'react-router-dom';

import AuthSession from '../../utils/AuthSession';
import API from '../../utils/API';
import Logger from '../../utils/Logger';

import {
  alertConfig,
} from '../../constants/common';

import { apiURLs } from '../../configs/apiURLs';
import { routeURLs as URL } from '../../configs/routeURLs';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Invader from '../shared/Invader/Invader';
import ConfirmationResolved from './ConfirmationResolved/ConfirmationResolved';

import './styles/Confirmation.css';

import withTracker from '../shared/AnalyticsTracker/withTracker';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const TITLE = 'Confirmation';

class Confirmation extends React.Component {
  state = {
    isLoading: false,
    isConfirmed: false,
  }

  componentDidMount() {
    this.confirmUser();
  }

  toggleLogInModal = () => {
    this.props.modalsStore.toggleModal('logIn');
  }

  confirmUser = async () => {
    const {params} = this.props.match;
    const {appStore} = this.props;


    if (AuthSession.isTokenSet() || this.props.appStore.isUserLoggedIn) {
      return;
    }

    this.setState({
      isLoading: true,
    })

    appStore.updateProgressData();

    try {
      await API.postData(apiURLs.confirmation, params);

      this.setState({
        isConfirmed: true,
        isLoading: false,
      })
      appStore.updateProgressData(false);
    } catch (error) {
      ReactAlert.error(error.response.data.message, alertConfig);
      Logger.error(error.response.data.message);
      this.setState({
        isLoading: false,
      })
      appStore.updateProgressData(false);
    }
  }

  render() {
    const {t} = this.props;
    const {state} = this;
    const {from} = this.props.location.state || { from: { pathname: URL.home.link } }

    if (AuthSession.isTokenSet() || !this.props.match.params.code) {
      return (
        <Redirect to={from} />
      )
    }

    return (
      <div className="page-wrapper">
        <Helmet
          title={t(TITLE)}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />

        <Header />

        <main className="main vertical-outer">
          <div className="vertical-inner">
            <div className="container-fluid text-center">
              <h1>{t('Confirmation')}</h1>
              <section className="section section--gradient section--top section--ytr">
                {
                  state.isLoading ? (
                    <div className="loader-container">
                      <h1 className="confirmation__header text-center">
                        {t('Please wait. Loading next level...')}
                      </h1>
                      <Invader />
                    </div>
                  ) : (
                    <ConfirmationResolved
                      toggleLogInModal={this.toggleLogInModal}
                      isConfirmed={state.isConfirmed}/>
                  )
                }
              </section>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    );
  }
}

const WrappedConfirmation = inject('appStore', 'modalsStore')(observer(Confirmation));
const TranslateConfirmation = translate()(WrappedConfirmation);
TranslateConfirmation.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslateConfirmation, {title: TITLE});
