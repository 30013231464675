import React from 'react';
import {Link} from 'react-router-dom';
import { I18n } from 'react-i18next';

import { routeURLs as URL } from '../../../../configs/routeURLs';

import ShippingForm from '../ShippingForm/ShippingForm';


import './styles/ShippingAddress.css';

const ShippingAddress = (props) => {
  return (
    <I18n ns="translations">
      {
        (t, {i18n}) => (
          <div className="shipping-address">
            <h1 className="section__title">
              {t('Where are we sending the goods?')}
            </h1>
            <p className="section__subtitle">
              {t('Your up-front pricing already includes return shipping & insurance, based on the number of media certified plus the total declared value of the media. If you don’t mind, please specify the address where you’d like your completed order(s) shipped:')}
            </p>
            <div className="container-fluid">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <ShippingForm />
                </div>
                <div className="col-xs-12 col-sm-6">
                  <ul className="shipping-address__list arrow-list">
                    <li className="arrow-list__item">
                      {t('Please have a second look to ensure everything looks accurate before clicking Proceed.')}
                    </li>
                    <li className="arrow-list__item">
                      {t('Feeling a little overwhelmed? We’re here to help:')}
                      <div className="arrow-list__btn-wrapper">
                        <Link
                          to={URL.faq.link}
                          className="btn">
                          <span className="btn__text btn__text--black">{t('View shipping F.A.Q.')}</span>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </I18n>
  );
}


export default ShippingAddress;
