import React from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';

import { translate } from 'react-i18next';

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  modalsStore: PropTypes.object.isRequired,
  removeItem: PropTypes.func.isRequired,
};


class DeleteModal extends React.Component {
  toggleModal = () => {
    this.props.modalsStore.toggleModal('deleteItem');
  }

  render() {
    const {t} = this.props;

    return (
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="section__title section__title--white">
              {t('Delete game: are you sure?')}
            </h2>
          </div>
          <div className="modal-body modal-form">
            <div className="form-field">
              <button
                type="button"
                onClick={this.toggleModal}
                className="btn btn--high btn--lg">
                <span>
                  {t('Nope, take me back!')}
                </span>
              </button>
            </div>
            <div className="form-field">
              <button
                onClick={this.props.removeItem}
                type="button"
                className="btn btn--high btn--lg btn--white">
                <span>
                  {t('Yep, delete it!')}
                </span>
              </button>
            </div>
            <div className="modal-warning text-center">
              {t('This cannot be undone')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const WrappedDeleteModal = inject('modalsStore')(observer(DeleteModal));
const TranslateDeleteModal = translate()(WrappedDeleteModal);
TranslateDeleteModal.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateDeleteModal;
