import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

import { inject, observer } from "mobx-react";

import ReactAlert from "react-s-alert";

import { translate } from "react-i18next";

import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

import LearnBanner from "../LearnBanner/LearnBanner";
import UpcomingEvents from "../UpcomingEvents/UpcomingEvents";

import { parse, isAfter } from "date-fns";

import { alertConfig, errorMessages as err } from "../../../constants/common";

import { apiURLs } from "../../../configs/apiURLs";

import API from "../../../utils/API";

import Logger from "../../../utils/Logger";

import withTracker from "../../shared/AnalyticsTracker/withTracker";

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
};

const TITLE = "Newsletter";

class Newsletter extends React.Component {
  state = {
    upcomingEvents: [],
  };

  componentDidMount() {
    this.fetchEventsData();
  }

  fetchEventsData = async () => {
    const { appStore } = this.props;

    appStore.updateProgressData();

    /* TODO: Create events store to minimize requests */

    try {
      const response = await API.getData(apiURLs.events);

      const { data } = response;

      let mergedEvents = [];

      Object.keys(data).forEach((type) => {
        mergedEvents = data[type].concat(mergedEvents);
      });

      mergedEvents.sort((a, b) => {
        return isAfter(parse(a.start), parse(b.start));
      });

      this.setState({
        upcomingEvents: mergedEvents.slice(0, 4),
      });

      appStore.updateProgressData(false);
    } catch (error) {
      Logger.error(error);
      if (!!error.response) {
        ReactAlert.error(error.response.data.message, alertConfig);
      } else {
        ReactAlert.error(err.somethingWentWrong, alertConfig);
      }
      appStore.updateProgressData(false);
    }
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        <Helmet
          title={`${t("Learn + Connect")} - ${t(TITLE)}`}
          meta={[
            {
              name: "description",
              content: "",
            },
          ]}
        />

        <Header />

        <main className="main">
          <LearnBanner />
          <div className="article editable">
            <div className="article__header">
              <div className="article__header-item">
                <h2 className="article__title">ISSUE 0.5</h2>
              </div>
              <div className="article__header-item article__header-item--btp">
                <p className="article__subtitle">‘WATA Start’</p>
              </div>
              <div className="article__header-nav">
                <a className="article__header-prev">
                  <span className="icon-pix-arrow-lt"></span>
                </a>
                <a className="article__header-next">
                  <span className="icon-pix-arrow-rt"></span>
                </a>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row-fluid">
                <div className="article__content">
                  <div className="info-block">
                    <div className="editable__content editable__two-column">
                      <p>
                        We’ve put it all together under one roof. Whatever
                        brings you to video game collecting, we’ve got you
                        covered.
                      </p>
                      <p>
                        Perhaps you played games well into the next morning with
                        your buddies… memories of simpler times… the good old
                        days.
                      </p>
                      <p>
                        Maybe you feel right at home in the gaming and
                        collecting community, no matter where you are.
                      </p>
                      <p>
                        You might even be making a serious return on your
                        investment in games. There are lots of ways to get here.
                        We’re glad you’re here.
                      </p>
                      <p>
                        We aim to make your video game collecting even more
                        rewarding, and one of those ways is by keeping you in
                        the loop with all sorts of exciting content through our
                        newsletter. Tips from the experts, featured collectors,
                        and catered articles will cover topics that dive into
                        the world of collecting and certification ranging from
                        uncovering the world of variants to answering questions
                        from you, our readers.
                      </p>
                      <p>
                        To hear the latest in what has been graded, unique
                        market trends, and exciting opportunities to get
                        involved, stay in the loop by subscribing to the WATA
                        Word newsletter. Stay tuned!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <UpcomingEvents data={this.state.upcomingEvents} />
        </main>

        <Footer />
      </div>
    );
  }
}

const WrappedNewsletter = inject("appStore")(observer(Newsletter));
const TranslateNewsletter = translate()(WrappedNewsletter);
TranslateNewsletter.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslateNewsletter, { title: TITLE });
