import React from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';

import classnames from 'classnames';

import { translate } from 'react-i18next';

import {
  Redirect,
} from 'react-router-dom';

import ShippingAddress from './ShippingAddress/ShippingAddress';
import Review from './Review/Review';
import PaymentForm from './PaymentForm/PaymentForm';
import Summary from './Summary/Summary';

import './styles/Checkout.css';

import { routeURLs as URL } from '../../../configs/routeURLs';

import withTracker from '../../shared/AnalyticsTracker/withTracker';


const wrappedPropTypes = {
  cartStore: PropTypes.object.isRequired,
  modalsStore: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const TITLE = 'Checkout';


class Checkout extends React.Component {
  componentWillUnmount() {
    this.props.cartStore.updateIsDataFromCartStore(false);
  }

  render() {
    const {props} = this;
    const {t} = this.props;

    const {from} = props.location.state || {
      from: {
        pathname: URL.submitGame.child.cart.link
      }
    }

    if (props.cartStore.cartStep < 1) {
      return (
        <Redirect to={from} />
      )
    }

    return (
      <div className="submit-game">
        <div className="container-fluid">
          <div className="row-fluid">
            <div className="submit-game__content">
              <div className="submit-game__section section section--top section--gradient section--btp">

                <div className="checkout">
                  <div className="container-fluid">

                    <div className="checkout__steps-wrapper">
                      <div className="row">

                        <ul className="checkout__steps">
                          <li className="checkout__steps-item">
                            <div
                              className={classnames(
                                'checkout__steps-link',
                                {
                                  'active': props.cartStore.cartStep === 1
                                }
                              )}>
                              <span className="icon-map-marker-alt" />
                              {t('SHIPPING ADDRESS')}
                            </div>
                          </li>
                          <li className="checkout__steps-item">
                            <div
                              className={classnames(
                                'checkout__steps-link',
                                {
                                  'active': props.cartStore.cartStep === 2
                                }
                              )}>
                              <span className="icon-check-square" />
                              {t('REVIEW & SUBMIT')}
                            </div>
                          </li>
                          <li className="checkout__steps-item">
                            <div
                              className={classnames(
                                'checkout__steps-link',
                                {
                                  'active': props.cartStore.cartStep === 3
                                }
                              )}>
                              <span className="icon-credit-card" />
                              {t('PAYMENT METHOD')}
                            </div>
                          </li>
                          <li className="checkout__steps-item">
                            <div
                              className={classnames(
                                'checkout__steps-link',
                                'checkout__steps-link--success',
                                {
                                  'active': props.cartStore.cartStep === 4
                                }
                              )}>
                              {t('SUCCESS')}
                            </div>
                          </li>
                        </ul>

                      </div>
                    </div>

                  </div>

                  <div className="checkout__content">
                    {
                      (props.cartStore.cartStep === 1) && <ShippingAddress />
                    }
                    {
                      (props.cartStore.cartStep === 2) && <Review />
                    }
                    {
                      (props.cartStore.cartStep === 3) && <PaymentForm />
                    }
                    {
                      (props.cartStore.cartStep === 4) && <Summary />
                    }
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedCheckout = inject('cartStore', 'modalsStore')(observer(Checkout));
const TranslateCheckout = translate()(WrappedCheckout);
TranslateCheckout.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslateCheckout, {title: TITLE});
