import React from 'react';

import { tt } from '../../../../constants/tooltips';

class GameInfoBlock extends React.Component {

  constructor(props) {
    super(props);

    this.dataGood = (props.game && props.platform);
  }

  render(){
    // Escape if there's nothing for it
    if ( ! this.dataGood){
      console.log("Game Info block error:",this.props);
      return '';
    }

    const {
      platform: {
        name: plat,
      },
      game: {
        publisher,
        year,
        name
      },
      noteForTt
    } = this.props;
    return (
              <label className="games__info-wrapper">
                <h3 className="games__title">{name}{!!this.props.noteForTt && tt.custom(noteForTt,"icon-notes")}</h3>
                <ul className="games__info">
                  <li>{plat}</li>
                  <li>{publisher}</li>
                  <li>{year}</li>
                </ul>
              </label>
    );
  }
}

export default GameInfoBlock;
