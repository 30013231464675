//TODO rename this to "technical"

import Helpers from '../utils/Helpers';

export const socialsURL = {
  facebook: 'https://www.facebook.com/Wata-Games-830676047139190/',
  instagram: 'https://www.instagram.com/watagames/',
  twitter: 'https://twitter.com/thewatagames',
};

export const userSessionTokenKey = 'wataGamesUserSession';
export const userSessionRoleKey = 'wataGamesUserRole';

export const baseAPIurl = () => {
  return `${process.env.REACT_APP_API_URL}/api/`;
};

export const i18nextConfig = {
  fallbackLng: 'en',

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  nsSeparator: false,
  keySeparator: false,

  debug: Helpers.parseBool(process.env.REACT_APP_DEBUG),

  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  react: {
    wait: true
  },
};

export const analyticsConfig = {
  trackingId: process.env.REACT_APP_GA_TRACKING_ID,
  debug: Helpers.parseBool(process.env.REACT_APP_DEBUG),
};

export const langOpts = {
  languageKey: 'wataGamesLanguage',
  defaultAppLanguage: 'en',
  listOfAvailableLanguages: ['en'],
}

export const errorMessages = {
  unknownType: 'Unknown data type',
  tokenExpired: 'Token expired. Please relogin',
  somethingWentWrong: 'Something went wrong',
  isDefaultRequiredValue: 'This field is required',
  isEmail: 'Enter a valid email',
  isUrl: 'Enter a valid URL',
  isTwitterUrl: 'Enter a valid Twitter account URL',
  isLinkedInUrl: 'Enter a valid LinkedIn account URL',
  isFacebookUrl: 'Enter a valid Facebook account URL',
  isInstagramUrl: 'Enter a valid Instagram account URL',
  isCertificationNumber: 'Enter a valid Certification Number (XXXXXX-XXX)',
  isLatinAndNumber: 'This field must contain latin and number symbols',
  equalsField: 'These passwords don’t match',
  isWhitespace: 'You can’t use more then one space or leading and trailing spaces',
  minLength: (length) => {
    return `This field must be at least ${length} characters long`;
  },
  maxLength: (length) => {
    return `This field must not be more than ${length} characters long`;
  },
  maxFileSize: (size) => {
    return `You can’t upload image more than ${size}Mb`
  },
  isInt: 'The value can be only an integer',
  isMoreThan: (value) => {
    return `The value can’t be less than ${value}`;
  },
  isLessThan: (value) => {
    return `The value can’t be more than ${value}`;
  },
};

export const warnMessages = {
  isMoreThan: 'Total declared value can’t be more than $50000',
};

export const modalOptions = {
  modalBodyOpenClassName: 'modal__body--open',
  modalContainer: (className = 'modal--small') => (
    {
      base: `modal ${className}`,
      afterOpen: 'modal--open',
      beforeClose: 'modal--close',
    }
  ),
  modalOverlayContainer: (className = '') => (
    {
      base: `modal-overlay ${className}`,
      afterOpen: 'modal-overlay--open',
      beforeClose: 'modal-overlay--close',
    }
  ),
}

export const maxRating = 5;

export const searchLabels = {
  searchPromptText: 'Type to search media',
  noResultsText: 'No media found',
};

export const placeholders = {
  image: `${process.env.PUBLIC_URL}/images/image-unassigned.svg`,
  userImage: `${process.env.PUBLIC_URL}/images/default-photo.svg`,
}

export const braintreeConfig = {
  paymentOptionPriority: ['card', 'paypal', 'paypalCredit', 'applePay'],
  card: {
    overrides: {
      styles: {
        input: {
         "padding":"0 30px 0 10px",
         "font-family":"Area, sans-serif",
         "font-size":"16px",
         "font-weight":"400",
        },
      }
    }
  }
}

export const alertConfig = {
  position: 'top-right',
  timeout: 5000
};

export const tippyConfig = {
  useContext: true,
  unmountHTMLWhenHide: true,
  delay: 0,
  interactive: true,
  arrow: true,
};

export const tippyConfigLeft = {
  useContext: true,
  unmountHTMLWhenHide: true,
  delay: 0,
  interactive: true,
  arrow: true,
  position: 'left',
};

// export const dateTimeFormat = {
  // dateTime: 'DD/MM/YYYY HH:mm',
// };

export const selectMeasures = {
  maxMenuHeight: 220,
  averageOptionHeight: 35,
};

export const debounceTimeout = 200;

export const headerStickyTrigger = -148;

export const serverConstants = {
  availableTypesOfAttachments: {
    // image
    image: ['.jpeg', '.jpg', '.png', '.bmp'],
    // video
    video: ['.wmv', '.mp4', '.mov', '.avi'],
  }
};

export const imageSizes = {
  profileImage: 245,
};
