import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import ReactAlert from 'react-s-alert';

import {inject, observer} from 'mobx-react';

import { translate } from 'react-i18next';

import FormModel from '../../../models/FormModel';

import Logger from '../../../utils/Logger';
import API from '../../../utils/API';

import Storage from '../../../utils/Storage';

import {
  searchLabels,
  debounceTimeout,
  alertConfig,
} from '../../../constants/common';

import { apiURLs } from '../../../configs/apiURLs';
import { routeURLs as URL } from '../../../configs/routeURLs';

import './styles/SearchForm.css';

import Select from '../../shared/form-elements/Select';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  submitGameStore: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

class SearchFormModel extends FormModel { // <- !!!
  updateSearchFormItem = (key, value) => {
    const newData = {
      [key]: value,
    };

    this.update(newData);

    if (!!value && Object.keys(value).length) {
      this.refToForm.submit();
    }
  };
}

class SearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.store = new SearchFormModel({
      email: '',
    });
  }

  getSelectData = async (value) => {
    const {store} = this;

    if (value.length <= 2) {
      return {
        options: [],
      };
    }

    store.updateLoadingStatus();

    try {
      let result = await API.getData(apiURLs.game.list, {
        name: value,
        page: 1,
        size: 10,
      });

      store.updateLoadingStatus(false);

      return {
        options: result.data.data.filter(item => item.platforms.length > 0),
      };

    } catch(error) {
      Logger.error(error);
      ReactAlert.error(
        error.hasOwnProperty('message') ? error.message : error,
        alertConfig
      );

      store.updateLoadingStatus(false);

      return {
        options: [],
      };
    }
  }

  promptTextCreator = (label) => {
    return `Press Enter for searching "${label}"`;
  }

  onValidSubmit = (model, resetForm, invalidateForm) => {
    const {
      submitGameStore,
      router,
    } = this.props;

    const game = this.store.elements.get('game');

    /* TODO: Find out how to set a first option as value on submit */

    if (!game) return;

    const gameData = {
      platformType: game.platforms[0].type,
      game,
      platform: game.platforms[0],
    }

    // clear the search field
    this.store.updateFormItem('game', '');

    submitGameStore.gameItem.updateGameItem(gameData);
    this.props.appStore.updateSidebarStatus(false);

    Storage.set('watagamesGameItem', gameData);
    window.location = URL.submitGame.child.stepOne.link;
    // router.push(URL.submitGame.child.stepOne.link);
  };

  renderOption = (option) => {
    return (
      <div>
        {option.name}
        <span>
          {' - '}
          <small>{option.platforms[0].name}</small>
        </span>
      </div>
    )
  }

  render() {
    const {t} = this.props;
    const {store} = this;
    return (
      <div className="header-top__search header-top__item">

        <Formsy
          className="search"
          role="search"
          ref={(component) => {
            store.updateRefToForm(component);
          }}
          onValidSubmit={this.onValidSubmit}
        >
          <Select
            async={true}
            name="game"
            autoload={false}
            className="search-select"
            inputClassName="search__item"
            searchPromptText={t(searchLabels.searchPromptText)}
            noResultsText={t(searchLabels.noResultsText)}
            placeholder={t('Search media...')}
            multi={false}
            optionRenderer={this.renderOption}
            searchable={true}
            clearable={false}
            cache={false}
            debounceTimeout={debounceTimeout}
            labelKey="name"
            valueKey="id"
            value={store.elements.get('game')}
            updateStateData={store.updateSearchFormItem}
            getData={this.getSelectData}
            promptTextCreator={this.promptTextCreator}
          />
          <button
            className="search__btn"
            title={t('Apply search')}
            disabled={store.isLoading.get()}
            type="submit">
              <span className="icon-search" />
          </button>
        </Formsy>
      </div>
    );
  }
}

const WrappedSearchForm = inject(
  'router',
  'appStore',
  'submitGameStore'
)(observer(SearchForm));
const TranslateSearchForm = translate()(WrappedSearchForm);
TranslateSearchForm.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateSearchForm;
