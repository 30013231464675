import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  translate,
} from 'react-i18next';

import {inject, observer} from 'mobx-react';



import './styles/OrderPhotos.css';

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  availability: PropTypes.bool,
  className: PropTypes.string,
  status: PropTypes.string,
  data: PropTypes.object,
}

const defaultProps = {
  availability: false
}


class OrderPhotos extends React.Component {
  render() {
    const {
      t,
      data,
      title,
      alt,
      className,
      status,
      availability,
    } = this.props;

    return (
      <div className={
        classnames(
          'order-item order-photos-list',
          className
        )}
      >

        <div className="order-item__header order-photos__header">
          <b>{title}</b>
          {
            availability && (
              <span>{t(status)}</span>
            )
          }
        </div>
        {
          !!data.length && (
            <div className="order-item__content row">
              {
                data.map((photo, i) => {
                  const imageAlt = `${alt} ${i + 1}`;

                  const setImageToModal = () => {
                    this.props.orderDetailsStore.updateImageToModalProps(photo.url, imageAlt)
                    this.props.modalsStore.toggleModal('scaleImage');
                  };

                  return (
                    <button
                      key={photo.id}
                      className="col-xs-12 col-sm-6 col-md-4 order-item__img-wrapper"
                      onClick={setImageToModal}
                      type="button"
                    >
                      <img
                        className="order-item__img"
                        src={photo.url}
                        alt={imageAlt}
                      />
                    </button>
                  );
                })
              }
            </div>
          )
        }
      </div>
    );
  }
}

const WrappedOrderPhotos = inject('appStore', 'orderDetailsStore', 'modalsStore')(observer(OrderPhotos));
const TranslateOrderPhotos = translate()(WrappedOrderPhotos);
TranslateOrderPhotos.wrappedComponent.propTypes = wrappedPropTypes;
TranslateOrderPhotos.wrappedComponent.defaultProps = defaultProps;

export default TranslateOrderPhotos;
