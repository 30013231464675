import React from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';

import {translate} from 'react-i18next';

import './styles/PrintSummary.css';

import Helpers from '../../../utils/Helpers';

import PrintSummaryGameItem from '../PrintSummaryGameItem/PrintSummaryGameItem';

import { shippingOptions } from '../../../constants/shippingOptions';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  orderNumber: PropTypes.number.isRequired,
  box: PropTypes.object.isRequired,
  qrcode: PropTypes.string,
  orderAmount: PropTypes.number.isRequired,
  orderTotalDiscount: PropTypes.number.isRequired,
  orderDeliveryServicePrice: PropTypes.number.isRequired,
};


class PrintSummary extends React.Component {
  render() {
    const {
      t,
      box,
      qrcode,
      orderAmount,
      orderTotalDiscount,
      orderDeliveryServicePrice,
    } = this.props;

    const {round} = Helpers;

    const shippingIndex = shippingOptions
      .map(item => item.id).indexOf(box.deliveryService);

    const countHeaders = ["S","T","SR","WZ","Pro","RH","sum"];
    const counters = box.gameList.ids.reduce((accum,curr) => {
      const item = box.gameList.dataMap[curr];
      let targ=item.data.serviceLevel;
      if(item.data.reholder){
        targ=5;
      } else if (item.data.serviceLevel > 3){
        targ=4;
      }
      accum[targ]+=item.data.count;
      accum[6]+=item.data.count;
      return accum;
    },[0,0,0,0,0,0,0]
      //S,T,SR,WS,Pro,RH
    );
    const counterOutput = countHeaders.map((title,i) => {
      return (
        <div key={title} style={{width:"2.2em",textAlign:"center",float:"left",marginTop:"2.6rem",paddingBottom:"0.2rem"}}>
          {title}<br />{counters[i]}
        </div>
      );
    });

    const orderList = box.gameList.ids.map(id => {
      const item = box.gameList.dataMap[id];
      return <PrintSummaryGameItem
        key={id}
        item={item} />
    });

    return (
      <div className="section-to-print summary-printable__container">
        <div className="summary-printable__company-info">
          <table>
            <tbody>
              <tr>
                <td className="summary-printable__company-logo">
                  <img className="forum__img" src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="img"/>
                </td>
                <td>
                  <p><b>CU/WATA</b></p>
                  <p>
                    1610 E St. Andrew Pl.
                    <br />
                    Suite 150
                  </p>
                  <p>Santa Ana, CA 92705</p>
                </td>
                <td>
                  <p>info@WataGames.com</p>
                </td>
                <td>
                  <p>www.watagames.com</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="summary-printable__top">
          <div className="summary-printable__shipping-address">
            <div className="confirmed__address-wrapper">
              <div className="confirmed__order-wrapper confirmed__order-wrapper--no-center">
                <p className="confirmed__order">
                  {t('ORDER')} <b>#{this.props.orderNumber}</b>
                </p>
              </div>
              <h3 className="confirmed__title">
                {t('SHIP TO')}:
              </h3>
              <div className="order__label--margin">
                {
                  this.props.isSendTogether && (
                    <strong>{t('The order should be sent together')}</strong>
                  )
                }
                <strong>This is not a shipping label! This order sheet should be packed inside of the box with your media.</strong>
              </div>
              <ul className="arrow-list">
                <li className="arrow-list__item arrow-list__item--no-arrow">
                  <p>
                    <b>CU/WATA</b>
                  </p>
                </li>
                <li className="arrow-list__item arrow-list__item--no-arrow arrow-list__item--no-margin">
                  <p>
                    1610 E St. Andrew Pl.
                    <br />
                    Suite 150
                  </p>
                  <p>
                    Santa Ana,
                    {' '}
                    CA
                    {' '}
                    92705,
                    {' '}
                    United States
                  </p>
                </li>
              </ul>
            </div>
          </div>
          {
            !!qrcode && (
              <div className="summary-printable__qr" style={{width:"10rem",height:"10rem",float:"right"}}>
                <img
                  className="summary-printable__image"
                  src={qrcode}
                  alt={t('Your order QR code')} />
              </div>
            )
          }
          <div className="summary-printable__qr" style={{width:"20rem",float:"right"}}>
            {counterOutput}
          </div>
        </div>
        <table className="summary-printable__order-data">
          <thead>
            <tr>
              <th>{t('Qty')}</th>
              <th>{t('Media')}</th>
              <th>{t('Label Type')}</th>
              <th>{t('Service Level')}</th>
              <th>{t('Add-ons')}</th>
              <th>{t('Price')}</th>
            </tr>
          </thead>
          <tbody>
            {orderList}
          </tbody>
        </table>
        <div className="summary-printable__order-summary">
          <h3 className="cart__title">{t('ORDER SUMMARY')}:</h3>
          <div className="cart__price-list box">
            <ul className="price-list">
              <li className="price-list__item">

                <span className="price-list__label">{t('Total')}:</span>

                <div className="price-list__info">
                  <span className="price-list__price">
                    ${round(box.totalNormalPrice).toFixed(2)}
                  </span>
                </div>

              </li>

              <li className="price-list__item">
                <span className="price-list__label price-list__label--border">
                  {t('Shipping & Insurance')}
                  {' '}
                  ({(shippingIndex >= 0) ? shippingOptions[shippingIndex].name : null}):
                  {
                    !!box.totalDeclaredValue && (
                      <div>
                        ({t('Declared Value')} - ${box.totalDeclaredValue})
                      </div>
                    )
                  }
                </span>

                <div className="price-list__info">
                  <span className="price-list__price price-list__price--border">
                    ${round(orderDeliveryServicePrice).toFixed(2)}
                  </span>
                </div>

              </li>
            </ul>

            <ul className="price-list price-list--no-margin">
              <li className="price-list__item">

                <span className="price-list__label price-list__label--border">
                  {t('Discounts')}:
                </span>

                <div className="price-list__info">
                  <span className="price-list__price price-list__price--pink price-list__price--border">
                    -${round(orderTotalDiscount).toFixed(2)}
                  </span>
                </div>

              </li>
            </ul>

            <ul className="price-list price-list--no-margin">
              <li className="price-list__item">

                <span className="price-list__label">
                  {t('Order Total')}:
                </span>
                <div className="price-list__info">
                  <span className="price-list__price price-list__price--total price-list__price--pink">
                    ${orderAmount}
                  </span>
                </div>

              </li>
            </ul>
          </div>
        </div>
        <div className="summary-printable__order-summary">
          <br/>
          <br/>
          <br/>
          <h3 className="confirmed__title">Estimated Turnaround Times:</h3>
          <ul>
            <li>Warp-Zone 5 business days</li>
            <li>SpeedRun 15 business days</li>
            <li>Turbo 30 business days</li>
            <li>Prototype 120 business days</li>
          </ul>
        </div>
      </div>
    );
  }

}

const WrappedPrintSummary = inject('appStore')(observer(PrintSummary));
const TranslatePrintSummary = translate()(WrappedPrintSummary);
TranslatePrintSummary.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslatePrintSummary;
