import OrderItemModel from '../models/OrderItemModel';

import {
  extendObservable,
  action,
} from 'mobx';

class SummaryOrderStore extends OrderItemModel { // <- !!!

  constructor() {
    super();
    extendObservable(this, {
      qrcode: '',
    });

    this.updateQrCode = action(
      'update order Id', (qrcode) => {
      if (this.qrcode !== qrcode) {
        this.qrcode = qrcode;
      }
    });
  }

}

const summaryOrderStore = new SummaryOrderStore();

export default summaryOrderStore;
