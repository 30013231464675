import { gameStates } from "./collectibleEnumsAndDefs";

export const platforms = {
  "1": {
    id: 1,
    name: "Xbox", // Microsoft Xbox
    icon: "microsoft-xbox",
    company: "Microsoft",
    gameStates: [0, 1],
    allowPrototype: true,
    img: 'Controllers/wata-ico_dbico_db_microsoft-xbox.svg',
  },
  "2": {
    id: 2,
    name: "Xbox 360", // Microsoft Xbox 360
    icon: "microsoft-xbox-360",
    company: "Microsoft",
    gameStates: [0, 1],
    allowPrototype: true,
    img: 'Controllers/wata-ico_db_microsoft-xbox-360.svg',
  },
  "3": {
    id: 3,
    name: "NES", // Nintendo NES
    icon: "nintendo-nes",
    company: "Nintendo",
    img: 'Controllers/wata-ico_dbico_db_nintendo-nes.svg',
    gameStates: [0, 1, 2],
    allowPrototype: true,
  },
  "4": {
    id: 4,
    name: "Game Boy Advance", // Nintendo Gameboy Advance
    icon: "nintendo-gameboy-advance",
    company: "Nintendo",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_nintendo-gameboy-advance.svg',
  },
  "5": {
    id: 5,
    name: "Virtual Boy", // Nintendo Virtual Boy
    icon: "nintendo-virtualboy",
    company: "Nintendo",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_nintendo-virtualboy.svg',
  },
  "6": {
    id: 6,
    name: "Nintendo DS", // Nintendo DS
    icon: "nintendo-ds",
    company: "Nintendo",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_nintendo-ds.svg',
  },
  "7": {
    id: 7,
    name: "3DS", // Nintendo 3DS
    icon: "nintendo-3ds",
    company: "Nintendo",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_nintendo-3ds.svg',
  },
  "8": {
    id: 8,
    name: "Super NES", // Nintendo Super NES
    icon: "nintendo-super-nes",
    company: "Nintendo",
    img: 'Controllers/wata-ico_dbico_db_nintendo-super-nes.svg',
    gameStates: [0, 1],
    allowPrototype: true,
  },
  "9": {
    id: 9,
    name: "N64", // Nintendo 64
    icon: "nintendo-64",
    company: "Nintendo",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_nintendo-64.svg',
  },
  "10": {
    id: 10,
    name: "Gamecube", // Nintendo Gamecube
    icon: "nintendo-game-cube",
    company: "Nintendo",
    gameStates: [0, 1],
    allowPrototype: true,
    img: 'Controllers/wata-ico_dbico_db_nintendo-game-cube.svg',
  },
  "11": {
    id: 11,
    name: "Wii", // Nintendo Wii
    icon: "nintendo-wii",
    company: "Nintendo",
    gameStates: [0, 1],
    allowPrototype: true,
    img: 'Controllers/wata-ico_dbico_db_nintendo-wii.svg',
  },
  "12": {
    id: 12,
    name: "Wii U", // Nintendo Wii U
    icon: "nintendo-wii-u",
    company: "Nintendo",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_nintendo-wii-u.svg',
  },
  "13": {
    id: 13,
    name: "Switch", // Nintendo Switch
    icon: "nintendo-switch",
    img: 'Controllers/wata-ico_dbico_db_nintendo-switch.svg',
    gameStates: [0, 1],
    company: "Nintendo",
  },
  "14": {
    id: 14,
    name: "GameBoy", // Nintendo Gameboy
    icon: "nintendo-gameboy",
    company: "Nintendo",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_nintendo-gameboy.svg',
  },
  "15": {
    id: 15,
    name: "Game Boy Color", // Nintendo Gameboy Color
    icon: "nintendo-gameboy-color",
    company: "Nintendo",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_nintendo-gameboy-color.svg',
  },
  "16": {
    id: 16,
    name: "Master System", // Sega Master System
    icon: "sega-master-system",
    company: "Sega",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_sega-master-system.svg',
  },
  "17": {
    id: 17,
    name: "Master System Card", // Sega Master System Card
    icon: "sega-master-system",
    company: "Sega",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_sega-master-system.svg',
  },
  "18": {
    id: 18,
    name: "Genesis", // Sega Genesis
    icon: "sega-genesis",
    company: "Sega",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_sega-genesis.svg',
  },
  "19": {
    id: 19,
    name: "Sega CD", // Sega CD
    icon: "sega-cd",
    company: "Sega",
    gameStates: [0, 1],
    allowPrototype: true,
    img: 'Controllers/wata-ico_dbico_db_sega-cd.svg',
  },
  "20": {
    id: 20,
    name: "Genesis 32X", // Sega 32x
    icon: "sega-32x",
    company: "Sega",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_sega-32x.svg',
  },
  "21": {
    id: 21,
    name: "Sega CD/32X", // Sega CD
    icon: "sega-cd",
    company: "Sega",
    gameStates: [0, 1],
    allowPrototype: true,
    img: 'Controllers/wata-ico_dbico_db_sega-32x.svg',
  },
  "22": {
    id: 22,
    name: "Saturn", // Sega Saturn
    icon: "sega-saturn",
    company: "Sega",
    gameStates: [0, 1],
    allowPrototype: true,
    img: 'Controllers/wata-ico_dbico_db_sega-saturn.svg',
  },
  "23": {
    id: 23,
    name: "Dreamcast", // Sega Dreamcast
    icon: "sega-dreamcast",
    company: "Sega",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_sega-dreamcast.svg',
  },
  "24": {
    id: 24,
    name: "Game Gear", // Sega Gamegear
    icon: "sega-gamegear",
    company: "Sega",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_sega-gamegear.svg',
  },
  "25": {
    id: 25,
    name: "Pico", // Sega Pico
    icon: "",
    gameStates: [0, 1],
    company: "Sega",
  },
  "26": {
    id: 26,
    name: "Playstation", // Sony PlayStation
    icon: "sony-playstation",
    company: "Sony",
    img: 'Controllers/wata-ico_dbico_db_sony-playstation.svg',
    gameStates: [0, 1],
    allowPrototype: true,
  },
  "27": {
    id: 27,
    name: "Playstation 2", // Sony PlayStation 2
    icon: "sony-playstation-2",
    company: "Sony",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_sony-playstation-2.svg',
    allowPrototype: true,
  },
  "28": {
    id: 28,
    name: "Playstation 4", // Sony PlayStation 4
    icon: "sony-playstation-4",
    company: "Sony",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_sony-playstation-4.svg',
  },
  "29": {
    id: 29,
    name: "Playstation 3", // Sony PlayStation 3
    icon: "sony-playstation-3",
    company: "Sony",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_sony-playstation-3.svg',
  },
  "30": {
    id: 30,
    name: "Vita", // Sony Vita
    icon: "sony-vita",
    company: "Sony",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_sony-vita.svg',
  },
  "31": {
    id: 31,
    name: "Sony PSP",  // Sony PSP
    icon: "sony-psp",
    company: "Sony",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_sony-psp.svg',
  },
  "32": {
    id: 32,
    name: "SNK Neo Geo Pocket Color", // SNK Neo Geo Pocket Color
    icon: "snk-neogeo-pocket-color-o",
    company: "SNK",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_snk-neogeo-pocket-color.svg',
  },
  "33": {
    id: 33,
    name: "SNK Neo Geo Pocket", // SNK Neo Geo Pocket
    icon: "snk-neogeo-pocket",
    company: "SNK",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_snk-neogeo-pocket.svg',
  },
  "34": {
    id: 34,
    name: "SNK Neo Geo CD", // SNK Neo Geo CD
    icon: "snk-neogeo-cd",
    company: "SNK",
    gameStates: [0, 1],
    allowPrototype: true,
    img: 'Controllers/wata-ico_dbico_db_snk-neogeo-cd.svg',
  },
  "35": {
    id: 35,
    name: "SNK Neo Geo", // SNK Neo Geo
    icon: "snk-neogeo",
    company: "SNK",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_snk-neogeo.svg',
  },
  "36": {
    id: 36,
    name: "Philips CDI", // Philips CDI
    icon: "philips-cdi",
    company: "Philips",
    gameStates: [0, 1],
    allowPrototype: true,
    img: 'Controllers/wata-ico_dbico_db_philips-cdi.svg',
  },
  "37": {
    id: 37,
    name: "Panasonic 3DO", // Panasonic 3DO
    icon: "panasonic-3do",
    company: "Panasonic",
    gameStates: [0, 1],
    allowPrototype: true,
    img: 'Controllers/wata-ico_dbico_db_panasonic-3do.svg',
  },
  "38": {
    id: 38,
    name: "Nintendo Game Watch", // Nintendo Game Watch
    icon: "nintendo-game-watch",
    company: "Nintendo",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_nintendo-game-_-watch.svg',
  },
  "39": {
    id: 39,
    name: "NEC Turbografx CD", // NEC Turbografx CD
    icon: "nec-turbografx-cd",
    company: "NEC",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_nec-turbografx-cd.svg',
  },
  "40": {
    id: 40,
    name: "NEC Turbografx 16", // NEC Turbografx 16
    icon: "nec-turbografx-16",
    company: "NEC",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_nec-turbografx-16.svg',
  },
  "41": {
    id: 41,
    name: "Xbox One", // Microsoft Xbox One
    icon: "microsoft-xbox-one",
    company: "Microsoft",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_microsoft-xbox-one.svg',
  },
  "42": {
    id: 42,
    name: "Memorex VIS", // Memorex VIS
    icon: "memorex-vis",
    company: "Memorex",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_memorex-vis.svg',
  },
  "43": {
    id: 43,
    name: "Mattel Intellivision", // Mattel Intellivision
    icon: "mattel-intellivision",
    company: "Mattel",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_mattel-intellivision.svg',
  },
  "44": {
    id: 44,
    name: "Magnavox Odyssey 2", // Magnavox Odyssey 2
    icon: "magnavox-odyssey2",
    company: "Magnavox",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_magnavox-odyssey2.svg',
  },
  "45": {
    id: 45,
    name: "Magnavox Odyssey", // Magnavox Odyssey
    icon: "magnavox-odyssey",
    company: "Magnavox",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_magnavox-odyssey.svg',
  },
  "46": {
    id: 46,
    name: "GCE Vectrex", // GCE Vectrex
    icon: "gce-vectrex",
    company: "GCE",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_gce-vectrex.svg',
  },
  "47": {
    id: 47,
    name: "Coleco Colecovision", // Coleco Colecovision
    icon: "coleco-colecovision",
    company: "Coleco",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_coleco-colecovision.svg',
  },
  "48": {
    id: 48,
    name: "Atari Lynx", // Atari Lynx
    icon: "atari-lynx",
    company: "Atari",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_atari-lynx.svg',
  },
  "49": {
    id: 49,
    name: "Atari Jaguar CD", // Atari Jaguar CD
    icon: "atari-jaguar-cd",
    company: "Atari",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_atari-jaguar-cd.svg',
  },
  "50": {
    id: 50,
    name: "Atari Jaguar", // Atari Jaguar
    icon: "atari-jaguar",
    company: "Atari",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_atari-jaguar.svg',
  },
  "51": {
    id: 51,
    name: "7800", // Atari 7800
    icon: "atari-7800",
    company: "Atari",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_atari-7800.svg',
  },
  "52": {
    id: 52,
    name: "5200", // Atari 5200
    icon: "atari-5200",
    company: "Atari",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_atari-5200.svg',
  },
  "53": {
    id: 53,
    name: "2600", // Atari 2600
    icon: "atari-2600",
    company: "Atari",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_atari-2600.svg',
  },
  "56": {
    id: 56,
    name: "MEMOREX",
    icon: "",
    company: "MEMOREX",
    gameStates: [0, 1],
    img: "",
  },
  "101": {
    id: 101,
    name: "Nuon",
    icon: "",
    company: "VM Labs",
    gameStates: [0, 1],
    allowPrototype: true,
    img: "",
  },
  "103": {
    id: 103,
    name: "PC - Jewel Case",
    icon: "",
    company: "CD-Rom",
    gameStates: [0, 1],
    img: "",
    allowPrototype: true,
  },
  "104": {
    id: 104,
    name: "Gizmondo",
    icon: "",
    company: "Tiger Telematics",
    gameStates: [0, 1],
    img: "",
  },
  "105": {
    id: 105,
    name: "Apple II",
    icon: "",
    company: "Apple",
    gameStates: [0, 1],
    img: "",
  },
  "106": {
    id: 106,
    name: "Soundtrack",
    icon: "",
    company: "CD-Rom",
    gameStates: [0, 1],
    img: "",
  },
  "107": {
    id: 107,
    name: "Mega Drive",
    icon: "",
    company: "Sega",
    gameStates: [0, 1],
    allowPrototype: true,
    img: "",
  },
  "108": {
    id: 108,
    name: "Super Famicom",
    icon: "",
    company: "Nintendo",
    gameStates: [0, 1],
    allowPrototype: true,
    img: "",
  },
  "109": {
    id: 109,
    name: "Mega CD",
    icon: "",
    company: "Sega",
    gameStates: [0, 1],
    img: "",
  },
  "120": {
    id: 120,
    name: "PCE CD",
    icon: "",
    company: "NEC",
    gameStates: [0, 1],
    allowPrototype: true,
    img: "",
  },
  "122": {
    id: 122,
    name: "PlayStation 5",
    icon: "sony-playstation-4",
    company: "Sony",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_sony-playstation-4.svg',
  },
  "123": {
    id: 123,
    name: "Xbox Series",
    icon: "microsoft-xbox-one",
    company: "Microsoft",
    gameStates: [0, 1],
    img: 'Controllers/wata-ico_dbico_db_microsoft-xbox-one.svg',
  },
  "127": {
    id: 127,
    name: "Mac",
    icon: "",
    company: "Apple",
    gameStates: [0, 1],
    allowPrototype: true,
    img: "",
  },
  "130": {
    id: 130,
    name: "Film",
    icon: "",
    company: "Film",
    gameStates: [0, 1],
    img: "",
  },
  "131": {
    id: 131,
    name: "TV",
    icon: "",
    company: "TV",
    gameStates: [0, 1],
    img: "",
  },
};
