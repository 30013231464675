import React from 'react';
import PropTypes from 'prop-types';
import ReactAlert from 'react-s-alert';
import {inject, observer} from 'mobx-react';
import Formsy from 'formsy-react';

import {
  translate,
} from 'react-i18next';

import FormModel from '../../../models/FormModel';

import './styles/SubscribeForm.css';

import Input from '../../shared/form-elements/Input';

import Logger from '../../../utils/Logger';
import API from '../../../utils/API';

import {
  errorMessages as err,
  alertConfig,
} from '../../../constants/common';

import { apiURLs } from '../../../configs/apiURLs';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


class SubscribeForm extends React.Component {
  constructor(props) {
    super(props);

    this.store = new FormModel({
      email: '',
    });
  }

  onValidSubmit = async (model, resetForm, invalidateForm) => {
    const {
      appStore,
      t,
    } = this.props;

    const {store} = this;

    appStore.updateProgressData();
    store.updateLoadingStatus();

    try {
      await API.postData(apiURLs.subscribe, store.convertToJS());

      appStore.updateProgressData(false);
      store.updateLoadingStatus(false);

      store.resetForm();

      ReactAlert.success(t('We’ve got your email'), alertConfig);

    } catch (error) {
      Logger.error(error);
      if (!!error.response) {
        const {data} = error.response;

        // invalidateForm or formRef.updateInputsWithError
        Object.keys(data).forEach(key => {
          const value = data[key];

          if (!(key in model)) return;
          // invalidate if key exist in current form model
          invalidateForm({
            [key]: Array.isArray(value) ? value.join(' ') : value,
          });
        });
      } else {
        ReactAlert.error(t(err.somethingWentWrong), alertConfig);
      }

      appStore.updateProgressData(false);
      store.updateLoadingStatus(false);
    }
  }

  render() {
    const {t} = this.props;
    const {store} = this;

    return (
      <Formsy
        className="learn-banner__form"
        ref={(component) => {
          store.updateRefToForm(component);
        }}
        onValidSubmit={this.onValidSubmit}>

        <div className="learn-banner__field-wrapper">
          <Input
            name="email"
            type="email"
            value={store.elements.get('email')}
            updateStateData={store.updateFormItem}
            id="subscribe_email"
            placeholder={t('Your email address...')}
            required
            iconClassName="icon-envelope"

            validations={{
              isEmail: true
            }}
            validationErrors={{
              isEmail: err.isEmail,
              isDefaultRequiredValue: err.isDefaultRequiredValue,
            }} />

          <Input
            name="message"
            type="hidden"
            className="form-field--no-margin" />
        </div>

        <button
          type="submit"
          disabled={store.isLoading.get()}
          className="btn btn--high btn--black">
          <span className="btn__text">
            {t('Subscribe!')}
          </span>
        </button>
      </Formsy>
    );
  };
}

const WrappedSubscribeForm = inject('appStore')(observer(SubscribeForm));
const TranslateSubscribeForm = translate()(WrappedSubscribeForm);
TranslateSubscribeForm.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateSubscribeForm;
