import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import ReactAlert from "react-s-alert";

import { inject, observer } from "mobx-react";
import { translate, Trans } from "react-i18next";

import Helpers from "../../../../utils/Helpers";
import Logger from "../../../../utils/Logger";
import API from "../../../../utils/API";

import {
  alertConfig,
  // warnMessages as warn,
} from "../../../../constants/common";

import { apiURLs } from "../../../../configs/apiURLs";
import { routeURLs as URL } from "../../../../configs/routeURLs";

import "./styles/Review.css";

import GamesList from "../../../shared/GamesList/GamesList";

import PromoForm from "../../PromoForm/PromoForm";

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  cartStore: PropTypes.object.isRequired,
  modalsStore: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

class Review extends React.Component {
  state = {
    isAgree: false,
    isNewsletter: true,
  };

  onCheck = (e) => {
    const { target } = e;

    const useVar = target.name === "agreement" ? "isAgree" : "isNewsletter";

    if (target.checked !== this.state[useVar]) {
      const objVar = {};
      objVar[useVar] = target.checked;
      this.setState(objVar);
    }
  };

  goToPayment = () => {
    this.props.cartStore.updateCartStep(3, {
      newsletterSignup: this.state.isNewsletter,
    });
  };

  goToShipmentForm = () => {
    this.props.cartStore.updateCartStep(1);
  };

  updateData = async (listId) => {
    const { cartStore } = this.props;

    const { data } = cartStore.gameList.dataMap[listId];

    const resData = {
      count: data.count,
      badge: data.badge,
      cleaning: data.cleaning,
      heavyCleaning: data.heavyCleaning,
      report: data.report,
      testing: data.testing,
      reholder: data.reholder,
      notes: data.notes,
      declaredValueType: data.declaredValueType,
      declaredValue: data.declaredValue,
      gameId: data.game.id,
      displayType: data.displayType,
      serviceLevel: data.serviceLevel,
      photoServices: data.photoServices,
      boardPhotos: data.boardPhotos,
      regionId: data.region.id,
      certificationNumber: data.certificationNumber,
      cibPlus: data.cibPlus,
      legacyHolder: data.legacyHolder,
      stickerRemoval: data.stickerRemoval,
      genDesignation: data.genDesignation,
      autographAuth: data.autographAuth,
    };

    try {
      await API.putData(apiURLs.queue.item(listId), resData);
    } catch (error) {
      Logger.error(error);
      ReactAlert.error(error.response.data.message, alertConfig);
    }
  };

  removeItem = async () => {
    const { cartStore, appStore, router, modalsStore, t } = this.props;

    appStore.updateProgressData();

    try {
      await API.deleteData(apiURLs.queue.item(cartStore.activeGameListItemID));

      cartStore.removeItemFromGameList(cartStore.activeGameListItemID);
      cartStore.updateActiveGameListItemID();
      appStore.updateProgressData(false);

      ReactAlert.success(
        t("The game was successfully deleted from the cart"),
        alertConfig
      );
      modalsStore.toggleModal("deleteItem");

      if (!cartStore.gameList.ids.length) {
        cartStore.updateCartStep(0);
        router.push(URL.submitGame.child.cart.link);
      }
    } catch (error) {
      Logger.error(error);
      ReactAlert.error(error.response.data.message, alertConfig);
      appStore.updateProgressData(false);
    }
  };

  render() {
    const { t, cartStore } = this.props;

    const { round } = Helpers;

    const isProceedDisabled =
      !cartStore.gameList.ids.length ||
      !this.state.isAgree;

    const shippingAndInsurance = cartStore.shippingAndInsurance.length
      ? cartStore.shippingAndInsurance[cartStore.deliveryService]
      : cartStore.shippingAndInsurance;

    return (
      <div className="review">
        <h2 className="section__title">{t("Let’s get you on your way.")}</h2>
        <p className="section__subtitle">
          {t(
            "Please take a moment to review your order details, address, and payment method"
          )}
          :
        </p>
        <div className="container-fluid">
          <div className="review__content">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-3">
                <div className="review__item">
                  <h3 className="review__title">
                    {t("SHIPPING ADDRESS")}:
                    <button
                      type="button"
                      onClick={this.goToShipmentForm}
                      className="btn"
                    >
                      <span className="btn__text btn__text--black">
                        {t("Edit")}
                      </span>
                    </button>
                  </h3>
                  <ul className="arrow-list">
                    <li className="arrow-list__item">
                      <p>
                        <b>{cartStore.shippingFormData.fullName}</b>
                      </p>
                      {!!cartStore.shippingFormData.businessName && (
                        <p>{cartStore.shippingFormData.businessName}</p>
                      )}
                    </li>
                    <li className="arrow-list__item arrow-list__item--no-arrow">
                      <p>{cartStore.shippingFormData.address}</p>
                      <p>
                        {cartStore.shippingFormData.city},{" "}
                        {cartStore.shippingFormData.state}{" "}
                        {cartStore.shippingFormData.zip},{" "}
                        {cartStore.shippingFormData.country.name}
                      </p>
                    </li>
                    <li className="arrow-list__item arrow-list__item--no-arrow">
                      <p>
                        <b>{cartStore.shippingFormData.phone}</b>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xs-12 col-sm-6 col-md-3">
                <div className="review__item">
                  <h3 className="review__title">{t("HELP")}:</h3>
                  <ul className="arrow-list">
                    <li className="arrow-list__item">
                      <p>{t("Feeling a little overwhelmed?")}</p>
                      <p>{t("We’re here to help")}:</p>
                    </li>
                    <li className="arrow-list__item arrow-list__item--no-arrow">
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={URL.faq.link}
                        className="btn"
                      >
                        <span className="btn__text btn__text--black">
                          {t("View payment F.A.Q.")}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xs-12  col-md-offset-1 col-md-5">
                {!!cartStore.gameList.ids.length && (
                  <div>
                    <div className="review__item">
                      <h3 className="cart__title">{t("Discount code")}</h3>
                      <PromoForm />
                      {cartStore.promoNotApplicable && (
                        <div style={{ color: 'red' }}>
                          Promo codes not valid on orders containing prototype submissions
                          </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="review__price-list">
                  <h3 className="review__title">{t("ORDER SUMMARY")}:</h3>
                  <div className="box box--arrow-bottom">
                    <ul className="price-list">
                      <li className="price-list__item">
                        <span className="price-list__label">{t("Items")}:</span>
                        <div className="price-list__info">
                          <span className="price-list__price">
                            $
                            {round(cartStore.totalServiceLevelsPrice).toFixed(
                              2
                            )}
                          </span>
                        </div>
                      </li>
                      <li className="price-list__item">
                        <span className="price-list__label">
                          {t("Add-ons")}:
                        </span>
                        <div className="price-list__info">
                          <span className="price-list__price">
                            ${round(cartStore.totalAddOnsPrice).toFixed(2)}
                          </span>
                        </div>
                      </li>
                      <li className="price-list__item">
                        <span className="price-list__label price-list__label--border">
                          {t("Shipping & Insurance")}:
                          {!!cartStore.totalDeclaredValue && (
                            <div>
                              ({t("Declared Value")} - $
                              {cartStore.totalDeclaredValue})
                            </div>
                          )}
                        </span>
                        <div className="price-list__info">
                          {!!shippingAndInsurance ? (
                            <span className="price-list__price price-list__price--border">
                              ${round(shippingAndInsurance).toFixed(2)}
                            </span>
                          ) : (
                            <span className="price-list__price price-list__price--border">
                              {t("N/A")}
                            </span>
                          )}
                        </div>
                      </li>
                    </ul>

                    <ul className="price-list price-list--no-margin">
                      <li className="price-list__item">
                        <span className="price-list__label price-list__label--border">
                          {t("Discounts")}:
                        </span>
                        <div className="price-list__info">
                          <span className="price-list__price price-list__price--pink price-list__price--border">
                            -${round(cartStore.totalDiscount).toFixed(2)}
                          </span>
                        </div>
                      </li>
                    </ul>

                    <ul className="price-list price-list--total price-list--no-margin">
                      <li className="price-list__item">
                        <span className="price-list__label price-list__label--total">
                          {t("Order Total")}:
                        </span>
                        <div className="price-list__info">
                          <span className="price-list__price price-list__price--total price-list__price--pink">
                            ${round(cartStore.totalPrice).toFixed(2)}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="cart__content">
              <GamesList
                removeItem={this.removeItem}
                updateData={this.updateData}
                updateActiveItemID={cartStore.updateActiveGameListItemID}
                isCheckboxesVisible={false}
                labelsClassName="games__labels--black"
                emptyListText={t("You haven’t any game in the cart yet.")}
                data={cartStore.gameList}
              />
            </div>
          </div>

          <div className="review__footer">
            <div
              className="form-field form-field--checkbox"
              style={{ display: "none" }}
            >
              <label className="form-checkbox form-checkbox--black">
                <input
                  type="checkbox"
                  name="newsletter"
                  checked={this.state.isNewsletter}
                  onChange={this.onCheck}
                  id="newsletter"
                />
                <i className="icon-check" />
              </label>
              <div htmlFor="review_agreement" className="form-checkbox__label">
                <p className="shipping-form__save">
                  <Trans i18nKey="Send me WATA updates (timelines, newsletter, etc)">
                    Send me WATA updates (timelines, newsletter, etc)
                  </Trans>
                </p>
              </div>
            </div>
            <div className="form-field form-field--checkbox">
              <label className="form-checkbox form-checkbox--black">
                <input
                  type="checkbox"
                  name="agreement"
                  checked={this.state.isAgree}
                  onChange={this.onCheck}
                  id="review_agreement"
                />
                <i className="icon-check" />
              </label>
              <div htmlFor="review_agreement" className="form-checkbox__label">
                <p className="shipping-form__save">
                  I agree with{" "}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={URL.services.link}
                  >
                    WATA Services Agreement
                  </Link>
                </p>
              </div>
            </div>
            <button
              onClick={this.goToPayment}
              type="button"
              className="btn btn--high btn--md btn--icon-right"
              disabled={isProceedDisabled}
            >
              <span className="btn__text btn__text--black">
                {t("Proceed")}
                <i className="btn__icon icon-pix-arrow-rt" />
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedReview = inject(
  "router",
  "appStore",
  "cartStore",
  "modalsStore"
)(observer(Review));
const TranslateReview = translate()(WrappedReview);
TranslateReview.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateReview;
