import {cloneDeep} from 'lodash';

class Helpers {
  static setCopyrightYear(start, divider = ' \u2014 ') {
    const year = new Date().getFullYear();
    const n_start = parseInt(start, 10);
    let res;

    switch (true) {
      case (n_start === year || isNaN(n_start)):
        res = year;
      break;

      case (n_start > year):
        res = n_start;
      break;

      default:
        res = `${start}${divider}${year}`;
      break;
    }

    return res;
  }

  static customizer(objValue, srcValue) {
    if (Array.isArray(objValue)) {
      return srcValue;
    }
  }

  static parseBool(value) {
    return (value === 'true')
  }

  static isNil(val) {
    return val === '' || val === null || val === undefined;
  }

  static notNil(val) {
    return ( ! this.isNil(val) );
  }

  static updateSliderOptions(options, data) {
    let updatedOptions = cloneDeep(options);
    const {responsive} = updatedOptions;
    const {length} = data;

    const getSlidersNumber = (slidesToShow, length) => {
      return (slidesToShow < length) ? slidesToShow : length;
    }

    updatedOptions.slidesToShow = getSlidersNumber(options.slidesToShow, length);

    if (responsive.length) {
      updatedOptions.responsive = options.responsive.map(item => {
        const {slidesToShow} = item.settings;

        item.settings.slidesToShow = getSlidersNumber(slidesToShow, length);
        return item;
      });
    }
    return updatedOptions;
  }

  static hashLinkScroll(retryCount = 0, retryLimit = 300, offset = 0, setId = null) {
    const w = window;
    const { hash } = w.location;

    if (hash === '') return;

    // Fix Chrome scroll position memorizing
    if ('scrollRestoration' in w.history) {
      w.history.scrollRestoration = 'manual';
    }

    w.requestAnimationFrame(() => {
      const id = setId || hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView(true);
        w.scrollBy(0, offset);
      } else if (retryCount < retryLimit) {
        setTimeout(
          Helpers.hashLinkScroll(retryCount + 1, retryLimit, offset, id), 100
        );
      }
    });
  }

  static move(arr, pos1, pos2) {
    // local variables
    let i;
    let tmp;
    // cast input parameters to integers
    pos1 = parseInt(pos1, 10);
    pos2 = parseInt(pos2, 10);
    // if positions are different and inside array
    if (
      pos1 !== pos2
      && 0 <= pos1
      && pos1 <= arr.length
      && 0 <= pos2
      && pos2 <= arr.length
    ) {
      // save element from position 1
      tmp = arr[pos1];
      // move element down and shift other elements up
      if (pos1 < pos2) {
        for (i = pos1; i < pos2; i++) {
          arr[i] = arr[i + 1];
        }
      }
      // move element up and shift other elements down
      else {
        for (i = pos1; i > pos2; i--) {
          arr[i] = arr[i - 1];
        }
      }
      // put element from position 1 to destination
      arr[pos2] = tmp;

      return arr;
    }
  }

  static closest(arr, num) {
    return arr
      .reduce((prev, curr) => (Math.abs(curr - num) < Math.abs(prev - num) ? curr : prev));
  }

  static currencyFormatter(num){
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });

    return formatter.format(num)
  }

  static round(num, places = 2) {
    // loses accuracy compared to following roundTo function
    return +(Math.round(num + "e+" + places) + "e-" + places);
  }

  static roundTo(n, digits) {
    // does not handle negatives(which would need to be converted to positive then back after rounding)
    //   but is only used for prices
   if (digits === undefined) {
     digits = 0;
   }

   var multiplyBy = Math.pow(10, digits);
   n = parseFloat((n * multiplyBy).toFixed(11));
   var res =(Math.round(n) / multiplyBy);
   return +(res.toFixed(digits));
 }
}

export default Helpers;
