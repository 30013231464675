import { addValidationRule } from 'formsy-react';
import {
  isNil,
  trim
} from 'lodash';


addValidationRule('isLatinAndNumber', (values, value) => {
  return /^.*(?=.*\d)(?=.*[a-zA-Z]).*$/.test(value)
});

addValidationRule('isWhitespace', (values, value) => {
  return !/(^\s+|\s+$)|[ ]{2,}/g.test(value);
});

addValidationRule('isTwitterUrl', (values, value) => {
  return /^(|http(s)?:\/\/(.*\.)?twitter\.com\/[A-z 0-9 _]+\/?)$/g.test(value);
});

addValidationRule('isLinkedInUrl', (values, value) => {
  return /^(|http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z 0-9 _ -]+\/?)$/g.test(value);
});

addValidationRule('isFacebookUrl', (values, value) => {
  return /^(|http(s)?:\/\/((www|m)\.)?(facebook|fb)\.com\/([A-z 0-9 _ - ./]+)\/?)$/g.test(value);
});

addValidationRule('isInstagramUrl', (values, value) => {
  return /^(|https?:\/\/(www\.)?instagram\.com\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)\/?)$/g.test(value);
});

addValidationRule('isCertificationNumber', (values, value) => {
  return /^(?:\s*)\d{6}-\d{3}(?:\s*)$/g.test(value);
});

addValidationRule('isMoreThan', (allFormValues, currentValue, availableMinValue) => {
  return parseFloat(currentValue) >= parseFloat(availableMinValue);
});

addValidationRule('isLessThan', (allFormValues, currentValue, availableMaxValue) => {
  return parseFloat(currentValue) <= parseFloat(availableMaxValue);
});

/*
  @string `otherFieldName`: name of field which should be firstly filled
*/
addValidationRule('isDependentOn', (allFormValues, currentValue, otherFieldName) => {
  return (!isNil(allFormValues[otherFieldName]) && trim(allFormValues[otherFieldName]) !== '');
});
