import React from "react";
import { Tooltip } from "react-tippy-ie11-fix";
import { tippyConfig } from "./common";

const iconExtraStyles = { marginLeft: "3px" };

function customTt(content, icon = "icon-question-circle") {
  return (
    <Tooltip {...tippyConfig} html={<div>{content}</div>}>
      <span
        style={iconExtraStyles}
        className={icon + " text-gradient"}
        aria-hidden="true"
      />
    </Tooltip>
  );
}

export const ttCIB = "Whether you view CIB as Complete In Box or Cartridge+Instructions+Box, we generally need a cartridge or disk, a box, and a manual to grade it CIB. Contact us for exceptions.";
export const ttBoardPhotos = "High definition photos of PCB chips inside game cartridges are digitally preserved.";
export const ttLightCleaning = "Most media have superficial dust, dirt, and/or debris that can be gently removed to increase the media’s appearance and grade. Soil, dust, and residue are expertly removed to avoid damage to your media while also making it more likely to receive a higher grade.";
export const ttHeavyCleaning = "*Temporarily unavailable* For media valued at ≥ $2,500, the cost of cleaning is 1% of the declared value. Cleaner/grader discretion determines what will be cleaned/removed. To be determined on a case-by-case basis so as to not risk damaging the media.";
export const ttPhotoServices = "Show off your newly graded media with high-quality studio digital photos. You’ll receive three professional quality photos right on your dashboard after encapsulation - perfect for sharing online! Note: These photos are separate from the high-resolution photos taken of your media before the grading process.";
export const ttGameTest = "Cartridges &lt; $200 value are tested and identified as “working” or “not working” (additional cost). Game testing will not be conducted if it would damage the game, e.g. for unplayed/mint condition games.";
export const ttBadge = "Canyon Drive Technologies’ “Deep Badge” is a prestigious, visually striking, and non-replicable badge reserved only for games in mint condition to signify their pristine grade and quality. Only games that receive a grade of 9.0 or higher are eligible for the Deep Badge. By selecting the badge upgrade, you are agreeing to pay the cost of the upgrade should your game qualify— you will not be charged unless your game receives a qualifying mint grade.";
export const ttGradersReport = "Temporarily unavailable";
export const ttReholder = "You always want your media to be encapsulated in perfect cases but sometimes accidents happen. Chipped or broke your case? Make sure your collection is always looking sharp in new, clean, WATA cases for a nominal upgrade cost.";
export const ttCibPlus = 'This premium offer includes extra features on top of our CIB service. With CIB Plus, you get the basic service plus pre-grading photos of all the components, cartridge and manual variants listed on the label, and Game Specific Inserts (GSIs) graded and listed on the label.';
export const ttLegacyHolder = "If you would like the original style WATA holder used from 2018 to mid 2023, check this box";
export const ttStickerRemoval = "Through the Sticker Removal service, the WATA team may be able to remove stickers from the plastic seal on your media before encapsulation, which may improve the aesthetics and grade of media. This service, however, is inherently risky—often due to circumstances beyond our control. For more information, check out our FAQs. Note: selecting this add-on doesn’t guarantee sticker removal. If WATA’s team of experts determine that removal of the sticker is likely to damage the game or uncover pre-existing damage, rather than attempting to proceed, we will let you know that and refund the fee associated with this service.";
export const ttGenDesignation = "For the media that mean the most to you, get them authenticated and encapsulated without a grade on the label. This is the perfect option for displaying your favorite well-played media without the condition showing on the label.";
export const ttAutographAuth = "Your item will go through PSA/DNA's industry leading autograph authentication process. If your item and autograph(s) are deemed authentic, your item's label will feature a separate PSA/DNA certification number confirming the autograph(s) authenticity. Please specify the signer(s) of your autograph(s) in the “notes” box during your submission. Limit 5 autographs per item.";

export class tt {
  static custom(msg, icon) {
    return customTt(msg, icon);
  }

  static CIB = customTt(ttCIB);
  static boardPhotos = customTt(ttBoardPhotos);
  static lightCleaning = customTt(ttLightCleaning);
  static heavyCleaning = customTt(ttHeavyCleaning);
  static photoServices = customTt(ttPhotoServices);
  static gameTest = customTt(ttGameTest);
  static badge = customTt(ttBadge);
  static gradersReport = customTt(ttGradersReport);
  static reholder = customTt(ttReholder);
  static cibPlus = customTt(ttCibPlus);
  static legacyHolder = customTt(ttLegacyHolder);
  static stickerRemoval = customTt(ttStickerRemoval);
  static genDesignation = customTt(ttGenDesignation);
  static autographAuth = customTt(ttAutographAuth);
}
