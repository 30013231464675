export const shippingOptions = [
  {
    id: 0,
    name: 'USPS Ground',
    available: false,
  },
  {
    id: 1,
    name: 'UPS Ground',
    available: false,
  },
  {
    id: 2,
    name: 'FedEx Home Delivery',
    available: true,
  },
];
