import React from 'react';

import './styles/Banner.css';

const Banner = (props) => {
  return (
    <div className="scale-banner banner">
      <div className="scale-banner__container">

        <div className="container-fluid">
          <div className="row-fluid">
            <div className="scale-banner__wrapper section section--top section--gradient section--btp">

              <div className="scale-banner__content">
                <div className="scale-banner__info">
                  <h1 className="banner__title">Level up.</h1>
                  <p className="scale-banner__text">
                    Only WATA has a combined 60+ years of experience in the industry. We pride ourselves on accuracy, consistency and the leading video game and media grading scale that provides a universal measure of quality and reliability.
                  </p>
                  <p className="scale-banner__text">
                   As collectors ourselves, we searched far and wide for a grading service we could truly count on to get things right. One that was reliable, repeatable, fair and objective. When we didn’t find one, we created it ourselves. We love our media, and we hold ourselves to the same exacting standards we demand for our own media.
                  </p>
                </div>
              </div>

              <div className="scale-banner__img-wrapper">
                <img className="scale-banner__img" src={`${process.env.PUBLIC_URL}/images/scale-img.png`} alt="img"/>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}


export default Banner;
