export const apiURLs = {
  login: '/login',
  registration: '/registration',
  confirmation: '/confirmation',
  reset: '/reset',
  completePasswordReset: '/completepasswordreset',
  contact: '/contact',
  program: '/contact/program',

  user: {
    data: '/user',
    password: '/user/password',
    avatar: '/user/avatar',
  },

  countries: '/countries',

  file: {
    original: '/file',
    crop: params => `/file?${params}`,
  },

  game: {
    list: '/game',
    data: gameId => `/game/${gameId}`,
  },

  platform: {
    list: '/platform',
    data: platformId => `/platform/${platformId}`,
    all: '/platform/all',
  },

  billing: {
    token: '/billing/token',
    checkout: '/billing/checkout',
    badge: '/billing/badge',
    promo: code => `/billing/promo/${code}`,
  },

  queue: {
    list: '/queue',
    item: gameId => `/queue/${gameId}`,
    submit: '/queue/submit',
    move: '/queue/move',
    orders: '/queue/orders',
  },

  order: {
    details: (orderId) => `/backoffice/order/${orderId}`,
    grades: (certOrOrderNum) => `/label/${certOrOrderNum}`,
    itemDetails: (orderId, queueItemId) => `/queue/order/${orderId}/details/${queueItemId}`,
    report: (orderId, queueItemId) => `/backoffice/order/${orderId}/details/${queueItemId}`,
    updateAddress: (orderNum) => `/backoffice/address/${orderNum}`,
  },

  cert: {
    details: (cert) => `/certdetails/${cert}`,
  },

  cms: {
    any: key => `/cms/${key}`,
    newsletter: 'cms/public/newsletter',
  },

  events: '/common/event',

  subscribe: '/common/subscribe',
};
