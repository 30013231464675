import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {withFormsy} from 'formsy-react';

import './styles/form.css';


const propTypes = {
  name: PropTypes.string.isRequired,
  updateStateData: PropTypes.func.isRequired,
  cols: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  rows: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  maxlength: PropTypes.number,
  value: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
  isPristine: PropTypes.func,
  getErrorMessage: PropTypes.func,
  isCharsLeft: PropTypes.bool,
};

const defaultProps = {
  className: "form-group",
  updateStateData: () => {},
  value: '',
  isCharsLeft: false,
};


class Textarea extends React.Component {
  onChange = (e) => {
    const {props} = this;
    const {name} = props;
    let {value} = e.target;

    props.setValue(value);
    props.updateStateData(name, value);
  }

  render() {
    const {props} = this;
    const {isPristine, getErrorMessage} = this.props;
    const length = !!props.getValue() ? props.getValue().toString().length : 0;
    return (
      <div
        className={classnames({
            'form-field': true,
            'has-error': !isPristine() ? !!getErrorMessage() : false,
          },
          props.className
        )}
      >
        {props.children}
        <div className={classnames(
          'form-field__container',
          'form-field__container--textarea'
        )}>
          <textarea
            cols={props.cols}
            rows={props.rows}
            name={props.name}
            value={props.getValue()}
            onChange={this.onChange}
            maxLength={props.maxLength}
            id={props.id}
            disabled={props.disabled}
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            className={classnames(
              'form-field__item',
              'form-field__item--textarea',
              props.inputClassName
            )}
          />
          {
            (!!props.maxLength && props.isCharsLeft) && (
              <div className="form-field__container--chars-left">
                Characters left: {props.maxLength - length}
              </div>
            )
          }
        </div>
        {(!isPristine() && getErrorMessage())
          ? <div className="help-block text-danger">{getErrorMessage()}</div>
          : null
        }
      </div>
    );
  }
}

Textarea.propTypes = propTypes;

Textarea.defaultProps = defaultProps;

export default withFormsy(Textarea);
