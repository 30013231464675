import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';
import ReactAlert from 'react-s-alert';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { translate } from 'react-i18next';

import API from '../../utils/API';
import Logger from '../../utils/Logger';
import Helpers from '../../utils/Helpers';

import {
  alertConfig,
  braintreeConfig,
} from '../../constants/common';

import {
  prices,
} from '../../constants/prices';

import {
  serviceLevelById
} from '../../constants/serviceLevels';

import { apiURLs } from '../../configs/apiURLs';
import { routeURLs as URL } from '../../configs/routeURLs';

import DropIn from "../shared/DropIn/DropIn";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Invader from '../shared/Invader/Invader';

import withTracker from '../shared/AnalyticsTracker/withTracker';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const TITLE = 'Payment';

class Payment extends React.Component {
  constructor(props){
    super(props);
    const {params} = this.props.match;

    this.state = {
      clientToken: '',
      nonce: '',
      isLoading: false,
      isLoadingOnSubmit: false,
      isConfirmed: false,
      itemMultiplier: 1,
      isBadge: true
      //itemCost: 0,
      //description: '',
      //shortDescription: '',
    };


    if (params.orderNum){
      const setIsBadge = (params.slOrItem.length === 1);

      const descAfterOrder = (setIsBadge)
      ? params.countOrAmount + " " + serviceLevelById(parseInt(params.slOrItem,10)).name + " badge(s)"
      : params.slOrItem;

      const shortDescAfterOrder = (setIsBadge)
      ? params.slOrItem + "-" + params.countOrAmount
      : params.slOrItem;

      this.state.isBadge = setIsBadge;
      this.state.itemCost = (setIsBadge) ? prices.addOns.badge : params.countOrAmount;
      //this.state.itemCost = (setIsBadge) ? 0.01 : params.countOrAmount;
      this.state.itemMultiplier = (setIsBadge) ? parseInt(params.countOrAmount,10) : 1;
      this.state.description = params.orderNum + " " + descAfterOrder;
      this.state.shortDescription = params.orderNum + "-" + shortDescAfterOrder;
    } else {
      this.state.itemCost = prices.addOns.badge;
      this.state.description = "1 Badge";
      this.state.shortDescription = "1 Badge";
    }
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
      isConfirmed: false,
    });
    try {
      const response = await API.getData(apiURLs.billing.token);
      const { clientToken } = response.data;
      this.setState({
        clientToken: clientToken
      });
    } catch (error) {
      ReactAlert.error(error.message, alertConfig);
      Logger.error(error);
    }
  }

  onInstance = (instance) => {
    this.setState({
      isLoading: false,
    });

    return this.instance = instance;
  }

  proceedPay = async () => {
    this.setState({
      isLoading: true,
      isLoadingOnSubmit: true,
    });

    try {
      let response = await this.instance.requestPaymentMethod();

      if (this.state.nonce.length === 0){
        this.setState({
          nonce: response.nonce
        });
      }

      const query = {
        nonce: this.state.nonce,
        total: this.getTotal(),
        description: this.state.description,
        shortDescription: this.state.shortDescription
      };

      const res = await API.postData(apiURLs.billing.badge, query);
      const { data } = res;

      this.setState({
        isLoading: false,
        isLoadingOnSubmit: false,
      });

      if (!data.success) {
        // Error response
        Logger.error(data);
        ReactAlert.error(data.message, alertConfig);

        return;
      }

      // Success response
      this.setState({
        isConfirmed: true,
      });
    } catch (error) {
      Logger.error(error);
      ReactAlert.error(error.message, alertConfig);
      this.setState({
        isLoading: false,
        isLoadingOnSubmit: false,
      });
    }

  }

  getTotal = () => {
    return this.state.itemCost * this.state.itemMultiplier;
  }

  render() {
    const {
      t
    } = this.props;

    return (
      <div className="page-wrapper">
        <Helmet
          title={t(TITLE)}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />

        <Header className="header--gradient-ytr" />

        <main className="main vertical-outer">
          <div className="vertical-inner">
            <div className="submit-game__content">
              <div className="container-fluid">
                <section className="section section--gradient section--top section--ytr submit-game__section">
                  {(() => {
                    return (this.state.isLoading || !this.state.clientToken) ?
                      (
                        <div className="loader-container">
                          <h1 className="confirmation__header text-center">
                            {t('Please wait. Loading next level...')}
                          </h1>
                          <Invader />
                        </div>
                      ) : null
                  })()}
                  {
                    (!!this.state.clientToken && !this.state.isConfirmed) && (
                      <div className={classnames({
                          'hidden': this.state.isLoadingOnSubmit,
                        }
                      )}>
                        <div className="braintree-show-options">
                          <h1 className="confirmation__header">
                            Amount due: {Helpers.currencyFormatter(this.getTotal())}
                          </h1>
                          <p>
                            {this.state.description}
                          </p>
                        </div>
                        <DropIn
                          options={{
                            ...braintreeConfig,
                            authorization: this.state.clientToken,
                            applePay: {
                              displayName: 'Merchant Name', // TODO: Ask for the test applepay account
                              paymentRequest: {
                                flow: 'checkout',
                                total: this.getTotal(),
                                description: this.state.description
                              }
                            },
                            paypal: {
                              flow: 'checkout',
                              amount: this.getTotal(),
                              description: this.state.description,
                              currency: 'USD'
                            },
                            googlePay: {
                              merchantId: 'merchant-id-from-google', // TODO: Ask for the test merchant id account
                              transactionInfo: {
                                totalPriceStatus: 'FINAL',
                                totalPrice: this.getTotal(),
                                description: this.state.description,
                                currencyCode: 'USD'
                              }
                            },
                            paypalCredit: {
                              flow: 'checkout',
                              amount: this.getTotal(),
                              description: this.state.description,
                              currency: 'USD'
                            },
                          }}
                          onInstance={this.onInstance}
                        />
                        {
                          !this.state.isLoading && (
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn btn--high btn--lg"
                                onClick={this.proceedPay}>
                                <span className="btn__text btn__text--black">
                                  {t('Send us your gold!')}
                                </span>
                              </button>
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                  {
                    this.state.isConfirmed && (
                      <div className="text-center">
                        <h1 className="confirmation__header">
                          {t('Congratulations!')}
                        </h1>
                        <h2 className="confirmation__header">
                          {t('We will send your game as soon as possible')}
                        </h2>
                        <Link
                          className="btn"
                          to={URL.dashboard.child.orders.link}
                        >
                          <span className="btn__text btn__text--black">
                            {t('Check your orders')}
                          </span>
                        </Link>
                      </div>
                    )
                  }
                </section>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    );
  }
}

const WrappedPayment = inject('appStore', 'modalsStore', 'router')(observer(Payment));
const TranslatePayment = translate()(WrappedPayment);
TranslatePayment.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslatePayment, {title: TITLE});
