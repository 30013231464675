import React from 'react';

import './styles/Bragging.css';

const Bragging = (props) => {
  return (
    <div className="bragging" id="case">
      <div className="container-fluid">
        <div className="row-fluid">

          <div className="bragging__wrapper section">
            <div className="bragging__content">
              <h2 className="bragging__title text-gradient">PROTECT WHAT YOU COLLECT</h2>
              <div className="bragging__info">

                <div className="bragging__img-wrapper">
                  <img className="bragging__img" src={`${process.env.PUBLIC_URL}/images/bragging-img.png`} alt="img"/>
                </div>

                <ul className="bragging__list">
                  <li className="bragging__item-wrapper">

                    <div className="bragging__item">
                        <span className="bragging__icon icon-process-grade"/>
                      <div className="bragging__list-content">
                        <p className="bragging__list-text">
                        Our holders are the most durable, toughest and crystal clear ones on the market
                        </p>
                      </div>
                    </div>

                  </li>

                  <li className="bragging__item-wrapper">

                    <div className="bragging__item">
                        <span className="bragging__icon icon-wata-perks"/>
                      <div className="bragging__list-content">
                        <p className="bragging__list-text">
                        Six sizing options for both horizontal and vertical art to ensure your media looks its best
                        </p>
                      </div>
                    </div>

                  </li>

                </ul>

              </div>

              <div className="editable">
                <div className="editable__content editable__content--no-margin">
                  <p>
                  We defined the industry standard with our legacy case. Now, we’re revolutionizing the market again. The slim profile, interlocking and stackable like Tetris cases are enhanced to fit even more needs of the collector. The six-sizes range allow for more types of media to be graded (looking at you, international!), new Flow holograms denote even more exclusivity and new security features with a simplified locking mechanism means the sleek look doesn’t interfere with your media’s safety. 
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}


export default Bragging;
