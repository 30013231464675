import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";

/* INFO: You can use the original react-to-print library
if you don't need IE11 support */
// import ReactToPrint from "react-to-print";
import ReactToPrint from "../../../shared/ToPrint/ToPrint";

import { translate, Trans } from "react-i18next";

import { Link } from "react-router-dom";

import "./styles/Summary.css";

import { routeURLs as URL } from "../../../../configs/routeURLs";

import PrintSummary from "../../../shared/PrintSummary/PrintSummary";

const wrappedPropTypes = {
  summaryOrderStore: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

class Summary extends React.Component {
  componentWillUnmount() {
    this.props.summaryOrderStore.clearOrderItem();
    this.props.summaryOrderStore.box.clearDiscount();
  }

  render() {
    const { t, summaryOrderStore } = this.props;

    return (
      <div className="confirmed">
        <h2 className="section__title">
          <Trans i18nKey={"Order confirmed! (now the fun part...)"}>
            Order confirmed! <span>(now the fun part...)</span>
          </Trans>
        </h2>

        <h3 className="text-center section__subtitle">
          <Trans
            i18nKey={
              "NOTE: Be sure to include your printed slip INSIDE your package when shipping"
            }
          >
            NOTE: Be sure to include your printed slip INSIDE your package when
            shipping (select 'Print' to the right and place the printed page
            inside your shipping box. The printout can also be made from the
            open orders portion of your dashboard)
          </Trans>
        </h3>

        <div className="container-fluid">
          <div className="confirmed__content">
            <div className="confirmed__list">
              <ul className="arrow-list">
                <li className="arrow-list__item">
                  <Trans
                    i18nKey={"An email confirmation has been sent to"}
                    email={this.props.appStore.user.email}
                  >
                    An email confirmation has been sent to{" "}
                    <b>{this.props.appStore.user.email}</b> with complete order
                    details. Please note, you are responsible for getting the
                    games to us using your preferred carrier.
                  </Trans>
                </li>
                <li className="arrow-list__item">
                  <Trans
                    i18nKey={
                      "You may check the status of your order at any time"
                    }
                  >
                    You may check the status of your order at any time via the{" "}
                    <Link to={URL.dashboard.link}>User Dashboard</Link> on
                    watagames.com!
                  </Trans>
                </li>
                <li className="arrow-list__item">
                  <Trans i18nKey={"packing tips intro"}>
                    Here are a few preferred packing + shipping instructions to
                    ensure your prized game(s) arrive to WATA safe & sound!
                  </Trans>
                  <ul>
                    <li>
                      Bubble wrap is great - wrap each game in a little bubble
                      wrap. But don't use tape to seal them up as it slows us
                      down in receiving and could damage a game.
                    </li>
                    <li>
                      Use strong boxes - make sure you ship in a sturdy box to
                      protect the games.
                    </li>
                    <li>
                      Use popcorn - use popcorn to create a 4-5 inch barrier
                      around the games on all sides (i.e. fill the bottom of the
                      box, add the games in the middle of the box, surround w/
                      more popcorn and cover them up)
                    </li>
                    <li>Don't overfill a box - especially with heavy games!</li>
                    <li>
                      Don't forget your order form from this page (or printed
                      from open orders on your dashboard) - you'd be surprised
                      how many folks forget.
                    </li>
                    <li>
                      If your order contains WarpZone items, write "WarpZone" on
                      the outside so we know as soon as it arrives.
                    </li>
                  </ul>
                </li>
                <li className="arrow-list__item">
                  <Trans
                    i18nKey={
                      "If you’ve already got a head start on packing and ready to ship everything"
                    }
                  >
                    If you’ve already gotten a head start on packing and you are
                    ready to ship everything via your preferred carrier, please
                    ship your submission to our address (located on this page
                    and on the order sheet).
                  </Trans>
                </li>
              </ul>
            </div>

            <div className="confirmed__info">
              <div className="confirmed__order-wrapper">
                <p className="confirmed__order">
                  {t("ORDER")} <b>#{summaryOrderStore.data.orderNumber}</b>
                </p>
              </div>

              <ReactToPrint
                trigger={() => (
                  <button type="button" className="btn btn--md">
                    <span className="btn__text btn__text--black">
                      {t("Print")}
                    </span>
                  </button>
                )}
                pageStyle={null}
                copyStyles={true}
                content={() => this.toPrint}
              />
              {/*
                <Link
                  to={URL.learn.child.howTo.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn--md">
                  <span className="btn__text btn__text--black">
                    {t('PACKING HOW TO')}
                  </span>
                </Link>
                */}

              <div className="confirmed__address-wrapper">
                <h3 className="confirmed__title">{t("SHIP TO")}:</h3>
                <div className="order__label--margin"></div>
                <ul className="arrow-list">
                  <li className="arrow-list__item">
                    <p>
                      <b>Wata, Inc.</b>
                    </p>
                  </li>
                  <li className="arrow-list__item arrow-list__item--no-arrow arrow-list__item--no-margin">
                    <p>
                      1610 E St. Andrew Pl.
                      <br />
                      Suite 150
                    </p>
                    <p>Santa Ana, CA 92705, United States</p>
                  </li>
                </ul>
              </div>
              <Link to={URL.dashboard.link} className="btn btn--md">
                <span className="btn__text btn__text--black">
                  {t("VIEW DASHBOARD")}
                </span>
              </Link>
            </div>
          </div>

          <div className="confirmed__footer">
            <p className="section__title">{t("Thank you for your order!")}</p>
          </div>
        </div>
        <div className="summary-printable">
          <PrintSummary
            orderAmount={summaryOrderStore.data.amount}
            orderTotalDiscount={summaryOrderStore.data.totalDiscount}
            orderDeliveryServicePrice={summaryOrderStore.data.deliveryServicePrice}
            box={summaryOrderStore.box}
            address={summaryOrderStore.address}
            isSendTogether={summaryOrderStore.data.isSendTogether}
            qrcode={summaryOrderStore.qrcode}
            orderNumber={summaryOrderStore.data.orderNumber}
            ref={(el) => (this.toPrint = el)}
          />
        </div>
      </div>
    );
  }
}

const WrappedSummary = inject(
  "appStore",
  "summaryOrderStore"
)(observer(Summary));
const TranslateSummary = translate()(WrappedSummary);
TranslateSummary.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateSummary;
