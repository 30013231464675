import React from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';

import { translate } from 'react-i18next';

import {NavLink, withRouter} from 'react-router-dom';

import { routeURLs as URL } from '../../../configs/routeURLs';

const wrappedPropTypes = {
  toggleDropdown: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};


class AccountMenu extends React.Component {
  logOut = () => {
    this.props.router.push(URL.logout.link);
  }

  render() {
    const {t} = this.props;

    return (
      <div className="account-menu">
        <ul className="list">
          {/*
            <li className="list__item">
              <NavLink
                exact
                to={URL.dashboard.link}
                className="list__link list__link--space-between">
                {t('Overview')} <span className="icon-account" />
              </NavLink>
            </li>
            */}
          <li className="list__item">
            <NavLink
              to={URL.dashboard.child.orders.link}
              className="list__link list__link--space-between">
              {t('Order History')} <span className="icon-orders" />
            </NavLink>
          </li>
          <li className="list__item">
            <NavLink
              to={URL.dashboard.child.profile.link}
              className="list__link list__link--space-between">
              {t('Manage Account')} <span className="icon-cog" />
            </NavLink>
          </li>
          <li className="list__item">
            <button
              className="list__button"
              onClick={this.logOut}
              type="button">
              <div className="list__link list__link--space-between">
                {t('Log out')} <span className="icon-exit" />
              </div>
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

const WrappedAccountMenu = inject('appStore', 'router')(observer(AccountMenu));
const TranslateAccountMenu = translate()(WrappedAccountMenu);
// TranslateAccountMenu.wrappedComponent.propTypes = wrappedPropTypes;

const RouterAccountMenu = withRouter(TranslateAccountMenu);
RouterAccountMenu.wrappedComponent.propTypes = wrappedPropTypes;

export default RouterAccountMenu;

// export default TranslateAccountMenu;
