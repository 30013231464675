export const regions = [
  // {
  //   id: 230,
  //   name: "United States",
  //   code: "US",
  // },
  // {
  //   id: 39,
  //   name: "Canada",
  //   code: "CA",
  // },
  // {
  //   id: 110,
  //   name: "Japan",
  //   code: "JP",
  // },
  // {
  //   id: 229,
  //   name: "United Kingdom",
  //   code: "GB",
  // },
  {
    id: 500,
    name: "North America",
    code: "NA",
  },
  {
    id: 501,
    name: "South America",
    code: "SA",
  },
  {
    id: 502,
    name: "Europe",
    code: "EU",
  },
  {
    id: 503,
    name: "Asia",
    code: "Asia",
  },
  {
    id: 504,
    name: "Africa",
    code: "AF",
  },
  {
    id: 600,
    name: "Worldwide",
    code: "WW",
  },
];
