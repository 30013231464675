export const serviceLevels = [
  {
    id: 0,
    name: "Select",
    icon: "sl-select",
    key: "select",
    submissionTypes: ["standard"],
    shortDescription: "Currently paused" /*The most cost-conscious approach*/,
    turnaroundEstimate: 170,
    disabled: true
  },
  {
    id: 1,
    name: "Turbo",
    icon: "sl-turbo",
    key: "turbo",
    submissionTypes: ["standard"],
    shortDescription: "You’re patient and can wait, but not too long",
    turnaroundEstimate: 30,
    disabled: false
  },
  {
    id: 2,
    name: "SpeedRun",
    icon: "sl-speedrun",
    key: "speedRun",
    submissionTypes: ["standard"],
    shortDescription: "When you can’t wait",
    turnaroundEstimate: 15,
    disabled: false
  },
  {
    id: 3,
    name: "WarpZone",
    icon: "sl-warpzone",
    key: "warpZone",
    submissionTypes: ["standard"],
    shortDescription: "When you really, seriously can’t wait",
    turnaroundEstimate: 5,
    disabled: false
  },
  {
    id: 4,
    name: "Prototype",
    icon: "add-report",
    key: "proStandard",
    submissionTypes: ["prototype"],
    shortDescription:
      "Prototype submission",
    turnaroundEstimate: 45,
    disabled: false
  },
  {
    id: 5,
    name: "Extensive",
    icon: "glossary",
    key: "proExtensive",
    submissionTypes: [],
    shortDescription:
      "Standard services with a more detailed report plus a printed copy of the report and in-depth gameplay review (if applicable) including side-by-side screenshots with notable differences to final production game",
    turnaroundEstimate: 45,
    disabled: true
  }
];

export const serviceLevelByKey = key =>
  serviceLevels.find(sl => sl.key === key);
export const serviceLevelById = id => serviceLevels.find(sl => sl.id === id);
