import React from 'react';
import PropTypes from 'prop-types';

import {inject, observer} from 'mobx-react';
import {
  translate,
  // Trans,
} from 'react-i18next';
import Helmet from 'react-helmet';

import {groupBy} from 'lodash';

import PlatformsAndCompanies from './PlatformsAndCompanies/PlatformsAndCompanies';
import Submission from './Submission/Submission';

import ReactAlert from 'react-s-alert';
import Logger from '../../../utils/Logger';

import withTracker from '../../shared/AnalyticsTracker/withTracker';

import API from '../../../utils/API';
import { alertConfig } from '../../../constants/common';

import { apiURLs } from '../../../configs/apiURLs';

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
};

const TITLE = 'Submission Process';

class SubmissionProcess extends React.Component {
  state = {
    platformsByCompany: null,
  }

  componentDidMount() {
    this.fetchPlatforms();
  }

  fetchPlatforms = async () => {
    const {appStore} = this.props;
    appStore.updateProgressData();
    try {
      const response = await API.getData(apiURLs.platform.all);
      const groupByCompany = groupBy(response.data.data, 'company');

      const companies = {};

      Object.keys(groupByCompany).forEach(company => {
        companies[company] = {
          name: company,
          platforms: groupByCompany[company],
        };
      });

      this.setState({
        platformsByCompany: companies,
      });

      appStore.updateProgressData(false);

    } catch (error) {
      Logger.error(error);
      ReactAlert.error(error.message, alertConfig);
      appStore.updateProgressData(false);
    }
  }

  render() {
    const {props} = this;
    const {t} = props;

    return (
      <div>
        <Helmet
          title={`${t('What We Do')} - ${t(TITLE)}`}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />
        <PlatformsAndCompanies companies={this.state.platformsByCompany} />
        <Submission />

      </div>
    );
  }
}

const WrappedSubmissionProcess = inject('appStore')(observer(SubmissionProcess));
const TranslateSubmissionProcess = translate()(WrappedSubmissionProcess);
TranslateSubmissionProcess.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslateSubmissionProcess, {title: TITLE});
