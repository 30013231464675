import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import Helmet from 'react-helmet';
import {inject, observer} from 'mobx-react';
import Storage from '../../utils/Storage';
import AuthSession from '../../utils/AuthSession';
import { routeURLs } from '../../configs/routeURLs';
import { Redirect } from 'react-router-dom';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import withTracker from '../shared/AnalyticsTracker/withTracker';

const propTypes = {
    t: PropTypes.func.isRequired,
    modalsStore: PropTypes.object.isRequired,
    appStore: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  };
  
const TITLE = 'Login';

class Login extends React.Component {
  state = {
    modalShown: false,
  }
    
  componentDidMount() {
    const {
        appStore,
        modalsStore,
        router,
        location: {
            search
        },
      } = this.props;

    // See if there's a redirect query parameter to a relative url
    const r = new URLSearchParams(search).get('r');
    if (r && new URL(document.baseURI).origin === new URL(r, document.baseURI).origin) {
        Storage.set('watagamesNextRedirect', r);
    }

    if (!AuthSession.isTokenSet() && !appStore.isUserLoggedIn) {
        modalsStore.toggleModal('logIn');
        this.setState({modalShown: true});
    } else {
        const dest = Storage.get('watagamesNextRedirect') || routeURLs.dashboard.link;
        Storage.remove('watagamesNextRedirect');
        if (Storage.get('watagamesDirectNavigate')) {
          Storage.remove('watagamesDirectNavigate');
          router.push(dest);
        } else {
          window.location = dest;
        }
    }
  }
    
  render() {
    const {t, modalsStore, location} = this.props;

    return !this.state.modalShown || modalsStore.modals.logIn || modalsStore.modals.forgot ? (
      <div className="page-wrapper">
        <Helmet
          title={t(TITLE)}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />

        <Header className="header--gradient-btp" />

        <main className="main">
          <div className="faq info-page">
            <div className="container-fluid">
              <div className="row-fluid">
                <div className="info-page__wrapper section section--top section--gradient section--btp">

                  <div className="info-page__content">
                    <h2 className="section__title">
                      {t('Login')}
                    </h2>
                    <div style={{height: '500px'}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    ) : (
        <Redirect to={
            {
              pathname: routeURLs.home.link,
              state: { from: location },
            }
          }
          />        
    );
  }
}


Login.propTypes = propTypes;

const TranslateLogin = translate()(inject('router', 'appStore', 'modalsStore')(observer(Login)));

export default withTracker(TranslateLogin, {title: TITLE});
