import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import classnames from 'classnames';

import {
  debounce,
} from 'lodash';

import {withFormsy} from 'formsy-react';
import withDrop from './withDrop';

import './styles/form.css';

import 'react-select/dist/react-select.css';
import './styles/Select.css';

const propTypes = {
  name: PropTypes.string.isRequired,
  updateStateData: PropTypes.func.isRequired,

  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(
      PropTypes.object,
    ),
  ]),
  options: PropTypes.arrayOf(PropTypes.object),

  url: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  matchProp: PropTypes.string,
  searchPromptText: PropTypes.string,
  noResultsText: PropTypes.string,
  multi: PropTypes.bool,
  async: PropTypes.bool,
  creatable: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  valueRenderer: PropTypes.func,
  backspaceRemoves: PropTypes.bool,
  cache: PropTypes.bool,
  getData: PropTypes.func,
  isPristine: PropTypes.func,
  getErrorMessage: PropTypes.func,
  ignoreCase: PropTypes.bool,
  minLength: PropTypes.number,
};

const defaultProps = {
  autoload: false,
  disabled: false,
  readOnly: false,
  multi: false,
  clearable: false,
  backspaceRemoves: true,
  searchPromptText: 'Type to search',
  noResultsText: 'No results found',
  cache: false,
  matchProp: 'label',
  creatable: false,
  simpleValue: false,
  ignoreCase: true
};

class Select extends React.Component {
  constructor(props) {
    super();
    this.debounceSelectData = debounce((value, cb) => {
      this.getData(value, cb)
        .then(res => cb(undefined, res))
        .catch(res => cb(res, undefined));
    }, props.debounceTimeout);
  }

  onChange = (value) => {
    const {name, updateStateData, multi} = this.props;

    if (!multi && Array.isArray(value) && !value.length) {
      value = {
        id: ''
      }
    }
    updateStateData(name, value);
  }

  getData = (value) => {
    const {async, getData} = this.props;
    if (async && getData) {
      return this.props.getData(value);
    }
  }

  render() {
    const {props} = this;

    const {
      isPristine,
      getErrorMessage,
      async,
      creatable
    } = props;

    const inputClassName = classnames(
      'form-field__item',
      'form-field__item--select',
      props.inputClassName,
      {
        'read-only': props.readOnly,
        'drop-up': props.dropUp,
      }
    );

    let SelectType = async ? ReactSelect.Async : ReactSelect;
    if (creatable) {
      SelectType = ReactSelect.Creatable;
    } else if (creatable && async) {
      SelectType = ReactSelect.AsyncCreatable;
    }
    return (
      <div
        className={classnames({
            'form-field': true,
            'has-error': !isPristine() ? !!getErrorMessage() : false,
          },
          props.className
        )}
      >
        {props.children}
        <div className={classnames(
          'form-field__container',
          'form-field__container--select'
        )}>
          <SelectType
            className={inputClassName}
            autoload={props.autoload}
            disabled={props.disabled || props.readOnly}
            name={props.name}
            simpleValue={props.simpleValue}
            multi={props.multi}
            searchable={props.searchable}
            clearable={props.clearable}
            placeholder={props.placeholder}
            value={props.getValue()}
            valueRenderer={props.valueRenderer}
            onChange={this.onChange}
            loadOptions={!!props.debounceTimeout ? this.debounceSelectData : this.getData}
            options={props.options}
            optionClassName={props.optionClassName}
            optionRenderer={props.optionRenderer}
            optionComponent={props.optionComponent}
            backspaceRemoves={props.backspaceRemoves}
            valueKey={props.valueKey}
            labelKey={props.labelKey}
            cache={props.cache}
            matchProp={props.matchProp}
            searchPromptText={props.searchPromptText}
            noResultsText={props.noResultsText}
            ref={(inst) => (this.selectInst = inst)}
            ignoreCase={props.ignoreCase}
            />
        </div>
        {(!isPristine() && getErrorMessage())
          ? <div className="help-block text-danger">{getErrorMessage()}</div>
          : null
        }
      </div>
    );
  }
}

Select.propTypes = propTypes;

Select.defaultProps = defaultProps;

const DropSelect = withDrop(Select);
export default withFormsy(DropSelect);
