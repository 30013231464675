import React from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { BrowserNavLink } from '../../shared/BrowserNavLink';

import { I18n } from 'react-i18next';

import './styles/Links.css';

import { routeURLs as URL } from '../../../configs/routeURLs';

import AuthSession from '../../../utils/AuthSession';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
};


const FooterMenu = (props) => {
  return (
    <I18n ns="translations">
      {
        (t, {i18n}) => (
          <div className="footer__menu">
            <div className="footer__menu-item">
              <ul className="links">
                <li className="links__item">
                  <NavLink
                    to={{pathname: URL.learn.child.blog.link}}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="links__link">
                    <span className="links__icon icon-forum" />
                    {t('Blog')}
                  </NavLink>
                </li>
                <li className="links__item">
                  <NavLink
                    exact
                    to={URL.faq.link}
                    className="links__link">
                    <span className="links__icon icon-faq" />
                    {' '}
                    {t('F.A.Q.')}
                  </NavLink>
                </li>
                <li className="links__item">
                  <NavLink
                    exact
                    to={URL.glossary.link}
                    className="links__link">
                    <span className="links__icon icon-glossary" />
                    {' '}
                    {t('Glossary')}
                  </NavLink>
                </li>
                {/*
                  <li className="links__item">
                    <NavLink
                      exact
                      to={URL.learn.child.howTo.link}
                      className="links__link">
                      <span className="links__icon icon-lightbulb" />
                      {' '}
                      {t('How To')}
                    </NavLink>
                  </li>
                */}
              </ul>
            </div>
            <div className="footer__menu-item">
              <h3 className="footer__title">
                {t('Links')}
              </h3>
              <ul className="footer__list">
                <li className="footer__list-item">
                  <NavLink
                    exact
                    to={URL.home.link}
                    className="footer__list-link">
                    {t('Home')}
                  </NavLink>
                </li>
                <li className="footer__list-item">
                  <NavLink
                    exact
                    to={URL.whoWeAre.link}
                    className="footer__list-link">
                    {t('Who We Are')}
                  </NavLink>
                </li>
                <li className="footer__list-item">
                  <NavLink
                    to={URL.whatWeDo.link}
                    className="footer__list-link">
                    {t('What We Do')}
                  </NavLink>
                </li>
                <li className="footer__list-item">
                  <BrowserNavLink
                    to={URL.submitGame.link}
                    className="footer__list-link">
                    {t('Submit Here')}
                  </BrowserNavLink>
                </li>
                <li className="footer__list-item">
                  <NavLink
                    to={URL.learn.link}
                    className="footer__list-link">
                    {t('Learn + Connect')}
                  </NavLink>
                </li>
                <li className="footer__list-item">
                  {
                    (!AuthSession.isTokenSet() && !props.appStore.isUserLoggedIn) ? (
                      <NavLink
                        exact
                        to={URL.join.link}
                        className="footer__list-link">
                        {t('Join')}
                      </NavLink>
                    ) : (
                      <NavLink
                        to={URL.dashboard.link}
                        className="footer__list-link">
                        {t('Dash')}
                      </NavLink>
                    )
                  }
                </li>
              </ul>
            </div>
            <div className="footer__menu-item">
              <h3 className="footer__title">
                {t('Corporate')}
              </h3>
              <ul className="footer__list">
                <li className="footer__list-item">
                  <NavLink
                    exact
                    to={URL.contactUs.link}
                    className="footer__list-link">
                    {t('Contact Us')}
                  </NavLink>
                </li>
                <li className="footer__list-item">
                  <NavLink
                    exact
                    to={URL.dealer.link}
                    className="footer__list-link">
                    {t('Dealer Program')}
                  </NavLink>
                </li>
                <li className="footer__list-item">
                  <NavLink
                    exact
                    to={URL.legal.link}
                    className="footer__list-link">
                    {t('Legal')}
                  </NavLink>
                </li>
                <li className="footer__list-item">
                  <NavLink
                    exact
                    to={URL.services.link}
                    className="footer__list-link">
                    {t('Service Agreement')}
                  </NavLink>
                </li>
                {/* <li className="footer__list-item">
                  <NavLink
                    exact
                    to={URL.careers.link}
                    className="footer__list-link">
                    {t('Careers')}
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>
        )
      }
    </I18n>
  );
}

const WrappedFooterMenu = inject('appStore')(observer(FooterMenu));
WrappedFooterMenu.wrappedComponent.propTypes = wrappedPropTypes;


export default WrappedFooterMenu;
