import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {withFormsy} from 'formsy-react';

import './styles/form.css';

const propTypes = {
  name: PropTypes.string.isRequired,

  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  iconClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  id: PropTypes.string,
  updateStateData: PropTypes.func,
  isPristine: PropTypes.func,
  getErrorMessage: PropTypes.func,
};

const defaultProps = {
  type: 'text',
  updateStateData: () => {},
  value: '',
  iconClassName: '',
};

class Input extends React.Component {
  onChange = (e) => {
    const {props} = this;
    const {name} = props;
    let {value} = e.currentTarget;

    props.setValue(value);
    props.updateStateData(name, value);
  }

  render() {
    const {props} = this;
    const {isPristine, getErrorMessage} = props;

    return (
      <div
        className={classnames({
            'form-field': true,
            'has-error': !isPristine() ? !!getErrorMessage() : false,
          },
          props.className
        )}>
        {props.children}
        <div className={classnames(
          'form-field__container',
          `form-field__container--${props.type}`
        )}>
          <input
            name={props.name}
            type={props.type}
            disabled={props.disabled}
            readOnly={props.readOnly}
            value={props.getValue()}
            onFocus={props.onFocus}
            onChange={this.onChange}
            id={props.id}
            placeholder={props.placeholder}
            className={classnames(
              'form-field__item',
              props.inputClassName
            )} />
          {
            !!props.iconClassName && <label
              className="form-field__icon"
              htmlFor={props.id}>
                <i className={props.iconClassName} aria-hidden="true" />
            </label>
          }
        </div>
        {(!isPristine() && getErrorMessage())
          ? <div className="help-block text-danger">{getErrorMessage()}</div>
          : null
        }
      </div>
    );
  }
}

Input.propTypes = propTypes;

Input.defaultProps = defaultProps;

export default withFormsy(Input);
