/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import React from 'react';
import ReactGA from 'react-ga';

import {withRouter} from 'react-router-dom';

import {inject, observer} from 'mobx-react';

function withTracker(WrappedComponent, options = {}) {
  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page, options.title);
  };

  const HOC = class extends React.Component {
    componentDidMount() {
      const page = this.props.location.pathname;


      if (this.props.appStore.isAnalyticsInitialised) {
        trackPage(page);
      }
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage && this.props.appStore.isAnalyticsInitialised) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  const WrappedHOC = inject('appStore')(observer(HOC));

  return withRouter(WrappedHOC);
}

export default withTracker;
