import React from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';
import { BrowserNavLink } from '../../shared/BrowserNavLink';

import {
  withRouter,
  NavLink,
  Link,
} from 'react-router-dom';

import classnames from 'classnames';

import { translate } from 'react-i18next';

import { userSessionRoleKey } from '../../../constants/common';

import { routeURLs as URL } from '../../../configs/routeURLs';

import AuthSession from '../../../utils/AuthSession';
import Storage from '../../../utils/Storage';

import Logo from '../../shared/Logo/Logo';

import './styles/HeaderNav.css';
import './styles/Submenu.css';
import './styles/Logo.css';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  isSticky: PropTypes.bool.isRequired,
};


class HeaderNav extends React.Component {
  render() {
    const {
      t,
      location,
      isSticky,
    } = this.props;

    const {hash} = location;

    return (
      <div className="navigation-container">
        <nav className="header__nav navigation">
          <ul className="navigation__list">
            <li className="navigation__item">
              <NavLink
                exact
                to={URL.home.link}
                className="navigation__link">
                {t('Home')}
              </NavLink>
            </li>
            <li className="navigation__item">
              <NavLink
                to={URL.whoWeAre.link}
                className="navigation__link">
                {t('Who We Are')}
              </NavLink>
            </li>
            <li className="navigation__item">
              <NavLink
                to={URL.whatWeDo.link}
                className="navigation__link">
                {t('What We Do')}
              </NavLink>
              <ul className="submenu">
                <li className="submenu__item">
                  <NavLink
                    to={URL.whatWeDo.child.submissionProcess.link}
                    className="submenu__link">
                    {t('Submission Process')}
                  </NavLink>
                </li>
                <li className="submenu__item">
                  <NavLink
                    to={URL.whatWeDo.child.pricing.link}
                    className="submenu__link">
                    {t('Pricing')}
                  </NavLink>
                </li>
                <li className="submenu__item">
                  <NavLink
                    to={URL.whatWeDo.child.wataScale.link}
                    className="submenu__link">
                    {t('The WATA Scale')}
                  </NavLink>
                </li>
                <li className="submenu__item">
                  <NavLink
                    to={URL.whatWeDo.child.ourTechnology.link}
                    className="submenu__link">
                    {t('Our Technology')}
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>

          {isSticky ?  
            <Logo
              isSquare={true}
              src="logo-flat-blue-dot.png"
              srcSet="logo-flat-blue-dot@2x.png"
              linkClassName="logo"
              imageClassName="logo__img--flat"
            /> :  
            <Logo
              isSquare={true}
              src="logo.png"
              srcSet="logo@2x.png"
              linkClassName="logo"
              imageClassName="logo__img"
            />
          }
         

          <ul className="navigation__list">
            <li className="navigation__item">
              <BrowserNavLink
                to={URL.submitGame.link}
                className="navigation__link">
                {t('Submit Here')}
              </BrowserNavLink>
              <ul className="submenu submit-game__submenu">
                <li className="submenu__item">
                  <span
                    className={classnames(
                      'submenu__link-submit',
                      {
                        'active': location.pathname === URL.submitGame.child.stepOne.link,
                      },
                    )}>
                    <span>01 |</span> {t('Select Media')}
                  </span>
                </li>
                <li className="submenu__item">
                  <span
                    className={classnames(
                      'submenu__link-submit',
                      {
                        'active': location.pathname === URL.submitGame.child.stepTwo.link,
                      },
                    )}>
                    <span>02 |</span> {t('Media Details')}
                  </span>
                </li>
                <li className="submenu__item">
                  <span
                    className={classnames(
                      'submenu__link-submit',
                      {
                        'active': location.pathname === URL.submitGame.child.stepThree.link,
                      },
                    )}>
                    <span>03 |</span> {t('Options+Add-ons')}
                  </span>
                </li>
                <li className="submenu__item">
                  <span
                    className={classnames(
                      'submenu__link-submit',
                      {
                        'active': location.pathname === URL.submitGame.child.cart.link,
                      },
                    )}>
                    <span>04 |</span> {t('Shopping cart')}
                  </span>
                </li>
                <li className="submenu__item">
                  <span
                    className={classnames(
                      'submenu__link-submit',
                      {
                        'active': location.pathname === URL.submitGame.child.checkout.link,
                      },
                    )}>
                    <span>05 |</span> {t('Checkout')}
                  </span>
                </li>
              </ul>
            </li>
            <li className="navigation__item">
              <a
                href="/populations/index.html"
                target="_blank"
                className="navigation__link">
                Pop Reports
              </a>
            </li>
            <li className="navigation__item">
              <NavLink
                to={URL.learn.link}
                className="navigation__link">
                {t('Learn+Connect')}
              </NavLink>

              <ul className="submenu">
                <li className="submenu__item">
                  <NavLink
                    to={URL.learn.child.newsletter.link}
                    className="submenu__link">
                    {t('Newsletter')}
                  </NavLink>
                </li>
                {/*
                  <li className="submenu__item">
                    <NavLink
                      to={URL.learn.child.howTo.link}
                      className="submenu__link">
                      {t('How to')}
                    </NavLink>
                  </li>
                */}
                <li className="submenu__item">
                  <NavLink
                    to={URL.learn.child.events.link}
                    className="submenu__link">
                    {t('Events')}
                  </NavLink>
                </li>
                {/* <li className="submenu__item">
                  <NavLink
                    to={URL.learn.child.database.link}
                    className="submenu__link">
                    {t('Database')}
                  </NavLink>
                </li>
                <li className="submenu__item">
                  <NavLink
                    to={URL.learn.child.registry.link}
                    className="submenu__link">
                    {t('Registry')}
                  </NavLink>
                </li>
                <li className="submenu__item">
                  <NavLink
                    to={URL.learn.child.census.link}
                    className="submenu__link">
                    {t('Census')}
                  </NavLink>
                </li>
                <li className="submenu__item">
                  <NavLink
                    to={URL.learn.child.ebay.link}
                    className="submenu__link">
                    {t('eBay')}
                  </NavLink>
                </li> */}
                <li className="submenu__item">
                  <NavLink
                    target="_blank"
                    rel="noopener noreferrer"
                    to={{pathname: URL.learn.child.blog.link}}
                    className="submenu__link">
                    {t('Blog')}
                  </NavLink>
                </li>
              </ul>
            </li>
            {
              (!AuthSession.isTokenSet() && !this.props.appStore.isUserLoggedIn) ? (
                <li className="navigation__item">
                  <NavLink
                    to={URL.join.link}
                    className="navigation__link">
                    {t('Join')}
                  </NavLink>
                  <ul className="submenu">
                    <li className="submenu__item">
                      <NavLink
                        to={URL.join.child.usThem.link}
                        className="submenu__link">
                        {t('Us > Them')}
                      </NavLink>
                    </li>
                    <li className="submenu__item">
                      <NavLink
                        to={URL.dealer.link}
                        className="submenu__link">
                        {t('Dealer Program')}
                      </NavLink>
                    </li>
                  </ul>
                </li>
              ) : (
                <li className="navigation__item">
                  <NavLink
                    to={URL.dashboard.link}
                    className="navigation__link">
                    {t('Dash')}
                  </NavLink>
                  <ul className="submenu">
                    <li className="submenu__item">
                      <NavLink
                        to={URL.dashboard.child.orders.link}
                        className="submenu__link">
                        {t('Orders')}
                      </NavLink>
                    </li>
                    <li className="submenu__item">
                      <NavLink
                        to={URL.dashboard.child.savedGames.link}
                        className="submenu__link">
                        {t('Saved media')}
                      </NavLink>
                    </li>
                    {
                      Storage.get(userSessionRoleKey) === 0 && (
                        <li className="submenu__item">
                          <NavLink
                            to={URL.dashboard.child.profile.link}
                            className="submenu__link">
                            {t('Profile')}
                          </NavLink>
                        </li>
                      )
                    }
                  </ul>
                </li>
              )
            }
          </ul>
        </nav>
      </div>
    );
  }
}


const WrappedHeaderNav = inject('appStore')(observer(HeaderNav));
const TranslateHeaderNav = translate()(WrappedHeaderNav);

const RouterHeaderNav = withRouter(TranslateHeaderNav);
RouterHeaderNav.wrappedComponent.propTypes = wrappedPropTypes;

export default RouterHeaderNav;
