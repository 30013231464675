import React from 'react';

import {Tooltip} from 'react-tippy-ie11-fix';

import {
  tippyConfig,
  // alertConfig,
} from '../../../../constants/common';

import './styles/Size.css';

const Size = (props) => {
  return (
    <div className="size">
      <div className="container-fluid">
        <div className="row-fluid">

          <div className="size__wrapper section">
            <div className="size__content">

              <h2 className="section__line-through section__line-through--white">
                <span>Designed for your display</span>
              </h2>

              <p className="size__subtitle">
              We know that keeping your media in a stylish case that doesn’t detract from their cover art and value is a critical balance. So we’ve created six sizes and different orientations to make sure you can be organized, display-worthy and get that perfect IG-worthy shot every time. 
              </p>

              <div className="size__items">

                <div className="size__item">
                  <h3 className="size__title">Six Case Sizes:</h3>
                  <ul className="size__list">
                    <li className="size__list-item">

                      <div className="square square--gradient">
                        <div className="square__content">
                          <span className="size__value">
                          10.54”
                          </span>
                        </div>
                      </div>

                      <div className="size__list-content">
                        <div className="size__label">
                          <b>6.90” x 1.58” x 10.54”</b>
                        </div>
                      </div>
                    </li>

                    <li className="size__list-item">

                      <div className="square square--gradient">
                        <div className="square__content">
                          <span className="size__value">
                          9.36”
                          </span>
                        </div>
                      </div>

                      <div className="size__list-content">
                        <div className="size__label">
                          <b>6.90” x 1.58” x 9.36”</b>
                        </div>
                      </div>
                    </li>

                    <li className="size__list-item">

                      <div className="square square--gradient">
                        <div className="square__content">
                          <span className="size__value">
                          7.56”
                          </span>
                        </div>
                      </div>

                      <div className="size__list-content">
                        <div className="size__label">
                          <b>6.90” x 1.58” x 7.56”</b>
                        </div>
                      </div>
                    </li>
                    </ul>
                </div>
                <div className="size__item">
                  <ul className="size__list" style={{marginTop: "84px"}}>

                    <li className="size__list-item">

                      <div className="square square--gradient">
                        <div className="square__content">
                          <span className="size__valueh">
                          8.30”
                          </span>
                        </div>
                      </div>

                      <div className="size__list-content">
                        <div className="size__label">
                          <b>8.30” x 1.98” x 7.37”</b>
                        </div>
                      </div>
                    </li>

                    <li className="size__list-item">

                      <div className="square square--gradient">
                        <div className="square__content">
                          <span className="size__value">
                          9.91”
                          </span>
                        </div>
                      </div>

                      <div className="size__list-content">
                        <div className="size__label">
                          <b>6.90” x 1.42” x 9.91”</b>
                        </div>
                      </div>
                    </li>

                    <li className="size__list-item">

                      <div className="square square--gradient">
                        <div className="square__content">
                          <span className="size__value">
                          7.26”
                          </span>
                        </div>
                      </div>

                      <div className="size__list-content">
                        <div className="size__label">
                          <b>6.74” x 1.13” x 7.26”</b>
                        </div>
                      </div>
                    </li>

                  </ul>
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Size;
