import React from 'react';
import PropTypes from 'prop-types';

import './styles/Rating.css';


const propTypes = {
  rating: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  maxRating: PropTypes.number.isRequired,
};

const defaultProps = {
  maxRating: 5,
  rating: 0,
};

const Rating = (props) => {
  const {
    maxRating,
    rating,
  } = props;

  let n_rating = parseFloat(rating);

  const ratingChecked = (n_rating > maxRating) ? maxRating : n_rating;
  const ratingPercent = 100/(maxRating/ratingChecked);

  const starsTemplate = (starIcon, amount) => (
    Array.apply(null, Array(amount)).map((item, i) => {
      return (
        <li key={`${props.id}_${i}`} className="rating__item">
          <span className={starIcon} />
        </li>
      )
    })
  );

  const emptyStars = starsTemplate('icon-star', maxRating);
  const fullStars = starsTemplate('icon-star2', maxRating);

  return (
    <div className="rating-container">
      <ul
        className="rating rating--full"
        style={{
          width: `${ratingPercent}%`
        }}>
        {fullStars}
      </ul>
      <ul className="rating rating--empty">
        {emptyStars}
      </ul>
    </div>
  );
}

Rating.propTypes = propTypes;
Rating.defaultProps = defaultProps;

export default Rating;
