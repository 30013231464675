import React from 'react';
import { I18n, Trans } from 'react-i18next';

import { Link } from 'react-router-dom';

import { routeURLs as URL } from '../../../configs/routeURLs';

import './styles/Features.css';

const Features = (props) => {
  return (
    <I18n ns="translations">
      {
        (t, {i18n}) => (
          <div className="feature">
            <div className="container-fluid feature__content">
              <div className="section">
                <div className="row">

                  <div className="feature__container" style={{justifyContent: 'center'}}>

                    <div className="col-xs-12 col-sm-6 col-md-3 feature__item-wrapper">
                      <div className="feature__item">

                        <div className="feature__item-content">
                          <span className="feature__icon text-gradient icon-graders" />
                          <h3 className="feature__title">
                            {t('Expert graders')}
                          </h3>
                          <p className="feature__text">
                            <Trans i18nKey={"Objective, analytical, impartial grading by industry experts."}>
                              Objective, analytical, impartial grading by <b>industry experts</b>.
                            </Trans>
                          </p>
                        </div>

                        <div className="feature__btn-wrapper">
                          <Link
                            className="btn btn--md"
                            to={URL.whatWeDo.link}>
                            <span className="btn__text">{t('Our process')}</span>
                          </Link>
                        </div>

                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-3 feature__item-wrapper">
                      <div className="feature__item">

                        <div className="feature__item-content">
                          <span className="feature__icon text-gradient icon-forum" />
                          <h3 className="feature__title">
                            {t('Learn + Connect')}
                          </h3>
                          <p className="feature__text">
                            <Trans i18nKey={"Connect with a thriving community of collectors."}>
                              Connect with a thriving <b>community</b> of collectors.
                            </Trans>
                          </p>
                        </div>

                        <div className="feature__btn-wrapper">
                          <a
                            className="btn btn--md"
                            target="_blank"
                            href={URL.learn.child.blog.link}>
                            <span className="btn__text">{t('Learn')}</span>
                          </a>
                        </div>

                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-3 feature__item-wrapper">
                      <div className="feature__item">

                        <div className="feature__item-content">
                          <span className="feature__icon text-gradient icon-dealer" />
                          <h3 className="feature__title">
                            {t('Become a dealer')}
                          </h3>
                          <p className="feature__text">
                            <Trans i18nKey={"Are you a seller? Join our Authorized Dealer Program!"}>
                              Are you a seller? Join our <b>Authorized Dealer Program</b>!
                            </Trans>
                          </p>
                        </div>

                        <div className="feature__btn-wrapper">
                          <Link
                            className="btn btn--md"
                            to={URL.dealer.link}>
                            <span className="btn__text">{t('Tell me more')}</span>
                          </Link>
                        </div>

                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-3 feature__item-wrapper">
                      <div className="feature__item">

                        <div className="feature__item-content">
                          <span className="feature__icon text-gradient icon-process-grade" />
                          <h3 className="feature__title">
                            {t('CERT VERIFICATION')}
                          </h3>
                          <p className="feature__text">
                          Check <b>media authenticity</b> before you buy.
                          </p>
                        </div>

                        <div className="feature__btn-wrapper">
                          <Link
                            className="btn btn--md"
                            to={URL.cert.link}>
                            <span className="btn__text">{t('Verify Now')}</span>
                          </Link>
                        </div>

                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        )
      }
    </I18n>
  );
}


export default Features;
