import GameListModel from '../models/GameListModel';

import {
  extendObservable,
  action,
  toJS,
} from 'mobx';

import {mergeWith, each} from 'lodash';


class CartStore extends GameListModel { // <- !!!
  shippingFormDataInitialState = {
    fullName: '',
    businessName: '',
    phone: '',
    country: '',
    zip: '',
    city: '',
    state: '',
    address: '',
    saveAddress: false,
  };

  constructor() {
    super();
    extendObservable(this, {
      cartStep: 0,
      shippingFormData: mergeWith({}, this.shippingFormDataInitialState),
      isDataFromCartStore: false,
      orderId: null,
      newsletterSignup: true,
    });

    this.updateCartStep = action(
      'update current step of the cart flow', (nextStep, extraData={}) => {
      if (this.cartStep !== nextStep){
        this.cartStep = nextStep;
      }
      if (extraData.newsletterSignup){
        this.newsletterSignup = extraData.newsletterSignup;
      }
    });

    this.updateShippingFormData = action('update shipping form data', data => {
      each(data, (value, key) => {
        if (this.shippingFormData[key] !== value) {
          this.shippingFormData[key] = value;
        }
      });
    });

    this.resetShippingFormData = action(
      'reset shipping form data', () => {
      this.updateShippingFormData(this.shippingFormDataInitialState);
    });

    this.updateIsDataFromCartStore = action(
      'update status of shipping address source', (status) => {
      if (this.isDataFromCartStore !== status) {
        this.isDataFromCartStore = status;
      }
    });

    this.convertAddressToJS = () => {
      return toJS(this.shippingFormData);
    };
  }

}

const cartStore = new CartStore();

export default cartStore;
