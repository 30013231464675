import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routeURLs as URL } from '../../../configs/routeURLs';

import EventsList from '../EventsList/EventsList';


import './styles/UpcomingEvents.css';


const propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array,
};

const defaultProps = {
  data: [],
};

class UpcomingEvents extends React.Component {
  render() {
    const {props} = this;
    const {t} = props;

    return (
      <div className="upcoming-events">
        <div className="container-fluid">
          <div className="row-fluid">

            <div className="upcoming-events__wrapper section">
              <div className="upcoming-events__content">
                <h2 className="section__line-through section__line-through--white">
                  <span><b>{t('Upcoming Events')}</b></span>
                </h2>

                <EventsList
                  data={props.data}
                  emptyClassName="events__empty--white" white={true} />

                <div className="upcoming-events__btn-wrapper">
                  <Link
                    to={URL.learn.child.events.link}
                    className="btn btn--high btn--lg">
                    <span className="btn__text">
                      {t('View all events')}
                    </span>
                  </Link>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}


UpcomingEvents.propTypes = propTypes;
UpcomingEvents.defaultProps = defaultProps;

const TranslateUpcomingEvents = translate()(UpcomingEvents);

export default TranslateUpcomingEvents;
