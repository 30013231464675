import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import './styles/Dealer.css';

import { routeURLs as URL } from '../../configs/routeURLs';

import withTracker from '../shared/AnalyticsTracker/withTracker';

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const TITLE = 'Dealer Program';


class Dealer extends React.Component {
  render() {
    const {props} = this;
    const {t} = props;
    // const {history} = props;

    return (
      <div className="page-wrapper">
        <Helmet
          title={t('Dealer Program')}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />
        <Header className="header--gradient-btp" />

        <main className="main dealer__wrapper">
          <div className="container-fluid dealer">
            <div className="row-fluid">
              <div className="dealer__content section section--top section--gradient section--ytr">

                <div className="dealer__info">
                  <h2 className="section__title">Partner with WATA</h2>
                  <p className="section__subtitle">
                  WATA's Authorized Dealer Program offers many unique perks and benefits, dependent on order size and frequency. These benefits can include:
                  </p>
                  <ul className="dealer__list arrow-list">
                    <li className="arrow-list__item">Discounts on orders and specific add-ons</li>
                    <li className="arrow-list__item">Exclusive special offers</li>
                    <li className="arrow-list__item">Unique add-ons and services</li>
                    <li className="arrow-list__item">Early access to new offerings</li>
                    <li className="arrow-list__item">Concierge drop-offs and pick-ups</li>
                  </ul>
                  <p className="section__subtitle">
                  If you would like to apply for this program, please email <a href="mailto:watabizdev@collectors.com">watabizdev@collectors.com</a> for more information.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    );
  }
}



Dealer.propTypes = wrappedPropTypes;

const TranslateDealer = translate()(Dealer);

export default withTracker(TranslateDealer, {title: TITLE});
