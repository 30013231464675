import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';
import {inject, observer} from 'mobx-react';

import withTracker from '../../shared/AnalyticsTracker/withTracker';

import Formsy from 'formsy-react';
import FormModel from '../../../models/FormModel';
import Input from '../../shared/form-elements/Input';
import { Tooltip } from "react-tippy-ie11-fix";
import {
  tippyConfig,
} from "../../../constants/common";
import { Link } from "react-router-dom";

import { routeURLs as URL } from "../../../configs/routeURLs";

const propTypes = {
  t: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

const TITLE = 'Search Certificates';


class CertSearch extends React.Component {
  constructor(props) {
    super(props);

    this.store = new FormModel({
      certNumber: '',
    });
  }

  onValidSubmit = async (model) => {
    const {
      router,
    } = this.props;
    router.push(URL.cert.child.details.link(model.certNumber));
  }

  render() {
    const {t} = this.props;
    const {store} = this;

    return (
      <>
        <h2 className="section__title">
          {t('WATA Cert Verification')}
        </h2>
        <p className="section__subtitle">Want to buy a WATA-graded game? You can verify the validity and status of WATA certification numbers below. When purchasing online, be sure to confirm the certification number after receiving your game.</p>
        <Formsy
          ref={(component) => {
            store.updateRefToForm(component);
          }}
          onValidSubmit={this.onValidSubmit}
        >
          <div className='cert__search_form'>
            <Input
              name="certNumber"
              value={store.elements.get('certNumber')}
              id="certSearch_certNumber"
              placeholder={t('Certificate Number')}
              required
            />

            <button
              type="submit"
              disabled={store.isLoading.get()}
              className="btn btn--high btn--lg btn__text--black">
              <span className="btn__text">
                {t('Verify')}
              </span>
            </button>
          </div>
        </Formsy>
        <h4 className="cert__search_tip">
          Quick Tip
          <Tooltip
            {...tippyConfig}
            html={
              <div>
                Find certification numbers on the lower left of the encapsulated label. On legacy holders (before June 2023), you’ll find the numbers on the back to the left of the “Grade Details” box.
              </div>
            }
          >
            <span className="icon-question-circle text-gradient scale__tooltip" />
          </Tooltip>
        </h4>
        <br /><br />
        <div>
          <h2 className="cert__section_small">New WATA Holder</h2>
          <img
            className='cert__search_img'
            src={`${process.env.PUBLIC_URL}/images/cert_new_holder.jpg`}
            alt="New Holder Cert Location"
          />
          <h2 className="cert__section_small">Legacy WATA Holder (Before June 2023)</h2>
          <img
            className='cert__search_img'
            src={`${process.env.PUBLIC_URL}/images/cert_legacy_holder.jpg`}
            alt="Legacy Holder Cert Location"
          />
        </div>
        <div className="cert__search_why">
          <h2 className="cert__section_small">Why Verify?</h2>
          <ul className="arrow-list">
            <li className="arrow-list__item">Provides transparency and confidence that your media is authentic</li>
            <li className="arrow-list__item">Prevents against fraud and fake holders</li>
            <li className="arrow-list__item">Ensures peace of mind when buying online</li>
          </ul>
        </div>
        <div className='cert__search_area'>
          <h2 className="cert__section_small">WATA Grading Standards</h2>
          <p className="section__subtitle">WATA has developed an iron-clad, road-tested grading process, where your media is taken care of every step of the way.</p>
          <p className="section__subtitle">Media is secure throughout the process from receiving to shipping. Two graders independently assess the contents, authenticity, and condition. Then a third expert Final Grader independently reviews before assigning the final grade.</p>
          <p className="section__subtitle">Your media is then encapsulated in our toughest, most durable case with a high-tech, secure label.</p>
          <Link
            className="btn btn--high btn--lg m-2"
            style={{ margin: "1rem" }}
            to={URL.whatWeDo.child.pricing.link}
          >
            <span className="btn__text btn__text--black">
              LEARN ABOUT GRADING
            </span>
          </Link>
        </div>
        <div>
          <p>Note: Verification of certification numbers on the WATA Certification database does not eliminate risk. Though uncommon, criminals do attempt to counterfeit WATA grading inserts using actual certification numbers derived from public sources. As a general rule, WATA encourages the purchase of WATA verified collectibles from trustworthy sources. Marketplaces such as Craigslist, Mercari, and Offer Up are not recommended.</p>
          <br /><br />
          <p>WATA is providing access to its database for the sole purpose of confirming data regarding authenticated items with a particular certification number. WATA does not view authenticated items listed on the web and does not warrant or guarantee that any such authenticated item is, in fact, a genuine WATA authenticated item.</p>
        </div>
      </>
    );
  }
}


CertSearch.propTypes = propTypes;
const WrappedCertSearch= inject(
  'router',
)(observer(CertSearch));

const TranslateCertSearch = translate()(WrappedCertSearch);

export default withTracker(TranslateCertSearch, {title: TITLE});
