import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Invader from '../Invader/Invader';

import './styles/Spinner.css';


const propTypes = {
  spinnerStatus: PropTypes.bool.isRequired,
};

const Spinner = (props) => {
  const spinnerClasses = classnames({
    'spinner-wrapper': true,
    'isShown': props.spinnerStatus
  });

  return (
    <div className={spinnerClasses}>
      <div className="loader">
        <Invader />
      </div>
    </div>
  );
}


Spinner.propTypes = propTypes;

export default Spinner;
