import {
  extendObservable,
  action,
} from 'mobx';
import uuid from 'uuid-v4';
import {
  mergeWith,
} from 'lodash';


class GraderModel {
  constructor(data) {
    extendObservable(this, {
      data: mergeWith({}, this.crateItemInitialState),

      get isFinalGrader() {
        return this.data.role === 3;
      },

      get isGrader() {
        return this.data.role === 2;
      },
    });

    if (data) {
      this.updateGraderItem(data);
    }
  }

  id = uuid();
  graderInitialState = {
    id: '',
    role: 0,
  };

  clearGraderItem = action(
    'clear cgrader item', () => {
    this.updateGraderItem(this.graderInitialState);
  });

  updateGraderItem = action('update grader item', newData => {
    const keys = Object.keys(newData);

    keys.forEach(key => {
      if (this.data[key] !== newData[key]) {
        this.data[key] = newData[key];
      }
    });
  });
};

export default GraderModel;
