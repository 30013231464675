import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';

import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import { routeURLs as URL } from '../../configs/routeURLs';

import withTracker from '../shared/AnalyticsTracker/withTracker';

import CertSearch from './CertSearch/CertSearch';
import CertDetails from './CertDetails/CertDetails';

import './styles/Certs.css';

const propTypes = {
  t: PropTypes.func.isRequired,
};

const TITLE = 'Certificates';


class Certs extends React.Component {
  render() {
    const {t} = this.props;

    return (
      <div className="page-wrapper">
        <Helmet
          title={t(TITLE)}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />
        <Header />

        <main className="main">
          <div className="cert">
            <div className="cert__section section section--top section--gradient section--btp">
              <div className="container-fluid">
                <Switch>
                  <Route
                    exact
                    path={URL.cert.link}
                    render={props => <CertSearch {...props} />}
                    />

                  <Route
                    exact
                    path={URL.cert.child.details.route}
                    render={props => <CertDetails {...props} />}
                    />
                  {/* 404 */}
                  <Redirect to={{ state: { error: true } }} />
                </Switch>
              </div>
            </div>
         </div>
        </main>

        <Footer />
      </div>
    );
  }
}


Certs.propTypes = propTypes;

const TranslateCerts = translate()(Certs);

export default withTracker(TranslateCerts, {title: TITLE});
