import React from 'react';
import PropTypes from 'prop-types';


import {inject, observer} from 'mobx-react';
import {
  translate,
} from 'react-i18next';

import ImageLoader from '../../shared/ImageLoader/ImageLoader';

// default styles
import './styles/WataScaleTabContent.css';


const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
  onMount: PropTypes.func,
  toggleModal: PropTypes.func.isRequired,
  setImageToModal: PropTypes.func.isRequired,
};

const wrappedDefaultProps = {
  onMount: () => {},
};

const data = {
  '0.5': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-0.5.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-0.5.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-0.5.jpg`,
  },
  '1': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-1.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-1.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-1.jpg`,
  },
  '1.5': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-1.5.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-1.5.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-1.5.jpg`,
  },
  '2': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-2.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-2.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-2.jpg`,
  },
  '2.5': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-2.5.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-2.5.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-2.5.jpg`,
  },
  '3': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-3.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-3.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-3.jpg`,
  },
  '3.5': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-3.5.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-3.5.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-3.5.jpg`,
  },
  '4': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-4.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-4.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-4.jpg`,
  },
  '4.5': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-4.5.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-4.5.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-4.5.jpg`,
  },
  '5': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-5.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-5.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-5.jpg`,
  },
  '5.5': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-5.5.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-5.5.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-5.5.jpg`,
  },
  '6': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-6.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-6.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-6.jpg`,
  },
  '6.5': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-6.5.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-6.5.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-6.5.jpg`,
  },
  '7': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-7.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-7.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-7.jpg`,
  },
  '7.5': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-7.5.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-7.5.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-7.5.jpg`,
  },
  '8': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-8.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-8.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-8.jpg`,
  },
  '8.5': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-8.5.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-8.5.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-8.5.jpg`,
  },
  '9': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-9.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-9.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-9.jpg`,
  },
  '9.2': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-9.2.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-9.2.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-9.2.jpg`,
  },
  '9.4': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-9.4.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-9.4.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-9.4.jpg`,
  },
  '9.6': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-9.6.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-9.6.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-9.6.jpg`,
  },
  '9.8': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-9.8.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-9.8.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-9.8.jpg`,
  },
  '10': {
    'manual': `${process.env.PUBLIC_URL}/images/wata-scale/manual/wata-scale-manual-10.jpg`,
    'box': `${process.env.PUBLIC_URL}/images/wata-scale/box/wata-scale-box-10.jpg`,
    'cart': `${process.env.PUBLIC_URL}/images/wata-scale/cart/wata-scale-cart-10.jpg`,
  },
}

class WataScaleTabContent extends React.Component {
  componentDidMount() {
    this.props.setPosInst(this.posInst);
    this.onMount();
  }

  onMount = async () => {
    /* Wait for full component data load. */
    await this.props.onMount();
  }

  setImageToModalCart = () => {
    const {currentTabValue} = this.props;
    this.props.setImageToModal(data[currentTabValue].cart, `${currentTabValue} LOOSE CART`);
  }

  setImageToModalBox = () => {
    const {currentTabValue} = this.props;
    this.props.setImageToModal(data[currentTabValue].box, `${currentTabValue} C.I.B GAME`);
  }

  setImageToModalManual = () => {
    const {currentTabValue} = this.props;
    this.props.setImageToModal(data[currentTabValue].manual, `${currentTabValue} SEALED GAME`);
  }

  render() {
    const {props} = this;
    const item = data[props.currentTabValue];

    return (
      <div className="scale-content">

        <h3 className="section__line-through">
          <span>Click the scale numbers to learn more</span>
        </h3>

        <div className="scale-content__info">
          <div className="square square--big square--gradient">
            <div className="square__content">
              <span
                ref={(inst) => (this.posInst = inst)}
                className="scale-content__label">
                {props.currentTabValue}
              </span>
              <div className="square__helper-container">
                {this.props.helperLine()}
              </div>
            </div>
          </div>
          <p className="scale-content__text">
          Below you’ll find useful photos that demonstrate the selected grade and condition. These examples can help you determine how your media might be rated:
          </p>
        </div>

        <div className="scale-content__items">

          <div className="scale-content__item">
            <div className="scale-content__img-wrapper">
              <button
                className="scale__btn-modal"
                onClick={this.setImageToModalBox}
                type="button">
                <ImageLoader
                  src={item.box}
                  imageClassName="scale-content__item-img"
                  alt={`${props.currentTabValue} BOX`} />
              </button>
            </div>
            <div className="scale-content__item-title">
              EXAMPLE: <b><span>{props.currentTabValue}</span> BOX</b>
            {/*
              <Tooltip
                {...tippyConfig}
                html={(
                  <div>
                    This is a tooltip text with the link to the <Link target="_blank" rel="noopener noreferrer" to={URL.join.link}>Glossary</Link> page.
                  </div>
                )}>
                <span className="icon-question-circle text-gradient scale__tooltip" />
              </Tooltip>
              */}
            </div>
          </div>

          <div className="scale-content__item">
            <div className="scale-content__img-wrapper">
              <button
                className="scale__btn-modal"
                onClick={this.setImageToModalCart}
                type="button">
                <ImageLoader
                  src={item.cart}
                  imageClassName="scale-content__item-img"
                  alt={`${props.currentTabValue} CART`} />
              </button>
            </div>
            <div className="scale-content__item-title">
              EXAMPLE: <b><span>{props.currentTabValue}</span> CART</b>
            {/*
              <Tooltip
                {...tippyConfig}
                html={(
                  <div>
                    This is a tooltip text with the link to the <Link target="_blank" rel="noopener noreferrer" to={URL.join.link}>Glossary</Link> page.
                  </div>
                )}>
                <span className="icon-question-circle text-gradient scale__tooltip" />
              </Tooltip>
              */}

            </div>
          </div>

          <div className="scale-content__item">
            <div className="scale-content__img-wrapper">
              <button
                className="scale__btn-modal"
                onClick={this.setImageToModalManual}
                type="button">
                <ImageLoader
                  src={item.manual}
                  imageClassName="scale-content__item-img"
                  alt={`${props.currentTabValue} MANUAL`}
                />
              </button>
            </div>
            <div className="scale-content__item-title">
              EXAMPLE: <b><span>{props.currentTabValue}</span> MANUAL</b>
            {/*
              <Tooltip
                {...tippyConfig}
                html={(
                  <div>
                    This is a tooltip text with the link to the <Link target="_blank" rel="noopener noreferrer" to={URL.join.link}>Glossary</Link> page.
                  </div>
                )}>
                <span className="icon-question-circle text-gradient scale__tooltip" />
              </Tooltip>
              */}
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const WrappedWataScaleTabContent = inject('appStore')(observer(WataScaleTabContent));
const TranslateWataScaleTabContent = translate()(WrappedWataScaleTabContent);
TranslateWataScaleTabContent.wrappedComponent.propTypes = wrappedPropTypes;
TranslateWataScaleTabContent.wrappedComponent.defaultProps = wrappedDefaultProps;

export default TranslateWataScaleTabContent;
