import React from "react";
import PropTypes from "prop-types";
import { translate, Trans } from "react-i18next";
import Helmet from "react-helmet";

import { NavLink, Link } from "react-router-dom";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import "./styles/Faq.css";

import withTracker from "../shared/AnalyticsTracker/withTracker";

import { routeURLs as URL } from "../../configs/routeURLs";

import { BrowserLink } from "../shared/BrowserLink";

const propTypes = {
  t: PropTypes.func.isRequired,
};

const TITLE = "F.A.Q.";

class FAQ extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className="page-wrapper">
        <Helmet
          title={t(TITLE)}
          meta={[
            {
              name: "description",
              content: "",
            },
          ]}
        />

        <Header className="header--gradient-btp" />

        <main className="main">
          <div className="faq info-page">
            <div className="container-fluid">
              <div className="row-fluid">
                <div className="info-page__wrapper section section--top section--gradient section--btp">
                  <div className="info-page__content">
                    <h2 className="section__title">
                      {t("Frequently Asked Questions")}
                    </h2>
                    <p className="section__subtitle">
                      <Trans
                        i18nKey={
                          "Though we strive to make the whole process as intuitive as possible, questions are inevitable!"
                        }
                      >
                        Though we strive to make the whole process as intuitive
                        as possible, questions are inevitable! We ask that you
                        browse our F.A.Q. before contacting us directly with
                        your questions. If for some reason we haven’t covered it
                        here, reach out - we’re here to help!
                      </Trans>
                    </p>

                    <ul className="drop-list">
                      <li className="drop-list__item">
                        <label className="drop-list__label">
                          <input
                            type="checkbox"
                            name="faq"
                            className="drop-list__toogle"
                          />

                          <div className="drop-list__header">
                            <div className="drop-list__mark" />
                            <h3 className="drop-list__title">
                              HOW TO SUBMIT
                            </h3>
                          </div>

                          <div className="drop-list__content">
                            <div className="arrow-list">
                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  How do I submit my games?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    Fill in a in{" "}
                                    <BrowserLink to={URL.submitGame.link}>
                                      submission form
                                    </BrowserLink>{" "}
                                    online, then print it out. Mail the submission form and your item(s) by:
                                  </p>
                                  <p className="faq__text">
                                    USPS:<br />
                                    CU Receiving<br />
                                    P.O. Box 6180<br />
                                    Newport Beach, CA 92658
                                  </p>
                                  <p className="faq__text">
                                    FEDEX/UPS:<br />
                                    1610 E St. Andrew Pl<br />
                                    Suite 150<br />
                                    Santa Ana, CA 92705
                                  </p>
                                  <p className="faq__text">
                                    NOTE: if a submission form is not included in your package it will lead to a delay in processing. 
                                  </p>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  How do I determine the declared value for my game?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    The declared value is your estimate of the value of the game after it has been assigned a grade by WATA. We understand you can’t know the true value of your game until it has been graded, so we ask that you form a realistic, educated estimate based on your own research. Keep in mind that the declared value acts as a maximum value for shipping insurance purposes and in the event of a claim related to the item.
                                  </p>
                                  <p className="faq__text">
                                  You can check the value of your game on sites like eBay, Heritage Auctions, and Goldin Auctions to make an educated guess. It is important that you spend the time, utilize the available resources, and take the necessary steps to accurately estimate the declared value.
                                  </p>
                                  <p className="faq__text">
                                    The declared value you provide establishes:
                                    <ul className="faq__list">
                                      <li>the maximum item value in the event of a claim connected with that item while at WATA</li>
                                      <li>the maximum item value for shipping insurance purposes</li>
                                    </ul>
                                  </p>
                                  <p className="faq__text">
                                    If the game is worth significantly more than your declared value, it will be bumped up to the appropriate insurance amount with a 2% fee. The difference will need to be paid before the game is shipped back. 
                                  </p>
                                  <p className="faq__text">
                                    Note that if you desire a faster turnaround time on your order, you may select a Service Level above the declared value for your item.
                                  </p>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  What happens if my declared value is understated?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    If WATA determines, in its sole discretion and at any point in the process, that the submitted declared value has been understated relative to the market value of the item, we reserve the right to decline your stated declared value. If that happens, we will require you to pay for the accurate Service Level as a condition of us completing the authentication and grading process. If you refuse, or are unable, to pay for the accurate Service Level, we will return the item to you unprocessed at your cost, and you will be charged for the Service Level at which you submitted the item. WATA’s determination that you have understated the declared value will affect only the Service Level charge, it will not change the declared value for purposes of establishing the maximum item value for claim or shipping insurance purposes.
                                  </p>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  Should I use the Light Cleaning service and Sticker Removal service on my video game submission? Can these services damage my game?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    Most media have dust or fingerprints that can be carefully removed, but only with the right tools and a lot of experience. Our Light Cleaning service allows us to gently remove dirt or fingerprint residue from shrink-wrap, and remove light soil from CIB or cartridges. Your media can potentially grade higher due to this cleaning.  
                                  </p>
                                  <p className="faq__text">
                                    In addition to dirt and debris, through the Sticker Removal service, the WATA team may also be able to remove security, retail, and other stickers from your game before encapsulation, which in some cases can improve the aesthetics and grade of a game. All our cleaning services are performed in a professional environment and using the proprietary methods and processes that we have developed to safely and gently remove stickers from seals. Although we take every precaution to mitigate risks and minimize the chance of damaging your game when performing cleaning services, the Sticker Removal service is inherently risky, often due to circumstances beyond our control. Pre-existing conditions such as inconspicuous holes or tears, including “snagged vent holes,” may be uncovered during the sticker removal process. For example, we’ve found that stickers have sometimes been placed on torn seals, concealing damage that exists underneath the sticker. In those cases, the sticker cannot be easily removed without uncovering the pre-existing damage or further exacerbating the issue.
                                  </p>
                                  <p className="faq__text">
                                    Because of these and other risks, WATA’s team of experts will evaluate the seal and sticker on your game before attempting to remove a sticker. Rest assured that if our experts decide that removal of the sticker is likely to damage the game, then we will let you know that and refund the fee associated with the Sticker Removal add-on. If our experts believe the sticker can be removed safely, but nevertheless encounter an unanticipated issue upon proceeding, they will exercise judgment based on their experience, expertise, and training to minimize further damage. Still, given that sticker removal may damage games or uncover pre-existing damage (which can possibly lead to grade reductions), you will be asked to agree to a waiver acknowledging the inherent risks and releasing WATA of liability in order to proceed with the Sticker Removal service.
                                  </p>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  Should I submit my game if I have the box and cartridge but am missing one or more of the: manual, inserts, sleeve, baggie, game specific inserts, Styrofoam block?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    For a game to be CIB it must have an original box, cartridge and manual (if one was packed with the game from the factory). Your game will still be Complete in Box (CIB) without inserts or a sleeve. NES requires Dust Sleeve & Styrofoam; SNES/N64 requires the Tray; Game Boy/GBC/GBA requires the Tray. Games that are submitted without trays or styrofoam will not be rejected, but will have the components provided for a fee of $10 per missing component. 
                                  </p>
                               </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  I am getting my game certified in order to
                                  sell it. Where can I sell my certified game?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    There are many sites where you can sell your certified video game. Some of Collectors’ favorites include {" "}
                                    <NavLink
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      to={{pathname:"https://www.ebay.com/"}}
                                    >
                                      eBay
                                    </NavLink>
                                    ,{" "}
                                    <NavLink
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      to={{pathname:"https://www.ha.com/"}}
                                    >
                                      Heritage Auctions
                                    </NavLink>
                                    ,{" "}
                                    <NavLink
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      to={{pathname:"https://goldin.co/"}}
                                    >
                                      Goldin Auctions
                                    </NavLink>, and{" "}
                                    <NavLink
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      to={{pathname:"https://www.whatnot.com/"}}
                                    >
                                      WhatNot
                                    </NavLink>.
                                  </p>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  Do you certify consoles?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    Not yet! 
                                  </p>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  What if my game turns out not to be factory
                                  sealed?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    If you submit a game that was sealed post manufacturing, the contents of the box could be in any condition. It could be missing the manual, be the wrong game, or have something else entirely inside!
                                  </p>
                                  <p className="faq__text">
                                    In this case, you will receive an email explaining that your game is not factory sealed and it will be returned to you unprocessed with a reject fee of $15 per game.
                                  </p>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  What is exactly is the “Deep Badge” upgrade?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    Canyon Drive Technologies has provided WATA Games with “Deep”, a 3-D, high security badge only for games graded a 9.0 or higher. Badges are optional add-ons that you can pre-purchase for any game, but will only be applied and charged if the game it’s applied to grades at 9.0 or higher. These badges can’t be duplicated with traditional copying technologies or mechanisms. One side of the badge contains an array of tiny spherical lenses. This unique design results in a 99.9% fill factor, previously unachievable in any lens array graphic arts material. Our WATA logo is featured where the lenses magnify the patterns to create 3-D depth, motion, and color-shifting effects.
                                  </p>
                                </div>
                              </label>
                            </div>
                          </div>
                        </label>
                      </li>

                      <li className="drop-list__item">
                        <label className="drop-list__label">
                          <input
                            type="checkbox"
                            name="faq"
                            className="drop-list__toogle"
                          />

                          <div className="drop-list__header">
                            <div className="drop-list__mark" />
                            <h3 className="drop-list__title">
                              GRADING AND ENCAPSULATION
                            </h3>
                          </div>

                          <div className="drop-list__content">
                            <div className="arrow-list">
                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  Where can I find the full list of what you grade?	
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    You’ll find the most up to date information for what we grade on the{" "}
                                    <BrowserLink to={URL.submitGame.link}>
                                      submission process
                                    </BrowserLink>{" "}
                                    page. We can only accept standard sized games. Below are the dimensions in millimeters for the games we grade:
                                  </p>
                                  <table className="faq__table">
                                    <thead>
                                      <tr>
                                        <th>Description</th>
                                        <th>X</th>
                                        <th>Y</th>
                                        <th>Z</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr><td>“DVD” (PS2, GameCube, Wii, Wii U, Xbox, Xbox 360)</td><td>5.4</td><td>7.5</td><td>0.6</td></tr>
                                      <tr><td>SNES & N64</td><td>7.1</td><td>5.0</td><td>1.3</td></tr>
                                      <tr><td>NES & Genesis Paper Box</td><td>5.0</td><td>7.0</td><td>1.0</td></tr>
                                      <tr><td>“Blu Ray” (PS3, PS4, PS5, Xbox One, Xbox Series X)</td><td>5.5</td><td>6.8</td><td>0.5</td></tr>
                                      <tr><td>Game Boy, Game Boy Color, Game Boy Advance, Virtual Boy</td><td>5.0</td><td>5.0</td><td>1.0</td></tr>
                                      <tr><td>Single Jewel (PS1, Dreamcast)</td><td>5.8</td><td>5.0</td><td>0.4</td></tr>
                                      <tr><td>Double Jewel (PS1, Dreamcast)</td><td>5.6</td><td>4.9</td><td>0.9</td></tr>
                                      <tr><td>Atari #1 (Standard 2600 Size)</td><td>5.5</td><td>7.6</td><td>1.0</td></tr>
                                      <tr><td>Genesis Clamshell (Genesis/Mega Drive, 32X, Sega Master System)</td><td>5.1</td><td>7.0</td><td>1.1</td></tr>
                                      <tr><td>Long box 1 (Sega Saturn, Sega CD, PS1 Longbox)</td><td>5.7</td><td>8.1</td><td>0.9</td></tr>
                                      <tr><td>Long box 2 (Sega CD/32X)</td><td>5.8</td><td>8.3</td><td>1.0</td></tr>
                                      <tr><td>Long box 3 (Intellivision)</td><td>5.7</td><td>8.1</td><td>0.8</td></tr>
                                      <tr><td>TurboGrafx-16</td><td>6.0</td><td>7.0</td><td>0.6</td></tr>
                                      <tr><td>DS</td><td>5.4</td><td>4.9</td><td>0.8</td></tr>
                                      <tr><td>Switch</td><td>4.1</td><td>6.7</td><td>0.5</td></tr>
                                      <tr><td>3DS</td><td>5.4</td><td>4.9</td><td>0.5</td></tr>
                                      <tr><td>PSP</td><td>4.2</td><td>7.0</td><td>0.6</td></tr>
                                      <tr><td>Game Gear</td><td>4.4</td><td>6.3</td><td>1.0</td></tr>
                                      <tr><td>PS Vita</td><td>4.1</td><td>5.3</td><td>0.4</td></tr>
                                      <tr><td>Atari #2 (Various Atari, Colecovision)</td><td>5.3</td><td>7.6</td><td>1.0</td></tr>
                                      <tr><td>Atari #3 (Atari “20th Century FOX” games)</td><td>5.6</td><td>7.6</td><td>1.1</td></tr>
                                    </tbody>
                                  </table>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  What we don’t grade
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    REMEMBER! If your game fits the dimensions listed on our website we can grade it. 
                                  </p>
                                  <div className="faq__text">
                                    <ul className="faq__list unordered-list">
                                      <li>All video game systems/consoles</li>
                                      <li>All video game accessories and non-standard system game boxes (some standard sized Cleaning Kits will fit)</li>
                                      <li>
                                        Video Game Epherma (magazines,
                                        paperwork, etc.)
                                      </li>
                                      <li>Video game trophies & miscellanea</li>
                                      <li>Nintendo Game and Watch series</li>
                                      <li>Neo-Geo AES</li>
                                      <li>Philips CDi longbox</li>
                                      <li>Sega Pico</li>
                                      <li>Panasonic 3DO</li>
                                      <li>PC/Mac/Computer Big Box</li>
                                      <li>Specific oversized games we don’t grade include but are not limited to:</li>
                                      <ul className="faq__list unordered-list">
                                        <li>NES Arkanoid</li>
                                        <li>NES Carmen Sandiego</li>
                                        <li>NES Stack Up</li>
                                        <li>NES Game Genie/Action Replay</li>
                                        <li>NES Aladdin Deck Enhancer Titles</li>
                                        <li>SNES Earthbound</li>
                                        <li>SNES Mario Paint</li>
                                        <li>SNES Super Gameboy</li>
                                        <li>N64 Pokémon Hey You, Pikachu</li>
                                        <li>N64 Pokémon Stadium</li>
                                        <li>N64 Rampage w/plush</li>
                                        <li>N64 Star Fox with rumble pak</li>
                                        <li>GameCube DDR Max</li>
                                        <li>GameCube Mario Party 6 & 7 Big Box</li>
                                      </ul>
                                    </ul>
                                  </div>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  How can WATA tell if a game is factory sealed?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    WATA uses a combination of years of expertise evaluating and identifying patterns in sealing, proprietary technologies and methodologies to look for seal characteristics, signs of wear or "re-sealing," extensive databases for expected sealing qualities, and tactile evaluations. WATA will not authenticate games if we do not feel 100% confident in a seal's authenticity and will air on the side of caution in situations where seal authenticity is questionable.
                                  </p>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  I have a large volume of video games to grade. How can I do this most effectively?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    We’d love to see your collection! Reach out to us by {" "}
                                    <a href="mailto:info@watagames.com">
                                      email
                                    </a>{" "}and we’ll be in touch to talk about your options.
                                  </p>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  I have prototypes - can I get them certified?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    We have this program coming soon! Check back often for more details. 
                                  </p>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  My video game box is perfect, but the shrink-wrap is damaged. Will removing the shrink-wrap get me a higher grade?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    If the shrink-wrap is still intact and can’t easily slide off the video game, then leave it on. The grade your box  will receive will not change, it’ll only affect the grade for the factory seal. WATA will not remove any seals or take any actions that affect the outcome of the grade, so if you have a Sealed game with a poor seal condition and desire to have the game certified as Sealed but without the damaged seal or low seal rating, you should remove the seal prior to submission and submit the game as Sealed. If WATA determines that only the seal was removed and the box was still never opened, WATA will certify the game as Sealed with a “NS” (No Seal) rating for the seal.
                                  </p>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  My video game is still in the original shrink-wrap, but the shrink-wrap was carefully opened so the game could be played. Will it be CIB or Factory Sealed? 
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    Some stores would re-seal games whose seals were damaged in house prior to selling, but in many instances these games were never opened and would be considered brand new. In these instances, WATA removes the unoriginal seal and grades the game as sealed with a “NS” (No Seal) designation for the seal rating. If you are unsure it’s best to air on the side of caution and leave the seal on. If it is determined that the box has been opened, even if just once to inspect the contents, the game would be certified as CIB instead of Sealed.
                                  </p>
                                  <p className="faq__text">
                                    If the shrink-wrap is not original or aftermarket (meaning it never could have been the original Factory Seal for that game), the game will be returned to you unprocessed, minus a $15 return fee and shipping costs. 
                                  </p>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                How do I get the old style of cases?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                  Much like a certain famous rib sandwich, our legacy cases will be coming back throughout the year for limited times. Keep an eye on social media and on our monthly newsletter to see when our legacy cases will be available, and for how long. Note that some games, no matter the season, will always be encapsulated in a legacy case. They will continue to be open for submission and placed in Legacy holders. These include SNES Cartridges, Japanese N64 boxes, small box PC, and oversized/special edition DVD-style games.
                                  </p>
                                </div>
                              </label>
                            </div>
                          </div>
                        </label>
                      </li>

                      <li className="drop-list__item">
                        <label className="drop-list__label">
                          <input
                            type="checkbox"
                            name="faq"
                            className="drop-list__toogle"
                          />

                          <div className="drop-list__header">
                            <div className="drop-list__mark" />
                            <h3 className="drop-list__title">
                              SHIPPING AND PAYMENTS
                            </h3>
                          </div>

                          <div className="drop-list__content">
                            <div className="arrow-list">
                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  Which items are rejected?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">Damaged packages will not be opened. To pack your games properly, please see our recommended packing guidelines.</p>
                                  <p className="faq__text">International packages that have duties assigned to WATA will be sent back. </p>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  Can I submit games in person? If so, where?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    Yes! We do accept submissions at some events. For a full list check the website{" "}
                                    <Link to={URL.learn.child.events.link}>
                                      Events page
                                    </Link>{" "}
                                    and follow us on social media for the most up to date information. We will also be accepting drop-offs at WATA HQ later this year for qualifying submissions.
                                    These submissions must be submitted under the WarpZone tier and must meet a specific value threshold.
                                    If your submission qualifies and you would like to set up a drop-off appointment, please{" "}
                                    <a href="mailto:info@watagames.com">
                                      contact us
                                    </a>.
                                  </p>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  Are my video games insured after I ship them
                                  to you?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                  Yes! After your games arrive to us undamaged your games are covered for the declared value or the fair market value, whichever is lower. We recommend you insure your game until it arrives to our facility. When you ship them don’t forget to add the packing slip in!
                                  </p>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  I submitted several games using different
                                  Service Levels. Can I have them all shipped to
                                  me in a single package?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                    For insurance purposes the games that have been graded need to be shipped out when they are ready according to the Service Levels you selected.
                                  </p>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  My game is over $100,000 in declared value but
                                  the submission only lets me declare under
                                  $100,000.
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                  Nice, we can’t wait to see it! If you have a game over $100,000 in declared value please{" "}
                                    <a href="mailto:info@watagames.com">
                                      contact us
                                    </a>{" "}
                                    so we can help you through the process.
                                  </p>
                                </div>
                              </label>

                              <label className="faq__question">
                                <input
                                  type="radio"
                                  name="faq-question"
                                  className="faq__toggle"
                                />
                                <p className="arrow-list__item">
                                  Do you offer your services and shipping to
                                  Canada?
                                </p>
                                <div className="faq__content">
                                  <p className="faq__text">
                                  Yes, we offer shipping to Canada via our Preferred FedEx International shipping with full insurance coverage. All packages will be insured for the full value and its contents will be marked appropriately to minimize any tampering by Canadian customs.
                                  </p>
                                </div>
                              </label>
                            </div>
                          </div>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    );
  }
}

FAQ.propTypes = propTypes;

const TranslateFAQ = translate()(FAQ);

export default withTracker(TranslateFAQ, { title: TITLE });
