import {
  extendObservable,
  action,
  toJS,
} from 'mobx';
import BigNumber from 'bignumber.js';
import {
  mergeWith,
  inRange,
  isNil,
} from 'lodash';

import { insurance } from '../constants/insurance';
import { newShipping, shippingMaxGames, perGameCost } from '../constants/shipping';
import Helpers from "../utils/Helpers";

import GameItemModel from './GameItemModel';

class GameListModel {
  gameListInitialState = {
    ids: [],
    dataMap: {},
  };

  discountInitialState = {
    code: '',
    percent: 0,
    shippingPercent: 0,
  };

  constructor() {
    extendObservable(this, {
      gameList: mergeWith({}, this.gameListInitialState),
      activeGameListItemID: '',
      discount: {
        code: '',
        percent: 0,
        shippingPercent: 0,
      },

      deliveryServicePrice: 0, // from BE

      deliveryService: 2,

      shippingCountry: '',

      isSendTogether: false,
      isGetPeronally: false,

      total: '', // from BE

      get checkedLength() {
        const { gameList } = this;
        const checkedItems = gameList.ids.map((id) => {
          return gameList.dataMap[id];
        }).filter(item => item.data.checked);

        return checkedItems.length;
      },

      get serviceLevels() {
        const { gameList } = this;
        const serviceLevels = gameList.ids.map(id => gameList.dataMap[id].data.serviceLevel);
        const setOfServiceLevels = new Set(serviceLevels);
        let res = [];

        setOfServiceLevels.forEach(item => res.push(item));

        return res;
      },

      get totalCount() {
        return this.getGamesCountWithFilter();
      },

      get selectCount() {
        const filterFunc = item => item.data.serviceLevel === 0 && !item.data.reholder;
        return this.getGamesCountWithFilter(filterFunc);
      },

      get warpZoneCount() {
        const filterFunc = item => item.data.serviceLevel === 3 && !item.data.reholder;
        return this.getGamesCountWithFilter(filterFunc);
      },

      get addOnsCount() {
        const filterFunc = item => (
          (item.data.cleaning || item.data.photoServices || item.data.cibPlus || item.data.legacyHolder || item.data.stickerRemoval || item.data.genDesignation || item.data.autographAuth) && !item.data.reholder
        );

        return this.getGamesCountWithFilter(filterFunc);
      },

      get prototypeCount() {
        const filterFunc = item => item.data.serviceLevel === 4 && !item.data.reholder;
        return this.getGamesCountWithFilter(filterFunc);
      },

      get effectiveDiscount() {
        return this.prototypeCount > 0 ? this.discountInitialState : this.discount;
      },

      get promoNotApplicable() {
        return this.discount.code !== '' && this.prototypeCount > 0;
      },

      get insurance() {
        let priceArray = [null, null, null]; // [usps, ups, fedex]

        const totalDeclaredValue = this.getTotalDeclaredValue(this.gameList);

        const filteredData = insurance
          .filter(item => {
            if (!!item.range[1]) {
              return inRange(totalDeclaredValue, item.range[0], item.range[1])
            } else {
              return totalDeclaredValue >= item.range[0];
            }
          });

        const foreignOrDomestic = 39; // Always use Canada prices

        if (!!filteredData.length && !!this.shippingCountry) {
          return [
            /* disable usps and ups
            filteredData[0][this.shippingCountry].usps,
            filteredData[0][this.shippingCountry].ups,
            */
            null,
            null,
            filteredData[0][foreignOrDomestic].fedex
          ];
        } else {
          return priceArray;
        }
      },

      get shipping() {
        let priceArray = [null, null, null]; // [usps, ups, fedex]
        const groupSizes = [];

        const collectibleGroupShippingPrice = (totalCount) => {
          let shippingCost = 0;
          const shippingKey = (this.shippingCountry == '230' || this.shippingCountry == '39') ? this.shippingCountry : 'other';
          for (let i = newShipping.length - 1; i >= 0; i--) {
            if (totalCount >= newShipping[i].games) {
              shippingCost = newShipping[i][shippingKey];
              break;
            }
          }
          if (totalCount > shippingMaxGames) {
            shippingCost += (totalCount - shippingMaxGames) * perGameCost[shippingKey];
          }
          return [
            0, // unused
            0, // unused
            Helpers.roundTo(shippingCost, 2),
          ];
        };

        const addGroupConditionally = groupId => {
          const useFilter = item => {
            if (groupId === "reholder") return item.data.reholder;
            return (item.data.serviceLevel === groupId && !item.data.reholder);
          }
          const size = this.getGamesCountWithFilter(useFilter);
          if (size > 0) {
            groupSizes.push(size);
          }
        }

        if (!!this.shippingCountry) {
          addGroupConditionally("reholder");
          this.serviceLevels.forEach(sl => addGroupConditionally(sl));

          priceArray = groupSizes.reduce((acum, curr) => {
            collectibleGroupShippingPrice(curr).forEach((price, i) => { if (price !== null) acum[i] = BigNumber(price).plus(acum[i]).toNumber(); });
            return acum;
          }, [0, 0, 0]);
        }

        return priceArray;
      },

      get shippingAndInsurance() {
        // the isGetPeronally variable indicates
        //   the collector will pick it up in person
        //   and shipping calculations are unnecessary
        if (this.isGetPeronally) {
          return 0.01;
        }

        let res = this.insurance.map((price, i) => {
          return (!isNil(price) && this.shipping[i]) ?
            BigNumber(price).plus(this.shipping[i]).toNumber() : null;
        });

        const discount = this.effectiveDiscount;
        if (discount.shippingPercent && discount.shippingPercent > 0) {
          res = res.map(p => p * (100.0 - discount.shippingPercent) / 100.0);
        }

        return res;
      },

      get shippingOptions() {
        return [
          {
            id: 0,
            value: this.insurance[0],
            disabled: isNil(this.insurance[0]),
          },
          {
            id: 1,
            value: this.insurance[1],
            disabled: isNil(this.insurance[1]),
          },
          {
            id: 2,
            value: this.insurance[2],
            disabled: isNil(this.insurance[2]),
          },
        ];
      },

      get totalDeclaredValue() {
        return this.getTotalDeclaredValue(this.gameList);
      },

      get totalServiceLevelsPrice() {
        const reducer = (acc, cur) => {
          const item = this.gameList.dataMap[cur];
          const currentServiceLevelPrice_bn = BigNumber(
            item.data.reholder ? 0.0 : item.serviceLevelPrices[item.data.serviceLevel].price
          ).times(item.data.count);

          return acc.plus(currentServiceLevelPrice_bn);
        };

        let price_bn = this.gameList.ids.reduce(reducer, BigNumber(0));
        return price_bn.toNumber();
      },

      get totalAddOnsPrice() {
        const reducer = (acc, cur) => {
          const item = this.gameList.dataMap[cur];
          const currentAddOnsPricePrice_bn = BigNumber(item.addOnsPrice)
            .times(item.data.count);

          return acc.plus(currentAddOnsPricePrice_bn);
        };

        let price_bn = this.gameList.ids.reduce(reducer, BigNumber(0));

        return price_bn.toNumber();
      },

      get totalNormalPrice() {
        const reducer = (acc, cur) => acc.plus(this.gameList.dataMap[cur].data.normalPrice);
        let price_bn = this.gameList.ids.reduce(reducer, BigNumber(0));

        return price_bn.toNumber();
      },

      get summaryPrice() {
        const reducer = (acc, cur) => acc.plus(this.gameList.dataMap[cur].price);
        let price_bn = this.gameList.ids.reduce(reducer, BigNumber(0));

        return price_bn.toNumber();
      },

      get promoDiscount() {
        const discount = this.effectiveDiscount;

        // This is fragile, but it must match the logic in billing.service.ts in the api code
        // Round so that the price for each item is an integral number of cents
        let totalPrice = this.gameList.ids.reduce(
          (acc, cur) => acc.plus(
            parseFloat(((this.gameList.dataMap[cur].price / this.gameList.dataMap[cur].data.count * (100.0 - discount.percent) / 100.0).toFixed(2)) * this.gameList.dataMap[cur].data.count).toFixed(2)),
          BigNumber(0));

        return BigNumber(this.summaryPrice)
          .minus(totalPrice);
      },

      get totalDiscount() {
        return this.promoDiscount;
      },

      get totalPrice() {
        const res = BigNumber(this.summaryPrice)
          .minus(this.totalDiscount);
        const isLessThanZero = res.isLessThan(0);

        const result = isLessThanZero ? BigNumber(0) : res;

        const shippingAndInsurance = this.shippingAndInsurance.length
          ? this.shippingAndInsurance[2]
          : this.shippingAndInsurance;

        return result
          .plus(shippingAndInsurance || 0)
          .toNumber().toFixed(2);
      },

      get promoCode() {
        const discount = this.effectiveDiscount;
        return discount.code;
      },

      get promoPercent() {
        const discount = this.effectiveDiscount;
        return discount.percent;
      },
    });

    this.updateActiveGameListItemID = action(
      'update active game list item ID', (id) => {
        if (this.activeGameListItemID !== id) {
          this.activeGameListItemID = id || '';
        }
      });

    this.clearGameList = action(
      'clear game list', () => {
        this.gameList = mergeWith({}, this.gameListInitialState);
      });

    this.addItemToGameList = action(
      'add item to game list', (data, innerKeyID) => {
        const { gameList } = this;
        if (gameList.ids.indexOf(data[innerKeyID]) === -1) {
          gameList.ids.push(data[innerKeyID]);

          data.checked = false;
          gameList.dataMap[data[innerKeyID]] = new GameItemModel(data);
        }
      });

    this.removeItemFromGameList = action(
      'remove item from game list', (id) => {
        const { gameList } = this;
        gameList.ids.forEach((item, i) => {
          if (item === id) {
            gameList.ids.splice(i, 1);
          }
        });

        delete gameList.dataMap[id];
      });

    this.updateTotal = action(
      'update total price from backend', (price) => {
        if (this.total !== price) {
          this.total = price;
        }
      });

    this.updateDeliveryServicePrice = action(
      'update shipping service price from backend', (price) => {
        if (this.deliveryServicePrice !== price) {
          this.deliveryServicePrice = price;
        }
      });

    this.updateDeliveryService = action(
      'update shipping service id', (id) => {
        if (this.deliveryService !== id) {
          this.deliveryService = id;
        }
      });

    this.updateShippingCountry = action(
      'update shipping country', (name) => {
        if (this.shippingCountry !== name) {
          this.shippingCountry = name;
        }
      });

    this.updateIsSendTogether = action(
      'update is send together', (data) => {
        if (this.isSendTogether !== data) {
          this.isSendTogether = data;
        }
      });

    this.updateIsGetPeronally = action(
      'update is get personally', (data) => {
        if (this.isGetPeronally !== data) {
          this.isGetPeronally = data;
        }
      });

    this.updateDiscount = action(
      'update discount data', (obj) => {

        if (!isNil(obj.percent)) {
          this.discount = obj;
        }
      });

    this.clearDiscount = action(
      'clear discount data', () => {
        this.updateDiscount(this.discountInitialState);
      });

    // helpers
    this.convertGameListToJS = () => {
      return toJS(this.gameList);
    };

    this.convertDiscountToJS = () => {
      const discount = this.effectiveDiscount;
      return toJS(discount);
    };

    this.getTotalDeclaredValue = (gameList) => {
      return gameList.ids.reduce(
        (acum, currId) => acum + gameList.dataMap[currId].totalDecValue,
        0);
    };

    this.getGamesCountWithFilter = (filterFunc = item => item) => {
      const { gameList } = this;
      let count = 0;
      let countArray = gameList.ids
        .map(id => this.gameList.dataMap[id])
        .filter(filterFunc)
        .map(item => {
          return typeof item.data.count === 'string'
            ? parseInt(item.data.count, 10)
            : item.data.count;
        });

      if (countArray.length) {
        count = countArray.reduce((acc, cur) => acc + cur);
      }

      return count;
    };
  }
}

export default GameListModel;
