import {extendObservable, action} from 'mobx';

class ModalsStore {
  constructor() {
    extendObservable(this, {
      modals: {
        logIn: false,
        logOut: false,
        forgot: false,
        deleteItem: false,
        changePass: false,
        scaleImage: false,
        changeAddress: false,
        completePasswordReset: false,
      },
    });

    this.toggleModal = action('toggle modals', (type) => {
      const modals = Object.assign({}, this.modals);
      const modalsKeys = Object.keys(modals);

      modalsKeys.forEach(key => {
        if (key !== type) {
          modals[key] = false;
        }
      });

      modals[type] = !modals[type];

      this.modals = modals;
    });
  }
}

const store = new ModalsStore();

export default store;
