import React from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';
import {
  withRouter,
  NavLink,
} from 'react-router-dom';

import { BrowserNavLink } from '../shared/BrowserNavLink';

import { translate } from 'react-i18next';

import { routeURLs as URL } from '../../configs/routeURLs';

import { userSessionRoleKey } from '../../constants/common';
import AuthSession from '../../utils/AuthSession';
import Storage from '../../utils/Storage';

import SearchForm from '../Header/SearchForm/SearchForm';

import './styles/Sidebar.css';


const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

class Sidebar extends React.Component {
  state = {
    account: true,
    whoWeAre: true,
    whatWeDo: true,
    learnConnect: true,
    join: true,
    dashboard: true,
  }

  goToLogin = () => {
    Storage.set('watagamesNextRedirect', this.props.location.pathname);
    this.props.router.push(URL.login.link);
  }

  logOut = () => {
    this.props.router.push(URL.logout.link);
  }

  closeSidebar = () => {
    this.props.appStore.updateSidebarStatus(false);
  }

  onCheck = (e) => {
    const {target} = e;
    const name = target.getAttribute('name');

    this.setState({
      [name]: target.checked
    });
  }

  render() {
    const {t} = this.props;
    const {hash} = this.props.location;

    const whatWeDoSubmenu = Object.keys(URL.whatWeDo.child).map( linkKey => {
      return (
        <li key={linkKey}>
          <NavLink
            onClick={this.closeSidebar}
            to={URL.whatWeDo.child[linkKey].link}>
            {t(URL.whatWeDo.child[linkKey].title)}
          </NavLink>
        </li>
      );
    })

    return (
      <nav id="menu" className="menu">
        <SearchForm />
        <ul className="menu__list">
          {
            this.props.appStore.isUserLoggedIn && (
              <li className="menu__item">
                <input
                  type="checkbox"
                  name="account"
                  onChange={this.onCheck}
                  className="menu__triger"
                  checked={this.state.account}
                  id="account"/>
                <div className="menu__link">
                  <NavLink
                    onClick={this.closeSidebar}
                    exact
                    to={URL.dashboard.child.profile.link}>
                    {this.props.appStore.user.fullName || t('Account')}
                  </NavLink>
                  <label htmlFor="account" className="menu__arrow">
                    <span className="icon-pix-arrow-dn" />
                  </label>
                </div>

                <ul className="menu__submenu">
                  <li>
                    <NavLink
                      onClick={this.closeSidebar}
                      to={URL.dashboard.child.orders.link}>
                      {t('Order History')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={this.closeSidebar}
                      exact
                      to={URL.dashboard.child.profile.link}>
                      {t('Manage Account')}
                    </NavLink>
                  </li>
                </ul>
              </li>
            )
          }

          {
            (AuthSession.isTokenSet() && this.props.appStore.isUserLoggedIn) && (
              <li className="menu__item">
                <div className="menu__link">
                  <BrowserNavLink
                    onClick={this.closeSidebar}
                    exact
                    to={URL.submitGame.child.cart.link}>
                    {t('Cart')}
                  </BrowserNavLink>
                </div>
              </li>
            )
          }

          <li className="menu__item">
            <div className="menu__link">
              <NavLink
                onClick={this.closeSidebar}
                exact
                to={URL.home.link}>
                {t('Home')}
              </NavLink>
            </div>
          </li>

          <li className="menu__item">
            <div className="menu__link">
              <NavLink
                onClick={this.closeSidebar}
                to={URL.whoWeAre.link}>
                {t('Who We Are')}
              </NavLink>
            </div>
          </li>

          <li className="menu__item">
            <input
              type="checkbox"
              name="whatWeDo"
              className="menu__triger"
              onChange={this.onCheck}
              checked={this.state.whatWeDo}
              id="what_we_do" />
            <div className="menu__link">
              <NavLink
                onClick={this.closeSidebar}
                to={URL.whatWeDo.link}>
                {t('What We Do')}
              </NavLink>
              <label htmlFor="what_we_do" className="menu__arrow">
                <span className="icon-pix-arrow-dn" />
              </label>
            </div>
            <ul className="menu__submenu">
              {whatWeDoSubmenu}
            </ul>
          </li>

          <li className="menu__item">
            <input
              type="checkbox"
              name="learnConnect"
              checked={this.state.learnConnect}
              onChange={this.onCheck}
              className="menu__triger"
              id="learn_connect" />
            <div className="menu__link">
              <NavLink
                onClick={this.closeSidebar}
                to={URL.learn.link}>
                {t('Learn+Connect')}
              </NavLink>
              <label htmlFor="learn_connect" className="menu__arrow">
                <span className="icon-pix-arrow-dn" />
              </label>
            </div>

            <ul className="menu__submenu">
              <li>
                <NavLink
                  onClick={this.closeSidebar}
                  to={URL.learn.child.newsletter.link}>
                  {t('Newsletter')}
                </NavLink>
              </li>
              {/*
                <li>
                  <NavLink
                    onClick={this.closeSidebar}
                    to={URL.learn.child.howTo.link}>
                    {t('How to')}
                  </NavLink>
                </li>
              */}
              <li>
                <NavLink
                  onClick={this.closeSidebar}
                  to={URL.learn.child.events.link}>
                  {t('Events')}
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  onClick={this.closeSidebar}
                  to={URL.learn.child.database.link}>
                  {t('Database')}
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={this.closeSidebar}
                  to={URL.learn.child.registry.link}>
                  {t('Registry')}
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={this.closeSidebar}
                  to={URL.learn.child.census.link}>
                  {t('Census')}
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={this.closeSidebar}
                  to={URL.learn.child.ebay.link}>
                  {t('eBay')}
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  onClick={this.closeSidebar}
                  target="_blank"
                  rel="noopener noreferrer"
                  to={{pathname: URL.learn.child.blog.link}}>
                  {t('Blog')}
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu__item">
            <div className="menu__link">
              <NavLink
                onClick={this.closeSidebar}
                to={URL.submitGame.link}>
                {t('Submit Here')}
              </NavLink>
            </div>
          </li>
          <li className="menu__item">
            <div className="menu__link">
              <a
                href="/populations/index.html"
                target="_blank">
                {t('Pop Reports')}
              </a>
            </div>
          </li>
          {
            (!AuthSession.isTokenSet() && !this.props.appStore.isUserLoggedIn) ? (
              <li className="menu__item">
                <input
                  type="checkbox"
                  name="join"
                  className="menu__triger"
                  checked={this.state.join}
                  onChange={this.onCheck}
                  id="join" />
                <div className="menu__link">
                  <NavLink
                    onClick={this.closeSidebar}
                    to={URL.join.link}>
                    {t('Join')}
                  </NavLink>
                  <label htmlFor="join" className="menu__arrow">
                    <span className="icon-pix-arrow-dn" />
                  </label>
                </div>

                <ul className="menu__submenu">
                  <li>
                    <NavLink
                      exact
                      onClick={this.closeSidebar}
                      to={URL.join.child.usThem.link}>
                      {t('Us > Them')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      onClick={this.closeSidebar}
                      to={URL.dealer.link}>
                      {t('Dealer Program')}
                    </NavLink>
                  </li>
                </ul>
              </li>
            ) : (
              <li className="menu__item">
                <input
                  type="checkbox"
                  name="dashboard"
                  onChange={this.onCheck}
                  checked={this.state.dashboard}
                  className="menu__triger"
                  id="dashboard"/>
                <div className="menu__link">
                  <NavLink
                    onClick={this.closeSidebar}
                    to={URL.dashboard.link}>
                    {t('Dash')}
                  </NavLink>
                  <label htmlFor="dashboard" className="menu__arrow">
                    <span className="icon-pix-arrow-dn" />
                  </label>
                </div>
                <ul className="menu__submenu">
                  <li>
                    <NavLink
                      onClick={this.closeSidebar}
                      to={URL.dashboard.child.orders.link}>
                      {t('Orders')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={this.closeSidebar}
                      to={URL.dashboard.child.savedGames.link}>
                      {t('Saved media')}
                    </NavLink>
                  </li>
                  {
                    Storage.get(userSessionRoleKey) === 0 && (
                      <li>
                        <NavLink
                          onClick={this.closeSidebar}
                          to={URL.dashboard.child.profile.link}>
                          {t('Profile')}
                        </NavLink>
                      </li>
                    )
                  }
                </ul>
              </li>
            )
          }
        </ul>
        <div>
          {
            (!AuthSession.isTokenSet() && !this.props.appStore.isUserLoggedIn) ? (
              <button
                onClick={this.goToLogin}
                type="button"
                className="btn btn--block">
                <span className="btn__text">{t('Login')}</span>
              </button>
            ) : (
              <button
                onClick={this.logOut}
                type="button"
                className="btn btn--block">
                <span className="btn__text">{t('Log out')}</span>
              </button>
            )
          }
        </div>

      </nav>
    );
  }
}

const WrappedSidebar = inject('appStore', 'router')(observer(Sidebar));
const TranslateSidebar = translate()(WrappedSidebar);

const RouterSidebar = withRouter(TranslateSidebar);
RouterSidebar.wrappedComponent.propTypes = wrappedPropTypes;

export default RouterSidebar;
