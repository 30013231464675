import React from 'react';
import PropTypes from 'prop-types';
import ReactAlert from 'react-s-alert';
import {inject, observer} from 'mobx-react';

import { translate } from 'react-i18next';

import Formsy from 'formsy-react';

import FormModel from '../../../models/FormModel';

import Input from '../form-elements/Input';
import Password from '../form-elements/Password';

import Logger from '../../../utils/Logger';

import API from '../../../utils/API';
import Storage from '../../../utils/Storage';

import {
  errorMessages as err,
  alertConfig,
} from '../../../constants/common';

import { apiURLs } from '../../../configs/apiURLs';
import { routeURLs as URL } from '../../../configs/routeURLs';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  modalsStore: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.store = new FormModel({
      resetToken: Storage.get('resetToken'),
      newPassword: '',
    });
  }

  onValidSubmit = async (model, resetForm, invalidateForm) => {
    const {
      appStore,
      router,
      t,
    } = this.props;

    const {store} = this;

    appStore.updateProgressData();
    store.updateLoadingStatus();

    try {
      await API.postData(apiURLs.completePasswordReset, store.convertToJS());

      appStore.updateProgressData(false);
      store.updateLoadingStatus(false);

      store.resetForm();

      ReactAlert.success(t('Your password has been reset'), alertConfig);
      this.toggleModal();

      router.push(URL.login.link);

    } catch (error) {
      Logger.error(error);
      ReactAlert.error('This link is invalid or has expired', alertConfig);

      appStore.updateProgressData(false);
      store.updateLoadingStatus(false);
    }
  }

  toggleModal = () => {
    this.props.modalsStore.toggleModal('completeResetPassword');
  }

  render() {
    const {t} = this.props;

    const {store} = this;

    return (
      <div className="modal">
        <Formsy
          className="modal-content"
          ref={(component) => {
            this.refToForm = component
          }}
          onValidSubmit={this.onValidSubmit}
        >
          <div className="modal-header">
            <h2 className="section__title section__title--white">
              {t('Choose a new password')}
            </h2>
          </div>
          <div className="modal-body modal-form">
            <Input
              name="message"
              type="hidden"
              className="form-field--no-margin"
            />

            <Password
              name="newPassword"
              value={store.elements.get('newPassword')}
              updateStateData={store.updateFormItem}
              id="resetPass_newPassword"
              placeholder={t('New password')}
              required
              validations={{
                minLength: 8,
                isLatinAndNumber: true,
              }}
              validationErrors={{
                minLength: err.minLength(8),
                isDefaultRequiredValue: err.isDefaultRequiredValue,
                isLatinAndNumber: err.isLatinAndNumber,
              }}
            >
              <label className="form-label" htmlFor="changePass_newPassword">
                {t('New password')}:
              </label>
            </Password>
          </div>

          <div className="modal-footer modal-form">
            <button
              type="submit"
              disabled={store.isLoading.get()}
              className="btn btn--high btn--lg btn--white">
              <span className="btn__text">
                {t('Submit')}
              </span>
            </button>
          </div>
        </Formsy>
      </div>
    );
  }
}


const WrappedPasswordReset = inject('router', 'appStore', 'modalsStore')(observer(PasswordReset));
const TranslatePasswordReset = translate()(WrappedPasswordReset);
TranslatePasswordReset.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslatePasswordReset;
