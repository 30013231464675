export const orderStatuses = [
  {
    id: '',
    name: 'All',
  },
  {
    id: 0,
    name: 'Awaiting',
  },
  {
    id: 1,
    name: 'Scanned',
  },
  {
    id: 2,
    name: 'Crating',
  },
  {
    id: 3,
    name: 'Canceled',
  },
  {
    id: 4,
    name: 'Grading',
  },
  {
    id: 5,
    name: 'Post-Grading',
  },
  {
    id: 6,
    name: 'Held',
  },
  {
    id: 7,
    name: 'Shipped',
  },
];
