import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from 'react-modal';
import {inject, observer} from 'mobx-react';
import {translate} from 'react-i18next';

import {
  modalOptions,
} from '../../../constants/common';

import './styles/GamesList.css';

import GameListItem from '../GameListItem/GameListItem';
import DeleteModal from '../DeleteModal/DeleteModal';

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  updateData: PropTypes.func,
  removeItem: PropTypes.func,
  updateActiveItemID: PropTypes.func,
  modalsStore: PropTypes.object.isRequired,
  isCheckboxesVisible: PropTypes.bool,
  labelsClassName: PropTypes.string,
  emptyClassName: PropTypes.string,
  emptyListText: PropTypes.string,
  orderId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  data: PropTypes.shape({
    ids: PropTypes.oneOfType([
      PropTypes.object, // that's because of mobx observable array
      PropTypes.array,
    ]),
    dataMap: PropTypes.object,
    isEditable: PropTypes.bool,
  }),
};

const defaultProps = {
  verboseInfo: false,
  isCheckboxesVisible: false,
  isEditable: true,
  isAddLink: false,
  labelsClassName: '',
  orderId: '',
  removeItem: () => {},
  updateData: () => {},
  updateActiveItemID: () => {},
  data: {
    ids: [],
    dataMap: {},
  },
};

class GamesList extends React.Component {
  toggleDeleteModal = () => {
    this.props.modalsStore.toggleModal('deleteItem');
  }

  render() {
    const {props} = this;
    const {
      t,
      data,
      orderId,
      verboseInfo,
    } = props;

    const d = document;

    const queueList = data.ids.map(id => {
      return (
        <GameListItem
          key={id}
          id={id}
          orderId={orderId}
          updateData={props.updateData}
          updateActiveItemID={props.updateActiveItemID}
          isCheckboxVisible={props.isCheckboxesVisible}
          isEditable={props.isEditable}
          isAddLink={props.isAddLink}
          item={data.dataMap[id]}
          verboseInfo={verboseInfo}
        />
      )
    });

    return (
      <div>
        {
          !!queueList.length ? (
            <div className={classnames(
              'games__table',
              {
                'games__table--checkboxes': props.isCheckboxesVisible && props.isEditable,
                'games__table--non-editable': !props.isEditable,
                'games__table--add-link': props.isAddLink,
              }
            )}>
              <div className="games__table-head">
                <ul className={classnames(
                    'games__labels',
                    props.labelsClassName,
                  )}>
                  <li className="games__label-count">
                    {t('Qty')}:
                  </li>
                  <li className="games__label-game">
                    {t('Media')}:
                  </li>
                  <li className="games__label-type">
                    {t('Label Type')}:
                  </li>
                  <li className="games__label-service">
                    {t('Service Level')}:
                  </li>
                  <li className="games__label-addons">
                    {t('Addons')}:
                  </li>
                  <li className="games__label-price">
                    {t('Price')}:
                  </li>
                </ul>
              </div>

              <div className="games__table-body">
                {queueList}
              </div>
              {
                props.isEditable && (
                  <Modal
                    onRequestClose={this.toggleDeleteModal}
                    bodyOpenClassName={modalOptions.modalBodyOpenClassName}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    className={modalOptions.modalContainer()}
                    overlayClassName={modalOptions.modalOverlayContainer()}
                    parentSelector={() => d.body}
                    appElement={d.getElementById('root')}
                    isOpen={props.modalsStore.modals.deleteItem}>
                    <DeleteModal removeItem={props.removeItem} />
                  </Modal>
                )
              }
            </div>
          ) : (
            <h3 className={classnames(
                'games__empty',
                'text-center',
                props.emptyClassName,
              )}>
              {props.emptyListText}
            </h3>
          )
        }
      </div>
    );
  }
}

const WrappedGamesList = inject('modalsStore')(observer(GamesList));
const TranslateGamesList = translate()(WrappedGamesList);
TranslateGamesList.wrappedComponent.propTypes = wrappedPropTypes;
TranslateGamesList.wrappedComponent.defaultProps = defaultProps;

export default TranslateGamesList;
