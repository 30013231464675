import React from "react";

import { Link } from "react-router-dom";

import { routeURLs as URL } from "../../../../configs/routeURLs";

import { BrowserLink } from "../../../shared/BrowserLink";

import "./styles/Submission.css";

const Submission = (props) => {
  return (
    <div className="submission">
      <div className="container-fluid">
        <div className="row-fluid">
          <div className="submission__wrapper section section--gradient section--btp">
            <div className="submission__content">
              <div className="submission__info">
                <img
                  className="submission__img submission__img--right"
                  src={`${process.env.PUBLIC_URL}/images/submission-img.png`}
                  alt="img"
                />
                <div className="submission-banner__header">
                  <h1 className="banner__title">Your media, graded.</h1>
                </div>
                <p className="submission__text">
                  Loose cartridges, Complete in Box (CIB) and sealed media —
                  whatever you want to submit, we’ll grade it. WATA is the
                  destination for professional video game and media grading &
                  certification, bringing clarity and confidence to the process.
                  Learn how to submit your media, games and cartridges for grading
                  below and start pursuing your passion today.To see a list of
                  media types and platforms currently available for grading, please
                  see our FAQ by clicking{" "}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={URL.faq.link}
                  >
                    here
                  </Link>
                  . (See “Grading and encapsulation” section, question “What we
                  grade”).
                </p>
              </div>

              <div className="submission__easy">
                <h2 className="section__line-through">
                  <span>
                    Submission is <b>easy</b>
                  </span>
                </h2>

                <div className="submission__steps">
                  <div className="submission__step">
                    <h3 className="submission-step__title">
                      <span>01 |</span> SELECT YOUR MEDIA
                    </h3>
                    <p className="submission__text">
                      First, select Standard Submission. We’ll walk you through
                      the simple steps so our expert team can begin grading and
                      certifying your media.
                    </p>
                  </div>

                  <div className="submission__step">
                    <h3 className="submission-step__title">
                      <span>02 |</span> GIVE US THE DETAILS
                    </h3>
                    <p className="submission__text">
                      We’re ready and eager to help you authenticate your media.
                      Just choose the service level that fits your needs and
                      provide us with the media Platform, media Title, and Declared
                      Value. And that’s it – leave the rest to the experts!
                    </p>
                  </div>

                  <div className="submission__step">
                    <h3 className="submission-step__title">
                      <span>03 |</span> CHOOSE SERVICES
                    </h3>
                    <p className="submission__text">
                      Choose from a variety of services to enhance your
                      submission and receive the most value for your media.
                      Additional services include Light Cleaning, Reholder, and
                      Post-Grade Photos.
                    </p>
                  </div>
                </div>

                <div className="submission__process-wrapper">
                  <div className="submission__process"></div>
                  <BrowserLink className="btn" to={URL.submitGame.link}>
                    <span className="btn__text btn__text--black">
                      Start submission!
                    </span>
                  </BrowserLink>
                </div>

                <ul className="arrow-list">
                  <li className="arrow-list__item">
                    We care about your media like you would. They’re covered by
                    insurance from the time we get them to the time you get them
                    back.
                  </li>
                  <li className="arrow-list__item">
                    We handle your media exactly like we handle our own media and
                    – if we have a question – we check with you first.
                  </li>
                  <li className="arrow-list__item">
                    WATA accepts PayPal and all major credit and debit cards.
                    You can ship and receive via USPS, UPS, or FedEx. Return
                    shipping and insurance charges will be determined by the
                    number of media certified plus the total declared value of
                    the media. Ship media to CU WATA Receiving, P.O. Box
                    6180, Newport Beach, CA 92658
                  </li>
                </ul>

                <div className="text-center">
                  <Link
                    className="btn btn--high btn--lg"
                    style={{ margin: "1rem" }}
                    to={URL.whatWeDo.child.pricing.link}
                  >
                    <span className="btn__text btn__text--black">PRICING</span>
                  </Link>
                  <Link
                    className="btn btn--high btn--lg m-2"
                    style={{ margin: "1rem" }}
                    to={URL.whatWeDo.child.wataScale.link}
                  >
                    <span className="btn__text btn__text--black">
                      THE WATA SCALE
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Submission;
