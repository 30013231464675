import React from 'react';
import PropTypes from 'prop-types';

import {inject, observer} from 'mobx-react';

import { translate } from 'react-i18next';

import {NavLink, withRouter} from 'react-router-dom';
import { BrowserNavLink } from '../../shared/BrowserNavLink';

import AuthSession from '../../../utils/AuthSession';
import Storage from '../../../utils/Storage';

import './styles/HeaderTop.css';
import '../../shared/styles/common/list.css';

import SearchForm from '../SearchForm/SearchForm';

import HeaderDropDownMenu from '../HeaderDropDownMenu/HeaderDropDownMenu';

import { routeURLs as URL } from '../../../configs/routeURLs';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};


class HeaderTop extends React.Component {
  goToLogin = () => {
    Storage.set('watagamesNextRedirect', URL.submitGame.child.cart.link);
    this.props.router.push(URL.login.link);
  }

  render () {
    const { t } = this.props;

    return (
      <div className="header-top">
        <div className="container-fluid">
          <div className="row">
            <div className="header-top__content">
              <SearchForm />

              <div className="header-top__menu">
                <ul className="header-top__list">
                  <li className="header-top__list-item header-top__list-item--arrow-down">
                    <span className="header-top__list-link">
                      <span className="icon-help" />
                    </span>
                    <div className="header-top__submenu">
                      <ul className="list">
                        <li className="list__item">
                          <NavLink
                            exact
                            to={URL.faq.link}
                            className="list__link">
                              <span className="icon-faq" /> {t('F.A.Q.')}
                          </NavLink>
                        </li>
                        <li className="list__item">
                          <NavLink
                            exact
                            to={URL.glossary.link}
                            className="list__link">
                              <span className="icon-glossary" /> {t('Glossary')}
                          </NavLink>
                        </li>
                        {/*
                          <li className="list__item">
                            <NavLink
                              exact
                              to={URL.learn.child.howTo.link}
                              className="list__link">
                                <span className="icon-lightbulb" /> {t('How To')}
                            </NavLink>
                          </li>
                        */}
                        <li className="list__item">
                          <NavLink
                            exact
                            to={URL.contactUs.link}
                            className="list__link">
                              <span className="icon-phone" /> {t('Contact Us')}
                          </NavLink>
                        </li>
                        <li className="list__item">
                          <NavLink
                            exact
                            to={URL.dealer.link}
                            className="list__link">
                              <span className="icon-handshake-o" /> {t('Dealer program')}
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="header-top__list-item header-top__list-item--no-arrow">
                    {
                      (AuthSession.isTokenSet() && this.props.appStore.isUserLoggedIn) ? (
                        <BrowserNavLink
                          exact
                          to={URL.submitGame.child.cart.link}
                          className="header-top__list-link">
                            <span className="icon-cart" />
                        </BrowserNavLink>
                      ) : (
                        <button
                          onClick={this.goToLogin}
                          className="header-top__list-link">
                            <span className="icon-cart" />
                        </button>
                      )
                    }
                  </li>
                </ul>
                <HeaderDropDownMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const WrappedHeaderTop = inject('appStore', 'router')(observer(HeaderTop));
const TranslateHeaderTop = translate()(WrappedHeaderTop);

const RouterHeaderTop = withRouter(TranslateHeaderTop);
RouterHeaderTop.wrappedComponent.propTypes = wrappedPropTypes;

export default RouterHeaderTop;
