import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';

import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import Orders from './Orders/Orders';
import SavedGames from './SavedGames/SavedGames';
import Profile from './Profile/Profile';

import { routeURLs as URL } from '../../configs/routeURLs';

import './styles/Dash.css';

import withTracker from '../shared/AnalyticsTracker/withTracker';
import AuthRoute from '../shared/routes/AuthRoute';
import AuthSession from '../../utils/AuthSession';

const propTypes = {
  t: PropTypes.func.isRequired,
};

const TITLE = 'Dashboard';


class Dashboard extends React.Component {
  render() {
    const {t} = this.props;

    /* It makes redirect from /dashboard to /dashboard/saved-games */
    const redirectToSavedGames = props => {
      const {from} = this.props.location.state || {
        from: { pathname: URL.dashboard.child.savedGames.link }
      }
      return (
        <Redirect to={from} />
      )
    };

    return (
      <div className="page-wrapper">
        <Helmet
          title={t(TITLE)}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />
        <Header />

        <main className="main">
          <Switch>
            <Route
              exact
              path={URL.dashboard.link}
              render={redirectToSavedGames}
            />

            <Route
              path={URL.dashboard.child.orders.link}
              render={props => {
                return <Orders {...props} />
              }}
            />
            <Route
              exact
              path={URL.dashboard.child.savedGames.link}
              render={props => {
                return <SavedGames {...props} />
              }}
            />
            <AuthRoute
              exact
              redirectTo={URL.dashboard.child.savedGames.link}
              authenticated={AuthSession.isTokenSet()}
              component={Profile}
              path={URL.dashboard.child.profile.link}
            />
            {/* 404 */}
            <Redirect to={{ state: { error: true } }} />
          </Switch>
        </main>

        <Footer />
      </div>
    );
  }
}


Dashboard.propTypes = propTypes;

const TranslateDashboard = translate()(Dashboard);

export default withTracker(TranslateDashboard, {title: TITLE});
