import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import { translate } from 'react-i18next';
import uuid from 'uuid-v4';

import MembersItem from '../MembersItem/MembersItem';
import {
  sliderSettings,
} from '../../../constants/sliderSettings';

import Helpers from '../../../utils/Helpers';

import '../../shared/styles/slick-slider.css';
import './styles/Members.css';

const propTypes = {
  t: PropTypes.func.isRequired,
};

class Members extends React.Component {
  render() {
    const {
      t,
      data,
    } = this.props;
    const responsiveOptions = Helpers.updateSliderOptions(sliderSettings.members, data);

    const sliderItem = data.map(item => {
      const id = uuid();
      return (
        <div key={id}>
          <MembersItem
            id={id}
            text={item.text}
            name={item.name}
            rating={item.rating}
            />
        </div>
      )
    });

    return (
      <section className="members">
        <div className="container-fluid">
          <div className="row-fluid">
            <div className="members__wrapper section">
              <h2 className="section__title section__title--white">
                {t('What our members say:')}
              </h2>
              {
                data.length ? (
                  <Slider
                    className="slick-slider--visible"
                    {...responsiveOptions}>
                    {sliderItem}
                  </Slider>
                ) : (
                  <h2 className="section__title--white text-center">
                    We are asking them. Please wait
                  </h2>
                )
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}


Members.propTypes = propTypes;

const TranslateMembers = translate()(Members);

export default TranslateMembers;
