import React from 'react';
import PropTypes from 'prop-types';
import ReactAlert from 'react-s-alert';
import classnames from 'classnames';

import Formsy from 'formsy-react';

import {inject, observer} from 'mobx-react';
import {translate} from 'react-i18next';

import FormModel from '../../../models/FormModel';

import Input from '../../shared/form-elements/Input';

import '../../../utils/FormsyCustomValidationRules';

import {
  errorMessages as err,
  alertConfig,
} from '../../../constants/common';

import { apiURLs } from '../../../configs/apiURLs';

import API from '../../../utils/API';
import Logger from '../../../utils/Logger';

import './styles/PromoForm.css';

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
  modalsStore: PropTypes.object.isRequired,
  submitGameStore: PropTypes.object.isRequired,
  className: PropTypes.string,
};

class PromoForm extends React.Component {
  constructor(props) {
    super(props);

    this.store = new FormModel({
      code: '',
    });
  }

  onValidSubmit = async (model, resetForm, invalidateForm) => {
    const {
      props,
      store,
    } = this;

    const {
      appStore,
      cartStore,
      t,
    } = props;

    appStore.updateProgressData();
    store.updateLoadingStatus();

    try {
      const response = await API.getData(apiURLs.billing.promo(store.elements.get('code')));
      const {data} = response;

      cartStore.updateDiscount(data);

      store.resetForm();

      if (!!data) {
        if (cartStore.promoNotApplicable) {
          ReactAlert.warning(t('Discount code is not applicable to this order'), alertConfig);
        } else {
          ReactAlert.success(t('Magic! Discount code is enabled'), alertConfig);
        }
      } else {


        const getRandomInt = (min, max) => (
          Math.floor(Math.random() * (max - min + 1)) + min
        );

        const messages = [
          "Ha-ha! Nice try! But no",
          "That's not a cheatcode",
          "Try another secret word",
        ];

        ReactAlert.warning(
          messages[getRandomInt(0, messages.length - 1)],
          alertConfig
        );
      }


      appStore.updateProgressData(false);
      store.updateLoadingStatus(false);
    } catch (error) {
      Logger.error(error);
      if (!!error.response) {
        const {data} = error.response;

        // invalidateForm or formRef.updateInputsWithError
        Object.keys(data).forEach(key => {
          const value = data[key];

          if (!(key in model)) return;
          // invalidate if key exist in current form model
          invalidateForm({
            [key]: Array.isArray(value) ? value.join(' ') : value,
          });
        });
      } else {
        ReactAlert.error(err.somethingWentWrong, alertConfig);
      }

      appStore.updateProgressData(false);
      store.updateLoadingStatus(false);
    }
  }

  render() {
    const {
      t,
      className,
    } = this.props;

    const {store} = this;

    return (
      <Formsy
        ref={(component) => {
          store.updateRefToForm(component);
        }}
        className={classnames('promo-form__form', className)}
        onValidSubmit={this.onValidSubmit}>
        <div className="promo-form__field-wrapper">
          <Input
            name="code"
            type="text"
            value={store.elements.get('code')}
            updateStateData={store.updateFormItem}
            id="shipping_code"
            placeholder={t('Enter Promo Code')}
            required
            validations={{
              isWhitespace: true,
            }}
            validationErrors={{
              isWhitespace: err.isWhitespace,
            }}
          />

          <Input
            name="message"
            type="hidden"
            className="form-field--no-margin" />
        </div>
        <button
          type="submit"
          disabled={store.isLoading.get() || store.elements.get('code') === ''}
          className="promo-form__btn">
          <span className="btn__text">
            {t('Apply')}
          </span>
        </button>
      </Formsy>
    );
  }

}

const WrappedPromoForm = inject(
  'appStore',
  'cartStore',
  'submitGameStore',
  'modalsStore'
)(observer(PromoForm));

const TranslatePromoForm = translate()(WrappedPromoForm);
TranslatePromoForm.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslatePromoForm;
