import React from 'react';
import PropTypes from 'prop-types';
import ReactAlert from 'react-s-alert';
import {inject, observer} from 'mobx-react';

import Formsy from 'formsy-react';

import FormModel from '../../../models/FormModel';

import Logger from '../../../utils/Logger';
import API from '../../../utils/API';
import AuthSession from '../../../utils/AuthSession';

import Input from '../../shared/form-elements/Input';
import Password from '../../shared/form-elements/Password';

import { translate } from 'react-i18next';

import {
  errorMessages as err,
  alertConfig,
} from '../../../constants/common';

import { apiURLs } from '../../../configs/apiURLs';


const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  modalsStore: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
};


class ChangePass extends React.Component {
  constructor(props) {
    super(props);

    this.store = new FormModel({
      oldPassword: '',
      newPassword: '',
    });
  }

  onValidSubmit = async (model, resetForm, invalidateForm) => {
    const {
      appStore,
      t,
    } = this.props;

    const {store} = this;

    appStore.updateProgressData();
    store.updateLoadingStatus();

    try {
      const response = await API.putData(apiURLs.user.password, store.convertToJS());
      const {data} = response;

      AuthSession.set(data.token);

      appStore.updateProgressData(false);
      store.updateLoadingStatus(false);

      store.resetForm();

      ReactAlert.success(t('Your password was changed'), alertConfig);
      this.toggleModal();

    } catch (error) {
      Logger.error(error);
      if (!!error.response) {
        const {data} = error.response;

        // invalidateForm or formRef.updateInputsWithError
        Object.keys(data).forEach(key => {
          const value = data[key];

          if (!(key in model)) return;
          // invalidate if key exist in current form model
          invalidateForm({
            [key]: Array.isArray(value) ? value.join(' ') : value,
          });
        });
      } else {
        ReactAlert.error(t(err.somethingWentWrong), alertConfig);
      }

      appStore.updateProgressData(false);
      store.updateLoadingStatus(false);
    }
  }

  toggleModal = () => {
    this.props.modalsStore.toggleModal('changePass');
  }

  render() {
    const {t} = this.props;

    const {store} = this;

    return (
      <div className="modal">
        <Formsy
          className="modal-content"
          ref={(component) => {
            store.updateRefToForm(component);
          }}
          onValidSubmit={this.onValidSubmit}
        >
          <div className="modal-header">
            <h2 className="section__title section__title--white">
              {t('Change Password')}
            </h2>
          </div>
          <div className="modal-body modal-form">
            <Input
              id="changePass_message"
              name="message"
              type="hidden"
            />

            <Password
              name="oldPassword"
              value={store.elements.get('oldPassword')}
              updateStateData={store.updateFormItem}
              id="changePass_oldPassword"
              placeholder={t('Current password')}
              required
              validations={{
                minLength: 8,
                isLatinAndNumber: true,
              }}
              validationErrors={{
                minLength: err.minLength(8),
                isDefaultRequiredValue: err.isDefaultRequiredValue,
                isLatinAndNumber: err.isLatinAndNumber,
              }}
            >
              <label className="form-label" htmlFor="changePass_oldPassword">
                {t('Current password')}:
              </label>
            </Password>

            <Password
              name="newPassword"
              value={store.elements.get('newPassword')}
              updateStateData={store.updateFormItem}
              id="changePass_newPassword"
              placeholder={t('New password')}
              required
              validations={{
                minLength: 8,
                isLatinAndNumber: true,
              }}
              validationErrors={{
                minLength: err.minLength(8),
                isDefaultRequiredValue: err.isDefaultRequiredValue,
                isLatinAndNumber: err.isLatinAndNumber,
              }}
            >
              <label className="form-label" htmlFor="changePass_newPassword">
                {t('New password')}:
              </label>
            </Password>

          </div>

          <div className="modal-footer modal-form">
            <button
              type="submit"
              disabled={store.isLoading.get()}
              className="btn btn--high btn--lg btn--white">
              <span className="btn__text">
                {t('Submit')}
              </span>
            </button>
          </div>
        </Formsy>
      </div>
    );
  }
}


const WrappedChangePass = inject('appStore', 'modalsStore')(observer(ChangePass));
const TranslateChangePass = translate()(WrappedChangePass);
TranslateChangePass.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateChangePass;
