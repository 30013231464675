import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import Slider from 'react-slick';

import { sliderSettings } from '../../../../constants/sliderSettings';
import { companies } from '../../../../constants/companies';

import './styles/CompaniesSlider.css';
import Helpers from '../../../../utils/Helpers';

const propTypes = {
  activeCompany: PropTypes.string.isRequired,
  updateActiveCompany: PropTypes.func.isRequired,
  companies: PropTypes.object.isRequired,
};

class CompaniesSlider extends React.Component {
  render() {
    const companiesData = this.props.companies;
    const companiesKeys = Object.keys(companiesData);
    const {activeCompany} = this.props;
    const company = (name) => {
      const companiesMapped = companies.map(i => i.name);
      const index = companiesMapped.indexOf(name);

      return index >= 0 ? companies[index] : null;
    };

    const sliderItems = companiesKeys
    .filter(item => {
      const { name } = companiesData[item];
      const itemCompany = company(name);
      if (!!itemCompany && itemCompany.icon){
        return itemCompany.icon.length > 0;
      }
      return false;
    }).map(item => {
      const setActive = () => {
        this.props.updateActiveCompany(name);
      }
      const { name } = companiesData[item];
      const itemCompany = company(name);

      return (
        <div
          key={name}
          className="companies-slider__item">
          {
            !!itemCompany && (
              <button
                className={classnames([
                  'companies-slider__item-btn',
                  {
                    'active': name === activeCompany,
                  }
                ])}
                onClick={setActive}
                type="button">
                <span className={classnames([
                  itemCompany.icon,
                  'companies-slider__icon',
                ])} />
              </button>
            )
          }
        </div>
      );
    });

    const responsiveOptions = Helpers.updateSliderOptions(sliderSettings.companiesSlider, sliderItems);

    return (
      <div className="companies-slider">
        <Slider
          {...responsiveOptions}
          initialSlide={companiesKeys.indexOf(activeCompany) > 0 ? companiesKeys.indexOf(activeCompany) : 0}
          infinite={true}>
          {sliderItems}
        </Slider>
      </div>
    );
  }
}


CompaniesSlider.propTypes = propTypes;

export default CompaniesSlider;
