export const insurance = [
  {
    "range": [0, 50],
    "230": { // USA
      "usps": 1.65,
      "ups": 1,
      "fedex": 0
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 5
    }
  },
  {
    "range": [50, 100],
    "230": { // USA
      "usps": 2.05,
      "ups": 1,
      "fedex": 0
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 5
    }
  },
  {
    "range": [100, 200],
    "230": { // USA
      "usps": 2.45,
      "ups": 2.7,
      "fedex": 0
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 5
    }
  },
  {
    "range": [200, 300],
    "230": { // USA
      "usps": 5.6,
      "ups": 3.6,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [300, 400],
    "230": { // USA
      "usps": 6.5,
      "ups": 4.5,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [400, 500],
    "230": { // USA
      "usps": 7.4,
      "ups": 5.4,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [500, 600],
    "230": { // USA
      "usps": 8.3,
      "ups": 6.3,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [600, 700],
    "230": { // USA
      "usps": 9.2,
      "ups": 7.2,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [700, 800],
    "230": { // USA
      "usps": 10.1,
      "ups": 8.1,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [800, 900],
    "230": { // USA
      "usps": 11,
      "ups": 9,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [900, 1000],
    "230": { // USA
      "usps": 11.9,
      "ups": 9.9,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [1000, 1100],
    "230": { // USA
      "usps": 12.8,
      "ups": 10.8,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [1100, 1200],
    "230": { // USA
      "usps": 13.7,
      "ups": 11.7,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [1200, 1300],
    "230": { // USA
      "usps": 14.6,
      "ups": 12.6,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [1300, 1400],
    "230": { // USA
      "usps": 15.5,
      "ups": 13.5,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [1400, 1500],
    "230": { // USA
      "usps": 16.4,
      "ups": 14.4,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [1500, 1600],
    "230": { // USA
      "usps": 17.3,
      "ups": 15.3,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [1600, 1700],
    "230": { // USA
      "usps": 18.2,
      "ups": 16.2,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [1700, 1800],
    "230": { // USA
      "usps": 19.1,
      "ups": 17.1,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [1800, 1900],
    "230": { // USA
      "usps": 20,
      "ups": 18,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [1900, 2000],
    "230": { // USA
      "usps": 20.9,
      "ups": 18.9,
      "fedex": 5
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 10
    }
  },
  {
    "range": [2000, 2100],
    "230": { // USA
      "usps": 21.8,
      "ups": 19.8,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [2100, 2200],
    "230": { // USA
      "usps": 22.7,
      "ups": 20.7,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [2200, 2300],
    "230": { // USA
      "usps": 23.6,
      "ups": 21.6,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [2300, 2400],
    "230": { // USA
      "usps": 24.5,
      "ups": 22.5,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [2400, 2500],
    "230": { // USA
      "usps": 25.4,
      "ups": 23.4,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [2500, 2600],
    "230": { // USA
      "usps": 26.3,
      "ups": 24.3,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [2600, 2700],
    "230": { // USA
      "usps": 27.2,
      "ups": 25.2,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [2700, 2800],
    "230": { // USA
      "usps": 28.1,
      "ups": 26.1,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [2800, 2900],
    "230": { // USA
      "usps": 29,
      "ups": 27,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [2900, 3000],
    "230": { // USA
      "usps": 29.9,
      "ups": 27.9,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [3000, 3100],
    "230": { // USA
      "usps": 30.8,
      "ups": 28.8,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [3100, 3200],
    "230": { // USA
      "usps": 31.7,
      "ups": 29.7,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [3200, 3300],
    "230": { // USA
      "usps": 32.6,
      "ups": 30.6,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [3300, 3400],
    "230": { // USA
      "usps": 33.5,
      "ups": 31.5,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [3400, 3500],
    "230": { // USA
      "usps": 34.4,
      "ups": 32.4,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [3500, 3600],
    "230": { // USA
      "usps": 35.3,
      "ups": 33.3,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [3600, 3700],
    "230": { // USA
      "usps": 36.2,
      "ups": 34.2,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [3700, 3800],
    "230": { // USA
      "usps": 37.1,
      "ups": 35.1,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [3800, 3900],
    "230": { // USA
      "usps": 38,
      "ups": 36,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [3900, 4000],
    "230": { // USA
      "usps": 38.9,
      "ups": 36.9,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [4000, 4100],
    "230": { // USA
      "usps": 39.8,
      "ups": 37.8,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [4100, 4200],
    "230": { // USA
      "usps": 40.7,
      "ups": 38.7,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [4200, 4300],
    "230": { // USA
      "usps": 41.6,
      "ups": 39.6,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [4300, 4400],
    "230": { // USA
      "usps": 42.5,
      "ups": 40.5,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [4400, 4500],
    "230": { // USA
      "usps": 43.4,
      "ups": 41.4,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [4500, 4600],
    "230": { // USA
      "usps": 44.3,
      "ups": 42.3,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [4600, 4700],
    "230": { // USA
      "usps": 45.2,
      "ups": 43.2,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [4700, 4800],
    "230": { // USA
      "usps": 46.1,
      "ups": 44.1,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [4800, 4900],
    "230": { // USA
      "usps": 47,
      "ups": 45,
      "fedex": 10
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 15
    }
  },
  {
    "range": [4900, 5000],
    "230": { // USA
      "usps": null,
      "ups": 45.9,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [5000, 5100],
    "230": { // USA
      "usps": null,
      "ups": 46.8,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [5100, 5200],
    "230": { // USA
      "usps": null,
      "ups": 47.7,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [5200, 5300],
    "230": { // USA
      "usps": null,
      "ups": 48.6,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [5300, 5400],
    "230": { // USA
      "usps": null,
      "ups": 49.5,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [5400, 5500],
    "230": { // USA
      "usps": null,
      "ups": 50.4,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [5500, 5600],
    "230": { // USA
      "usps": null,
      "ups": 51.3,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [5600, 5700],
    "230": { // USA
      "usps": null,
      "ups": 52.2,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [5700, 5800],
    "230": { // USA
      "usps": null,
      "ups": 53.1,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [5800, 5900],
    "230": { // USA
      "usps": null,
      "ups": 54,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [5900, 6000],
    "230": { // USA
      "usps": null,
      "ups": 54.9,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [6000, 6100],
    "230": { // USA
      "usps": null,
      "ups": 55.8,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [6100, 6200],
    "230": { // USA
      "usps": null,
      "ups": 56.7,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [6200, 6300],
    "230": { // USA
      "usps": null,
      "ups": 57.6,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [6300, 6400],
    "230": { // USA
      "usps": null,
      "ups": 58.5,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [6400, 6500],
    "230": { // USA
      "usps": null,
      "ups": 59.4,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [6500, 6600],
    "230": { // USA
      "usps": null,
      "ups": 60.3,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [6600, 6700],
    "230": { // USA
      "usps": null,
      "ups": 61.2,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [6700, 6800],
    "230": { // USA
      "usps": null,
      "ups": 62.1,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [6800, 6900],
    "230": { // USA
      "usps": null,
      "ups": 63,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [6900, 7000],
    "230": { // USA
      "usps": null,
      "ups": 63.9,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [7000, 7100],
    "230": { // USA
      "usps": null,
      "ups": 64.8,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [7100, 7200],
    "230": { // USA
      "usps": null,
      "ups": 65.7,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [7200, 7300],
    "230": { // USA
      "usps": null,
      "ups": 66.6,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [7300, 7400],
    "230": { // USA
      "usps": null,
      "ups": 67.5,
      "fedex": 15
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 20
    }
  },
  {
    "range": [7400, 7500],
    "230": { // USA
      "usps": null,
      "ups": 68.4,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [7500, 7600],
    "230": { // USA
      "usps": null,
      "ups": 69.3,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [7600, 7700],
    "230": { // USA
      "usps": null,
      "ups": 70.2,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [7700, 7800],
    "230": { // USA
      "usps": null,
      "ups": 71.1,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [7800, 7900],
    "230": { // USA
      "usps": null,
      "ups": 72,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [7900, 8000],
    "230": { // USA
      "usps": null,
      "ups": 72.9,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [8000, 8100],
    "230": { // USA
      "usps": null,
      "ups": 73.8,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [8100, 8200],
    "230": { // USA
      "usps": null,
      "ups": 74.7,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [8200, 8300],
    "230": { // USA
      "usps": null,
      "ups": 75.6,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [8200, 8400],
    "230": { // USA
      "usps": null,
      "ups": 76.5,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [8400, 8500],
    "230": { // USA
      "usps": null,
      "ups": 77.4,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [8500, 8600],
    "230": { // USA
      "usps": null,
      "ups": 78.3,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [8600, 8700],
    "230": { // USA
      "usps": null,
      "ups": 79.2,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [8700, 8800],
    "230": { // USA
      "usps": null,
      "ups": 80.1,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [8800, 8900],
    "230": { // USA
      "usps": null,
      "ups": 81,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [8900, 9000],
    "230": { // USA
      "usps": null,
      "ups": 81.9,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [9000, 9100],
    "230": { // USA
      "usps": null,
      "ups": 82.8,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [9100, 9200],
    "230": { // USA
      "usps": null,
      "ups": 83.7,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [9200, 9300],
    "230": { // USA
      "usps": null,
      "ups": 84.6,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [9300, 9400],
    "230": { // USA
      "usps": null,
      "ups": 85.5,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [9400, 9500],
    "230": { // USA
      "usps": null,
      "ups": 86.4,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [9500, 9600],
    "230": { // USA
      "usps": null,
      "ups": 87.3,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [9600, 9700],
    "230": { // USA
      "usps": null,
      "ups": 88.2,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [9700, 9800],
    "230": { // USA
      "usps": null,
      "ups": 89.1,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [9800, 9900],
    "230": { // USA
      "usps": null,
      "ups": 90,
      "fedex": 20
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 25
    }
  },
  {
    "range": [9900, 10000],
    "230": { // USA
      "usps": null,
      "ups": 90.9,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [10000, 10100],
    "230": { // USA
      "usps": null,
      "ups": 91.8,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [10100, 10200],
    "230": { // USA
      "usps": null,
      "ups": 92.7,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [10200, 10300],
    "230": { // USA
      "usps": null,
      "ups": 93.6,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [10300, 10400],
    "230": { // USA
      "usps": null,
      "ups": 94.5,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [10400, 10500],
    "230": { // USA
      "usps": null,
      "ups": 95.4,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [10500, 10600],
    "230": { // USA
      "usps": null,
      "ups": 96.3,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [10600, 10700],
    "230": { // USA
      "usps": null,
      "ups": 97.2,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [10700, 10800],
    "230": { // USA
      "usps": null,
      "ups": 98.1,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [10800, 10900],
    "230": { // USA
      "usps": null,
      "ups": 99,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [10900, 11000],
    "230": { // USA
      "usps": null,
      "ups": 99.9,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [11000, 11100],
    "230": { // USA
      "usps": null,
      "ups": 100.8,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [11100, 11200],
    "230": { // USA
      "usps": null,
      "ups": 101.7,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [11200, 11300],
    "230": { // USA
      "usps": null,
      "ups": 102.6,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [11300, 11400],
    "230": { // USA
      "usps": null,
      "ups": 103.5,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [11400, 11500],
    "230": { // USA
      "usps": null,
      "ups": 104.4,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [11500, 11600],
    "230": { // USA
      "usps": null,
      "ups": 105.3,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [11600, 11700],
    "230": { // USA
      "usps": null,
      "ups": 106.2,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [11700, 11800],
    "230": { // USA
      "usps": null,
      "ups": 107.1,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [11800, 11900],
    "230": { // USA
      "usps": null,
      "ups": 108,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [11900, 12000],
    "230": { // USA
      "usps": null,
      "ups": 108.9,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [12000, 12100],
    "230": { // USA
      "usps": null,
      "ups": 109.8,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [12100, 12200],
    "230": { // USA
      "usps": null,
      "ups": 110.7,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [12200, 12300],
    "230": { // USA
      "usps": null,
      "ups": 111.6,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [12300, 12400],
    "230": { // USA
      "usps": null,
      "ups": 112.5,
      "fedex": 25
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 30
    }
  },
  {
    "range": [12400, 112500],
    "230": { // USA
      "usps": null,
      "ups": 113.4,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [12500, 12600],
    "230": { // USA
      "usps": null,
      "ups": 114.3,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [12600, 12700],
    "230": { // USA
      "usps": null,
      "ups": 115.2,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [12700, 12800],
    "230": { // USA
      "usps": null,
      "ups": 116.1,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [12800, 12900],
    "230": { // USA
      "usps": null,
      "ups": 117,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [12900, 13000],
    "230": { // USA
      "usps": null,
      "ups": 117.9,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [13000, 13100],
    "230": { // USA
      "usps": null,
      "ups": 118.8,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [13100, 13200],
    "230": { // USA
      "usps": null,
      "ups": 119.7,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [13200, 13300],
    "230": { // USA
      "usps": null,
      "ups": 120.6,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [13300, 13400],
    "230": { // USA
      "usps": null,
      "ups": 121.5,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [13400, 13500],
    "230": { // USA
      "usps": null,
      "ups": 122.4,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [13500, 13600],
    "230": { // USA
      "usps": null,
      "ups": 123.3,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [13600, 13700],
    "230": { // USA
      "usps": null,
      "ups": 124.2,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [13700, 13800],
    "230": { // USA
      "usps": null,
      "ups": 125.1,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [13800, 13900],
    "230": { // USA
      "usps": null,
      "ups": 126,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [13900, 14000],
    "230": { // USA
      "usps": null,
      "ups": 126.9,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [14000, 14100],
    "230": { // USA
      "usps": null,
      "ups": 127.8,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [14100, 14200],
    "230": { // USA
      "usps": null,
      "ups": 128.7,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [14200, 14300],
    "230": { // USA
      "usps": null,
      "ups": 129.6,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [14300, 14400],
    "230": { // USA
      "usps": null,
      "ups": 130.5,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [14400, 14500],
    "230": { // USA
      "usps": null,
      "ups": 131.4,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [14500, 14600],
    "230": { // USA
      "usps": null,
      "ups": 132.3,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [14600, 14700],
    "230": { // USA
      "usps": null,
      "ups": 133.2,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [14700, 14800],
    "230": { // USA
      "usps": null,
      "ups": 134.1,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [14800, 14900],
    "230": { // USA
      "usps": null,
      "ups": 135,
      "fedex": 30
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 35
    }
  },
  {
    "range": [14900, 15000],
    "230": { // USA
      "usps": null,
      "ups": 135.9,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [15000, 15100],
    "230": { // USA
      "usps": null,
      "ups": 136.8,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [15100, 15200],
    "230": { // USA
      "usps": null,
      "ups": 137.7,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [15200, 15300],
    "230": { // USA
      "usps": null,
      "ups": 138.6,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [15300, 15400],
    "230": { // USA
      "usps": null,
      "ups": 139.5,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [15400, 15500],
    "230": { // USA
      "usps": null,
      "ups": 140.4,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [15500, 15600],
    "230": { // USA
      "usps": null,
      "ups": 141.3,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [15600, 15700],
    "230": { // USA
      "usps": null,
      "ups": 142.2,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [15700, 15800],
    "230": { // USA
      "usps": null,
      "ups": 143.1,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [15800, 15900],
    "230": { // USA
      "usps": null,
      "ups": 144,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [15900, 16000],
    "230": { // USA
      "usps": null,
      "ups": 144.9,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [16000, 16100],
    "230": { // USA
      "usps": null,
      "ups": 145.8,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [16100, 16200],
    "230": { // USA
      "usps": null,
      "ups": 146.7,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [16200, 16300],
    "230": { // USA
      "usps": null,
      "ups": 147.6,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [16300, 16400],
    "230": { // USA
      "usps": null,
      "ups": 148.5,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [16400, 16500],
    "230": { // USA
      "usps": null,
      "ups": 149.4,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [16500, 16600],
    "230": { // USA
      "usps": null,
      "ups": 150.3,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [16600, 16700],
    "230": { // USA
      "usps": null,
      "ups": 151.2,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [16700, 16800],
    "230": { // USA
      "usps": null,
      "ups": 152.1,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [16800, 16900],
    "230": { // USA
      "usps": null,
      "ups": 153,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [16900, 17000],
    "230": { // USA
      "usps": null,
      "ups": 153.9,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [17000, 17100],
    "230": { // USA
      "usps": null,
      "ups": 154.8,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [17100, 17200],
    "230": { // USA
      "usps": null,
      "ups": 155.7,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [17200, 17300],
    "230": { // USA
      "usps": null,
      "ups": 156.6,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [17300, 17400],
    "230": { // USA
      "usps": null,
      "ups": 157.5,
      "fedex": 35
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 40
    }
  },
  {
    "range": [17400, 17500],
    "230": { // USA
      "usps": null,
      "ups": 158.4,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [17500, 17600],
    "230": { // USA
      "usps": null,
      "ups": 159.3,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [17600, 17700],
    "230": { // USA
      "usps": null,
      "ups": 160.2,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [17700, 17800],
    "230": { // USA
      "usps": null,
      "ups": 161.1,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [17800, 17900],
    "230": { // USA
      "usps": null,
      "ups": 162,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [17900, 18000],
    "230": { // USA
      "usps": null,
      "ups": 162.9,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [18000, 18100],
    "230": { // USA
      "usps": null,
      "ups": 163.8,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [18100, 18200],
    "230": { // USA
      "usps": null,
      "ups": 164.7,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [18200, 18300],
    "230": { // USA
      "usps": null,
      "ups": 165.6,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [18300, 18400],
    "230": { // USA
      "usps": null,
      "ups": 166.5,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [18400, 18500],
    "230": { // USA
      "usps": null,
      "ups": 167.4,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [18500, 18600],
    "230": { // USA
      "usps": null,
      "ups": 168.3,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [18600, 18700],
    "230": { // USA
      "usps": null,
      "ups": 169.2,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [18700, 18800],
    "230": { // USA
      "usps": null,
      "ups": 170.1,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [18800, 18900],
    "230": { // USA
      "usps": null,
      "ups": 171,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [18900, 19000],
    "230": { // USA
      "usps": null,
      "ups": 171.9,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [19000, 19100],
    "230": { // USA
      "usps": null,
      "ups": 172.8,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [19100, 19200],
    "230": { // USA
      "usps": null,
      "ups": 173.7,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [19200, 19300],
    "230": { // USA
      "usps": null,
      "ups": 174.6,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [19300, 19400],
    "230": { // USA
      "usps": null,
      "ups": 175.5,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [19400, 19500],
    "230": { // USA
      "usps": null,
      "ups": 176.4,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [19500, 19600],
    "230": { // USA
      "usps": null,
      "ups": 177.3,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [19600, 19700],
    "230": { // USA
      "usps": null,
      "ups": 178.2,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [19700, 19800],
    "230": { // USA
      "usps": null,
      "ups": 179.1,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [19800, 19900],
    "230": { // USA
      "usps": null,
      "ups": 180,
      "fedex": 40
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 45
    }
  },
  {
    "range": [19900, 20000],
    "230": { // USA
      "usps": null,
      "ups": 180.9,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [20000, 20100],
    "230": { // USA
      "usps": null,
      "ups": 181.8,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [20100, 20200],
    "230": { // USA
      "usps": null,
      "ups": 182.7,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [20200, 20300],
    "230": { // USA
      "usps": null,
      "ups": 183.6,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [20300, 20400],
    "230": { // USA
      "usps": null,
      "ups": 184.5,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [20400, 20500],
    "230": { // USA
      "usps": null,
      "ups": 185.4,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [20500, 20600],
    "230": { // USA
      "usps": null,
      "ups": 186.3,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [20600, 20700],
    "230": { // USA
      "usps": null,
      "ups": 187.2,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [20700, 20800],
    "230": { // USA
      "usps": null,
      "ups": 188.1,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [20800, 20900],
    "230": { // USA
      "usps": null,
      "ups": 189,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [20900, 21000],
    "230": { // USA
      "usps": null,
      "ups": 189.9,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [21000, 21100],
    "230": { // USA
      "usps": null,
      "ups": 190.8,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [21100, 21200],
    "230": { // USA
      "usps": null,
      "ups": 191.7,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [21200, 21300],
    "230": { // USA
      "usps": null,
      "ups": 192.6,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [21300, 21400],
    "230": { // USA
      "usps": null,
      "ups": 193.5,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [21400, 21500],
    "230": { // USA
      "usps": null,
      "ups": 194.4,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [21500, 21600],
    "230": { // USA
      "usps": null,
      "ups": 195.3,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [21600, 21700],
    "230": { // USA
      "usps": null,
      "ups": 196.2,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [21700, 21800],
    "230": { // USA
      "usps": null,
      "ups": 197.1,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [21800, 21900],
    "230": { // USA
      "usps": null,
      "ups": 198,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [21900, 22000],
    "230": { // USA
      "usps": null,
      "ups": 198.9,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [22000, 22100],
    "230": { // USA
      "usps": null,
      "ups": 199.8,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [22100, 22200],
    "230": { // USA
      "usps": null,
      "ups": 200.7,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [22200, 22300],
    "230": { // USA
      "usps": null,
      "ups": 201.6,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [22300, 22400],
    "230": { // USA
      "usps": null,
      "ups": 202.5,
      "fedex": 45
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 50
    }
  },
  {
    "range": [22400, 22500],
    "230": { // USA
      "usps": null,
      "ups": 203.4,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [22500, 22600],
    "230": { // USA
      "usps": null,
      "ups": 204.3,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [22600, 22700],
    "230": { // USA
      "usps": null,
      "ups": 205.2,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [22700, 22800],
    "230": { // USA
      "usps": null,
      "ups": 206.1,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [22800, 22900],
    "230": { // USA
      "usps": null,
      "ups": 207,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [22900, 23000],
    "230": { // USA
      "usps": null,
      "ups": 207.9,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [23000, 23100],
    "230": { // USA
      "usps": null,
      "ups": 208.8,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [23100, 23200],
    "230": { // USA
      "usps": null,
      "ups": 209.7,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [23200, 23300],
    "230": { // USA
      "usps": null,
      "ups": 210.6,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [23300, 23400],
    "230": { // USA
      "usps": null,
      "ups": 211.5,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [23400, 23500],
    "230": { // USA
      "usps": null,
      "ups": 212.4,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [23500, 23600],
    "230": { // USA
      "usps": null,
      "ups": 213.3,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [23600, 23700],
    "230": { // USA
      "usps": null,
      "ups": 214.2,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [23700, 23800],
    "230": { // USA
      "usps": null,
      "ups": 215.1,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [23800, 23900],
    "230": { // USA
      "usps": null,
      "ups": 216,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [23900, 24000],
    "230": { // USA
      "usps": null,
      "ups": 216.9,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [24000, 24100],
    "230": { // USA
      "usps": null,
      "ups": 217.8,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [24100, 24200],
    "230": { // USA
      "usps": null,
      "ups": 218.7,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [24200, 24300],
    "230": { // USA
      "usps": null,
      "ups": 219.6,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [24300, 24400],
    "230": { // USA
      "usps": null,
      "ups": 220.5,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [24400, 24500],
    "230": { // USA
      "usps": null,
      "ups": 221.4,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [24500, 24600],
    "230": { // USA
      "usps": null,
      "ups": 222.3,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [24600, 24700],
    "230": { // USA
      "usps": null,
      "ups": 223.2,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [24700, 24800],
    "230": { // USA
      "usps": null,
      "ups": 224.1,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [24800, 24900],
    "230": { // USA
      "usps": null,
      "ups": 225,
      "fedex": 50
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 55
    }
  },
  {
    "range": [24900, 25000],
    "230": { // USA
      "usps": null,
      "ups": 225.9,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [25000, 25100],
    "230": { // USA
      "usps": null,
      "ups": 226.8,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [25100, 25200],
    "230": { // USA
      "usps": null,
      "ups": 227.7,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [25200, 25300],
    "230": { // USA
      "usps": null,
      "ups": 228.6,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [25300, 25400],
    "230": { // USA
      "usps": null,
      "ups": 229.5,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [25400, 25500],
    "230": { // USA
      "usps": null,
      "ups": 230.4,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [25500, 25600],
    "230": { // USA
      "usps": null,
      "ups": 231.3,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [25600, 25700],
    "230": { // USA
      "usps": null,
      "ups": 232.2,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [25700, 25800],
    "230": { // USA
      "usps": null,
      "ups": 233.1,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [25800, 25900],
    "230": { // USA
      "usps": null,
      "ups": 234,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [25900, 26000],
    "230": { // USA
      "usps": null,
      "ups": 234.9,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [26000, 26100],
    "230": { // USA
      "usps": null,
      "ups": 235.8,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [26100, 26200],
    "230": { // USA
      "usps": null,
      "ups": 236.7,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [26200, 26300],
    "230": { // USA
      "usps": null,
      "ups": 237.6,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [26300, 26400],
    "230": { // USA
      "usps": null,
      "ups": 238.5,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [26400, 26500],
    "230": { // USA
      "usps": null,
      "ups": 239.4,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [26500, 26600],
    "230": { // USA
      "usps": null,
      "ups": 240.3,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [26600, 26700],
    "230": { // USA
      "usps": null,
      "ups": 241.2,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [26700, 26800],
    "230": { // USA
      "usps": null,
      "ups": 242.1,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [26800, 26900],
    "230": { // USA
      "usps": null,
      "ups": 243,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [26900, 27000],
    "230": { // USA
      "usps": null,
      "ups": 243.9,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [27000, 27100],
    "230": { // USA
      "usps": null,
      "ups": 244.8,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [27100, 27200],
    "230": { // USA
      "usps": null,
      "ups": 245.7,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [27200, 27300],
    "230": { // USA
      "usps": null,
      "ups": 246.6,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [27300, 27400],
    "230": { // USA
      "usps": null,
      "ups": 247.5,
      "fedex": 55
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 60
    }
  },
  {
    "range": [27400, 27500],
    "230": { // USA
      "usps": null,
      "ups": 248.4,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [27500, 27600],
    "230": { // USA
      "usps": null,
      "ups": 249.3,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [27600, 27700],
    "230": { // USA
      "usps": null,
      "ups": 250.2,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [27700, 27800],
    "230": { // USA
      "usps": null,
      "ups": 251.1,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [27800, 27900],
    "230": { // USA
      "usps": null,
      "ups": 252,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [27900, 28000],
    "230": { // USA
      "usps": null,
      "ups": 252.9,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [28000, 28100],
    "230": { // USA
      "usps": null,
      "ups": 253.8,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [28100, 28200],
    "230": { // USA
      "usps": null,
      "ups": 254.7,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [28200, 28300],
    "230": { // USA
      "usps": null,
      "ups": 255.6,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [28300, 28400],
    "230": { // USA
      "usps": null,
      "ups": 256.5,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [28400, 28500],
    "230": { // USA
      "usps": null,
      "ups": 257.4,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [28500, 28600],
    "230": { // USA
      "usps": null,
      "ups": 258.3,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [28600, 28700],
    "230": { // USA
      "usps": null,
      "ups": 259.2,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [28700, 28800],
    "230": { // USA
      "usps": null,
      "ups": 260.1,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [28800, 28900],
    "230": { // USA
      "usps": null,
      "ups": 261,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [28900, 29000],
    "230": { // USA
      "usps": null,
      "ups": 261.9,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [29000, 29100],
    "230": { // USA
      "usps": null,
      "ups": 262.8,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [29100, 29200],
    "230": { // USA
      "usps": null,
      "ups": 263.7,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [29200, 29300],
    "230": { // USA
      "usps": null,
      "ups": 264.6,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [29300, 29400],
    "230": { // USA
      "usps": null,
      "ups": 265.5,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [29400, 29500],
    "230": { // USA
      "usps": null,
      "ups": 266.4,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [29500, 29600],
    "230": { // USA
      "usps": null,
      "ups": 267.3,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [29600, 29700],
    "230": { // USA
      "usps": null,
      "ups": 268.2,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [29700, 29800],
    "230": { // USA
      "usps": null,
      "ups": 269.1,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [29800, 29900],
    "230": { // USA
      "usps": null,
      "ups": 270,
      "fedex": 60
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 65
    }
  },
  {
    "range": [29900, 30000],
    "230": { // USA
      "usps": null,
      "ups": 270.9,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [30000, 30100],
    "230": { // USA
      "usps": null,
      "ups": 271.8,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [30100, 30200],
    "230": { // USA
      "usps": null,
      "ups": 272.7,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [30200, 30300],
    "230": { // USA
      "usps": null,
      "ups": 273.6,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [30300, 30400],
    "230": { // USA
      "usps": null,
      "ups": 274.5,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [30400, 30500],
    "230": { // USA
      "usps": null,
      "ups": 275.4,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [30500, 30600],
    "230": { // USA
      "usps": null,
      "ups": 276.3,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [30600, 30700],
    "230": { // USA
      "usps": null,
      "ups": 277.2,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [30700, 30800],
    "230": { // USA
      "usps": null,
      "ups": 278.1,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [30800, 30900],
    "230": { // USA
      "usps": null,
      "ups": 279,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [30900, 31000],
    "230": { // USA
      "usps": null,
      "ups": 279.9,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [31000, 31100],
    "230": { // USA
      "usps": null,
      "ups": 280.8,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [31100, 31200],
    "230": { // USA
      "usps": null,
      "ups": 281.7,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [31200, 31300],
    "230": { // USA
      "usps": null,
      "ups": 282.6,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [31300, 31400],
    "230": { // USA
      "usps": null,
      "ups": 283.5,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [31400, 31500],
    "230": { // USA
      "usps": null,
      "ups": 284.4,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [31500, 31600],
    "230": { // USA
      "usps": null,
      "ups": 285.3,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [31600, 31700],
    "230": { // USA
      "usps": null,
      "ups": 286.2,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [31700, 31800],
    "230": { // USA
      "usps": null,
      "ups": 287.1,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [31800, 31900],
    "230": { // USA
      "usps": null,
      "ups": 288,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [31900, 32000],
    "230": { // USA
      "usps": null,
      "ups": 288.9,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [32000, 32100],
    "230": { // USA
      "usps": null,
      "ups": 289.8,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [32100, 32200],
    "230": { // USA
      "usps": null,
      "ups": 290.7,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [32200, 32300],
    "230": { // USA
      "usps": null,
      "ups": 291.6,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [32300, 32400],
    "230": { // USA
      "usps": null,
      "ups": 292.5,
      "fedex": 65
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 70
    }
  },
  {
    "range": [32400, 32500],
    "230": { // USA
      "usps": null,
      "ups": 293.4,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [32500, 32600],
    "230": { // USA
      "usps": null,
      "ups": 294.3,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [32600, 32700],
    "230": { // USA
      "usps": null,
      "ups": 295.2,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [32700, 32800],
    "230": { // USA
      "usps": null,
      "ups": 296.1,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [32800, 32900],
    "230": { // USA
      "usps": null,
      "ups": 297,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [32900, 33000],
    "230": { // USA
      "usps": null,
      "ups": 297.9,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [33000, 33100],
    "230": { // USA
      "usps": null,
      "ups": 298.8,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [33100, 33200],
    "230": { // USA
      "usps": null,
      "ups": 299.7,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [33200, 33300],
    "230": { // USA
      "usps": null,
      "ups": 300.6,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [33300, 33400],
    "230": { // USA
      "usps": null,
      "ups": 301.5,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [33400, 33500],
    "230": { // USA
      "usps": null,
      "ups": 302.4,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [33500, 33600],
    "230": { // USA
      "usps": null,
      "ups": 303.3,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [33600, 33700],
    "230": { // USA
      "usps": null,
      "ups": 304.2,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [33700, 33800],
    "230": { // USA
      "usps": null,
      "ups": 305.1,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [33800, 33900],
    "230": { // USA
      "usps": null,
      "ups": 306,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [33900, 34000],
    "230": { // USA
      "usps": null,
      "ups": 306.9,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [34000, 34100],
    "230": { // USA
      "usps": null,
      "ups": 307.8,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [34100, 34200],
    "230": { // USA
      "usps": null,
      "ups": 308.7,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [34200, 34300],
    "230": { // USA
      "usps": null,
      "ups": 309.6,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [34300, 34400],
    "230": { // USA
      "usps": null,
      "ups": 310.5,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [34400, 34500],
    "230": { // USA
      "usps": null,
      "ups": 311.4,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [34500, 34600],
    "230": { // USA
      "usps": null,
      "ups": 312.3,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [34600, 34700],
    "230": { // USA
      "usps": null,
      "ups": 313.2,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [34700, 34800],
    "230": { // USA
      "usps": null,
      "ups": 314.1,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [34800, 34900],
    "230": { // USA
      "usps": null,
      "ups": 315,
      "fedex": 70
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 75
    }
  },
  {
    "range": [34900, 35000],
    "230": { // USA
      "usps": null,
      "ups": 315.9,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [35000, 35100],
    "230": { // USA
      "usps": null,
      "ups": 316.8,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [35100, 35200],
    "230": { // USA
      "usps": null,
      "ups": 317.7,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [35200, 35300],
    "230": { // USA
      "usps": null,
      "ups": 318.6,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [35300, 35400],
    "230": { // USA
      "usps": null,
      "ups": 319.5,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [35400, 35500],
    "230": { // USA
      "usps": null,
      "ups": 320.4,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [35500, 35600],
    "230": { // USA
      "usps": null,
      "ups": 321.3,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [35600, 35700],
    "230": { // USA
      "usps": null,
      "ups": 322.2,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [35700, 35800],
    "230": { // USA
      "usps": null,
      "ups": 323.1,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [35800, 35900],
    "230": { // USA
      "usps": null,
      "ups": 324,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [35900, 36000],
    "230": { // USA
      "usps": null,
      "ups": 324.9,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [36000, 36100],
    "230": { // USA
      "usps": null,
      "ups": 325.8,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [36100, 36200],
    "230": { // USA
      "usps": null,
      "ups": 326.7,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [36200, 36300],
    "230": { // USA
      "usps": null,
      "ups": 327.6,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [36300, 36400],
    "230": { // USA
      "usps": null,
      "ups": 328.5,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [36400, 36500],
    "230": { // USA
      "usps": null,
      "ups": 329.4,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [36500, 36600],
    "230": { // USA
      "usps": null,
      "ups": 330.3,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [36600, 36700],
    "230": { // USA
      "usps": null,
      "ups": 331.2,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [36700, 36800],
    "230": { // USA
      "usps": null,
      "ups": 332.1,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [36800, 36900],
    "230": { // USA
      "usps": null,
      "ups": 333,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [36900, 37000],
    "230": { // USA
      "usps": null,
      "ups": 333.9,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [37000, 37100],
    "230": { // USA
      "usps": null,
      "ups": 334.8,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [37100, 37200],
    "230": { // USA
      "usps": null,
      "ups": 335.7,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [37200, 37300],
    "230": { // USA
      "usps": null,
      "ups": 336.6,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [37300, 37400],
    "230": { // USA
      "usps": null,
      "ups": 337.5,
      "fedex": 75
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 80
    }
  },
  {
    "range": [37400, 37500],
    "230": { // USA
      "usps": null,
      "ups": 338.4,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [37500, 37600],
    "230": { // USA
      "usps": null,
      "ups": 339.3,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [37600, 37700],
    "230": { // USA
      "usps": null,
      "ups": 340.2,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [37700, 37800],
    "230": { // USA
      "usps": null,
      "ups": 341.1,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [37800, 37900],
    "230": { // USA
      "usps": null,
      "ups": 342,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [37900, 38000],
    "230": { // USA
      "usps": null,
      "ups": 342.9,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [38000, 38100],
    "230": { // USA
      "usps": null,
      "ups": 343.8,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [38100, 38200],
    "230": { // USA
      "usps": null,
      "ups": 344.7,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [38200, 38300],
    "230": { // USA
      "usps": null,
      "ups": 345.6,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [38300, 38400],
    "230": { // USA
      "usps": null,
      "ups": 346.5,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [38400, 38500],
    "230": { // USA
      "usps": null,
      "ups": 347.4,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [38500, 38600],
    "230": { // USA
      "usps": null,
      "ups": 348.3,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [38600, 38700],
    "230": { // USA
      "usps": null,
      "ups": 349.2,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [38700, 38800],
    "230": { // USA
      "usps": null,
      "ups": 350.1,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [38800, 38900],
    "230": { // USA
      "usps": null,
      "ups": 351,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [38900, 39000],
    "230": { // USA
      "usps": null,
      "ups": 351.9,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [39000, 39100],
    "230": { // USA
      "usps": null,
      "ups": 352.8,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [39100, 39200],
    "230": { // USA
      "usps": null,
      "ups": 353.7,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [39200, 39300],
    "230": { // USA
      "usps": null,
      "ups": 354.6,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [39300, 39400],
    "230": { // USA
      "usps": null,
      "ups": 355.5,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [39400, 39500],
    "230": { // USA
      "usps": null,
      "ups": 356.4,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [39500, 39600],
    "230": { // USA
      "usps": null,
      "ups": 357.3,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [39600, 39700],
    "230": { // USA
      "usps": null,
      "ups": 358.2,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [39700, 39800],
    "230": { // USA
      "usps": null,
      "ups": 359.1,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [39800, 39900],
    "230": { // USA
      "usps": null,
      "ups": 360,
      "fedex": 80
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 85
    }
  },
  {
    "range": [39900, 40000],
    "230": { // USA
      "usps": null,
      "ups": 360.9,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [40000, 40100],
    "230": { // USA
      "usps": null,
      "ups": 361.8,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [40100, 40200],
    "230": { // USA
      "usps": null,
      "ups": 362.7,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [40200, 40300],
    "230": { // USA
      "usps": null,
      "ups": 363.6,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [40300, 40400],
    "230": { // USA
      "usps": null,
      "ups": 364.5,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [40400, 40500],
    "230": { // USA
      "usps": null,
      "ups": 365.4,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [40500, 40600],
    "230": { // USA
      "usps": null,
      "ups": 366.3,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [40600, 40700],
    "230": { // USA
      "usps": null,
      "ups": 367.2,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [40700, 40800],
    "230": { // USA
      "usps": null,
      "ups": 368.1,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [40800, 40900],
    "230": { // USA
      "usps": null,
      "ups": 369,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [40900, 41000],
    "230": { // USA
      "usps": null,
      "ups": 369.9,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [41000, 41100],
    "230": { // USA
      "usps": null,
      "ups": 370.8,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [41100, 41200],
    "230": { // USA
      "usps": null,
      "ups": 371.7,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [41200, 41300],
    "230": { // USA
      "usps": null,
      "ups": 372.6,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [41300, 41400],
    "230": { // USA
      "usps": null,
      "ups": 373.5,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [41400, 41500],
    "230": { // USA
      "usps": null,
      "ups": 374.4,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [41500, 41600],
    "230": { // USA
      "usps": null,
      "ups": 375.3,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [41600, 41700],
    "230": { // USA
      "usps": null,
      "ups": 376.2,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [41700, 41800],
    "230": { // USA
      "usps": null,
      "ups": 377.1,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [41800, 41900],
    "230": { // USA
      "usps": null,
      "ups": 378,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [41900, 42000],
    "230": { // USA
      "usps": null,
      "ups": 378.9,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [42000, 42100],
    "230": { // USA
      "usps": null,
      "ups": 379.8,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [42100, 42200],
    "230": { // USA
      "usps": null,
      "ups": 380.7,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [42200, 42300],
    "230": { // USA
      "usps": null,
      "ups": 381.6,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [42300, 42400],
    "230": { // USA
      "usps": null,
      "ups": 382.5,
      "fedex": 85
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 90
    }
  },
  {
    "range": [42400, 42500],
    "230": { // USA
      "usps": null,
      "ups": 383.4,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [42500, 42600],
    "230": { // USA
      "usps": null,
      "ups": 384.3,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [42600, 42700],
    "230": { // USA
      "usps": null,
      "ups": 385.2,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [42700, 42800],
    "230": { // USA
      "usps": null,
      "ups": 386.1,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [42800, 42900],
    "230": { // USA
      "usps": null,
      "ups": 387,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [42900, 43000],
    "230": { // USA
      "usps": null,
      "ups": 387.9,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [43000, 43100],
    "230": { // USA
      "usps": null,
      "ups": 388.8,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [43100, 43200],
    "230": { // USA
      "usps": null,
      "ups": 389.7,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [43200, 43300],
    "230": { // USA
      "usps": null,
      "ups": 390.6,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [43300, 43400],
    "230": { // USA
      "usps": null,
      "ups": 391.5,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [43400, 43500],
    "230": { // USA
      "usps": null,
      "ups": 392.4,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [43500, 43600],
    "230": { // USA
      "usps": null,
      "ups": 393.3,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [43600, 43700],
    "230": { // USA
      "usps": null,
      "ups": 394.2,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [43700, 43800],
    "230": { // USA
      "usps": null,
      "ups": 395.1,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [43800, 43900],
    "230": { // USA
      "usps": null,
      "ups": 396,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [43900, 44000],
    "230": { // USA
      "usps": null,
      "ups": 396.9,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [44000, 44100],
    "230": { // USA
      "usps": null,
      "ups": 397.8,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [44100, 44200],
    "230": { // USA
      "usps": null,
      "ups": 398.7,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [44200, 44300],
    "230": { // USA
      "usps": null,
      "ups": 399.6,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [44300, 44400],
    "230": { // USA
      "usps": null,
      "ups": 400.5,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [44400, 44500],
    "230": { // USA
      "usps": null,
      "ups": 401.4,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [44500, 44600],
    "230": { // USA
      "usps": null,
      "ups": 402.3,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [44600, 44700],
    "230": { // USA
      "usps": null,
      "ups": 403.2,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [44700, 44800],
    "230": { // USA
      "usps": null,
      "ups": 404.1,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [44800, 44900],
    "230": { // USA
      "usps": null,
      "ups": 405,
      "fedex": 90
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 95
    }
  },
  {
    "range": [44900, 45000],
    "230": { // USA
      "usps": null,
      "ups": 405.9,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [45000, 45100],
    "230": { // USA
      "usps": null,
      "ups": 406.8,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [45100, 45200],
    "230": { // USA
      "usps": null,
      "ups": 407.7,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [45200, 45300],
    "230": { // USA
      "usps": null,
      "ups": 408.6,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [45300, 45400],
    "230": { // USA
      "usps": null,
      "ups": 409.5,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [45400, 45500],
    "230": { // USA
      "usps": null,
      "ups": 410.4,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [45500, 45600],
    "230": { // USA
      "usps": null,
      "ups": 411.3,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [45600, 45700],
    "230": { // USA
      "usps": null,
      "ups": 412.2,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [45700, 45800],
    "230": { // USA
      "usps": null,
      "ups": 413.1,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [45800, 45900],
    "230": { // USA
      "usps": null,
      "ups": 414,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [45900, 46000],
    "230": { // USA
      "usps": null,
      "ups": 414.9,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [46000, 46100],
    "230": { // USA
      "usps": null,
      "ups": 415.8,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [46100, 46200],
    "230": { // USA
      "usps": null,
      "ups": 416.7,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [46200, 46300],
    "230": { // USA
      "usps": null,
      "ups": 417.6,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [46300, 46400],
    "230": { // USA
      "usps": null,
      "ups": 418.5,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [46400, 46500],
    "230": { // USA
      "usps": null,
      "ups": 419.4,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [46500, 46600],
    "230": { // USA
      "usps": null,
      "ups": 420.3,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [46600, 46700],
    "230": { // USA
      "usps": null,
      "ups": 421.2,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [46700, 46800],
    "230": { // USA
      "usps": null,
      "ups": 422.1,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [46800, 46900],
    "230": { // USA
      "usps": null,
      "ups": 423,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [46900, 47000],
    "230": { // USA
      "usps": null,
      "ups": 423.9,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [47000, 47100],
    "230": { // USA
      "usps": null,
      "ups": 424.8,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [47100, 47200],
    "230": { // USA
      "usps": null,
      "ups": 425.7,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [47200, 47300],
    "230": { // USA
      "usps": null,
      "ups": 426.6,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [47300, 47400],
    "230": { // USA
      "usps": null,
      "ups": 427.5,
      "fedex": 95
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 100
    }
  },
  {
    "range": [47400, 47500],
    "230": { // USA
      "usps": null,
      "ups": 428.4,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [47500, 47600],
    "230": { // USA
      "usps": null,
      "ups": 429.3,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [47600, 47700],
    "230": { // USA
      "usps": null,
      "ups": 430.2,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [47700, 47800],
    "230": { // USA
      "usps": null,
      "ups": 431.1,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [47800, 47900],
    "230": { // USA
      "usps": null,
      "ups": 432,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [47900, 48000],
    "230": { // USA
      "usps": null,
      "ups": 432.9,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [48000, 48100],
    "230": { // USA
      "usps": null,
      "ups": 433.8,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [48100, 48200],
    "230": { // USA
      "usps": null,
      "ups": 434.7,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [48200, 48300],
    "230": { // USA
      "usps": null,
      "ups": 435.6,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [48300, 48400],
    "230": { // USA
      "usps": null,
      "ups": 436.5,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [48400, 48500],
    "230": { // USA
      "usps": null,
      "ups": 437.4,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [48500, 48600],
    "230": { // USA
      "usps": null,
      "ups": 438.3,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [48600, 48700],
    "230": { // USA
      "usps": null,
      "ups": 439.2,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [48700, 48800],
    "230": { // USA
      "usps": null,
      "ups": 440.1,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [48800, 48900],
    "230": { // USA
      "usps": null,
      "ups": 441,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [48900, 49000],
    "230": { // USA
      "usps": null,
      "ups": 441.9,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [49000, 49100],
    "230": { // USA
      "usps": null,
      "ups": 442.8,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [49100, 49200],
    "230": { // USA
      "usps": null,
      "ups": 443.7,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [49200, 49300],
    "230": { // USA
      "usps": null,
      "ups": 444.6,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [49300, 49400],
    "230": { // USA
      "usps": null,
      "ups": 445.5,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [49400, 49500],
    "230": { // USA
      "usps": null,
      "ups": 446.4,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [49500, 49600],
    "230": { // USA
      "usps": null,
      "ups": 447.3,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [49600, 49700],
    "230": { // USA
      "usps": null,
      "ups": 448.2,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [49700, 49800],
    "230": { // USA
      "usps": null,
      "ups": 449.1,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [49800, 49900],
    "230": { // USA
      "usps": null,
      "ups": 450,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  },
  {
    "range": [49900],
    "230": { // USA
      "usps": null,
      "ups": 450.9,
      "fedex": 100
    },
    "39": { // Canada
      "usps": null,
      "ups": null,
      "fedex": 105
    }
  }
];
