import React from 'react';
import PropTypes from 'prop-types';
import ReactAlert from 'react-s-alert';

import Formsy from 'formsy-react';

import {inject, observer} from 'mobx-react';
import { Trans } from 'react-i18next';
import { translate } from 'react-i18next';

import FormModel from '../../../models/FormModel';

import Input from '../../shared/form-elements/Input';
import Textarea from '../../shared/form-elements/Textarea';

import '../../../utils/FormsyCustomValidationRules';

import Logger from '../../../utils/Logger';

import API from '../../../utils/API';

import {
  errorMessages as err,
  alertConfig,
} from '../../../constants/common';

import { apiURLs } from '../../../configs/apiURLs';


import './styles/ContactForm.css';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  modalsStore: PropTypes.object.isRequired,
};

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.store = new FormModel({
      fullName: '',
      email: '',
      text: '',
    });
  }

  onValidSubmit = async (model, resetForm, invalidateForm) => {
    const {
      appStore,
      t,
    } = this.props;

    const {store} = this;

    appStore.updateProgressData();
    store.updateLoadingStatus();

    try {
      await API.postData(apiURLs.contact, store.convertToJS());

      appStore.updateProgressData(false);
      store.updateLoadingStatus(false);

      store.resetForm();

      ReactAlert.success(t('Message was sent'), alertConfig);

    } catch (error) {
      Logger.error(error);
      if (!!error.response) {
        const {data} = error.response;

        // invalidateForm or formRef.updateInputsWithError
        Object.keys(data).forEach(key => {
          const value = data[key];

          if (!(key in model)) return;
          // invalidate if key exist in current form model
          invalidateForm({
            [key]: Array.isArray(value) ? value.join(' ') : value,
          });
        });
      } else {
        ReactAlert.error(t(err.somethingWentWrong), alertConfig);
      }

      appStore.updateProgressData(false);
      store.updateLoadingStatus(false);
    }
  }

  render() {
    const {t} = this.props;

    const {store} = this;

    return (
      <Formsy
        ref={(component) => {
          store.updateRefToForm(component);
        }}
        onValidSubmit={this.onValidSubmit}>

        <Input
          id="message"
          name="message"
          type="hidden"
          className="form-field--no-margin col-xs-12 text-center"
        />

        <div className="contact-form__fields">
          <Input
            name="fullName"
            type="text"
            value={store.elements.get('fullName')}
            updateStateData={store.updateFormItem}
            id="contact_fullName"
            iconClassName="icon-user"
            placeholder="Your Full Name"
            required
            className="contact-form__item"
            validations={{
              isWhitespace: true,
            }}
            validationErrors={{
              isWhitespace: err.isWhitespace,
              isDefaultRequiredValue: err.isDefaultRequiredValue,
            }}
          >
            <label htmlFor="contact_fullName"
              className="contact-form__label">
              <Trans i18nKey={"Your Name"}>
                Your Name
              </Trans>:
            </label>
          </Input>

          <Input
            name="email"
            type="email"
            className="contact-form__item"
            value={store.elements.get('email')}
            updateStateData={store.updateFormItem}
            id="contact_email"
            iconClassName="icon-envelope"
            placeholder={t('Email')}
            required
            validations={{
              isEmail: true
            }}
            validationErrors={{
              isEmail: err.isEmail,
              isDefaultRequiredValue: err.isDefaultRequiredValue,
            }}
          >
            <label htmlFor="contact_email"
              className="contact-form__label">
              <Trans i18nKey={"Email"}>
                Email
              </Trans>:
            </label>
          </Input>


          <Textarea
            name="text"
            value={store.elements.get('text')}
            updateStateData={store.updateFormItem}
            id="contact_text"
            rows="8"
            className="contact-form__item"
            placeholder="Message"
            required
            validationErrors={{
              isDefaultRequiredValue: err.isDefaultRequiredValue,
            }}>
            <label htmlFor="contact_text"
              className="contact-form__label">
              <Trans i18nKey={"Message"}>
                Message
              </Trans>:
            </label>
          </Textarea>
        </div>

        <div className="contact-form__btn-wrapper">
          <button
            type="submit"
            disabled={store.isLoading.get()}
            className="btn btn--high btn--lg">
            <span className="btn__text btn__text--black">
              {t('Submit')}
            </span>
          </button>
        </div>

      </Formsy>
    );
  }

}

const WrappedContactForm = inject('appStore', 'modalsStore')(observer(ContactForm));
const TranslateContactForm = translate()(WrappedContactForm);
TranslateContactForm.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateContactForm;
