import React from 'react';
import PropTypes from 'prop-types';

import {withRouter} from 'react-router-dom';

import {inject, observer} from 'mobx-react';

import { translate } from 'react-i18next';

import ClickOutside from '../../shared/ClickOutside/ClickOutside';

import AccountMenu from '../AccountMenu/AccountMenu';

import AuthSession from '../../../utils/AuthSession';
import Storage from '../../../utils/Storage';
import { routeURLs as URL } from '../../../configs/routeURLs';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};


class HeaderDropDownMenu extends React.Component {
  state = {
    isOpen: false,
  }

  onCheckboxChange = () => {
    if (!AuthSession.isTokenSet()) {
      Storage.set('watagamesNextRedirect', this.props.location.pathname);
      this.props.router.push(URL.login.link);  
    } else {
      this.toggleDropdown(!this.state.isOpen);
    }
  }

  toggleDropdown = (state) => {
    if (state !== this.state.isOpen) {
      this.setState({
        isOpen: state
      });
    }
  }

  handleClickOutside = () => {
    this.toggleDropdown(false);
  }

  render () {
    const { t } = this.props;

    const fullName = this.props.appStore.user.fullName || t('Account');

    return (
      <ClickOutside
        handleClickOutside={this.handleClickOutside}
        className="header-top__item"
      >
        <input
          type="checkbox"
          id="header-top-btn"
          checked={this.state.isOpen}
          onChange={this.onCheckboxChange}
        />
        <label
          className="header-top__btn"
          htmlFor="header-top-btn">
          {
            !AuthSession.isTokenSet()
              ? t('Login') : fullName
          }
          { AuthSession.isTokenSet() && <span className="icon-caret-down" /> }
        </label>

        {
          <AccountMenu toggleDropdown={this.toggleDropdown} />
        }
      </ClickOutside>
    );
  }
}

const WrappedHeaderDropDownMenu = inject('appStore', 'router')(observer(HeaderDropDownMenu));
const TranslateHeaderDropDownMenu = translate()(WrappedHeaderDropDownMenu);

const RouterHeaderDropDownMenu = withRouter(TranslateHeaderDropDownMenu);
RouterHeaderDropDownMenu.wrappedComponent.propTypes = wrappedPropTypes;

export default RouterHeaderDropDownMenu;
