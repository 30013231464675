import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';

import { translate } from 'react-i18next';

const propTypes = {
  isLoading: PropTypes.bool,
  t: PropTypes.func.isRequired,
  getNextPage: PropTypes.func.isRequired,
};

const defaultProps = {
  isLoading: false,
};

class LoadMoreButton extends React.Component {
  render() {
    const {props} = this;
    const {t} = this.props;

    return (
      <div className="load-more-wrapper text-center">
        <button
          className="btn"
          type="button"
          disabled={props.isLoading}
          onClick={props.getNextPage}>
          <span className="btn__text btn__text--black">
            {t('Load more')}
          </span>
        </button>
      </div>
    );
  }
}


LoadMoreButton.propTypes = propTypes;
LoadMoreButton.defaultProps = defaultProps;

const ObserverLoadMoreButton = observer(LoadMoreButton);
const TranslateLoadMoreButton = translate()(ObserverLoadMoreButton);

export default TranslateLoadMoreButton;
