import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Helmet from 'react-helmet';
import uuid from 'uuid-v4';
import BigNumber from 'bignumber.js';

import Formsy from 'formsy-react';

import {
  mergeWith,
} from 'lodash';

import { inject, observer } from 'mobx-react';

import { translate } from 'react-i18next';

import { tt } from '../../../constants/tooltips';

import { routeURLs as URL } from '../../../configs/routeURLs';

import { serviceLevels } from '../../../constants/serviceLevels';
import { prices } from '../../../constants/prices';

import Helpers from '../../../utils/Helpers';
import Storage from '../../../utils/Storage';

import FormModel from '../../../models/FormModel';

import {
  errorMessages as err,
} from '../../../constants/common';

import Checkbox from '../../shared/form-elements/Checkbox';
import Input from '../../shared/form-elements/Input';
import Radio from '../../shared/form-elements/Radio';

import './styles/AddOnsForm.css';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  submitGameStore: PropTypes.object.isRequired,
  queueStore: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  updateSucceed: PropTypes.func.isRequired,
};

class AddOnsFormForPrototypes extends React.Component {
  constructor(props) {
    super(props);

    this.store = new FormModel({
      displayType: 2,
      serviceLevel: 4,
      cleaning: false,
      heavyCleaning: false,
      photoServices: false,
      boardPhotos: false,
    });
  }

  componentDidMount() {
    // Just skip this whole page and jump to success page
    this.onValidSubmit();
  }

  componentWillReceiveProps(props) {
    this.updateFormState();
  }

  updateFormState = () => {
    const {gameItem} = this.props.submitGameStore;

    const firstAvailableServiceLevel = gameItem.serviceLevelPrices
      .map(
        (e,i) => (serviceLevels[i].submissionTypes.some( sType => sType === gameItem.data.submissionType ) && e.enabled)
      ).indexOf(true);

    this.store.update({
      serviceLevel: gameItem.data.serviceLevel || firstAvailableServiceLevel,
      cleaning: gameItem.data.cleaning,
      heavyCleaning: gameItem.data.heavyCleaning,
      photoServices: gameItem.data.photoServices,
      boardPhotos: gameItem.data.boardPhotos,
    });
  }

  onValidSubmit = () => {
    const {props} = this;
    const {elements} = this.store;
    const {
      submitGameStore,
      queueStore,
    } = props;

    submitGameStore.gameItem.updateGameItem({
      displayType: elements.get('displayType'),
      serviceLevel: elements.get('serviceLevel'),
      cleaning: elements.get('cleaning'),
      heavyCleaning: elements.get('heavyCleaning'),
      photoServices: elements.get('photoServices'),
      boardPhotos: elements.get('boardPhotos'),
      queueId: uuid(),
    });

    props.queueStore.addItemToGameList(submitGameStore.gameItem.data, 'queueId');

    const queueDataToStorage = queueStore.gameList.ids.map(id => {
      return queueStore.gameList.dataMap[id].data
    });

    let dataToAdd = {};
    let storage = Storage.get('watagamesQueueList');

    if (props.appStore.user.id) {
      dataToAdd[props.appStore.user.id] = queueDataToStorage;
    } else {
      dataToAdd['unauth'] = queueDataToStorage;
    }

    const dataToStorage = mergeWith({},
      storage,
      dataToAdd,
      Helpers.customizer);

    Storage.set('watagamesQueueList', dataToStorage);

    submitGameStore.gameItem.clearGameItem();
    props.updateSucceed();
  }

  goBack = () => {
    const {
      router,
      submitGameStore,
    } = this.props;

    submitGameStore.updateCurrentStep(1);
    router.push(URL.submitGame.child.stepTwo.link);
  }

  render() {
    const {
      store,
      props,
    } = this;

    const {elements} = store;
    const {t} = props;
    const {round} = Helpers;
    const { gameItem } = props.submitGameStore;

    const declaredValue = (gameItem.data.declaredValueType === 6)
      ? gameItem.data.declaredValue
      : prices.getDeclaredValueOptionsArray()[gameItem.data.declaredValueType];

    const serviceLevelPrice = (status, price) => {
      return status ? price : t('N/A');
    };

    const addOnsPrice = () => {
      const arrAddOns = [
        'cleaning',
        'heavyCleaning',
        'boardPhotos',
        'photoServices',
      ];

      let priceToRes;

      return arrAddOns.reduce((previousValue, currentValue) => {
        priceToRes = prices.addOnsForPrototypes[currentValue];

        const withAddOnValue = BigNumber(previousValue)
          .plus(priceToRes)
          .toNumber();

        return elements.get(currentValue) ? withAddOnValue : previousValue
      }, 0);

    };

    const serviceLevelSubmissionPrice = gameItem.serviceLevelPrices[elements.get('serviceLevel')].price;
    //= elements.get('reholder')
    //  ? 0
    //  : gameItem.serviceLevelPrices[elements.get('serviceLevel')].price;

    const totalPrice = () => {
      let price_bn = BigNumber(serviceLevelSubmissionPrice);

      price_bn = price_bn.plus(addOnsPrice());
      //price_bn = price_bn.plus(
      //  elements.get('reholder')
      //    ? prices.addOns.reholder
      //    : addOnsPrice()
      //);

      return price_bn.times(gameItem.data.count).toNumber();
    };

    const isCertificationNumberRequired = false; //!!elements.get('reholder');

    const certificationNumberValidation = isCertificationNumberRequired ? {
      isCertificationNumber: true,
    } : {};

    const serviceLevelOptions = serviceLevels
    .filter( sl => sl.submissionTypes.some( st => st === gameItem.data.submissionType ))
    .map( sl => {
      return (
        <li className="choice-option__item" key={sl.key}>
          <div className="choice-option__item-container">
            <Radio
              id={"serviceLevel_"+sl.key}
              checked={elements.get('serviceLevel') === sl.id}
              value={sl.id}
              disabled={!gameItem.serviceLevelPrices[sl.id].enabled}
              updateStateData={store.updateFormItem}
              className="choice-option__radio"
              isIndicatorIcon={false}
              name="serviceLevel"
            />
            <label
              htmlFor={"serviceLevel_"+sl.key}
              className={classnames(
                'choice-option__content',
                {
                  'checked': elements.get('serviceLevel') === sl.id,
                  'disabled': !gameItem.serviceLevelPrices[sl.id].enabled,
                },
              )}>
              <span className={"choice-option__icon icon-" + sl.icon} />
              <p className="choice-option__text">
                {t(sl.name)}
              </p>
            </label>
            <label
              htmlFor={"serviceLevel_"+sl.key}
              className={classnames(
                'choice-option__info',
                {
                  'checked': elements.get('serviceLevel') === sl.id,
                  'disabled': !gameItem.serviceLevelPrices[sl.id].enabled,
                },
              )}>
              <p className="choice-option__info-text">
                {sl.shortDescription}
              </p>
              <p className="choice-option__info-price choice-option__info-price-block">
                {
                  serviceLevelPrice(
                    gameItem.serviceLevelPrices[sl.id].enabled,
                    `$${round(gameItem.serviceLevelPrices[sl.id].price).toFixed(2)}`
                  )
                }
              </p>
            </label>
          </div>
        </li>
      );
    });


    return (
      <div className="add-ons">
        <Helmet
          title={`${t('Submit Here')} - ${t('Add-ons')}`}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />
        <h1 className="section__title">
          {t('Choose options & add-ons')}:
        </h1>
        <p className="section__subtitle">
          {t('Choose the level of detail you want in the report from the Video Game History Foundation, and any add-ons you want from Wata.')}
        </p>
        <Formsy
          className="container-fluid"
          ref={(component) => {
            store.updateRefToForm(component);
          }}
          onValidSubmit={this.onValidSubmit}>
          <div className="row">
            <div className="col-xs-12 col-md-7 submit-game__item">
              <div className="select-game__label">
                {t('Report Type')}:
              </div>
              <ul className="choice-option choice-option--content">
                {serviceLevelOptions}
              </ul>
            </div>

            <div className="col-xs-12 col-md-offset-1 col-md-4 submit-game__item add-ons__services">
              <div className="select-game__label">
                {t('Add-On Services')}:
              </div>
              <ul className="add-ons__list">
                <li className="add-ons__item">
                  <div className="add-ons__service-info">
                    <span className="add-ons__service-icon icon-cleaning" />
                    <Checkbox
                      name="cleaning"
                      checked={elements.get('cleaning')}
                      id="cleaning"
                      disabled={elements.get('reholder')}
                      checkboxClassName="form-checkbox--black"
                      updateStateData={store.updateFormItem} />
                    <label
                      htmlFor="cleaning"
                      className="add-ons__service-title">
                      {t('Light Cleaning')}{tt.lightCleaning}
                    </label>
                  </div>
                  <p className="add-ons__service-price">
                    {
                      gameItem.data.gameState === 1 ? (
                        `$${round(prices.addOnsForPrototypes.cleaning).toFixed(2)}`
                      ) : (
                        `$${round(prices.addOnsForPrototypes.cleaning).toFixed(2)}`
                      )
                    }
                  </p>
                </li>
                <li className="add-ons__item">
                  <div className="add-ons__service-info">
                    <span className="add-ons__service-icon icon-graders" />
                    <Checkbox
                      name="heavyCleaning"
                      checked={elements.get('heavyCleaning')}
                      disabled={elements.get('reholder')}
                      id="heavyCleaning"
                      checkboxClassName="form-checkbox--black"
                      updateStateData={store.updateFormItem} />
                    <label
                      htmlFor="heavyCleaning"
                      className="add-ons__service-title">
                      {t('Heavy Cleaning')}{tt.heavyCleaning}
                    </label>
                  </div>
                  <p className="add-ons__service-price">
                    ${round(prices.addOnsForPrototypes.heavyCleaning).toFixed(2)}
                  </p>
                </li>

                <li className="add-ons__item">
                  <div className="add-ons__service-info">
                    <span className="add-ons__service-icon icon-camera" />
                    <Checkbox
                      name="photoServices"
                      checked={elements.get('photoServices')}
                      id="photoServices"
                      checkboxClassName="form-checkbox--black"
                      updateStateData={store.updateFormItem} />
                    <label
                      htmlFor="photoServices"
                      className="add-ons__service-title">
                      {t('Photo Services')}{tt.photoServices}
                    </label>
                  </div>
                  <p className="add-ons__service-price">
                    ${round(prices.addOnsForPrototypes.photoServices).toFixed(2)}
                  </p>
                </li>

                <li className="add-ons__item">
                  <div className="add-ons__service-info">
                    <span className="add-ons__service-icon icon-board-photos" />
                    <Checkbox
                      name="boardPhotos"
                      checked={elements.get('boardPhotos')}
                      id="boardPhotos"
                      disabled={(
                        gameItem.data.gameState === 0
                        || elements.get('reholder')
                      )}
                      checkboxClassName="form-checkbox--black"
                      updateStateData={store.updateFormItem} />
                    <label
                      htmlFor="boardPhotos"
                      className="add-ons__service-title">
                      {t('Board Photos')}{tt.boardPhotos}
                    </label>
                  </div>
                  <p className="add-ons__service-price">
                    ${round(prices.addOnsForPrototypes.boardPhotos).toFixed(2)}
                  </p>
                </li>
              </ul>
              {
                isCertificationNumberRequired && (
                  <Input
                    id="certification_number"
                    name="certificationNumber"
                    type="text"
                    placeholder={t('Certification Number')}
                    value={elements.get('certificationNumber')}
                    updateStateData={store.updateFormItem}
                    required={isCertificationNumberRequired}
                    validations={certificationNumberValidation}
                    validationErrors={{
                      isCertificationNumber: err.isCertificationNumber,
                      isDefaultRequiredValue: err.isDefaultRequiredValue,
                    }}
                  />
                )
              }

              <div className="add-ons__box box">
                <div className="add-ons__box-header add-ons__box-item">
                  <div className="select-game__label">
                    {t('Declared Value')}:
                  </div>
                  <span className="add-ons__declared-price">
                    ( ${declaredValue} )
                  </span>
                </div>

                <ul className="price-list">
                  <li className="price-list__item">

                    <span className="price-list__label">{t('Quantity')}</span>

                    <div className="price-list__info">
                      <span className="price-list__price">
                        &times;{gameItem.data.count}
                      </span>
                    </div>

                  </li>
                  <li className="price-list__item">

                    <span className="price-list__label price-list__label--border">
                      {t('Add-ons')}
                    </span>

                    <div className="price-list__info">
                      <span className="price-list__price price-list__price--border">
                        +${round(addOnsPrice()).toFixed(2)}
                      </span>
                    </div>

                  </li>
                </ul>

                <ul className="price-list price-list--total price-list--no-margin">
                  <li className="price-list__item">
                    <span className="price-list__label price-list__label--total">
                      {t('Media Total')}:
                    </span>
                    <div className="price-list__info">
                      <span className="price-list__price price-list__price--total price-list__price--pink">
                        ${round(totalPrice()).toFixed(2)}
                      </span>
                    </div>
                  </li>
                </ul>

              </div>

            </div>
          </div>
          <div className="submit-game__footer horizontal-space-between">
            <button
              type="button"
              onClick={this.goBack}
              className="btn btn--high btn--lg btn--black btn--icon-left">
              <span className="btn__text">
                {t('Back')}
                <i className="btn__icon icon-pix-arrow-lt" />
              </span>
            </button>
            <button
              type="submit"
              className="btn btn--high btn--lg btn--black btn--icon-right">
              <span className="btn__text">
                {t('Add to queue')}
                <i className="btn__icon icon-pix-arrow-rt" />
              </span>
            </button>
          </div>
        </Formsy>
      </div>
    );
  }
}


const WrappedAddOnsFormForPrototypes = inject(
  'router',
  'appStore',
  'submitGameStore',
  'queueStore'
)(observer(AddOnsFormForPrototypes));
const TranslateAddOnsFormForPrototypes = translate()(WrappedAddOnsFormForPrototypes);
TranslateAddOnsFormForPrototypes.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateAddOnsFormForPrototypes;
