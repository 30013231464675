import React from 'react';
import PropTypes from 'prop-types';
import ReactAlert from 'react-s-alert';
import classnames from 'classnames';

import {mergeWith} from 'lodash';

import DropIn from "../../../shared/DropIn/DropIn";

import {inject, observer} from 'mobx-react';
import { translate } from 'react-i18next';

import Invader from '../../../shared/Invader/Invader';

import {
  alertConfig,
  braintreeConfig,
} from '../../../../constants/common';

import { apiURLs } from '../../../../configs/apiURLs';
import { routeURLs as URL } from '../../../../configs/routeURLs';

import { shippingOptions } from '../../../../constants/shippingOptions';

import API from '../../../../utils/API';
import Logger from '../../../../utils/Logger';
import Helpers from '../../../../utils/Helpers';

import './styles/PaymentForm.css';

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
  cartStore: PropTypes.object.isRequired,
  modalsStore: PropTypes.object.isRequired,
  summaryOrderStore: PropTypes.object.isRequired,
};

class PaymentForm extends React.Component {
  state = {
    clientToken: '',
    nonce: '',
    isLoading: false,
    isLoadingOnSubmit: false,
    isConfirmed: false,
  };

  async componentDidMount() {
    if (this.state.clientToken.length === 0){
      this.setState({
        isLoading: true,
        isConfirmed: false,
      });
      try {
        const response = await API.getData(apiURLs.billing.token);
        const { clientToken } = response.data;
        this.setState({
          clientToken: clientToken,
          isLoading: false
        });
      } catch (error) {
        ReactAlert.error(error.message, alertConfig);
        Logger.error(error);
      }
    }
  }

  onInstance = (instance) => {
    this.setState({
      isLoading: false,
    });

    return this.instance = instance;
  }

  proceedPay = async () => {
    const {
      cartStore,
      summaryOrderStore,
      appStore,
      router
    } = this.props;

    this.setState({
      isLoading: true,
      isLoadingOnSubmit: true,
    });

    try {
      let response = this.instance ? await this.instance.requestPaymentMethod() : {nonce: ''};

      if (this.state.nonce.length === 0){
        this.setState({
          nonce: response.nonce
        });
      }

      const isSendTogether =  false;

      const shippingAndInsurance = cartStore.shippingAndInsurance.length
        ? cartStore.shippingAndInsurance[cartStore.deliveryService]
        : cartStore.shippingAndInsurance;

      const deliveryService = cartStore.shippingAndInsurance.length
        ? cartStore.deliveryService
        : 3;

      const deliveryServiceName = cartStore.shippingAndInsurance.length
        ? shippingOptions[cartStore.deliveryService].name
        : '-';

      const query = {
        nonce: this.state.nonce,
        total: cartStore.totalPrice,
        shippingAddress: cartStore.convertAddressToJS(),
        saveAddress: cartStore.shippingFormData.saveAddress,
        deliveryService: deliveryService,
        deliveryServicePrice: shippingAndInsurance,
        deliveryServiceName: deliveryServiceName,
        itemsPrice: cartStore.totalServiceLevelsPrice,
        addonsPrice: cartStore.totalAddOnsPrice,
        discount: cartStore.totalDiscount,
        newsletterSignup: cartStore.newsletterSignup,
        isSendTogether,
        box: cartStore.gameList.ids.map((id) => {
          const {
            data: {
              reholder,
              certificationNumber,
            },
            price,
          } = cartStore.gameList.dataMap[id];

          return {
            id,
            price,
            ...(reholder && { certificationNumber })
          };
        }),
        declaredValue: cartStore.totalDeclaredValue,
        promoPercent: cartStore.promoPercent,
        promoCode: cartStore.promoCode,
      };

      query.shippingAddress.countryId = cartStore.convertAddressToJS().country.id;

      delete query.shippingAddress.country;

      const res = await API.postData(apiURLs.billing.checkout, query);
      const {data} = res;

      this.setState({
        isLoading: false,
        isLoadingOnSubmit: false,
        isConfirmed: !!data.success,
      });

      if (!data.success) {
        // Error response
        Logger.error(data);
        ReactAlert.error(data.message, alertConfig);

        return;
      }

      // Success response
      ReactAlert.success("Payment received", alertConfig);

      data.order = mergeWith({},
        data.order,
        {
          address: cartStore.convertAddressToJS(),
        },
      );

      summaryOrderStore.updateOrderItem(data.order);
      summaryOrderStore.updateQrCode(data.qrcode);
      summaryOrderStore.box.updateDiscount(cartStore.convertDiscountToJS());

      if (!!cartStore.shippingFormData.saveAddress) {
        appStore.updateProfileData({
          shippingAddress: cartStore.convertAddressToJS(),
        });

        let country = '';

        const {
          profile: {
            shippingAddress,
            address,
          }
        } = appStore;

        if (
          !!shippingAddress.country.id
        ) {
          country = shippingAddress.country.id;
        } else if (
          !!address.country.id &&
          getCountryIndex(address.country.id) >= 0
        ) {
          country = address.country.id;
        }

        cartStore.updateShippingCountry(country);
      }

      cartStore.clearGameList();
      cartStore.resetShippingFormData();
      cartStore.updateIsSendTogether(null);
      cartStore.updateDeliveryService(null);

      cartStore.clearDiscount();

      cartStore.updateIsDataFromCartStore(false);

      cartStore.updateCartStep(4);

      router.push(URL.submitGame.child.success.link);


    } catch (error) {
      Logger.error(error);
      ReactAlert.error(error.message, alertConfig);
      this.setState({
        isLoading: false,
        isLoadingOnSubmit: false,
      });
    }
  }

  render() {

    const {
      t,
      cartStore,
    } = this.props;

    return (
      <div>
        {
          (this.state.isLoading || !this.state.clientToken) && (
            <div className="loader-container">
              <h1 className="confirmation__header text-center">
                {t('Please wait. Loading next level...')}
              </h1>
              <Invader />
            </div>
          )
        }
        {
          !!this.state.clientToken && (
            <div className={classnames({
                'hidden': this.state.isLoadingOnSubmit,
              }
            )}>
              <div className="braintree-show-options">
                <h1 className="confirmation__header text-center">
                  Amount due: {Helpers.currencyFormatter(cartStore.totalPrice)}
                </h1>
              </div>
              { cartStore.totalPrice > 0.0 && (
                <DropIn
                  options={{
                    ...braintreeConfig,
                    authorization: this.state.clientToken,
                    applePay: {
                      displayName: 'Merchant Name', // TODO: Ask for the test applepay account
                      paymentRequest: {
                        flow: 'checkout',
                        total: cartStore.totalPrice
                      }
                    },
                    paypal: {
                      flow: 'checkout',
                      amount: cartStore.totalPrice,
                      currency: 'USD'
                    },
                    googlePay: {
                      merchantId: 'merchant-id-from-google', // TODO: Ask for the test merchant id account
                      transactionInfo: {
                        totalPriceStatus: 'FINAL',
                        totalPrice: cartStore.totalPrice,
                        currencyCode: 'USD'
                      }
                    },
                    paypalCredit: {
                      flow: 'checkout',
                      amount: cartStore.totalPrice,
                      currency: 'USD'
                    },
                  }}
                  onInstance={this.onInstance}
                />)}
              {
                (!this.state.isLoading && !this.state.isConfirmed) && (
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn--high btn--lg"
                      onClick={this.proceedPay}>
                      <span className="btn__text btn__text--black">
                        {(cartStore.totalPrice > 0.0) ? t('Send us your gold!'): t('Complete order')}
                      </span>
                    </button>
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    );
  }

}

const WrappedPaymentForm = inject(
  'appStore',
  'cartStore',
  'summaryOrderStore',
  'modalsStore',
  'router'
)(observer(PaymentForm));

const TranslatePaymentForm = translate()(WrappedPaymentForm);
TranslatePaymentForm.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslatePaymentForm;
