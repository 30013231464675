import React from 'react';
import PropTypes from 'prop-types';

import {inject, observer} from 'mobx-react';
import {
  translate,
} from 'react-i18next';
import Helmet from 'react-helmet';

import Submission from './Submission/Submission';
import Pricing from './Pricing/Pricing';
import Ons from './Ons/Ons';

import withTracker from '../../shared/AnalyticsTracker/withTracker';

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
};

const TITLE = 'Pricing';

class StandardSubmissions extends React.Component {

  render() {
    const {props} = this;
    const {t} = props;

    return (
      <div>
        <Helmet
          title={`${t('What We Do')} - ${t(TITLE)}`}
          meta={[
            {
              name: "description",
              content: "Submission process and pricing for collectable video games."
            },
          ]}
        />
        <div className="submission-banner">
          <div className="submission-banner__container">

            <div className="container-fluid">
              <div className="row-fluid">

                <div className="submission-banner__wrapper section section--top section--gradient section--ytr">
                  <div className="submission-banner__content">
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
        <Submission />
        <Pricing />
        <Ons />
      </div>
    );
  }
}

const WrappedStandardSubmissions = inject('appStore')(observer(StandardSubmissions));
const TranslateStandardSubmissions = translate()(WrappedStandardSubmissions);
TranslateStandardSubmissions.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslateStandardSubmissions, {title: TITLE});
