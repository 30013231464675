import {
  extendObservable,
  observable,
  action,
} from 'mobx';
import uuid from 'uuid-v4';
import {
  mergeWith,
} from 'lodash';
import GradeItemModel from './GradeItemModel';
import Helpers from '../utils/Helpers';
import { grades } from '../constants/grades';


class CrateItemModel {
  constructor(data) {
    extendObservable(this, {
      data: mergeWith({}, this.crateItemInitialState),

      get cleaningStatus() {
        return this.data.isCleaning ? 'Done' : 'In progress';
      },

      get heavyCleaningStatus() {
        return this.data.isHeavyCleaning ? 'Done' : 'In progress';
      },

      get reholderStatus() {
        return this.data.isReholder ? 'Done' : 'In progress';
      },

      get testingStatus() {
        return this.data.isTesting ? 'Done' : 'In progress';
      },

      get reportStatus() {
        return this.data.isReport ? 'Done' : 'In progress';
      },

      get boardPhotoStatus() {
        return this.data.isBoardPhoto ? 'Done' : 'In progress';
      },

      get photoServiceStatus() {
        return this.data.isPhotoService ? 'Done' : 'In progress';
      },

      get cibPlusStatus() {
        return this.data.isCibPlus ? 'Done' : 'In progress';
      },

      get legacyHolderStatus() {
        return this.data.isLegacyHolder ? 'Done' : 'In progress';
      },

      get stickerRemovalStatus() {
        return this.data.isStickerRemoval ? 'Done' : 'In progress';
      },

      get genDesignationStatus() {
        return this.data.isGenDesignation ? 'Done' : 'In progress';
      },

      get autographAuthStatus() {
        return this.data.isAutographAuth ? 'Done' : 'In progress';
      },

      get overAllGrade() {
        let overall = null;
        let res = 'In Progress';
        const isCIB = this.data.gameState === 1;
        const isLooseCart = this.data.gameState === 2;
        const isSealed = this.data.gameState === 0;
        const mapGrade = value => ((value === 12) ? 0 : value);

        if (this.indexFinalGrade < 0) return;

        const gradeData = this.data.grades[this.indexFinalGrade];

        switch (true) {
          case (isCIB && !gradeData.isPro && !gradeData.isGenAll):
            overall = mapGrade(gradeData.cartridgeNumber)*0.3 +
              mapGrade(gradeData.boxNumber)*0.5 +
              mapGrade(gradeData.instructionNumber)*0.2;
              res = Helpers.closest(grades.map(item => item.id), overall);
            break;

          case (isCIB && gradeData.isPro):
            res = 'PRO';
            break;

          case (isCIB && gradeData.isGenAll):
            res = 'GEN';
            break;

          case (isLooseCart):
            res = gradeData.cartridgeName;
            break;

          case (isSealed):
            res = `${gradeData.boxName} ${gradeData.sealName}`;
            break;

          default:
            res = 'Not assigned';
            break;
        }

        return res;
      },

      get gradeDataGraders() {
        return this.data.grades.filter(i => i.data.grader.data.role === 2);
      },

      get indexFinalGrade() {
        return this.data.grades.map(i => i.data.grader.data.role).indexOf(3);
      },

      get finalGraderData() {
        return (this.indexFinalGrade >= 0) ? this.data.grades[this.indexFinalGrade] : null;
      },

      get insertsStatus() {
        return (this.indexFinalGrade < 0) ? 'In Progress' : 'Done';
      },
    });

    if (data) {
      this.updateCrateItem(data);
    }
  }

  id = uuid();
  crateItemInitialState = {
    id: '',
    isBoardPhoto: false,
    isCleaning: false,
    isHeavyCleaning: false,
    isPhotoService: false,
    isReport: false,
    isTesting: false,
    isReholder: false,
    gameState: 0,
    label: '',
    attachments: [],
    grades: observable.array([]),
  };

  clearCrateItem = action(
    'clear crate item', () => {
    this.updateCrateItem(this.crateItemInitialState);
  });

  updateCrateItem = action('update crate item', newData => {
    const keys = Object.keys(newData);

    keys.forEach(key => {
      if (key === 'grades') {
        newData[key].forEach(item => {
          this.data.grades.push(new GradeItemModel(item));
        });
      } else {
        this.data[key] = newData[key];
      }
    });
  });
};

export default CrateItemModel;
