export const grades = [
  {
    id: 10,
    name: '10',
  },
  {
    id: 9.8,
    name: '9.8',
  },
  {
    id: 9.6,
    name: '9.6',
  },
  {
    id: 9.4,
    name: '9.4',
  },
  {
    id: 9.2,
    name: '9.2',
  },
  {
    id: 9,
    name: '9.0',
  },
  {
    id: 8.5,
    name: '8.5',
  },
  {
    id: 8,
    name: '8.0',
  },
  {
    id: 7.5,
    name: '7.5',
  },
  {
    id: 7,
    name: '7.0',
  },
  {
    id: 6.5,
    name: '6.5',
  },
  {
    id: 6,
    name: '6.0',
  },
  {
    id: 5.5,
    name: '5.5',
  },
  {
    id: 5,
    name: '5.0',
  },
  {
    id: 4.5,
    name: '4.5',
  },
  {
    id: 4,
    name: '4.0',
  },
  {
    id: 3.5,
    name: '3.5',
  },
  {
    id: 3,
    name: '3.0',
  },
  {
    id: 2.5,
    name: '2.5',
  },
  {
    id: 2,
    name: '2.0',
  },
  {
    id: 1.5,
    name: '1.5',
  },
  {
    id: 1,
    name: '1.0',
  },
  {
    id: 0.5,
    name: '0.5',
  },
];

export const gradesExtra = [
  {
    id: 11,
    name: 'PRO',
  },
  {
    id: 12,
    name: 'GEN',
  },
];

export const gradesFull = [...grades, ...gradesExtra];

export const sealGrades = [
  {
    id: 0,
    name: 'A++',
  },
  {
    id: 1,
    name: 'A+',
  },
  {
    id: 2,
    name: 'A',
  },
  {
    id: 3,
    name: 'B+',
  },
  {
    id: 4,
    name: 'B',
  },
  {
    id: 5,
    name: 'C+',
  },
  {
    id: 6,
    name: 'C',
  },
];

export const gradeLabels = [
  {
    translationId: "Overall grading",
    jsonKey: "overallGrade",
  },
  {
    translationId: "Seal grading",
    jsonKey: "seal",
  },
  {
    translationId: "Box grading",
    jsonKey: "box",
  },
  {
    translationId: "Cart grading",
    altTranslationId: "Disc grading",
    jsonKey: "cartridge",
  },
  {
    translationId: "Manual grading",
    jsonKey: "instruction",
  },
  {
    translationId: "Insert grading",
    jsonKey: "grade",
  },
  // TODO add seal type
  {
    translationId: "Grader notes",
    jsonKey: "notes",
  },
];
