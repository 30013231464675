import {
  extendObservable,
  action,
  toJS,
} from 'mobx';

import {
  mergeWith,
} from 'lodash';

import uuid from 'uuid-v4';

import OrderItemModel from './OrderItemModel';


class OrderListModel {
  orderListInitialState = [];

  id = uuid();

  constructor(orderType) {
    extendObservable(this, {
      orderList: mergeWith([], this.orderListInitialState),
      count: 0,
      page: 1,
      orderType: 0,
      orderIndex: 0,
    });

    this.updateOrderIndex = action(
      'update current orderIndex', (orderId) => {
      const index = this.orderList.map(item => item.data.id).indexOf(orderId);

      if (this.orderIndex !== index) {
        this.orderIndex = index;
      }
    });

    this.clearOrderList = action(
      'clear the order list', () => {
      this.orderList = mergeWith([], this.orderListInitialState);
    });

    this.addItemsToOrderList = action(
      'add items to the order list', (dataList) => {
      const orderListMapped = dataList.map(item => {
        delete item.user;
        return new OrderItemModel(item);
      });

      this.orderList = this.orderList.concat(orderListMapped);
    });

    this.incrementPage = action(
      'increment page number', () => {
      this.page++;
    });

    this.clearPage = action(
      'set page number to the start position', () => {
      this.page = 1;
    });

    this.updateOrderType = action(
      'update orderType', (type) => {
      if (this.orderType !== type) {
        this.orderType = type;
      }
    });

    this.updateCount = action(
      'update count', (count) => {
      if (this.count !== count) {
        this.count = count;
      }
    });

    this.updateOrderList = action(
      'update order list', (ordersObj) => {
      this.incrementPage();
      this.updateCount(ordersObj.count);
      this.addItemsToOrderList(ordersObj.data);
    });

    // helpers
    this.convertOrderListToJS = () => {
      return toJS(this.orderList);
    };

    if (!!orderType || orderType === 0) {
      this.updateOrderType(orderType);
    }
  }

}

export default OrderListModel;
