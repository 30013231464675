import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Link } from 'react-router-dom';

import Modal from 'react-modal';

import {inject, observer} from 'mobx-react';
import {
  translate,
  Trans,
} from 'react-i18next';

import ChangePass from '../ChangePass/ChangePass';

import '../../../utils/FormsyCustomValidationRules';

import { modalOptions } from '../../../constants/common';

import './styles/ProfileData.css';

const wrappedPropTypes = {
  toggleEditable: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
  modalsStore: PropTypes.object.isRequired,
};

class ProfileData extends React.Component {
  toggleModal = () => {
    this.props.modalsStore.toggleModal('changePass');
  }

  render() {
    const d = document;
    const {
      t,
      modalsStore,
    } = this.props;

    const {
      user,
      profile,
    } = this.props.appStore;

    return (
      <div className="profile-form-wrapper">
        <div className="profile-form">
          <div className="profile-form__content">

            <ul className="arrow-list">
              <li className="arrow-list__item">
                <div className="profile-form__item">

                  <h2 className="profile-form__label">
                    {user.fullName}
                  </h2>
                  { ' ' }
                  <span className="profile-form__text">
                    {user.email}
                  </span>
                </div>
              </li>
            </ul>

            <div className="profile-form__item">
                <ul className="profile-social">
                  <li className="profile-social__item">
                    <Link
                      to={profile.social.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classnames(
                        'profile-social__link',
                        {
                          'text-gradient': !!profile.social.facebook,
                          'disabled': !profile.social.facebook,
                        }
                      )}>
                      <span className="profile-social__icon icon-facebook-square" />
                    </Link>
                  </li>
                  <li className="profile-social__item">
                    <Link
                      to={profile.social.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classnames(
                        'profile-social__link',
                        {
                          'text-gradient': !!profile.social.instagram,
                          'disabled': !profile.social.instagram,
                        }
                      )}>
                      <span className="profile-social__icon icon-instagram" />
                    </Link>
                  </li>
                  <li className="profile-social__item">
                    <Link
                      to={profile.social.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classnames(
                        'profile-social__link',
                        {
                          'text-gradient': !!profile.social.twitter,
                          'disabled': !profile.social.twitter,
                        }
                      )}>
                      <span className="profile-social__icon icon-twitter-square" />
                    </Link>
                  </li>
                  <li className="profile-social__item">
                    <Link
                      to={profile.social.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classnames(
                        'profile-social__link',
                        {
                          'text-gradient': !!profile.social.linkedin,
                          'disabled': !profile.social.linkedin,
                        }
                      )}>
                      <span className="profile-social__icon icon-linkedin-square" />
                    </Link>
                  </li>
                </ul>
            </div>

            <ul className="arrow-list">
              {
                !!profile.bio && (
                  <li className="arrow-list__item">
                    <div className="profile-form__item">
                      <label
                        className="profile-form__label">
                        <Trans
                          htmlFor="profile_bio"
                          i18nKey={"Short Bio"}>
                          Short Bio
                        </Trans>:
                      </label>
                      <p className="profile-form__text">
                        {profile.bio}
                      </p>
                    </div>
                  </li>
                )
              }

              {
                !!profile.address.businessName && (
                  <li className="arrow-list__item">
                    <div className="profile-form__item">
                      <label htmlFor="profile_businessName"
                        className="profile-form__label">
                        <Trans i18nKey={"Business Name"}>
                          Business Name
                        </Trans>:
                      </label>
                      <p className="profile-form__text">
                        {profile.address.businessName}
                      </p>
                    </div>
                  </li>
                )
              }

              {
                !!profile.address.phone && (
                  <li className="arrow-list__item">
                    <div className="profile-form__item">
                      <label htmlFor="profile_phone"
                        className="profile-form__label">
                        <Trans i18nKey={"Phone number"}>
                          Phone number
                        </Trans>:
                      </label>
                      <p className="profile-form__text">
                        {profile.address.phone}
                      </p>
                    </div>
                  </li>
                )
              }
              {
                (
                  !!profile.country ||
                  !!profile.address.state ||
                  !!profile.address.city ||
                  !!profile.address.zip ||
                  !!profile.address.address
                ) && (
                  <li className="arrow-list__item">
                    <label className="profile-form__label">
                      {t('User Address')}
                    </label>
                    {
                      !!profile.country && (
                        <div className="profile-form__item">

                          <label
                            htmlFor="profile_country"
                            className="profile__label">
                            <Trans i18nKey={"Country"}>
                              Country
                            </Trans>:
                          </label>
                          { ' ' }
                          <span className="profile-form__text">
                            {profile.country.name}
                          </span>
                        </div>
                      )
                    }
                    {
                      !!profile.address.state && (
                        <div className="profile-form__item">
                          <label
                            className="profile__label"
                            htmlFor="profile_state">
                            <Trans i18nKey={"State"}>
                              State
                            </Trans>:
                          </label>
                          { ' ' }
                          <span className="profile-form__text">
                            {profile.address.state}
                          </span>
                        </div>
                      )
                    }
                    {
                      !!profile.address.city && (
                        <div className="profile-form__item">
                          <label
                            className="profile__label"
                            htmlFor="profile_city">
                            <Trans i18nKey={"City"}>
                              City
                            </Trans>:
                          </label>
                          { ' ' }
                          <span className="profile-form__text">
                            {profile.address.city}
                          </span>
                        </div>
                      )
                    }
                    {
                      !!profile.address.zip && (
                        <div className="profile-form__item">
                          <label htmlFor="profile_zip"
                            className="profile__label">
                            <Trans i18nKey={"Zip code"}>
                              Zip code
                            </Trans>:
                          </label>
                          { ' ' }
                          <span className="profile-form__text">
                            {profile.address.zip}
                          </span>
                        </div>
                      )
                    }
                    {
                      !!profile.address.address && (
                        <div className="profile-form__item">
                          <label
                            className="profile__label">
                            <Trans
                              htmlFor="profile_address"
                              i18nKey={"Address"}>
                              Address
                            </Trans>:
                          </label>
                          { ' ' }
                          <span className="profile-form__text">
                            {profile.address.address}
                          </span>
                        </div>
                      )
                    }
                  </li>
                )
              }
            </ul>

          </div>
        </div>

        <div className="profile__buttons-wrapper">
          <div className="profile__buttons">
            <button
              type="button"
              onClick={this.props.toggleEditable}
              className="btn btn--high btn--lg btn--icon-right">
              <span className="btn__text btn__text--black">
                {t('Edit profile info')}
                <i className="btn__icon icon-pix-arrow-rt" />
              </span>
            </button>
            <button
              type="button"
              onClick={this.toggleModal}
              className="btn btn--high btn--lg btn--icon-right btn--black">
              <span className="btn__text btn__text--black">
                {t('Change password')}
                <i className="btn__icon icon-pix-arrow-rt" />
              </span>
            </button>
          </div>
        </div>
        <Modal
          onRequestClose={this.toggleModal}
          shouldCloseOnOverlayClick={true}
          bodyOpenClassName={modalOptions.modalBodyOpenClassName}
          className={modalOptions.modalContainer()}
          overlayClassName={modalOptions.modalOverlayContainer()}
          parentSelector={() => d.body}
          appElement={d.getElementById('root')}
          shouldCloseOnEsc={true}
          isOpen={modalsStore.modals.changePass}>
          <ChangePass />
        </Modal>
      </div>
    );
  }

}

const WrappedProfileData = inject('appStore', 'modalsStore')(observer(ProfileData));
const TranslateProfileData = translate()(WrappedProfileData);
TranslateProfileData.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateProfileData;
