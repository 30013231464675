import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import OrderItem from '../OrderItem/OrderItem';
import LoadMoreButton from '../../../shared/LoadMoreButton/LoadMoreButton';

import Invader from '../../../shared/Invader/Invader';

import PrintSummary from '../../../shared/PrintSummary/PrintSummary';

import withTracker from '../../../shared/AnalyticsTracker/withTracker';

const TITLE = 'Open Orders';

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  getOrdersData: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
  completedOrdersStore: PropTypes.object.isRequired,
};

class CompletedOrders extends React.Component {
  componentDidMount() {
    const {
      completedOrdersStore: {
        clearOrderList,
        updateCount,
        clearPage,
      },
    } = this.props;

    clearOrderList();
    updateCount(0);
    clearPage();
    this.getNextPage();
  }

  getNextPage = () => {
    const { completedOrdersStore } = this.props;
    const query = {
      page: completedOrdersStore.page,
      orderType: completedOrdersStore.orderType,
    };

    this.props.getOrdersData(query, completedOrdersStore.updateOrderList);
  }

  render() {
    const {
      t,
      completedOrdersStore,
    } = this.props;

    const orderList = completedOrdersStore.orderList.map(item => {
      return (
        <li
          key={item.data.id}
          className="orders__list-item"
        >
          <OrderItem
            item={item}
            updateOrderIndex={completedOrdersStore.updateOrderIndex}
            toPrint={this.toPrint}
          />
        </li>
      );
    });

    return (
      <div className="completed-orders">
        <h2 className="section__title">
          {t('Aw, feel like reminiscing?')}
        </h2>
        <p className="section__subtitle">
          {t('We’ve been keeping track (so you don’t have to). Here are all of your completed orders:')}
        </p>

        <div className="completed-orders__content">

          <ul className="orders__list">
            {orderList}
          </ul>

          {
            !completedOrdersStore.orderList.length && !this.props.appStore.progressStatus && (
              <h1 className="confirmation__header text-center">
                {t('You have no completed orders')}
              </h1>
            )
          }

          {
            this.props.appStore.progressStatus && (
              <div className="loader-container">
                <h1 className="confirmation__header text-center">
                  {t('Please wait. Loading next level...')}
                </h1>
                <Invader />
              </div>
            )
          }

          {
            (completedOrdersStore.orderList.length < completedOrdersStore.count) && (
              <LoadMoreButton
                isLoading={this.props.appStore.progressStatus}
                getNextPage={this.getNextPage}
              />
            )
          }

          {
            !!completedOrdersStore.orderList.length && (
              <div className="summary-printable">
                <PrintSummary
                  orderAmount={completedOrdersStore.orderList[completedOrdersStore.orderIndex].data.amount}
                  orderTotalDiscount={completedOrdersStore.orderList[completedOrdersStore.orderIndex].data.totalDiscount}
                  orderDeliveryServicePrice={completedOrdersStore.orderList[completedOrdersStore.orderIndex].data.deliveryServicePrice}
                  box={completedOrdersStore.orderList[completedOrdersStore.orderIndex].box}
                  address={completedOrdersStore.orderList[completedOrdersStore.orderIndex].address}
                  isSendTogether={completedOrdersStore.orderList[completedOrdersStore.orderIndex].data.isSendTogether}
                  orderNumber={completedOrdersStore.orderList[completedOrdersStore.orderIndex].data.orderNumber}
                  ref={el => (this.toPrint = el)}
                />
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

const WrappedCompletedOrders = inject('appStore', 'completedOrdersStore')(observer(CompletedOrders));
const TranslateCompletedOrders = translate()(WrappedCompletedOrders);
TranslateCompletedOrders.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslateCompletedOrders, {title: TITLE});
