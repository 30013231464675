import React from 'react';
import PropTypes from 'prop-types';

import {inject, observer} from 'mobx-react';
import {
  translate,
  // Trans,
} from 'react-i18next';

import classnames from 'classnames';

import { routeURLs as URL } from '../../../../configs/routeURLs';

import Slider from 'react-slick';

import {
  sliderSettings,
} from '../../../../constants/sliderSettings';

import { platforms } from '../../../../constants/platforms';

import './styles/ConsolesSlider.css';
import Helpers from '../../../../utils/Helpers';
import Storage from '../../../../utils/Storage';

const wrappedPropTypes = {
  submitGameStore: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  currentPlatforms: PropTypes.array.isRequired,
};

class ConsolesSlider extends React.Component {
  state = {
    activePlatform: {
      id: '',
      type: 0,
      name: '',
    },
  }

  updateActivePlatform = (platform) => {
    if (this.state.activePlatform.id !== platform.id) {
      this.setState({
        activePlatform: platform,
      });
    }
  }

  goToSubmitGame = () => {
    const {
      submitGameStore,
    } = this.props;

    const {activePlatform} = this.state;

    const platformData = {
      platformType: activePlatform.type,
      platform: activePlatform,
    }

    submitGameStore.gameItem.updateGameItem(platformData);
    Storage.set('watagamesGameItem', platformData);
    window.location = URL.submitGame.child.stepOne.link;
    // router.push(URL.submitGame.child.stepOne.link);
  }

  render() {
    const sliderItems = this.props.currentPlatforms.filter(item => platforms.hasOwnProperty(item.id)).map(item => {
      const setActive = () => {
        this.updateActivePlatform(item);
      }
      return (
        <div
          key={item.id}
          className="consoles-slider__item">
          {
            (item.id === this.state.activePlatform.id) ? (
              <div className="consoles-slider__selected">
                <div className="square square--small square--gradient">
                  <div className="square__content">
                    <span
                      className={classnames(
                         'consoles-slider__icon',
                         `icon-${platforms[item.id].icon}`,
                         {
                           'icon-unassigned': !platforms[item.id] || !platforms[item.id].icon
                         }
                      )} />
                  </div>
                </div>
                <div className="consoles-slider__content">
                  <p className="consoles-slider__name"><b>{item.name}</b></p>
                  <button
                    onClick={this.goToSubmitGame}
                    type="button"
                    className="btn"
                    to={URL.submitGame.link}>
                    <span className="btn__text btn__text--black">
                      GET STARTED
                    </span>
                  </button>
                </div>
              </div>
            ) : (
              <button
                className="consoles-slider__item-btn"
                disabled={item.isRemoved}
                onClick={setActive}
                type="button">
                <span
                  className={classnames(
                     'consoles-slider__icon',
                     `icon-${platforms[item.id].icon}`,
                     {
                       'icon-unassigned': !platforms[item.id] || !platforms[item.id].icon
                     }
                  )} />
              </button>
            )
          }
        </div>
      );
    });

    const responsiveOptions = Helpers.updateSliderOptions(sliderSettings.consolesSlider, sliderItems);

    return (
      <div className="consoles-slider">
        <Slider
          {...responsiveOptions}
        >
          {sliderItems}
        </Slider>
      </div>
    );
  }

}


const WrappedConsolesSlider = inject('router', 'submitGameStore')(observer(ConsolesSlider));
const TranslateConsolesSlider = translate()(WrappedConsolesSlider);
TranslateConsolesSlider.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateConsolesSlider;
