import React from "react";

import { prices } from "../../../../constants/prices";
import { serviceLevels } from "../../../../constants/serviceLevels";

import "./styles/Pricing.css";

const pricingDefs = prices.serviceLevels;

const Pricing = (props) => {
  return (
    <div className="pricing" id="pricing">
      <div className="container-fluid">
        <div className="row-fluid">
          <div className="pricing__wrapper section section--gradient section--btp">
            <div className="pricing__content">
              <h2 className="pricing__title text-gradient">PRICING</h2>
              <p className="pricing__subtitle">
                Our pricing structure is simple! Whether your media is Complete in Box, Sealed, Loose, Vintage, Modern, or anything in between, just pick your turnaround time and you're good to go.
              </p>

              <div className="pricing__items">
                <div className="pricing__item--wrapper">
                  <div className="pricing__item">
                    <span className="pricing__icon text-gradient icon-sl-turbo" />
                    <h3 className="pricing__item-title">Turbo</h3>

                    <ul className="arrow-list">
                      <li className="arrow-list__item">
                        <b>{serviceLevels[1].turnaroundEstimate}</b> Business
                        Days*
                      </li>
                    </ul>

                    <ul className="pricing__list">
                      <li className="pricing__list-item">
                        <span className="pricing__price">
                          $<b>{pricingDefs.sealedLoose.base.turbo}</b>
                        </span>
                      </li>
                    </ul>

                    <ul className="pricing__list">
                      <li className="pricing__list-item">
                        <span className="pricing__price">
                          <small>
                            +&nbsp;liability<small>**</small>
                          </small>
                        </span>
                      </li>
                    </ul>

                  </div>
                </div>

                <div className="pricing__item--wrapper">
                  <div className="pricing__item">
                    <span className="pricing__icon text-gradient icon-sl-speedrun" />
                    <h3 className="pricing__item-title">Speed-Run</h3>

                    <ul className="arrow-list">
                      <li className="arrow-list__item">
                        <b>{serviceLevels[2].turnaroundEstimate}</b> Business
                        Days*
                      </li>
                    </ul>

                    <ul className="pricing__list">
                      <li className="pricing__list-item">
                        <span className="pricing__price">
                          $<b>{pricingDefs.sealedLoose.base.speedrun}</b>
                        </span>
                      </li>
                    </ul>

                    <ul className="pricing__list">
                      <li className="pricing__list-item">
                        <span className="pricing__price">
                          <small>
                            +&nbsp;liability<small>**</small>
                          </small>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="pricing__item--wrapper active">
                  <div className="pricing__item">
                    <span className="pricing__icon text-gradient icon-sl-warpzone" />
                    <h3 className="pricing__item-title">Warp-Zone</h3>

                    <ul className="arrow-list">
                      <li className="arrow-list__item">
                        <b>{serviceLevels[3].turnaroundEstimate}</b> Business
                        Days*
                      </li>
                    </ul>

                    <ul className="pricing__list">
                      <li className="pricing__list-item">
                        <span className="pricing__price">
                          $<b>{pricingDefs.sealedLoose.base.warpzone}</b>
                        </span>
                      </li>
                    </ul>

                    <ul className="pricing__list">
                      <li className="pricing__list-item">
                        <span className="pricing__price">
                          <small>
                            +&nbsp;liability<small>**</small>
                          </small>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="pricing__footer">
                <ul className="arrow-list">
                  <li className="arrow-list__item">
                    * Turnaround times are estimates only.
                  </li>
                  <li className="arrow-list__item">
                    ** Liability is included in the price of media valued below
                    $1000. Media valued from <b>$1000 to $1999</b> are charged
                    an additional{" "}<b>1% of their declared value</b>. Media valued at and
                    over <b>$2000</b> are charged an additional{" "}
                    <b>2% of their declared value</b>. If your order contains
                    undervalued media we may adjust pricing for correct
                    liability costs.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
