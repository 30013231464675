import React from 'react';
import PropTypes from 'prop-types';
import ReactAlert from 'react-s-alert';
import {Link} from 'react-router-dom';
import {inject, observer} from 'mobx-react';

import { translate } from 'react-i18next';

import Formsy from 'formsy-react';

import FormModel from '../../../models/FormModel';

import Input from '../form-elements/Input';
import Password from '../form-elements/Password';

import Logger from '../../../utils/Logger';

import AuthSession from '../../../utils/AuthSession';
import API from '../../../utils/API';
import Storage from '../../../utils/Storage';

import {
  errorMessages as err,
  alertConfig,
  userSessionRoleKey,
} from '../../../constants/common';

import { apiURLs } from '../../../configs/apiURLs';
import { routeURLs as URL } from '../../../configs/routeURLs';

import '../../../utils/FormsyCustomValidationRules';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  modalsStore: PropTypes.object.isRequired,
  cartStore: PropTypes.object.isRequired,
  savedGamesStore: PropTypes.object.isRequired,
  queueStore: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

class LogIn extends React.Component {
  constructor(props) {
    super(props);

    this.store = new FormModel({
      email: '',
      password: '',
    });
  }

  onValidSubmit = async (model, resetForm, invalidateForm) => {
    const {
      props,
      store,
    } = this;

    const {
      appStore,
      cartStore,
      savedGamesStore,
      queueStore,
      router,
      t,
    } = props;

    appStore.updateProgressData();
    store.updateLoadingStatus();

    try {
      const response = await API.postData(apiURLs.login, store.convertToJS());
      const {data} = response;

      const queueListFromStorage = Storage.get('watagamesQueueList') || {};

      const updateQueueStore = (userId) => {
        !!queueListFromStorage &&
        !!queueListFromStorage[userId] &&
        queueListFromStorage[userId].forEach(queueItem => {
          queueStore.addItemToGameList(queueItem, 'queueId');
        });
      };

      const updateStore = (responseData, store, keyID) => {
        responseData.length && responseData.forEach(item => {
          if (item.game && item.game.platforms && item.game.platforms.length > 0) {
            item.platformType = item.game.platforms[0].type;
            item.platform = item.game.platforms[0];

            store.addItemToGameList(item, keyID);
          }
        });
      };

      store.resetForm();

      store.updateLoadingStatus(false);

      updateStore(data.user.shoppingCart, cartStore, 'id');
      updateStore(data.user.savedGames, savedGamesStore, 'id');

      delete data.user.shoppingCart;
      delete data.user.savedGames;

      // update queue store

      updateQueueStore('unauth');
      updateQueueStore(data.user.id);

      const queueDataToStorage = queueStore.gameList.ids.map(id => {
        return queueStore.gameList.dataMap[id].data
      });

      queueListFromStorage[data.user.id] = queueDataToStorage;

      if (!!queueListFromStorage.unauth) {
        delete queueListFromStorage.unauth;
      }

      Storage.set('watagamesQueueList', queueListFromStorage);

      // update user store
      appStore.updateUserData(data.user);
      Storage.set(userSessionRoleKey, data.user.role);
      appStore.updateProgressData(false);
      AuthSession.set(data.token);
      ReactAlert.success(t('Welcome back, friend!'), alertConfig);
      appStore.updateSidebarStatus(false);

      if (!!Storage.get('watagamesNextRedirect')) {
        const dest = Storage.get('watagamesNextRedirect');
        console.log("login redirecting to:", dest);
        Storage.remove('watagamesNextRedirect');
        if (Storage.get('watagamesDirectNavigate')) {
          Storage.remove('watagamesDirectNavigate');
          router.push(dest);
        } else {
          window.location = dest;
        }
      } else {
        router.push(URL.dashboard.link);
      }

      this.toggleModal();

    } catch (error) {
      Logger.error(error);
      if (!!error.response) {
        const {data} = error.response;

        // invalidateForm or formRef.updateInputsWithError
        Object.keys(data).forEach(key => {
          const value = data[key];

          if (!(key in model)) return;
          // invalidate if key exist in current form model
          invalidateForm({
            [key]: Array.isArray(value) ? value.join(' ') : value,
          });
        });
      } else {
        ReactAlert.error(err.somethingWentWrong, alertConfig);
      }

      appStore.updateProgressData(false);
      store.updateLoadingStatus(false);
    }
  }

  toggleModal = () => {
    this.props.modalsStore.toggleModal('logIn');
  }

  toggleForgotModal = () => {
    this.props.modalsStore.toggleModal('forgot');
  }

  closeModalAndSidebar = () => {
    this.props.appStore.updateSidebarStatus(false);
    this.toggleModal();
  }

  render() {
    const {t} = this.props;

    const {store} = this;

    return (
      <Formsy
        className="modal-content modal-login"
        ref={component => {
          store.updateRefToForm(component);
        }}
        onValidSubmit={this.onValidSubmit}>

        <div className="modal-header">
          <button
            type="button"
            onClick={this.toggleModal}
            className="modal-close">
            <span className="icon-x" />
          </button>
          <h2 className="section__title section__title--white">
            {t('Welcome back, friend.')}
          </h2>
        </div>
        <div className="modal-body modal-form">
          <Input
            id="message"
            name="message"
            type="hidden"
          />

          <Input
            name="email"
            type="email"
            value={store.elements.get('email')}
            updateStateData={store.updateFormItem}
            id="email"
            placeholder={t('Email')}
            required
            iconClassName="icon-envelope"

            validations={{
              isEmail: true
            }}
            validationErrors={{
              isEmail: err.isEmail,
              isDefaultRequiredValue: err.isDefaultRequiredValue,
            }}>
            <label className="form-label" htmlFor="email">{t('Email')}:</label>
          </Input>

          <Password
            name="password"
            value={store.elements.get('password')}
            updateStateData={store.updateFormItem}
            id="password"
            placeholder={t('Password')}
            required
            validations={{
              minLength: 8,
              isLatinAndNumber: true,
            }}
            validationErrors={{
              minLength: err.minLength(8),
              isDefaultRequiredValue: err.isDefaultRequiredValue,
              isLatinAndNumber: err.isLatinAndNumber,
            }}>
            <label className="form-label" htmlFor="password">{t('Password')}:</label>
          </Password>

          <div className="modal-btn-wrapper">
            <button
              className="btn btn--high btn--lg btn--white"
              disabled={store.isLoading.get()}
              type="submit">
              <span className="btn__text">
                {t('Log In')}
              </span>
            </button>
            <button
              type="button"
              onClick={this.toggleForgotModal}
              className="modal-foget">
              {t('Forgetting something?')}
            </button>
          </div>
          <div className="modal-btn-wrapper">
            <h2>
              <span>{t('New to Wata?')}</span>
            </h2>
            <Link
              onClick={this.closeModalAndSidebar}
              className="btn btn--high btn--lg"
              to={URL.join.link}>
              <span className="btn__text">
                {t('Join!')}
              </span>
            </Link>
          </div>
        </div>
      </Formsy>
    );
  }
}


const WrappedLogIn = inject(
  'router',
  'appStore',
  'cartStore',
  'savedGamesStore',
  'modalsStore',
  'queueStore'
)(observer(LogIn));

const TranslateLogIn = translate()(WrappedLogIn);
TranslateLogIn.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateLogIn;
