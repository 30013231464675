import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import { translate } from 'react-i18next';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import WataScale from './WataScale/WataScale';
import SubmissionProcess from './SubmissionProcess/SubmissionProcess';
import OurTechnology from './OurTechnology/OurTechnology';
import StandardSubmissions from './StandardSubmissions/StandardSubmissions';

import { routeURLs as URL } from '../../configs/routeURLs';

import withTracker from '../shared/AnalyticsTracker/withTracker';


const propTypes = {
  t: PropTypes.func.isRequired,
};

const TITLE = 'What We Do';

class WhatWeDo extends React.Component {
  render() {
    const {t} = this.props;

    /* It makes redirect from /what-we-do to /what-we-do/submission/process */
    const redirectToSubmissionProcess = props => {
      const {from} = this.props.location.state || {
        from: { pathname: URL.whatWeDo.child.submissionProcess.link }
      }
      return (
        <Redirect to={from} />
      )
    };

    return (
      <div className="page-wrapper">
        <Helmet
          title={t(TITLE)}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />

        <Header />

        <main className="main">
          <Switch>
            <Route
              exact
              path={URL.whatWeDo.link}
              render={redirectToSubmissionProcess} />

            <Route
              exact
              path={URL.whatWeDo.child.submissionProcess.link}
              render={props => <SubmissionProcess {...props} />}
            />

            <Route
              exact
              path={URL.whatWeDo.child.pricing.link}
              render={props => <StandardSubmissions {...props} />}
            />

            <Route
              exact
              path={URL.whatWeDo.child.wataScale.link}
              render={props => <WataScale {...props} />}
            />

            <Route
              exact
              path={URL.whatWeDo.child.ourTechnology.link}
              render={props => <OurTechnology {...props} />}
            />

            {/* 404 */}
            <Redirect to={{ state: { error: true } }} />
          </Switch>
        </main>

        <Footer />
      </div>
    );
  }
}


WhatWeDo.propTypes = propTypes;
const TranslateWhatWeDo = translate()(WhatWeDo);

export default withTracker(TranslateWhatWeDo, {title: TITLE});
