import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {withFormsy} from 'formsy-react';
import ReactAlert from 'react-s-alert';

import {
  alertConfig,
} from '../../../constants/common';

const propTypes = {
  name: PropTypes.string.isRequired,

  className: PropTypes.string,
  // placeholder: PropTypes.string,
  iconClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  id: PropTypes.string,
  isPristine: PropTypes.func,
  getErrorMessage: PropTypes.func,

  uploadHandler: PropTypes.func.isRequired,

  value: PropTypes.string,
  accept: PropTypes.string,
  customValidation: PropTypes.object,
  customValidationErrors: PropTypes.object,
  updateInputsWithError: PropTypes.func,
};

const defaultProps = {
  value: '',
  iconClassName: '',
};

class File extends React.Component {
  onChange = (e) => {
    const file = e.target.files[0];
    const {props} = this;
    const {
      customValidation,
      customValidationErrors,
      uploadHandler,
      name
    } = this.props;

    const errors = [];
    if (customValidation.maxFileSize &&
      customValidation.maxFileSize * 1024 * 1024 < file.size) {
      ReactAlert.error(customValidationErrors.maxFileSize, alertConfig);
      return;
    }

    if (errors.length) {
    } else {
      props.updateInputsWithError({
        [name]: null
      });

      props.setValue(file);

      uploadHandler(name, file);
    }
  }

  render() {
    const {props} = this;
    const {isPristine, getErrorMessage} = props;
    return (
      <div
        className={classnames(
          'form-field',
          'form-field--file',
          {
            'has-error': !isPristine() ? !!getErrorMessage() : false,
          },
          props.className
        )}
      >
        {this.props.children}
        <input
          type="file"
          name={props.name}
          defaultValue={props.getValue()}
          accept={props.accept}
          onChange={this.onChange}
          id={props.id}
          className={classnames(
            'form-field__item--file',
            props.inputClassName
          )}
        />
        {!isPristine() ? <div className="help-block">{getErrorMessage()}</div> : ''}
      </div>
    );
  }
}

File.propTypes = propTypes;

File.defaultProps = defaultProps;

export default withFormsy(File);
