import React from 'react';
import {findDOMNode} from 'react-dom';

import {
  debounce,
} from 'lodash';

import {selectMeasures} from '../../../constants/common';

const withDrop = (Select) => class extends React.Component {
  state = {
    dropUp: false,
  }

  componentDidMount() {
    this.determineDropUp(this.props);
    const w = window;
    const timeout = 200;

    w.addEventListener('resize', debounce(() => {
      this.determineDropUp();
    }, timeout));

    w.addEventListener('scroll', debounce(() => {
      this.determineDropUp();
    }, timeout));
  }

  componentWillReceiveProps(nextProps) {
    this.determineDropUp(nextProps);
  }

  componentWillUnmount() {
    const w = window;
    const {determineDropUp} = this;

    w.removeEventListener('resize', determineDropUp);
    w.removeEventListener('scroll', determineDropUp);
  }

  determineDropUp = (props = {}) => {
    const {dropUp} = this.state;
    const options = props.options || this.props.options || [];
    const node = findDOMNode(this.selectInst);

    const {maxMenuHeight} = selectMeasures;
    const {averageOptionHeight} = selectMeasures;

    if (node) {
      const windowHeight = window.innerHeight;

      const menuHeight = Math.min(maxMenuHeight, (options.length * averageOptionHeight));
      const instOffsetWithMenu = node.getBoundingClientRect().bottom + menuHeight;

      if (dropUp !== (instOffsetWithMenu >= windowHeight)) {
        this.setState({
          dropUp: instOffsetWithMenu >= windowHeight
        });
      }
    }
  }

  render() {
    return <Select
      {...this.props}
      dropUp={this.state.dropUp}
      ref={(inst) => (this.selectInst = inst)} />;
  }
};

export default withDrop;
