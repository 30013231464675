import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import ReactAlert from 'react-s-alert';
import {inject, observer} from 'mobx-react';

import {translate} from 'react-i18next';

import Invader from '../../shared/Invader/Invader';

import { alertConfig } from '../../../constants/common';

import { apiURLs } from '../../../configs/apiURLs';

import API from '../../../utils/API';
import Logger from '../../../utils/Logger';

import './styles/SavedGames.css';

import GamesList from '../../shared/GamesList/GamesList';

import withTracker from '../../shared/AnalyticsTracker/withTracker';


const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  cartStore: PropTypes.object.isRequired,
  savedGamesStore: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
  modalsStore: PropTypes.object.isRequired,
};

const TITLE = 'Saved Media';

class SavedGames extends React.Component {
  state = {
    checkAll: false,
    isLoading: false,
  }

  updateData = async (listId) => {
    const {
      savedGamesStore,
    } = this.props;

    const {data} = savedGamesStore.gameList.dataMap[listId];

    const resData = {
      count: data.count,
      badge: data.badge,
      cleaning: data.cleaning,
      heavyCleaning: data.heavyCleaning,
      report: data.report,
      testing: data.testing,
      reholder: data.heholder,
      notes: data.notes,
      declaredValueType: data.declaredValueType,
      declaredValue: data.declaredValue,
      gameId: data.game.id,
      displayType: data.displayType,
      serviceLevel: data.serviceLevel,
      photoServices: data.photoServices,
      certificationNumber: data.certificationNumber,
      boardPhotos: data.boardPhotos,
      regionId: data.region.id,
      cibPlus: data.cibPlus,
      legacyHolder: data.legacyHolder,
      stickerRemoval: data.stickerRemoval,
      genDesignation: data.genDesignation,
      autographAuth: data.autographAuth,
    };


    try {
      await API.putData(apiURLs.queue.item(listId), resData);

    } catch (error) {
      Logger.error(error);
      ReactAlert.error(error.response.data.message, alertConfig);
    }
  }

  onCheck = (e) => {
    const {gameList} = this.props.savedGamesStore;

    gameList.ids.forEach(id => {
      gameList.dataMap[id].updateGameItem({
        checked: e.target.checked,
      });
    });
  }

  moveToCart = async () => {
    const {props} = this;
    const {appStore} = props;
    const {gameList} = this.props.savedGamesStore;
    let gamesToMoveIDs = [];

    gameList.ids.forEach(id => {
      if (!!gameList.dataMap[id].data.checked) {
        gamesToMoveIDs.push(id);
      };
    });

    appStore.updateProgressData();

    try {
      const data = {
        ids: gamesToMoveIDs.join(','),
        queueType: 1,
      };
      const savedGamesIdsCloned = gameList.ids.map(item => item);

      await API.postData(apiURLs.queue.move, data);

      savedGamesIdsCloned.forEach(id => {
        if (!!gameList.dataMap[id].data.checked) {
          this.props.cartStore.addItemToGameList(gameList.dataMap[id].data, 'id');
          this.props.savedGamesStore.removeItemFromGameList(id);
        };
      });
      appStore.updateProgressData(false);
    } catch (error) {
      Logger.error(error);
      ReactAlert.error(error.response.data.message, alertConfig);
      appStore.updateProgressData(false);
    }
  }

  removeItem = async () => {
    const {props} = this;
    const {
      savedGamesStore,
      appStore,
    } = props;

    appStore.updateProgressData();

    try {
      await API.deleteData(apiURLs.queue.item(savedGamesStore.activeGameListItemID));

      savedGamesStore.removeItemFromGameList(savedGamesStore.activeGameListItemID);
      savedGamesStore.updateActiveGameListItemID();
      appStore.updateProgressData(false);

      ReactAlert.success(props.t('The media was successfully deleted from the saved media list'), alertConfig);
      props.modalsStore.toggleModal('deleteItem');
    } catch (error) {
      Logger.error(error);
      ReactAlert.error(error.response.data.message, alertConfig);
      appStore.updateProgressData(false);
    }
  }

  render() {
    const {props} = this;
    const {
      t,
      savedGamesStore,
    } = props;

    return (
      <div className="dash">
        <Helmet
          title={`${t('Dashboard')} - ${t(TITLE)}`}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />

        <div className="container-fluid">
          <div className="row">

            <div className="dash__content">
              <div className="dash__section section section--top section--gradient section--btp">

                <div className="container-fluid">
                  <div className="saved-games">

                    <h1 className="section__title section__title--icon">
                      {t('We’re ready when you are.')}
                    </h1>
                    <p className="section__subtitle">
                      {t('Below is a list of media you’ve chosen from your Queue to Save For Later:')}
                    </p>
                    {
                      !!savedGamesStore.gameList.ids.length && (
                        <div className="saved-games__header">

                          <div className="saved-games__checkbox">
                            <div className="form-field form-field--checkbox">
                              <label className="form-checkbox form-checkbox--black">
                                <input
                                  type="checkbox"
                                  name="checkAll"
                                  onChange={this.onCheck}
                                  id="all"
                                  checked={savedGamesStore.checkedLength === savedGamesStore.gameList.ids.length}
                                  value={this.state.checkAll} />
                                <i className="icon-check" />
                              </label>
                            </div>
                            <label htmlFor="all" className="saved-games__label">
                              {t('SELECT ALL')}
                            </label>
                          </div>

                          <button
                            type="button"
                            disabled={!savedGamesStore.checkedLength}
                            onClick={this.moveToCart}
                            className="btn btn--high btn--lg btn--black btn--icon-right">
                            <span className="btn__text">
                              {t('Move to Cart')}
                              <i className="btn__icon icon-pix-arrow-rt" />
                            </span>
                          </button>

                        </div>
                      )
                    }

                    <div className="saved-games__content">
                      {
                        this.state.isLoading || !props.appStore.isUserLoggedIn ? (
                          <div className="loader-container">
                            <h1 className="confirmation__header text-center">
                              {t('Please wait. Loading next level...')}
                            </h1>
                            <Invader />
                          </div>
                        ) : (
                          <GamesList
                            removeItem={this.removeItem}
                            updateData={this.updateData}
                            updateActiveItemID={savedGamesStore.updateActiveGameListItemID}
                            isCheckboxesVisible={true}
                            labelsClassName="games__labels--black"
                            emptyListText={t('You haven’t any saved media yet.')}
                            data={savedGamesStore.gameList} />
                        )
                      }
                    </div>

                    <div className="saved-games__footer">
                      <ul className="arrow-list saved-games__list">
                        <li className="arrow-list__item">
                          <b>{t('Pro Tip')}</b> - {t('Running short on time? We’ve got your back. If you’re building your Queue and would like to save media for a later submission, simply select the appropriate titles in your Queue and click Save For Later.')}
                        </li>
                        <li className="arrow-list__item arrow-list__item--no-arrow">
                          {t('Return to this screen at any time to bump saved media back to your Queue when you’re ready to proceed to Checkout.')}
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

      </div>
    );
  }
}


const WrappedSavedGames = inject(
  'savedGamesStore',
  'cartStore',
  'appStore',
  'modalsStore'
)(observer(SavedGames));
const TranslateSavedGames = translate()(WrappedSavedGames);
TranslateSavedGames.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslateSavedGames, {title: TITLE});
