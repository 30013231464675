import React from "react";
import PropTypes from "prop-types";

import { translate, Trans } from "react-i18next";

import SubscribeForm from "../SubscribeForm/SubscribeForm";

import "./styles/LearnBanner.css";

const propTypes = {
  t: PropTypes.func.isRequired,
};

class LearnBanner extends React.Component {
  render() {
    return (
      <div className="learn-banner">
        <div className="container-fluid">
          <div className="row-fluid">
            <div className="learn-banner__wrapper section section--top section--gradient section--btp">
              <div className="learn-banner__content">
                <div className="learn-banner__info">
                  <div className="learn-banner__title">
                    THE
                    <img
                      src={`${process.env.PUBLIC_URL}/images/wata.png`}
                      alt="Wata"
                    />
                    WORD
                  </div>
                  <p className="learn-banner__text">
                    <b>WATA News:</b> Sign up for the latest news, exclusive
                    promo codes, market trends, rare finds, events, and much
                    more!
                  </p>
                  <SubscribeForm />
                </div>

                <div className="learn-banner__img">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/mario.png`}
                    alt="mario"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LearnBanner.propTypes = propTypes;

const TranslateLearnBanner = translate()(LearnBanner);

export default TranslateLearnBanner;
