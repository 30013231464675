export const routeURLs = {
  home: {
    link: '/',
  },
  confirmation: {
    route: '/confirmation/:code',
    link: (code) => {
      return `/confirmation/${code}`;
    },
  },
  reset: {
    route: '/reset/:resetToken',
    link: (resetToken) => {
      return `/reset/${resetToken}`;
    },
  },
  dealer: {
    link: '/dealer',
  },
  whoWeAre: {
    link: '/who-we-are',
  },
  whatWeDo: {
    link: '/what-we-do',
    child: {
      submissionProcess: {
        link: "/what-we-do/submission-process",
        title: "Submission Process"
      },
      pricing: {
        link: "/what-we-do/pricing",
        title: "Pricing"
      },
      wataScale: {
        link: "/what-we-do/wata-scale",
        title: "The WATA Scale"
      },
      ourTechnology: {
        link: "/what-we-do/our-technology",
        title: "Our Technology"
      },
    }
  },
  submitGame: {
    link: '/submit-game',
    child: {
      stepOne: {
        link: '/submit-game/step-one',
      },
      stepTwo: {
        link: '/submit-game/step-two',
      },
      stepThree: {
        link: '/submit-game/step-three',
      },
      cart: {
        link: '/submit-game/cart',
      },
      checkout: {
        link: '/submit-game/checkout',
      },
      success: {
        link: '/submit-game/success',
      },
    },
  },
  learn: {
    link: '/learn',
    child: {
      newsletter: {
        link: '/learn/newsletter',
      },
      events: {
        link: '/learn/events',
      },
      blog: {
        link: 'https://blog.watagames.com/',
      }
    }
  },
  join: {
    link: '/join',
    child: {
      usThem: {
        link: '/join/us-them',
      },
    },
  },
  dashboard: {
    link: '/dashboard',
    child: {
      orders: {
        link: '/dashboard/orders',
        child: {
          open: {
            link: '/dashboard/orders/open',
          },
          completed: {
            link: '/dashboard/orders/completed',
          },
          grades: {
            route: '/dashboard/orders/grades/:orderNum',
            link: (orderNum) => {
              return `/dashboard/orders/grades/${orderNum}`;
            },
          },
        }
      },
      savedGames: {
        link: '/dashboard/saved-games',
      },
      profile: {
        link: '/dashboard/profile',
      }
    }
  },
  faq: {
    link: '/faq',
  },
  glossary : {
    link: '/glossary',
  },
  contactUs: {
    link: '/contact-us',
  },
  legal: {
    link: '/legal',
  },
  services: {
    link: '/services',
  },
  payment: {
    link: '/payment/:orderNum/:slOrItem/:countOrAmount',
  },
  login: {
    link: '/login',
  },
  logout: {
    link: '/logout',
  },
  cert: {
    link: '/cert',
    child: {
      details: {
        route: '/cert/details/:certNum',
        link: (certNum) => {
          return `/cert/details/${certNum}`;
        },
      },
    },
  },
};
