import React from "react";
import PropTypes from 'prop-types';

import Invader from '../Invader/Invader';

const propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

class ImageLoader extends React.Component {
  state = {
    isLoading: true
  };

  componentWillReceiveProps(nextProps) {
    const currentImage = this.props.src;
    const nextImage = nextProps.src;

    if (currentImage !== nextImage) {
      this.setState({
        isLoading: true
      });
    }
  }

  setLoaderToFalse = () => {
    this.setState({
      isLoading: false
    });
  }

  render() {
    const {props} = this;

    return (
      <div className="image__container">
        <img
          src={props.src}
          onLoad={this.setLoaderToFalse}
          onError={this.setLoaderToFalse}
          className={props.imageClassName}
          alt={props.alt} />
        {
          this.state.isLoading && (
            <div className="image-loader-wrapper">
              <Invader className="image-loader" />
            </div>
          )
        }
      </div>
    );
  }
}

ImageLoader.propTypes = propTypes;

export default ImageLoader;
