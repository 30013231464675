import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {
  translate,
} from 'react-i18next';

import classnames from 'classnames';

import ImageLoader from '../../../shared/ImageLoader/ImageLoader';

import './styles/ScaleShrink.css';

const propTypes = {
  t: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  setImageToModal: PropTypes.func.isRequired,
};

const data = [
  {
    id: 0,
    name: 'A++',
    imgUrl: `${process.env.PUBLIC_URL}/images/wata-scale-a-p-p.jpg`,
    title: 'Like new condition',
    text: 'A seal that is in new or very near to perfect condition. In the condition as it was when it left the factory, this is the best condition one would hope to see in a seal. Only displays the lightest of factory processing marks, if any. Often described as “Case Fresh.”'
  },
  {
    id: 1,
    name: 'A+',
    imgUrl: `${process.env.PUBLIC_URL}/images/wata-scale-a-p.jpg`,
    title: 'Exceptional condition',
    text: 'A seal that is in near mint condition, but has a few small flaws that are not very distracting. Can have light scuffs or other small detracting marks, but no holes.'
  },
  {
    id: 2,
    name: 'A',
    imgUrl: `${process.env.PUBLIC_URL}/images/wata-scale-a.jpg`,
    title: 'Above average condition',
    text: 'A seal that exhibits some light to medium scuffing or other detracting marks, as well as no more than a few miniscule holes, usually seen at the corner edges.'
  },
  {
    id: 3,
    name: 'B+',
    imgUrl: `${process.env.PUBLIC_URL}/images/wata-scale-b-p.jpg`,
    title: 'Average condition',
    text: 'A seal that shows standard signs of wear including medium to large scuffs and small holes. Very small holes usually seen as the corner edges, or 1-2 larger holes may be seen. Scuffs are medium and covering a majority of the front and/or back.'
  },
  {
    id: 4,
    name: 'B',
    imgUrl: `${process.env.PUBLIC_URL}/images/wata-scale-b.jpg`,
    title: 'Below Average condition',
    text: 'A seal that shows more wear than average.  Scuffs are medium to heavy covering a majority of the front and/or back. Holes may be present, from small to medium size. '
  },
  {
    id: 5,
    name: 'C+',
    imgUrl: `${process.env.PUBLIC_URL}/images/wata-scale-c-p.jpg`,
    title: 'Fair condition',
    text: 'A seal that shows a single major detraction or heavy wear overall. Often characterized by a large sized hole caused by a removed sticker or plastic hang tab. Scuffing is generally heavy when present. Seal may show signs of looseness.'
  },
  {
    id: 6,
    name: 'C',
    imgUrl: `${process.env.PUBLIC_URL}/images/wata-scale-c.jpg`,
    title: 'Poor condition',
    text: 'A seal that has obvious signs of serious distress overall, or one very large flaw or missing piece. Often with multiple breaks and large holes, looseness, with pieces hanging off. This is the worst condition one could expect to see on a game seal that still qualifies as “sealed.”'
  },
]

class ScaleShrink extends React.Component{
  state = {
    selectedSeal: 0,
  }

  changeSeal = (e) => {
    this.setState({
      selectedSeal: parseInt(e.target.value, 10)
    });
  }

  getIndex = (data, id) => {
    return data.map(item => item.id).indexOf(id)
  }

  setImageToModal = () => {
    const item = data[this.getIndex(data, this.state.selectedSeal)];
    this.props.setImageToModal(item.imgUrl, `${item.name} SEAL-QUALITY`);
  }

  render() {
    const listItems = data.map(item => {
      return (
        <li key={item.id}
          className="scale-shrink__list-item">

          <label className={classnames(
              'square',
              'square--small',
              {
                'square--gradient': item.id === this.state.selectedSeal
              }
            )}>
            <div className="square__content">
              <input type="radio"
                name="square__label"
                className="scale-shrink__toggle"
                value={item.id}
                onChange={this.changeSeal}
                checked={item.id === this.state.selectedSeal}/>
              <span className="scale-shrink__label">{item.name}</span>
            </div>
          </label>

        </li>
      )
    });

    const item = data[this.getIndex(data, this.state.selectedSeal)];

    return (
      <div className="scale-shrink">
        <h3 className="section__line-through">
          <span>Our <b>Seal-Quality</b> Scale</span>
        </h3>
        <div className="scale-shrink__content">
          <div className="scale-shrink__info">
            <p className="scale-shrink__text">
              Factory sealed media receive a separate letter grade reflecting the quality and integrity of their shrink-wrap/seal. Browse below to see examples of each grade and learn more:
            </p>
            <ul className="scale-shrink__list">
              {listItems}
            </ul>
          </div>
          <div className="scale-shrink__img-wrapper">
            <div className="scale-shrink__img-caption">
              EXAMPLE: <b><span>{item.name}</span> SEAL-QUALITY</b>
            {/*
              <Tooltip
                {...tippyConfig}
                html={(
                  <div>
                    This is a tooltip text with the link to the <Link target="_blank" rel="noopener noreferrer" to={URL.join.link}>Glossary</Link> page.
                  </div>
                )}>
                <span className="icon-question-circle text-gradient scale__tooltip" />
              </Tooltip>
              */}
            </div>
            <button
              className="scale__btn-modal"
              onClick={this.setImageToModal}
              type="button">
              <ImageLoader
                src={item.imgUrl}
                imageClassName="scale-shrink__img"
                alt={`${item.name} SEAL-QUALITY`} />
            </button>
          </div>
        </div>

        <ul className="arrow-list">
          <li className="arrow-list__item">
            <b>{item.title}</b> - {item.text}
          </li>
        </ul>
      </div>
    );
  }

}


ScaleShrink.propTypes = propTypes;

const ObserverScaleShrink = observer(ScaleShrink);
const TranslateScaleShrink = translate()(ObserverScaleShrink);

export default TranslateScaleShrink;
