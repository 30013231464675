import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { translate } from 'react-i18next';
import ReactAlert from 'react-s-alert';
import {inject, observer} from 'mobx-react';

import ProfileForm from '../ProfileForm/ProfileForm';
import ProfileData from '../ProfileData/ProfileData';
import ProfileImage from '../ProfileImage/ProfileImage';

import './styles/Profile.css';
import './styles/ProfileSocial.css';

import Logger from '../../../utils/Logger';

import { alertConfig } from '../../../constants/common';

import { apiURLs } from '../../../configs/apiURLs';

import API from '../../../utils/API';

import withTracker from '../../shared/AnalyticsTracker/withTracker';


const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
};

const TITLE = 'Profile';

class Profile extends React.Component {
  state = {
    isEditable: false,
  }

  componentDidMount() {
    this.getSelectData();
  }

  getSelectData = async () => {
    const {appStore} = this.props;

    if (!!appStore.countries.length) return;

    try {
      let response = await API.getData(apiURLs.countries);

      appStore.updateCountries(response.data.items);
    } catch(error) {
      Logger.error(error);
      ReactAlert.error(
        error.hasOwnProperty('message') ? error.message : error,
        alertConfig
      );
    }
  }

  toggleEditable = (status) => {
    if (status === this.state.isEditable) return;

    this.setState({
      isEditable: status || !this.state.isEditable
    });
  }

  render() {
    const {t} = this.props;

    return (
      <div className="dash">
        <Helmet
          title={`${t('Dashboard')} - ${t(TITLE)}`}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />
        <div className="container-fluid">
          <div className="row">

            <div className="dash__content">
              <div className="dash__section section section--top section--gradient section--btp">

                <div className="profile">
                  <div className="container-fluid">
                    <div className="profile__content">
                      <ProfileImage />
                      {
                        !this.state.isEditable ? (
                          <ProfileData toggleEditable={this.toggleEditable} />
                        ) : (
                          <ProfileForm toggleEditable={this.toggleEditable} />
                        )
                      }
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

      </div>
    );
  }
}

const WrappedProfile = inject('appStore')(observer(Profile));
const TranslateProfile = translate()(WrappedProfile);
TranslateProfile.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslateProfile, {title: TITLE});
