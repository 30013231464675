import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  translate,
  Trans,
} from 'react-i18next';

import {
  parse,
  format
} from 'date-fns';

import {inject, observer} from 'mobx-react';

import { Link } from 'react-router-dom';

import Modal from 'react-modal';

import ReactToPrint from "../../../shared/ToPrint/ToPrint";


import GamesList from '../../../shared/GamesList/GamesList';
import { routeURLs as URL } from '../../../../configs/routeURLs';
import Helpers from '../../../../utils/Helpers';
import {
  modalOptions,
} from '../../../../constants/common';

import AddressForm from '../../../shared/AddressForm/AddressForm';

import './styles/OrderItem.css';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  item: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      orderNumber: PropTypes.number.isRequired,
      status: PropTypes.number.isRequired,
      detailsAvailable: PropTypes.bool.isRequired,
      deliveryService: PropTypes.number.isRequired,
      deliveryServicePrice: PropTypes.string.isRequired,
      transaction: PropTypes.shape({
        amount: PropTypes.string.isRequired,
        creditCard: PropTypes.shape({
          cardType: PropTypes.string,
          last4: PropTypes.string,
          imageUrl: PropTypes.string,
        }),
        paypal: PropTypes.shape({
          paymentId: PropTypes.string,
          type: PropTypes.string,
          imageUrl: PropTypes.string,
        }),
      }),
      metadata: PropTypes.string,
      isBadges: PropTypes.bool,
    }),
    box: PropTypes.object.isRequired,
    address: PropTypes.object.isRequired,
  }),
  toPrint: PropTypes.object,
  updateOrderIndex: PropTypes.func,
}


class OrderItem extends React.Component {
  constructor(props) {
    super(props);

    this.refToPrintButton = null;
  }

  formatDate = (date, formatTemp) => {
    return format(parse(date), formatTemp);
  }

  triggerPrint = async () => {
    if (!!this.props.updateOrderIndex){
      await this.props.updateOrderIndex(this.props.item.data.id);
    }
    this.refToPrintButton.triggerRef.click();
  }

  toggleModal = () => {
    this.props.modalsStore.toggleModal('changeAddress');
  }

  render() {
    if (this.props.item === undefined) return;

    const {
      modalsStore,
      t,
      item: {
        data,
        data: {
          isBadges,
          metadata,
        },
        box,
        address,
        statusName,
        changeOrderAddress,
      },
      mode,
      gradesBelow
    } = this.props;

    let isStaff = (this.props.appStore.user.role > 0);

    const gamesList = (isBadges) ? "" : (
      <GamesList
        verboseInfo={isStaff}
        isEditable={false}
        isAddLink={(mode === "single")}
        orderId={data.id}
        labelsClassName="games__labels--black"
        emptyListText={t('Games loading...')}
        data={box.gameList}
      />
    );

    const {
      creditCard,
      paypal,
    } = data.transaction ? data.transaction : {creditCard: null, paypal: null};

    const braintree_transaction_id = data.transaction ? data.transaction.id : 'n/a';
    const userEmail = (!!data.user) ? data.user.email : "";
    const userFullName = (!!data.user) ? data.user.fullName : "";

    let itemCount = 0;
    let priceColumnTotal = 0;

    if (!isBadges){
      box.gameList.ids.forEach( (curr) => {
          itemCount += box.gameList.dataMap[curr].data.count;
          priceColumnTotal += parseInt(box.gameList.dataMap[curr].data.normalPrice);
      });
    }


    let hideGradesButton = (isBadges || !!gradesBelow || !data.detailsAvailable)

    const viewGradesButton = (hideGradesButton) ? "" : (
      <Link
        to={URL.dashboard.child.orders.child.grades.link(data.orderNumber)}
        className="btn" style={{marginLeft:"1rem"}}>
        <span className="btn__text btn__text--white">
          {t('view grades')}
        </span>
      </Link>
    );

    let adminDueBreakdown, adminCostBreakdown, adminShipmentsBreakdown = "";

    if (isStaff){

      adminCostBreakdown = (
        <div style={{display:"block", border:"1px solid blue", padding:"2px"}}>
          <p>Admin Price Breakdown for {itemCount} media:</p>
          <div style={{clear:"both"}}>
            <span className="price-list__label">
              User:
            </span>
            <span style={{float:"right"}} className="price-list__price price-list__price--border">
                {userEmail}
            </span>
            <span style={{float:"right"}} className="price-list__price price-list__price--border">
                {userFullName}
            </span>
          </div>
          <div style={{clear:"both"}}>
            <span className="price-list__label">
              Transaction ID:
            </span>
            <span style={{float:"right"}} className="price-list__price price-list__price--border">
                {braintree_transaction_id}
            </span>
          </div>
          <div style={{clear:"both"}}>
            <span className="price-list__label">
              Price:
            </span>
            <span style={{float:"right"}} className="price-list__price price-list__price--border">
                ${Helpers.round(priceColumnTotal).toFixed(2)}
            </span>
          </div>
          <div style={{clear:"both"}}>
            <span className="price-list__label">
              {t('Shipping & Insurance')}
              {
                !!box.totalDeclaredValue && (
                  <div>
                    ({t('Dec. Value')} - ${box.totalDeclaredValue})
                  </div>
                )
              }
            </span>
            <span style={{float:"right"}} className="price-list__price price-list__price--border">
              ${Helpers.round(box.deliveryServicePrice).toFixed(2)}
            </span>
          </div>
          <div style={{clear:"both"}}>
            <span className="price-list__label">
              Price + Shipping:
            </span>
            <span style={{float:"right"}} className="price-list__price price-list__price--border">
                ${Helpers.round(
                  parseFloat(Helpers.round(box.deliveryServicePrice).toFixed(2))
                  +parseFloat(Helpers.round(priceColumnTotal).toFixed(2))
                  ).toFixed(2)}
            </span>
          </div>
        </div>
      );
      if (!isBadges && data.status > 0 && data.status !== 3){
          const crates = Array.from(
            box.gameList.ids.reduce( (acum,curr) => {
              if (!!box.gameList.dataMap[curr].data.crateItems){
                box.gameList.dataMap[curr].data.crateItems.forEach( crateItem => {
                  const crate = (acum.has(crateItem.crate.crateName))
                  ? acum.get(crateItem.crate.crateName)
                  : {
                    name: crateItem.crate.crateName,
                    due: crateItem.crate.dueDate.substring(5,10),
                    created: crateItem.crate.createdAt.substring(0,10),
                    redZone: crateItem.crate.redZone.substring(5,10),
                    orderCount: 0
                  };
                  ++crate.orderCount;
                  acum.set(crateItem.crate.crateName,crate);
                });
              } else {
                const fakeCrateName=(box.gameList.dataMap[curr].data.reholder) ? "Reholders" : "Other/none";
                const crate = (acum.has(fakeCrateName))
                ? acum.get(fakeCrateName)
                : {
                  name: fakeCrateName,
                  due: "",
                  created: "unavailable",
                  redZone: "(inaccurate)",
                  orderCount: 0
                };
                crate.orderCount += box.gameList.dataMap[curr].data.count;
                acum.set(fakeCrateName,crate);
              }
              return acum;
            },new Map())
            .values()
          ).map( crate => { return (
            <React.Fragment key={crate.name}>
              <dt>{crate.name} ({crate.orderCount})</dt>
              <dd>{crate.created} => {crate.due} or <span style={{color:"red"}}>{crate.redZone}</span></dd>
            </React.Fragment>
          )});

          adminDueBreakdown = (
            <div style={{display:"block", border:"1px solid blue", padding:"2px"}}>
              Crates and due dates:
              <dl>
                {crates}
              </dl>
            </div>
          );
      }
      if (data.shipments){
        const myShipments = data.shipments.map(n=>{ return (
          <li key={n.id}>{n.date}:{n.tracking}<br />{n.items}</li>
        )});
        adminShipmentsBreakdown = (
          <div style={{display:"block", border:"1px solid blue", padding:"2px"}}>
            <p>Shipments:</p>
            <ol>{myShipments}</ol>
          </div>
        );
      }
    }

    const printInvoice = (!this.props.toPrint || isBadges) ? "" : (
      <button
        type="button"
        onClick={this.triggerPrint}
        className="btn">
        <span className="btn__text btn__text--black">
          {t('PRINT INVOICE')}
        </span>
      </button>
    );

    const statusBar = (isBadges && metadata.description) ? (
      <div className="order-item__status-wrapper order-item__status">
        {metadata.description}
      </div>
    ) : (
      <div className="order-item__status-wrapper">
        <span className="order-item__status">
          {t('Stage')}: <b>{statusName}</b>
        </span>
        <ul className="order-item__status-list">
          <li className={classnames({
              'active': data.status === 0 || data.status === 6,
            })}>
            <span className={`order-item__status-icon ${(data.status === 6) ? 'icon-help' : 'icon-process-submit'}`} />
          </li>
          <li className={classnames({
              'active': data.status === 1,
            })}>
            <span className="order-item__status-icon icon-process-receive" />
          </li>
          <li className={classnames({
              'active': data.status === 4 || data.status === 2,
            })}>
            <span className="order-item__status-icon icon-process-grade" />
          </li>
          {
            /*
          <li className={classnames({
              'active': data.status === 3,
            })}>
            <span className="order-item__status-icon icon-process-encapsulate" />
          </li>
          <li className={classnames({
              'active': data.status === 2,
            })}>
            <span className="order-item__status-icon icon-process-qc" />
          </li>
            */
          }
          <li className={classnames({
              'active': data.status === 5 || data.status === 7,
            })}>
            <span className={`order-item__status-icon ${(data.status === 7) ? 'icon-process-ship' : 'icon-process-qc'}`} />
          </li>
        </ul>
      </div>
    );

    const shipToBlock = (isBadges) ? "" : (
      <div className="order__footer-item">
        <h3 className="order__label order__label--margin">SHIP TO:</h3>

        <ul className="arrow-list">
          <li className="arrow-list__item">
            <p><b>{address.fullName}</b></p>
            {
              !!address.businessName && (
                <p>{address.businessName}</p>
              )
            }
            {!!address.id && (
              <div>
                <pre><p>
                  {address.address}
                </p></pre>

                <p>
                  {address.city},
                  {' '}
                  {address.state}
                  {' '}
                  {address.zip},
                  {' '}
                  {!!address.country && address.country.name}
                </p>
                <p><b>{address.phone}</b></p>
              </div>
            )}
            {(!!gradesBelow && (isStaff || (data.status !== 7 && data.status !== 5))) && (
              <button className="btn" onClick={this.toggleModal} type="button">
                <span className="btn__text btn__text--black">
                  Change Address
                </span>
              </button>
            )}
          </li>
        </ul>
        {adminDueBreakdown}{adminShipmentsBreakdown}
      {!!gradesBelow && (
        <Modal
          onRequestClose={this.toggleModal}
          shouldCloseOnOverlayClick={true}
          bodyOpenClassName={modalOptions.modalBodyOpenClassName}
          className={modalOptions.modalContainer('modal--max')}
          overlayClassName={modalOptions.modalOverlayContainer()}
          parentSelector={() => document.body}
          appElement={document.getElementById('root')}
          shouldCloseOnEsc={true}
          isOpen={modalsStore.modals.changeAddress}
        >
          <button
            onClick={this.toggleModal}
            type="button"
            className="modal__btn-close">
            &times;
          </button>
          <div class="dash__content row">
            <div class="col-xs-12">
              <AddressForm
                prefill={address}
                orderNumber={data.orderNumber}
                countryLock={address.country}
                updateItemAddress={changeOrderAddress}
                afterUpdate={this.toggleModal}
              />
            </div>
          </div>
        </Modal>
      )}
      </div>
    );

    return (
      <div className="order-item">
        <input
          id={`order-item_${data.id}`}
          type="checkbox"
          className="order-item__toggle"
        />
        <label
          htmlFor={`order-item_${data.id}`}
          className="order-item__header">

          <div className="order-item__info">

            <p className="order-item__number">
              <span className="order-item__toggle-status" />
              <span className="order-item__order">
                {t('ORDER')} #<b>{data.orderNumber}</b>
              </span>
            </p>
            <p className="order-item__date align-bottom">PLACED:<br /><b>{this.formatDate(data.transaction ? data.transaction.createdAt : data.createdAt, 'M/D/YYYY')}</b></p>
            {viewGradesButton}
          </div>
          {statusBar}
        </label>

        <div className="order-item__content-wrapper">
          <div className="order-item__content">
            {gamesList}
            <div className="order__label--margin">
              {
                data.isSendTogether && (
                  <span>
                    <i
                      className="icon-check"
                      aria-hidden="true" />
                      {' '}
                      <strong>{t('The order will be sent together')}</strong>
                  </span>
                )
              }
            </div>

            <div className="order__footer">

              <div className="order__footer-info">
                {shipToBlock}
                {(!!data.transaction) && (
                  <div className="order__footer-item">
                    <h3 className="order__label order__label--margin">
                      {t('BILL TO')}:
                    </h3>

                    <ul className="arrow-list">
                      <li className="arrow-list__item">
                        <p className="order__payment">
                          <img
                            className="order__payment-icon"
                            src={!!paypal ? paypal.imageUrl : creditCard.imageUrl}
                            alt={!!paypal ? 'PayPal' : creditCard.cardType} />

                          {(!!creditCard && !!creditCard.cardType) && (
                            <span>
                              <Trans i18nKey={"Ending in"} last4={creditCard.last4}>
                                Ending in <b>{creditCard.last4}</b>
                              </Trans>
                            </span>
                          )}

                          {(!!paypal && !!paypal.paymentId) && (
                            'PayPal'
                          )}
                        </p>
                        <p>
                          <ReactToPrint
                            pageStyle={null}
                            ref={ref => this.refToPrintButton = ref}
                            trigger={() => {
                              return (
                                <button type="button" className="hidden"/>
                              )
                            }}
                            copyStyles={true}
                            content={() => this.props.toPrint}
                          />
                          {printInvoice}
                        </p>
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              <div className="order__price-info">
                {adminCostBreakdown}
                <div className="order__price-wrapper">
                  <p className="order__label">
                    {t('ORDER TOTAL')}:
                  </p>
                  <div className="order__price">
                    {/* ${box.total} */}
                    ${(box.total === '' || box.total === 'N/A') ? box.totalPrice : box.total}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

const WrappedOrderItem = inject('appStore')(observer(OrderItem));
const TranslateOrderItem = translate()(WrappedOrderItem);
TranslateOrderItem.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateOrderItem;
