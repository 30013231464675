import React from 'react';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';


import CompaniesSlider from '../CompaniesSlider/CompaniesSlider';
import ConsolesSlider from '../ConsolesSlider/ConsolesSlider';

import { routeURLs as URL } from '../../../../configs/routeURLs';

import './styles/PlatformsAndCompanies.css';

class PlatformsAndCompanies extends React.Component {
  state = {
    activeCompany: 'Nintendo',
  }

  updateActiveCompany = (company) => {
    if (this.state.activeCompany !== company) {
      this.setState({
        activeCompany: company,
      });
    }
  }

  render() {
    return (
      <div className="submission-banner">
        <div className="submission-banner__container">

          <div className="container-fluid">
            <div className="row-fluid">

              <div className="submission-banner__wrapper section section--top section--gradient section--ytr">
                <div className="submission-banner__content">

                  {
                    !!this.props.companies && (
                      <div className="submission-banner__sliders">

                        <div className="submission-banner__companies">
                          <CompaniesSlider
                            activeCompany={this.state.activeCompany}
                            updateActiveCompany={this.updateActiveCompany}
                            companies={this.props.companies} />
                        </div>
                        <div className="submission-banner__consoles">
                          {
                            this.props.companies[this.state.activeCompany] && (
                              <ConsolesSlider
                                currentPlatforms={this.props.companies[this.state.activeCompany].platforms}
                              />
                            )
                          }
                        </div>

                      </div>
                    )
                  }
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    );
  }
}

const ObserverPlatformsAndCompanies = observer(PlatformsAndCompanies);

export default ObserverPlatformsAndCompanies;
