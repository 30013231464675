import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import {Link} from 'react-router-dom';

import {inject, observer} from 'mobx-react';

import {translate} from 'react-i18next';

import AuthSession from '../../../utils/AuthSession';

import { routeURLs as URL } from '../../../configs/routeURLs';

import Storage from '../../../utils/Storage';

import './styles/Success.css';

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  submitGameStore: PropTypes.object.isRequired,
  queueStore: PropTypes.object.isRequired,
};


class Success extends React.Component {
  goToStepOne = () => {
    this.props.submitGameStore.updateCurrentStep(0);
  }

  goToCart = () => {
    const {
      appStore,
      submitGameStore,
      router,
      modalsStore,
    } = this.props;

    if (AuthSession.isTokenSet() && appStore.isUserLoggedIn) {
      submitGameStore.updateCurrentStep(0);
      router.push(URL.submitGame.child.cart.link);
    } else {
      Storage.set('watagamesNextRedirect', URL.submitGame.child.cart.link);
      Storage.set('watagamesDirectNavigate', true);
      modalsStore.toggleModal('logIn');
    }
  }

  render() {
    const {t} = this.props;

    return (
      <div className="success">
        <Helmet
          title={`${t('Submit Here')} - ${t('Success!')}`}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />
      <div className="success__content">
        <span className="success__icon icon-check-square" />

        <div className="success__title-wrapper">
          <h1 className="success__title">{t('Success!')}</h1>
          <p className="success__subtitle">
            {t('Media successfully added to Queue below! Ready for another?')}
          </p>
        </div>

      </div>


        <div className="success__footer">
          <Link
            onClick={this.goToStepOne}
            to={URL.submitGame.child.stepOne.link}
            className="btn btn--high btn--black btn--icon-left">
            <span className="btn__text">
              {t('Add another')}
              <i className="btn__icon icon-pix-arrow-lt" />
            </span>
          </Link>
          <button
            type="button"
            onClick={this.goToCart}
            disabled={!this.props.queueStore.gameList.ids.length}
            className="btn btn--high btn--black btn--icon-right">
            <span className="btn__text">
              {t('Go to Cart')}
              <i className="btn__icon icon-pix-arrow-rt" />
            </span>
          </button>
        </div>
      </div>
    );
  }
}

const WrappedSuccess = inject(
  'router',
  'appStore',
  'submitGameStore',
  'queueStore',
  'modalsStore'
)(observer(Success));
const TranslateSuccess = translate()(WrappedSuccess);
TranslateSuccess.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateSuccess;
