
import React from 'react';
import PropTypes from 'prop-types';

import {inject, observer} from 'mobx-react';
import ReactAlert from 'react-s-alert';
import {translate} from 'react-i18next';

import {
    alertConfig,
  } from '../../../constants/common';

import {
    gameStates,
} from "../../../constants/collectibleEnumsAndDefs";
  
import withTracker from '../../shared/AnalyticsTracker/withTracker';

import { apiURLs } from '../../../configs/apiURLs';
import API from '../../../utils/API';

const propTypes = {
  t: PropTypes.func.isRequired,
};

const TITLE = 'Certificate Details';


class CertDetails extends React.Component {
    constructor(props) {
        super(props);
    
        const {
          match: {
            params: {
              certNum
            }
          }
        } = props;
    
        this.fetchCertDetails(certNum);
    }

    fetchCertDetails = async (certNum) => {
        try {
          this.props.appStore.updateProgressData(true);
          const {data} = await API.getData(apiURLs.cert.details(certNum));
          if (Array.isArray(data)) { // We get an empty array if not available
            this.props.certDetailsStore.addCertDetails(certNum, {});
          } else {
              this.props.certDetailsStore.addCertDetails(certNum, data);
          }
          this.props.appStore.updateProgressData(false);
        } catch (error) {
          this.props.certDetailsStore.addCertDetails(certNum, {});
          this.props.appStore.updateProgressData(false);
        }
    }

    photos = (certDetails, type, title) => {
        const photos = certDetails.attachments.filter(p => !p.removed && p.attachmentTypeId == type);
        if (photos?.length > 0) {
            return <div>
                <br />
                <h2 className="section__title">{title}</h2>
                {photos.map((photo, index) => {
                    return <div key={index}>
                        <img className='cert__details_img' src={photo.url} />
                    </div>
                })}
            </div>;
        }
        return <></>;
    }

    formatVariant = (variant) => {
      let type = "Variant";
      let value = variant;
      const colonLoc = variant.indexOf(":");
      if (colonLoc !== -1){
        type = variant.substring(0, colonLoc);
        value = variant.substring(colonLoc + 1);
      }
      value = value.trim();
      if (value.length > 0 && value[0] == '*') {
        value = value.substring(1);
      }
      return <><th>{type}</th><td>{value}</td></>
    }

    formatNotes = (notes) => {
      // break note into lines
      const lines = notes.split("\n");
      return lines.map((line, index) => {
        if (line.length == 0) {
          return <br key={index} />;
        }
        if (line.length > 0 && line[0] == '*') {
          line = line.substring(1);
        }
        return <p key={index}>{line}</p>
      });
    }
    
    render() {
        const certNum = this.props.match.params.certNum;
        const certDetails = this.props.certDetailsStore.getCertDetails(certNum);

        return (
        <div>
            {certDetails && 
            <div>
                {certDetails.label && <>
                  <h2 className="section__title">Item Details</h2>
                  {!certDetails.certificateIssued && (<>
                        {(certDetails.grade?.role == 91 || certDetails.grade?.role == 95) ?
                        <p className="section__subtitle">
                          The certification number provided, {certDetails.label}, was at one point removed from the original WATA holder and is no longer an active/valid certification.
                          If you believe this message to be an error, please contact info@watagames.com.</p>
                        : 
                        <p className="section__subtitle">The certification number provided, {certDetails.label}, was intentionally DEACTIVATED in the WATA database.
                        If you believe the message to be an error, please try double checking your entry for accuracy.
                        If the entry is correct and you require assistance, please email front and back images of the item to info@watagames.com.</p>}
                    </>)}
                  {certDetails.certificateIssued && (
                  <table className='cert__details_table'>
                    <tbody>
                      <tr>
                        <th>Certification Number</th><td>{certDetails.label}</td>
                      </tr><tr>
                        <th>State</th><td>{gameStates.filter(s => s.id == certDetails.gameState)[0].name}</td>
                      </tr><tr>
                        <th>System</th><td>{certDetails.game?.platforms}</td>
                      </tr><tr>
                        <th>Country of Release</th><td>{certDetails.region}</td>
                      </tr><tr>
                        <th>Year</th><td>{certDetails.game?.year}</td>
                      </tr><tr>
                        <th>Title</th><td>{certDetails.game?.name}</td>
                      </tr><tr>
                        <th>Publisher</th><td>{certDetails.game?.publisher}</td>
                      </tr>
                      {certDetails.certificateIssued && (<>
                        {certDetails.overallGrade && <tr><th>Overall Grade</th><td>{certDetails.overallGrade}</td></tr>}
                        {certDetails.grade?.box && <tr><th>Box</th><td>{certDetails.grade.box}</td></tr>}
                        {certDetails.grade?.instruction && <tr><th>Instruction</th><td>{certDetails.grade.instruction}</td></tr>}
                        {certDetails.grade?.seal && <tr><th>Seal</th><td>{certDetails.grade.seal}</td></tr>}
                        {certDetails.grade?.cartridge && <tr><th>Cartridge</th><td>{certDetails.grade.cartridge}</td></tr>}
                        {certDetails.grade?.notes && <tr><th>Notes</th><td>{this.formatNotes(certDetails.grade.notes)}</td></tr>}
                        {certDetails.grade?.variants?.length > 0 && <>
                          {certDetails.grade.variants.map((variant, index) => {
                              return <tr key={index}>
                                  {this.formatVariant(variant)}
                              </tr>
                          })}
                        </>}
                      </>)}
                    </tbody>
                  </table>)}

                  {certDetails.certificateIssued && (<>
                    {this.photos(certDetails, 5, "Pre-Grading Photo")}
                    {this.photos(certDetails, 10, "Board Photo")}
                    {this.photos(certDetails, 15, "Post-Grading Photo")}
                    </>)}
                </>}
                {!certDetails.label && <p className="section__subtitle">
                  We're sorry. The certification number provided was not found in the database.
                  Try double checking your entry for accuracy. If the problem persists and you require assistance,
                  please email front and back images of the item to info@watagames.com.
                  </p>}
            </div>}
        </div>
        );
  }
}

CertDetails.propTypes = propTypes;

const WrappedCertDetails = inject(
    'appStore',
    'certDetailsStore',
  )(observer(CertDetails));
  
const TranslateCertDetails = translate()(WrappedCertDetails);

export default withTracker(TranslateCertDetails, {title: TITLE});
