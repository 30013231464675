import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  translate,
} from 'react-i18next';

import {inject, observer} from 'mobx-react';
import Helpers from '../../../../../utils/Helpers';

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  status: PropTypes.string,
  title: PropTypes.string,
};

const defaultProps = {
  data: [],
}


class OrderInserts extends React.Component {
  render() {
    const {
      t,
      data,
      className,
      status,
      title,
    } = this.props;

    // escape if there's no data array/iterable with map and items
    if (Helpers.isNil(data) || data.length < 1) return null;

    const presentableList = (data[0].hasOwnProperty("id")) ?
      data.map(insert => (
                    <div
                      key={`${insert.name || insert.text}_${insert.id}`}
                      className="order-item__insert row">
                      <div className={
                        classnames(
                          'order-item__insert-name',
                          {
                            'col-xs-8': !Helpers.isNil(insert.score),
                            'col-xs-12': Helpers.isNil(insert.score),
                          },
                        )}
                      >
                        {insert.name || insert.text}
                      </div>
                      {
                        !Helpers.isNil(insert.score) && (
                          <div className="order-item__insert-score col-xs-4 text-right">
                            {insert.score}
                          </div>
                        )
                      }
                    </div>
                  ))
    : data.map((entry,i) => (
        <div key={"entry-" + i} className="">
          {entry}
        </div>
      ));


    return (
      <div className={
        classnames(
          'order-item order-photos-list',
          className
        )}
      >

        <div className="order-item__header order-photos__header">
          <b>{title}</b>
          {
            <span>{t(status)}</span>
          }
        </div>
        {presentableList}
      </div>
    );
  }
}

const WrappedOrderInserts = inject('appStore', 'orderDetailsStore', 'modalsStore')(observer(OrderInserts));
const TranslateOrderInserts = translate()(WrappedOrderInserts);
TranslateOrderInserts.wrappedComponent.propTypes = wrappedPropTypes;
TranslateOrderInserts.wrappedComponent.defaultProps = defaultProps;

export default TranslateOrderInserts;
