import uuid from 'uuid-v4';
import {observable, action, toJS} from 'mobx';

class FormModel {
  id = `FormStore-${uuid()}`;

  constructor(formElements) {
    this.formElements = Object.assign({}, formElements);

    this.formIsInValid = observable.box(true);
    this.elements = observable.map();
    this.refToForm = observable.box();
    this.isLoading = observable.box(false);

    this.update(this.formElements);
  }

  // actions

  update = action('update a form data', (newData) => {
    Object.keys(newData).forEach((key) => {
      this.updateFormItem(key, newData[key]);
    });
  });

  updateFormItem = action('update a form item', (key, value) => {
    if (!!this.refToForm.updateInputsWithError &&
      ('message' in this.refToForm.getModel())) {
      this.refToForm.updateInputsWithError({
        message: null,
      });
    }
    if (this.elements.get(key) !== value) {
      this.elements.set(key, value);
    }
  });

  updateCountryFormItem = (key, value) => {
    const newData = {
      [key]: value,
      state: '',
      city: '',
    };

    this.update(newData);
  };

  updateStateFormItem = (key, value) => {
    const newData = {
      [key]: value,
      city: '',
    };

    this.update(newData);
  };

  resetForm = action('reset form', () => {
    Object.keys(this.formElements).forEach(async (key) => {
      await this.updateFormItem(key, this.formElements[key]);

      if (!!this.refToForm && !!this.refToForm.updateInputsWithError) {
        this.refToForm.updateInputsWithError({
          [key]: ''
        });
        this.refToForm.reset();
      }
    });
  });

  updateRefToForm = action('update ref to form', (ref) => {
    this.refToForm = ref;
  });

  updateLoadingStatus = action('update form loading status', (status = true) => {
    if (this.isLoading.get() !== status) {
      this.isLoading.set(status);
    }
  });

  setFormAsValid = action('update validity of the form', () => {
    if (this.formIsInValid.get()) {
      this.formIsInValid.set(false);
    }
  });

  setFormAsInValid = action('update validity of the form', () => {
    if (!this.formIsInValid.get()) {
      this.formIsInValid.set(true);
    }
  });

  // helpers
  convertToJS() {
    return toJS(this.elements);
  }
}


export default FormModel;
