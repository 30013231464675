import React from 'react';
import PropTypes from 'prop-types';

import Bragging from './Bragging/Bragging';
import Size from './Size/Size';
import LegacySize from './LegacySize/LegacySize';

import {inject, observer} from 'mobx-react';
import {
  translate,
} from 'react-i18next';
import Helmet from 'react-helmet';

import withTracker from '../../shared/AnalyticsTracker/withTracker';


const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
};

const TITLE = 'Our Technology';

class OurTechnology extends React.Component {
  render() {
    const {props} = this;
    const {t} = props;

    return (
      <div>
        <Helmet
          title={`${t('What We Do')} - ${t(TITLE)}`}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />
        <Bragging />
        <Size />
        <LegacySize />
      </div>
    );
  }
}

const WrappedOurTechnology = inject('appStore')(observer(OurTechnology));
const TranslateOurTechnology = translate()(WrappedOurTechnology);
TranslateOurTechnology.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslateOurTechnology, {title: TITLE});
