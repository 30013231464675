import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  translate,
} from 'react-i18next';

import {inject, observer} from 'mobx-react';

const wrappedPropTypes = {
  className: PropTypes.string,
  downloadLink: PropTypes.string,
}

const defaultProps = {
  downloadLink: '',
}


class OrderReport extends React.Component {
  render() {
    const {
      t,
      status,
      downloadLink,
      className,
    } = this.props;

    return (
      <div className={
        classnames(
          'order-item order-photos-list',
          className
        )}
      >

        <div className="order-item__header order-photos__header">
          <b>{t('Grader’s Report')}</b>
          {
            !!status ? (
              <a
                href={downloadLink}
                className="order-item__link"
                download
              >
                {t('Download')}
              </a>
            ) : (
              <span>{t('In progress')}</span>
            )
          }
        </div>
      </div>
    );
  }
}

const WrappedOrderReport = inject('orderDetailsStore')(observer(OrderReport));
const TranslateOrderReport = translate()(WrappedOrderReport);
TranslateOrderReport.wrappedComponent.propTypes = wrappedPropTypes;
TranslateOrderReport.wrappedComponent.defaultProps = defaultProps;

export default TranslateOrderReport;
