import React from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';

import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import AuthRoute from '../shared/routes/AuthRoute';

import SelectGame from './SelectGame/SelectGame';
import GameDetails from './GameDetails/GameDetails';
import AddOns from './AddOns/AddOns';
import Cart from './Cart/Cart';
import Checkout from './Checkout/Checkout';
import withTracker from '../shared/AnalyticsTracker/withTracker';

import AuthSession from '../../utils/AuthSession';

import { routeURLs as URL } from '../../configs/routeURLs';


import './styles/SubmitGame.css';
import './styles/ChoiceOption.css';

const wrappedPropTypes = {
  queueStore: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
};

const TITLE = 'Submit Here';


class SubmitGame extends React.Component {
  componentWillUnmount() {
    this.props.submitGameStore.gameItem.clearGameItem();
  }

  render() {
    /* It makes redirect from /submit-game to /submit-game/step-one */
    const redirectToStepOne = props => {
      const {from} = this.props.location.state || {
        from: { pathname: URL.submitGame.child.stepOne.link }
      }
      return (
        <Redirect to={from} />
      )
    };

    return (
      <div className="page-wrapper">
        <Header className="header--gradient-ytr" />

        <main className="main">
          <Switch>
            <Route
              exact
              path={URL.submitGame.link}
              render={redirectToStepOne}
            />

            <Route
              exact
              path={URL.submitGame.child.stepOne.link}
              render={props => <SelectGame {...props} />}
            />

            <Route
              exact
              path={URL.submitGame.child.stepTwo.link}
              render={props => <GameDetails {...props} />}
            />

            <Route
              exact
              path={URL.submitGame.child.stepThree.link}
              render={props => <AddOns {...props} />}
            />

            <AuthRoute
              exact
              redirectTo={URL.submitGame.child.stepOne.link}
              authenticated={AuthSession.isTokenSet()}
              component={Cart}
              path={URL.submitGame.child.cart.link}
            />

            <AuthRoute
              exact
              redirectTo={URL.submitGame.child.stepOne.link}
              authenticated={AuthSession.isTokenSet()}
              component={Checkout}
              path={URL.submitGame.child.checkout.link}
            />

            <AuthRoute
              exact
              redirectTo={URL.submitGame.child.stepOne.link}
              authenticated={AuthSession.isTokenSet()}
              component={Checkout}
              path={URL.submitGame.child.success.link}
            />

            {/* 404 */}
            <Redirect to={{ state: { error: true } }} />
          </Switch>
        </main>

        <Footer />
      </div>
    );
  }
}

const WrappedSubmitGame = inject(
  'appStore',
  'queueStore',
  'submitGameStore'
)(observer(SubmitGame));
WrappedSubmitGame.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(WrappedSubmitGame, {title: TITLE});
