import React from 'react';
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom';
import classnames from 'classnames';

import {translate} from 'react-i18next';

import {
  parse,
  format,
  isSameDay,
} from 'date-fns';


const propTypes = {
  t: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  white: PropTypes.bool.isRequired,
};

class EventItem extends React.Component {

  formatDate = (date, formatTemp) => {
    return format(parse(date), formatTemp);
  }

  render() {
    const {
      props,
      formatDate,
    } = this;

    const {
      t,
      item,
      white,
    } = props;

    console.log(white);

    return (
      <div className="event">
        <div className="event__date-wrapper">
          <time className="event__date">
            <p className="event__date-item">
              <span className="event__number">
                {formatDate(item.start, 'DD')}
              </span>
              <span className="event__month">
                {formatDate(item.start, 'MMM')}
              </span>
            </p>
            {
              !isSameDay(parse(item.start), parse(item.end)) && (
                <p className="event__date-item">
                  <span className="event__number">
                    {formatDate(item.end, 'DD')}
                  </span>
                  <span className="event__month">
                    {formatDate(item.end, 'MMM')}
                  </span>
                </p>
              )
            }
          </time>
        </div>

        <div className="event__content">
          <div
            className={classnames(
              "event__name-wrapper",
              white && "event__column-wrapper-white",
            )}
            title={`${t('Event name')}:`}>
            <p className="event__name">
              {item.eventName}
            </p>
            <ul className="event__list">
              <li>
                {item.placeName}
              </li>
              <li>{item.city}, {item.state}</li>
            </ul>
          </div>
          <div
            className={classnames(
              "event__url-wrapper",
              white && "event__column-wrapper-white",
            )}
            title={`${t('URL')}:`}>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={item.url}
              className="event__url">
              {item.url}
            </Link>
          </div>
        </div>

        <div
          className={classnames(
            "event__accept-wrapper",
            white && "event__column-wrapper-white",
          )}
          title={t('Accepting submissions?')}>
          {
            item.submissions && <span className="event__accept icon-check-square" />
          }
        </div>
      </div>
    );
  }

}


EventItem.propTypes = propTypes;

const TranslateEventItem = translate()(EventItem);

export default TranslateEventItem;
