import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { translate } from 'react-i18next';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import ContactForm from './ContactForm/ContactForm';

import './styles/ContactUs.css';

import withTracker from '../shared/AnalyticsTracker/withTracker';

const propTypes = {
  t: PropTypes.func.isRequired,
};

const TITLE = 'Contact Us'


class ContactUs extends React.Component {
  render() {
    const {t} = this.props;
    return (
      <div className="page-wrapper">
        <Helmet
          title={t(TITLE)}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />

        <Header />

        <main className="main">
          <div className="contact-us">
            <div className="contact-us__wrapper section section--top section--gradient section--btp">
              <div className="container-fluid">
                <h2 className="section__title">
                  {t('We hope you’ll reach out!')}
                </h2>

                <div className="contact-us__content">

                  <div className="contact-us__form">
                    <iframe
                      id="JotFormIFrame-241158181904152"
                      title="WataGames Support Form"
                      onload="window.parent.scrollTo(0,0)"
                      allowtransparency="true"
                      allowfullscreen="true"
                      allow="geolocation; microphone; camera"
                      src="https://forms.collectors.com/241158181904152"
                      frameborder="0"
                      style={{minWidth:"100%", maxWidth:"100%", height:"800px", border: "none"}}
                      scrolling="no"
                    >
                    </iframe>            
                  </div>

                  <div className="contact-us__info">
                    <div className="contact-us__contact">
                      <p className="contact-us__contact-wrapper">
                        <span className="contact-us__contact-icon icon-envelope text-gradient"/>
                        <a href="mailto:info@watagames.com" className="contact-us__contact-text">info@<b>WataGames</b>.com</a>
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    );
  }
}


ContactUs.propTypes = propTypes;
const TranslateContactUs = translate()(ContactUs);

export default withTracker(TranslateContactUs, {title: TITLE});
