const Logger = {
  log(...args) {
    if (process.env.NODE_ENV !== 'production') {
      console.log(...args);
    }
    return;
  },
  info(...args) {
    if (process.env.NODE_ENV !== 'production') {
      console.info(...args);
    }
    return;
  },
  warn(...args) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(...args);
    }
    return;
  },
  error(...args) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(...args);
    }
    return;
  }
};


export default Logger;
