import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import Helmet from 'react-helmet';
import {inject, observer} from 'mobx-react';
import { Redirect } from 'react-router-dom';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import AuthSession from '../../utils/AuthSession';

import { routeURLs as URL } from '../../configs/routeURLs';

import withTracker from '../shared/AnalyticsTracker/withTracker';

const propTypes = {
  t: PropTypes.func.isRequired,
  modalsStore: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

const TITLE = 'Logout';

class Logout extends React.Component {
  state = {
    modalShown: false,
  }

  componentDidMount() {
    const {
        appStore,
        modalsStore,
        router,
      } = this.props;

      if (!AuthSession.isTokenSet() && !appStore.isUserLoggedIn) {
        router.push(URL.home.link);
    } else {
        modalsStore.toggleModal('logOut');
        this.setState({modalShown: true});
    }
  }
    
  render() {
    const {t, modalsStore, location} = this.props;

    return !this.state.modalShown || modalsStore.modals.logOut ? (
      <div className="page-wrapper">
        <Helmet
          title={t(TITLE)}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />

        <Header className="header--gradient-btp" />

        <main className="main">
          <div className="faq info-page">
            <div className="container-fluid">
              <div className="row-fluid">
                <div className="info-page__wrapper section section--top section--gradient section--btp">

                  <div className="info-page__content">
                    <h2 className="section__title">
                      {t('Logout')}
                    </h2>
                    <div style={{height: '500px'}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    ) : (
      <Redirect to={
            {
              pathname: URL.home.link,
              state: { from: location },
            }
          }
          />        
    );
  }
}

Logout.propTypes = propTypes;

const TranslateLogout = translate()(inject('router', 'appStore', 'modalsStore')(observer(Logout)));

export default withTracker(TranslateLogout, {title: TITLE});
