import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

import { Redirect } from "react-router-dom";

import ReactAlert from "react-s-alert";

import { inject, observer } from "mobx-react";

import { translate, Trans } from "react-i18next";

import Formsy from "formsy-react";

import {
  errorMessages as err,
  searchLabels,
  alertConfig,
} from "../../../constants/common";

import { prices } from "../../../constants/prices";

import { apiURLs } from "../../../configs/apiURLs";
import { routeURLs as URL } from "../../../configs/routeURLs";

import Logger from "../../../utils/Logger";

import API from "../../../utils/API";

import FormModel from "../../../models/FormModel";

import Input from "../../shared/form-elements/Input";
import InputNumber from "../../shared/form-elements/InputNumber";
import Select from "../../shared/form-elements/Select";
import Radio from "../../shared/form-elements/Radio";
import Textarea from "../../shared/form-elements/Textarea";

import Queue from "../Queue/Queue";

import "../../../utils/FormsyCustomValidationRules";

import "./styles/GameDetails.css";

import withTracker from "../../shared/AnalyticsTracker/withTracker";

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  submitGameStore: PropTypes.object.isRequired,
  queueStore: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const TITLE = "Game Details";

class GameDetailsFormModel extends FormModel {
  // <- !!!
  focusDeclaredValue = (key, value) => {
    const newData = {
      declaredValueType: 6,
    };

    this.update(newData);
  };
}

class GameDetails extends React.Component {
  constructor(props) {
    super(props);

    this.store = new GameDetailsFormModel({
      region: "",
      count: "",
      notes: "",
      gameState: "",
      declaredValue: "",
      declaredValueType: 0,
    });
  }

  componentDidMount() {
    this.getSelectData();
    this.updateFormState();
  }

  componentWillReceiveProps(props) {
    this.updateFormState();
  }

  updateFormState = () => {
    const { gameItem } = this.props.submitGameStore;

    const firstAvailableGameState = gameItem.gameStateAvailability.indexOf(
      true
    );

    this.store.update({
      region: gameItem.data.region,
      count: gameItem.data.count,
      notes: gameItem.data.notes,
      gameState: gameItem.data.gameState || firstAvailableGameState,
      declaredValueType: gameItem.data.declaredValueType,
      declaredValue: gameItem.data.declaredValue,
    });
  };

  getSelectData = async () => {
    const { appStore } = this.props;

    if (!!appStore.countries.length) return;

    try {
      let response = await API.getData(apiURLs.countries);

      appStore.updateCountries(response.data.items);
    } catch (error) {
      Logger.error(error);
      ReactAlert.error(
        error.hasOwnProperty("message") ? error.message : error,
        alertConfig
      );
    }
  };

  onValidSubmit = (model, resetForm, invalidateForm) => {
    const { elements } = this.store;
    const { submitGameStore, router } = this.props;

    submitGameStore.gameItem.updateGameItem({
      region: elements.get("region"),
      count: elements.get("count"),
      notes: elements.get("notes"),
      gameState: elements.get("gameState"),
      declaredValueType: elements.get("declaredValueType"),
      declaredValue: elements.get("declaredValue"),
    });

    submitGameStore.updateCurrentStep(2);
    router.push(URL.submitGame.child.stepThree.link);
  };

  goBack = () => {
    const { submitGameStore, router } = this.props;

    submitGameStore.updateCurrentStep(0);
    router.push(URL.submitGame.child.stepOne.link);
  };

  render() {
    const { props, store } = this;
    const { t } = props;
    const { elements } = store;
    const { gameItem } = props.submitGameStore;
    const isdeclaredValueRequired = elements.get("declaredValueType") === 6;
    const declaredValueValidation = isdeclaredValueRequired
      ? {
          isMoreThan: 500,
          isLessThan: 100000,
        }
      : {};

    const { from } = props.location.state || {
      from: {
        pathname: URL.submitGame.child.stepOne.link,
      },
    };

    if (props.submitGameStore.currentStep !== 1) {
      return <Redirect to={from} />;
    }

    let currDecValueMin = 0;
    const decValueBasicOptions = prices
      .getDeclaredValueOptionsArray()
      .map((opt, i) => {
        const rangeText = currDecValueMin + "-" + opt;
        const ret = (
          <li
            className="choice-option__item"
            key={"decValueRadioOpt" + rangeText}
          >
            <Radio
              id={"declaredValueType_" + rangeText}
              checked={elements.get("declaredValueType") === i}
              value={i}
              updateStateData={store.updateFormItem}
              className="choice-option__radio"
              isIndicatorIcon={false}
              name="declaredValueType"
            />
            <label
              htmlFor={"declaredValueType_" + rangeText}
              className={classnames("choice-option__content", {
                checked: elements.get("declaredValueType") === i,
              })}
            >
              <p className="choice-option__text">${rangeText}</p>
            </label>
          </li>
        );
        currDecValueMin = opt + 1;
        return ret;
      });
    let helperTxt, hideGameState, submissionTypeName;
    if (gameItem.data.submissionType === "prototype") {
      submissionTypeName = "Prototype";
      hideGameState = true;
      helperTxt = (
        <React.Fragment>
          Please note that prototype submissions require significantly more time to process than standard submissions. We strive to process your prototype items in an estimated 120 business days, but please allow for more time if necessary.
        </React.Fragment>
      );
    } else {
      submissionTypeName = "Standard";
      hideGameState = false;
      helperTxt = (
        <React.Fragment>
          Is your media Sealed in its original shrinkwrap? Or unwrapped but
          Complete In Box (CIB)? Or maybe it’s just a killer loose cartridge?
          Let us know your Media State and its declared value as accurately as
          you can determine.
        </React.Fragment>
      );
    }

    return (
      <div className="submit-game">
        <div className="container-fluid">
          <div className="row-fluid">
            <div className="submit-game__content">
              <Helmet
                title={`${t("Submit Here")} - ${t(TITLE)}`}
                meta={[
                  {
                    name: "description",
                    content: "",
                  },
                ]}
              />
              <div className="submit-game__section section section--top section--gradient section--ytr">
                <div className="game-details">
                  <h1 className="section__title">
                    {t("Help us with the details")}:
                  </h1>
                  <p className="section__subtitle">{helperTxt}</p>
                  <Formsy
                    className="container-fluid"
                    ref={(component) => {
                      store.updateRefToForm(component);
                    }}
                    onValidSubmit={this.onValidSubmit}
                  >
                    <div className="row">
                      <div
                        className={
                          "col-xs-12 col-md-2 submit-game__item" +
                          (hideGameState ? " hidden" : "")
                        }
                      >
                        <div className="select-game__label">
                          {t("Media State")}:
                        </div>
                        <ul className="choice-option">
                          <li className="choice-option__item">
                            <Radio
                              id="gameState_sealed"
                              checked={elements.get("gameState") === 0}
                              disabled={!gameItem.gameStateAvailability[0]}
                              value="0"
                              updateStateData={store.updateFormItem}
                              className="choice-option__radio"
                              isIndicatorIcon={false}
                              name="gameState"
                            />
                            <label
                              htmlFor="gameState_sealed"
                              className={classnames("choice-option__content", {
                                disabled: !gameItem.gameStateAvailability[0],
                                checked: elements.get("gameState") === 0,
                              })}
                            >
                              <span className="choice-option__icon icon-sealed" />
                              <p className="choice-option__text">
                                {t("Sealed")}
                              </p>
                            </label>
                          </li>

                          <li className="choice-option__item">
                            <Radio
                              id="gameState_cib"
                              checked={elements.get("gameState") === 1}
                              disabled={!gameItem.gameStateAvailability[1]}
                              value="1"
                              updateStateData={store.updateFormItem}
                              className="choice-option__radio"
                              isIndicatorIcon={false}
                              name="gameState"
                            />
                            <label
                              htmlFor="gameState_cib"
                              className={classnames("choice-option__content", {
                                disabled: !gameItem.gameStateAvailability[1],
                                checked: elements.get("gameState") === 1,
                              })}
                            >
                              <span className="choice-option__icon icon-cib" />
                              <p className="choice-option__text">{t("CIB")}</p>
                            </label>
                          </li>

                          <li className="choice-option__item">
                            <Radio
                              id="gameState_loose-cart"
                              checked={elements.get("gameState") === 2}
                              disabled={!gameItem.gameStateAvailability[2]}
                              value="2"
                              updateStateData={store.updateFormItem}
                              className="choice-option__radio"
                              isIndicatorIcon={false}
                              name="gameState"
                            />
                            <label
                              htmlFor="gameState_loose-cart"
                              className={classnames("choice-option__content", {
                                disabled: !gameItem.gameStateAvailability[2],
                                checked: elements.get("gameState") === 2,
                              })}
                            >
                              <span className="choice-option__icon icon-loose-cart" />
                              <p className="choice-option__text">
                                {t("Loose Cart")}
                              </p>
                            </label>
                          </li>
                        </ul>
                      </div>

                      <div className="col-xs-12 col-md-3 submit-game__item">
                        <div className="select-game__label">
                          {t("Declared Value")}:
                        </div>
                        <ul className="choice-option choice-option__text">
                          {decValueBasicOptions}

                          <li className="choice-option__item choice-option__item--border flex-none">
                            <Radio
                              id="declaredValueType_1000"
                              checked={elements.get("declaredValueType") === 6}
                              value="6"
                              updateStateData={store.updateFormItem}
                              className="choice-option__radio"
                              isIndicatorIcon={false}
                              name="declaredValueType"
                            />

                            <label>
                              <Input
                                id="other_range"
                                name="declaredValue"
                                type="number"
                                placeholder={t("$1000+ (specify)")}
                                value={elements.get("declaredValue")}
                                onFocus={store.focusDeclaredValue}
                                updateStateData={store.updateFormItem}
                                inputClassName={classnames({
                                  "form-field__item--is-filled": isdeclaredValueRequired,
                                })}
                                required={isdeclaredValueRequired}
                                validations={declaredValueValidation}
                                validationErrors={{
                                  isMoreThan: err.isMoreThan(999),
                                  isLessThan:
                                    "If you have media over $100,000 in declared value that you would like to submit, please contact WATA Games.",
                                  isDefaultRequiredValue:
                                    err.isDefaultRequiredValue,
                                }}
                                className="form-field--no-margin"
                              />
                            </label>
                          </li>
                        </ul>
                      </div>

                      <div
                        className={
                          "col-xs-12 submit-game__item " +
                          (hideGameState ? "col-md-9" : "col-md-7")
                        }
                      >
                        <div className="select-game__label">
                          {t("Media Details")}:
                        </div>
                        <div className="game-details__content">
                          <div className="game-details__game">
                            <div className="game-details__settings-item game-details__country">
                              <span className="game-details__notes-label">
                                Type:
                              </span>
                              {submissionTypeName}
                            </div>
                            <div className="game-details__img-wrapper">
                              {!!gameItem.data.game.imgUrl ? (
                                <img
                                  src={gameItem.data.game.imgUrl}
                                  className="game-details__img"
                                  alt={gameItem.data.game.name}
                                />
                              ) : (
                                <i className="icon-unassigned" />
                              )}
                            </div>
                            <div className="game-details__settings">
                              <div className="game-details__settings-item game-details__count">
                                <InputNumber
                                  name="count"
                                  value={elements.get("count")}
                                  updateStateData={store.updateFormItem}
                                  id="gameDetails_count"
                                  placeholder={t("Quantity")}
                                  min={1}
                                  max={999}
                                  required
                                  validations={{
                                    isLessThan: 999,
                                    isMoreThan: 1,
                                    isInt: true,
                                  }}
                                  validationErrors={{
                                    isInt: err.isInt,
                                    isLessThan: err.isLessThan(999),
                                    isMoreThan: err.isMoreThan(1),
                                    isDefaultRequiredValue:
                                      err.isDefaultRequiredValue,
                                  }}
                                >
                                  <label
                                    className="game-details__notes-label"
                                    htmlFor="gameDetails_count"
                                  >
                                    <Trans i18nKey={"Quantity"}>Quantity</Trans>
                                    :
                                  </label>
                                </InputNumber>
                              </div>
                              <div
                                className="game-details__settings-item game-details__country"
                                style={{
                                  display: "none",
                                  visibility: "hidden",
                                }}
                              >
                                <Select
                                  name="region"
                                  id="gameDetails_region"
                                  searchPromptText={t(
                                    searchLabels.searchPromptText
                                  )}
                                  noResultsText={t(searchLabels.noResultsText)}
                                  placeholder={t("Select...")}
                                  multi={false}
                                  searchable={true}
                                  clearable={true}
                                  labelKey="name"
                                  valueKey="id"
                                  value={elements.get("region")}
                                  options={props.appStore.convertRegionsToJS()}
                                  updateStateData={store.updateFormItem}
                                >
                                  <label
                                    className="game-details__notes-label"
                                    htmlFor="gameDetails_region"
                                  >
                                    <Trans i18nKey={"Region (optional)"}>
                                      Region <span>(optional)</span>
                                    </Trans>
                                    :
                                  </label>
                                </Select>
                              </div>
                            </div>
                          </div>

                          <div className="game-details__info">
                            <h2 className="game-details__title">
                              <span>{gameItem.data.game.name}</span>
                            </h2>
                            <ul className="game-details__list">
                              <li className="game-details__list-item">
                                <span>{t("Platform")}:</span>{" "}
                                {gameItem.data.platform.name || "-"}
                              </li>
                              <li className="game-details__list-item">
                                <span>{t("Publisher")}:</span>{" "}
                                {gameItem.data.game.publisher || "-"}
                              </li>
                              <li className="game-details__list-item">
                                <span>{t("Year")}:</span>{" "}
                                {gameItem.data.game.year || "-"}
                              </li>
                            </ul>

                            <div className="game-details__notes-wrapper">
                              <label
                                htmlFor="gameDetails_notes"
                                className="game-details__notes-label"
                              >
                                <Trans
                                  i18nKey={
                                    "Notes: Requests/details for this submission"
                                  }
                                >
                                  <span>Notes:</span> Requests/details for this
                                  submission
                                </Trans>
                              </label>
                              <Textarea
                                name="notes"
                                className="game-details__comment"
                                value={elements.get("notes")}
                                updateStateData={store.updateFormItem}
                                id="gameDetails_notes"
                                placeholder={t("Notes")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="submit-game__footer horizontal-space-between">
                      <button
                        type="button"
                        onClick={this.goBack}
                        className="btn btn--high btn--lg btn--black btn--icon-left"
                      >
                        <span className="btn__text">
                          {t("Back")}
                          <i className="btn__icon icon-pix-arrow-lt" />
                        </span>
                      </button>
                      <button
                        type="submit"
                        disabled={!elements.get("count")}
                        className="btn btn--high btn--lg btn--black btn--icon-right"
                      >
                        <span className="btn__text">
                          {t("Continue")}
                          <i className="btn__icon icon-pix-arrow-rt" />
                        </span>
                      </button>
                    </div>
                  </Formsy>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!!props.queueStore.gameList.ids.length && (
          <Queue data={props.queueStore.gameList} />
        )}
      </div>
    );
  }
}

const WrappedGameDetails = inject(
  "router",
  "appStore",
  "queueStore",
  "submitGameStore"
)(observer(GameDetails));
const TranslateGameDetails = translate()(WrappedGameDetails);
TranslateGameDetails.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslateGameDetails, { title: TITLE });
