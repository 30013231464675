import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  name: PropTypes.string.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  options: PropTypes.array,
  updateValue: PropTypes.func.isRequired,
  infinite: PropTypes.bool,
};

const defaultProps = {
  min: 0,
}


class Switcher extends React.Component {
  up = () => {
    const {props} = this;

    if (!props.max || props.value < props.max) {
      const value = typeof props.value === 'string'
        ? parseInt(props.value, 10)
        : props.value;

      let nextValue = value + 1;
      let index;
      let optionsValue;

      if (!!props.options) {
        index = props.options.map(e => e.id).indexOf(value) + 1;
        optionsValue = (index >= 0) ? props.options[index].id : undefined;
        let altValue = props.options.hasOwnProperty(props.max) ? props.options[props.max].id : undefined;

        nextValue = optionsValue || altValue;
      }
      if (typeof nextValue !== "undefined"){
        props.updateValue(props.name, nextValue);
      }
    }
  }

  down = () => {
    const {props} = this;


    if (props.value > props.min) {
      const value = typeof props.value === 'string'
        ? parseInt(props.value, 10)
        : props.value;

      let nextValue = value - 1;
      let index;
      let optionsValue;

      if (!!props.options) {
        index = props.options.map(e => e.id).indexOf(value) - 1;
        optionsValue = (index >= 0) ? props.options[index].id : undefined;
        let altValue = props.options.hasOwnProperty(props.min) ? props.options[props.min].id : undefined;

        nextValue = optionsValue || altValue;
      }
      if (typeof nextValue !== "undefined"){
        props.updateValue(props.name, nextValue);
      }
    }

  }

  render() {
    const { props: { min, max, value } } = this;
    const extraUpStyles   = (value === max) ? {color:"grey",cursor:"default"} : {};
    const extraDownStyles = (value === min) ? {color:"grey",cursor:"default"} : {};
    return (
      <div className="switcher text-center">

        <button
          style={extraUpStyles}
          type="button"
          onClick={this.up}
          className="games__select-nav games__select-next">
          <i className="icon-caret-up" />
        </button>

        {this.props.children}

        <button
          style={extraDownStyles}
          type="button"
          onClick={this.down}
          className="games__select-nav games__select-next">
          <i className="icon-caret-down" />
        </button>

      </div>
    );
  }
}

Switcher.propTypes = propTypes;
Switcher.defaultProps = defaultProps;

export default Switcher;
