import React from 'react';
import Helmet from 'react-helmet';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import TopSlider from './TopSlider/TopSlider';
import Features from './Features/Features';
import Process from './Process/Process';
import Case from './Case/Case';
import LogoSlider from './LogoSlider/LogoSlider';

import ReactAlert from 'react-s-alert';
import {
  alertConfig,
} from '../../constants/common';

import { apiURLs } from '../../configs/apiURLs';

import API from '../../utils/API';

import Logger from '../../utils/Logger';

import withTracker from '../shared/AnalyticsTracker/withTracker';


import '../shared/styles/pageWrapper.css';
import './styles/Home.css';

const wrappedPropTypes = {
  modalsStore: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const TITLE = 'Home page';

class HomePage extends React.Component {
  componentDidMount() {
    this.fetchHomepageData();

    const script = document.createElement("script");

    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;

    document.body.appendChild(script);
  }

  state = {
    mainSlider: [],
  }

  fetchHomepageData = async () => {
    const { appStore } = this.props;
    appStore.updateProgressData();
    try {
      const response = await API.getData(apiURLs.cms.any('home'));
      const { data } = response;

      this.setState({
        mainSlider: (data.value && data.value.mainSlider && data.value.mainSlider.length) ? data.value.mainSlider: [],
      });

      appStore.updateProgressData(false);

    } catch (error) {
      Logger.error(error);
      ReactAlert.error(error.message, alertConfig);
      appStore.updateProgressData(false);
    }
  }

  toggleLogInModal = () => {
    this.props.modalsStore.toggleModal('logIn');
  }

  toggleForgotModal = () => {
    this.props.modalsStore.toggleModal('forgot');
  }

  render() {
    const { t } = this.props;

    const twitterHtml = `
    
  `

    return (
      <div className="page-wrapper">
        <Helmet
          title={t(TITLE)}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />

        <Header />

        <main className="main">
          <TopSlider
            data={this.state.mainSlider}
          />
          <Features />
          <Process />
          <Case />
          <div className="process">
            <div className="container-fluid process_wrapper">
              <div className="section">
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <div style={{width: '800px'}}>
                    <a class="twitter-timeline" data-height="1400px" data-theme="dark" href="https://twitter.com/TheWataGames?ref_src=twsrc%5Etfw">Tweets by TheWataGames</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LogoSlider />
        </main>

        <Footer />
      </div>
    );
  }
}

const WrappedHomePage = inject('modalsStore', 'appStore')(observer(HomePage));
WrappedHomePage.wrappedComponent.propTypes = wrappedPropTypes;
const TranslateHomePage = translate()(WrappedHomePage);

export default withTracker(TranslateHomePage, {title: TITLE});
