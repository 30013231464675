import React from 'react';
import PropTypes from 'prop-types';

import {
  translate,
  // Trans,
} from 'react-i18next';

import {inject, observer} from 'mobx-react';
import ReactAlert from 'react-s-alert';
import Modal from 'react-modal';

import { apiURLs } from '../../../../configs/apiURLs';
import API from '../../../../utils/API';
import Logger from '../../../../utils/Logger';

import {
  alertConfig,
  modalOptions,
} from '../../../../constants/common';

import PrintSummary from '../../../shared/PrintSummary/PrintSummary';

import OrderItem from '../OrderItem/OrderItem';

import LabelDetails from './LabelDetails/LabelDetails';

import './styles/OrderDetails.css';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
}


class OrderGrades extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: {
          orderNum
        }
      }
    } = props;

    this.fetchOrderGrades(orderNum);
  }

  getInfoFrom = async (url) => {
    const response = await API.getData(url);
    //TODO don't assume response is healthy
    const {data} = response;
    return data;
  };

  fetchOrderGrades = async (orderNum) => {
    const { orderDetailsStore } = this.props;
    try {
      this.props.appStore.updateProgressData(true);
      // Two requests is not perfectly efficient, but the alternative is get details then a request for each crate item (e.g. 2+)
      const orderItem = await this.getInfoFrom(apiURLs.order.details(orderNum));
      const labels    = await this.getInfoFrom(apiURLs.order.grades(orderNum));
      orderDetailsStore.addOrderItem(orderItem);
      orderDetailsStore.addLabels(orderNum,labels);
      this.props.appStore.updateProgressData(false);
    } catch (error) {
      Logger.error(error);
      ReactAlert.error(error.message, alertConfig);
      this.props.appStore.updateProgressData(false);
    }
  }

  toggleModal = () => {
    this.props.modalsStore.toggleModal('scaleImage');
  }

  render() {
    // escape if the store hasn't gotten loaded
    if (this.props.orderDetailsStore.lastOrderNum === '' || this.props.appStore.progressData) return null;

    const {
      modalsStore,
      orderDetailsStore: {
        orderItem,
        //crateItems,
        lastOrderNum,
        getLabels,
        imageToModal,
        imageToModalAlt,
      },
    } = this.props;

    let labelsList = getLabels(lastOrderNum).map( item => (
          <LabelDetails
            key={item.label}
            collectible={item}
            order={orderItem}
            orderId={orderItem.data.id}
            itemId={item.id}
          />
    ));

    if (labelsList.length === 0 && false === orderItem.isBadges){
        labelsList = (
          <h6>No grades available... yet. Check back soon!</h6>
        );
    }

    const orderSummary = (lastOrderNum.split('-').length > 1) ? "" : (
      <OrderItem
        item={orderItem}
        modalsStore={modalsStore}
        mode="single"
        toPrint={this.toPrint}
        gradesBelow={true}
      />
    );

    return (
      <div className="order-details">
        <div className="order-summary order-item--margin">{orderSummary}</div>
        <div className="order-details-list">
          {labelsList}
        </div>
        <Modal
          onRequestClose={this.toggleModal}
          shouldCloseOnOverlayClick={true}
          bodyOpenClassName={modalOptions.modalBodyOpenClassName}
          className={modalOptions.modalContainer('modal--max')}
          overlayClassName={modalOptions.modalOverlayContainer()}
          parentSelector={() => document.body}
          appElement={document.getElementById('root')}
          shouldCloseOnEsc={true}
          isOpen={modalsStore.modals.scaleImage}
        >
          <button
            onClick={this.toggleModal}
            type="button"
            className="modal__btn-close">
            &times;
          </button>
          {
            !!imageToModal && (
              <div className="modal-body">
                <img
                  src={imageToModal}
                  className="scale-content__item-img"
                  alt={imageToModalAlt}
                />
              </div>
            )
          }
        </Modal>
        <div className="summary-printable">
          <PrintSummary
            orderAmount={orderItem.data.amount}
            orderTotalDiscount={orderItem.data.totalDiscount}
            orderDeliveryServicePrice={orderItem.data.deliveryServicePrice}
            box={orderItem.box}
            address={orderItem.address}
            isSendTogether={orderItem.data.isSendTogether}
            orderNumber={orderItem.data.orderNumber}
            ref={el => this.toPrint = el}
          />
        </div>
      </div>
    );
  }
}

const WrappedOrderGrades = inject(
  'appStore',
  'orderDetailsStore',
  'modalsStore'
)(observer(OrderGrades));
const TranslateOrderGrades = translate()(WrappedOrderGrades);
TranslateOrderGrades.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateOrderGrades;
