import React from 'react';

import { Link } from 'react-router-dom';

import { routeURLs as URL } from '../../../../configs/routeURLs';

import './styles/Submission.css';

const Submission = (props) => {
  return (
    <div className="submission">
      <div className="container-fluid">
        <div className="row-fluid">

          <div className="submission__wrapper section section--gradient section--btp">
            <div className="submission__content">

              <div className="submission__info">
                <span className="scale-info__icon text-gradient icon-game submission__img--right"/>

                <p className="submission__text">
                  Whether your media is retro or modern, sealed or CIB, we can’t wait to grade it. We pride ourselves on having the industry-leading objective and analytical video game and media grading service, developed by our team of experts. As the industry pioneers and leaders, we provide media grading and certification services based on the <Link to={URL.whatWeDo.child.wataScale.link}>WATA scale</Link>. The best part? Our costs to grade media are accessible and affordable to all.
                </p>

              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  );
}


export default Submission;
