import React from 'react';
import { NavLink } from 'react-router-dom';

export const BrowserNavLink = ({ to, children, ...props }) => (
    <NavLink
      to={to}
      {...props}
      onClick={(e) => {
        e.preventDefault();
        window.location.href = to;
      }}>
      {children}
    </NavLink>
  );