import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Helmet from 'react-helmet';

import ReactAlert from 'react-s-alert';

import {inject, observer} from 'mobx-react';
import {translate, Trans} from 'react-i18next';

import Invader from '../../shared/Invader/Invader';

import PromoForm from '../PromoForm/PromoForm';

import { alertConfig } from '../../../constants/common';

import { apiURLs } from '../../../configs/apiURLs';
import { routeURLs as URL } from '../../../configs/routeURLs';

import API from '../../../utils/API';
import Storage from '../../../utils/Storage';
import Helpers from '../../../utils/Helpers';
import Logger from '../../../utils/Logger';

import './styles/Cart.css';

import GamesList from '../../shared/GamesList/GamesList';

import withTracker from '../../shared/AnalyticsTracker/withTracker';


const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  queueStore: PropTypes.object.isRequired,
  cartStore: PropTypes.object.isRequired,
  savedGamesStore: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
  modalsStore: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

const TITLE = 'Cart';

class Cart extends React.Component {
  state = {
    checkAll: false,
    isLoading: false,
  }

  componentDidMount() {
    this.sendDataFromQueue();
  }

  updateData = async (listId) => {
    const {
      cartStore,
    } = this.props;

    const {data} = cartStore.gameList.dataMap[listId];

    const resData = {
      count: data.count,
      badge: data.badge,
      cleaning: data.cleaning,
      heavyCleaning: data.heavyCleaning,
      report: data.report,
      testing: data.testing,
      reholder: data.reholder,
      notes: data.notes,
      declaredValueType: data.declaredValueType,
      declaredValue: data.declaredValue,
      gameId: data.game.id,
      displayType: data.displayType,
      serviceLevel: data.serviceLevel,
      photoServices: data.photoServices,
      boardPhotos: data.boardPhotos,
      certificationNumber: data.certificationNumber,
      regionId: !!data.region ? data.region.id : '',
      cibPlus: data.cibPlus,
      legacyHolder: data.legacyHolder,
      stickerRemoval: data.stickerRemoval,
      genDesignation: data.genDesignation,
      autographAuth: data.autographAuth,
    };

    try {
      await API.putData(apiURLs.queue.item(listId), resData);
    } catch (error) {
      Logger.error(error);
      ReactAlert.error(error.response.data.message, alertConfig);
    }
  }

  sendDataFromQueue = async () => {
    const {
      queueStore,
      cartStore,
    } = this.props;

    const {gameList} = queueStore;

    if (!gameList.ids.length) return;

    this.setState({
      isLoading: true,
    });

    const items = gameList.ids.map(id => {
      const {data} = gameList.dataMap[id];
      const resData = {
        count: data.count,
        badge: data.badge,
        cleaning: data.cleaning,
        heavyCleaning: data.heavyCleaning,
        report: data.report,
        testing: data.testing,
        notes: data.notes,
        declaredValueType: data.declaredValueType,
        declaredValue: data.declaredValue,
        gameState: data.gameState,
        gameId: data.game.id,
        displayType: data.displayType,
        serviceLevel: data.serviceLevel,
        photoServices: data.photoServices,
        certificationNumber: data.certificationNumber,
        boardPhotos: data.boardPhotos,
        reholder: data.reholder,
        regionId: !!data.region ? data.region.id : '',
        cibPlus: data.cibPlus,
        legacyHolder: data.legacyHolder,
        stickerRemoval: data.stickerRemoval,
        genDesignation: data.genDesignation,
        autographAuth: data.autographAuth,
      };

      return resData;
    });

    try {
      const response = await API.postData(apiURLs.queue.list, {items});

      cartStore.clearGameList();

      response.data.shoppingCart.forEach(queueItem => {
        if (queueItem.game) {
          queueItem.platform = queueItem.game.platforms[0];
          queueItem.certificationNumber = queueItem.crateItemPrototypeLabel;
          cartStore.addItemToGameList(queueItem, 'id');
        }
      });

      queueStore.clearGameList();
      Storage.remove('watagamesQueueList');

      this.setState({
        isLoading: false,
      });

    } catch (error) {
      Logger.error(error);
      if (error.response && error.response.data) {
        ReactAlert.error(error.response.data.message, alertConfig);
      } else {
        console.error(error);
      }

      this.setState({
        isLoading: false,
      });
    }
  }

  onCheck = (e) => {
    const {gameList} = this.props.cartStore;

    gameList.ids.forEach(id => {
      gameList.dataMap[id].updateGameItem({
        checked: e.target.checked,
      });
    });
  }

  goToCheckout = () => {
    const {
      cartStore,
      router,
    } = this.props;
    cartStore.updateCartStep(1);

    router.push(URL.submitGame.child.checkout.link);
  }

  saveForLater = async () => {
    const {props} = this;
    const {appStore} = props;
    const {gameList} = this.props.cartStore;
    let gamesToSaveIDs = [];

    gameList.ids.forEach(id => {
      if (!!gameList.dataMap[id].data.checked) {
        gamesToSaveIDs.push(id);
      };
    });

    appStore.updateProgressData();

    try {
      const data = {
        ids: gamesToSaveIDs.join(','),
        queueType: 0,
      };
      const cartIdsCloned = gameList.ids.map(item => item);

      await API.postData(apiURLs.queue.move, data);

      cartIdsCloned.forEach(id => {
        if (!!gameList.dataMap[id].data.checked) {
          this.props.savedGamesStore.addItemToGameList(gameList.dataMap[id].data, 'id');
          this.props.cartStore.removeItemFromGameList(id);
        };
      });

      appStore.updateProgressData(false);
    } catch (error) {
      Logger.error(error);
      ReactAlert.error(error.response.data.message, alertConfig);
      appStore.updateProgressData(false);
    }
  }

  removeItem = async () => {
    const {props} = this;
    const {
      cartStore,
      appStore,
    } = props;

    appStore.updateProgressData();

    try {
      await API.deleteData(apiURLs.queue.item(cartStore.activeGameListItemID));

      cartStore.removeItemFromGameList(cartStore.activeGameListItemID);
      cartStore.updateActiveGameListItemID();
      appStore.updateProgressData(false);

      ReactAlert.success(props.t('The media was successfully deleted from the cart'), alertConfig);
      props.modalsStore.toggleModal('deleteItem');
    } catch (error) {
      Logger.error(error);
      ReactAlert.error(error.response.data.message, alertConfig);
      appStore.updateProgressData(false);
    }
  }

  render() {
    const {props} = this;
    const {
      t,
      cartStore,
    } = props;

    const {round} = Helpers;

    const isProceedDisabled = !cartStore.gameList.ids.length;

    return (
      <div className="submit-game">
        <Helmet
          title={`${t('Submit Here')} - ${t(TITLE)}`}
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />
        <div className="container-fluid">
          <div className="row-fluid">
            <div className="submit-game__content">
              <div className="submit-game__section section section--top section--gradient section--btp">

                <div className="cart">
                  <div className="container-fluid">

                    <div className="cart__header">
                        {
                          !!cartStore.gameList.ids.length && (
                            <div className="cart__header-wrapper">

                              <div className="cart__header-checkbox">
                                <div className="form-field form-field--checkbox">
                                  <label
                                    className="form-checkbox form-checkbox--black">
                                    <input
                                      type="checkbox"
                                      name="checkAll"
                                      onChange={this.onCheck}
                                      id="all"
                                      checked={cartStore.checkedLength === cartStore.gameList.ids.length}
                                      value={this.state.checkAll} />
                                    <i className="icon-check" />
                                  </label>
                                </div>
                                <label htmlFor="all" className="cart__header-label">
                                  {t('SELECT ALL')}
                                </label>
                              </div>

                              <button
                                type="button"
                                disabled={!cartStore.checkedLength}
                                onClick={this.saveForLater}
                                className="btn btn--high btn--lg btn--black btn--icon-left">
                                <span className="btn__text">
                                  {t('Save for later')}
                                  <i className="btn__icon icon-pix-arrow-lt" />
                                </span>
                              </button>
                            </div>
                          )
                        }

                        <Link
                          className="btn btn--high btn--lg btn--black btn--icon-left"
                          to={URL.submitGame.child.stepOne.link}>
                          <span className="btn__text">
                            {t('Add one more thing')}
                            <i className="btn__icon icon-pix-arrow-lt" />
                          </span>
                        </Link>
                    </div>

                    <div className="cart__content">
                      {
                        this.state.isLoading || !props.appStore.isUserLoggedIn ? (
                          <div className="loader-container">
                            <h1 className="confirmation__header text-center">
                              {t('Please wait. Loading next level...')}
                            </h1>
                            <Invader />
                          </div>
                        ) : (
                          <GamesList
                            removeItem={this.removeItem}
                            updateData={this.updateData}
                            updateActiveItemID={cartStore.updateActiveGameListItemID}
                            isCheckboxesVisible={true}
                            labelsClassName="games__labels--black"
                            emptyListText={t('You haven’t any media in the cart yet.')}
                            data={cartStore.gameList} />
                        )
                      }
                    </div>

                    <div className="cart__footer">
                      <div className="cart__info-wrapper">
                        <div className="row">

                          <div className="col-xs-12 col-sm-6">
                            {
                              !!cartStore.gameList.ids.length && (
                                <div>
                                  <div className="cart__item">
                                    <h3 className="cart__title">
                                      {t('Discount code')}
                                    </h3>
                                    <PromoForm />
                                    {cartStore.promoNotApplicable && (
                                      <div style={{ color: 'red' }}>
                                        Promo codes not valid on orders containing prototype submissions
                                        </div>
                                    )}
                                  </div>
                                </div>
                              )
                            }
                            <ul className="cart__arrow-list arrow-list">
                              <li className="arrow-list__item">
                                <b>{t('Pro Tip')}</b> - {t('Not ready to submit all your media in your Queue? Simply select titles you’d like to hold off on and click Save For Later.')}
                              </li>
                              <li className="arrow-list__item">
                                <Trans i18nKey={"You may also freely adjust Quantity, Service Level, and Addons if something doesn’t look quite right."}>
                                  You may also freely adjust <b>Quantity,Service Level,</b> and <b>Addons</b> if something doesn’t look quite right.
                                </Trans>
                              </li>
                              <li className="arrow-list__item">
                                Prototype submissions have an estimated 120 business day turnaround time. Please keep in mind that in some cases, processing time for these orders may exceed 120 business days.
                              </li>
                            </ul>
                          </div>

                          <div className="col-xs-12 col-sm-6 col-md-offset-1 col-md-5">
                            <h3 className="cart__title">{t('ORDER SUMMARY')}:</h3>
                            <div className="cart__price-list box box--arrow-top">
                              <ul className="price-list">
                                <li className="price-list__item">

                                  <span className="price-list__label">{t('Items')}:</span>

                                  <div className="price-list__info">
                                    <span className="price-list__price">
                                      ${round(cartStore.totalServiceLevelsPrice).toFixed(2)}
                                    </span>
                                  </div>

                                </li>
                                <li className="price-list__item">

                                  <span className="price-list__label">{t('Add-ons')}:</span>

                                  <div className="price-list__info">
                                    <span className="price-list__price">
                                      ${round(cartStore.totalAddOnsPrice).toFixed(2)}
                                    </span>
                                  </div>

                                </li>
                                <li className="price-list__item">

                                  <span className="price-list__label price-list__label--border">
                                    {t('Shipping & Insurance')}:
                                    {
                                      !!cartStore.totalDeclaredValue && (
                                        <div>
                                          ({t('Declared Value')} - ${cartStore.totalDeclaredValue})
                                        </div>
                                      )
                                    }
                                  </span>

                                  <div className="price-list__info">
                                    <span className="price-list__price price-list__price--border">
                                      {t('N/A')}
                                    </span>
                                  </div>

                                </li>
                              </ul>

                              <ul className="price-list price-list--no-margin">
                                <li className="price-list__item">

                                  <span className="price-list__label price-list__label--border">
                                    {t('Discounts')}:
                                  </span>

                                  <div className="price-list__info">
                                    <span className="price-list__price price-list__price--pink price-list__price--border">
                                      -${round(cartStore.totalDiscount).toFixed(2)}
                                    </span>
                                  </div>

                                </li>
                              </ul>

                              <ul className="price-list price-list--total price-list--no-margin">
                                <li className="price-list__item">

                                  <span className="price-list__label price-list__label--total">
                                    {t('Order Total')}:
                                  </span>
                                  <div className="price-list__info">
                                    <span className="price-list__price price-list__price--total price-list__price--pink">
                                      ${round(cartStore.totalPrice).toFixed(2)}
                                    </span>
                                  </div>

                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="col-xs-12">
                            <div className="cart__footer-btn">
                              <Link
                                to={URL.dashboard.child.savedGames.link}
                                className="btn btn--high btn--lg btn--black btn--icon-left">
                                <span className="btn__text">
                                  {t('Go to saved media')}
                                  <i className="btn__icon icon-pix-arrow-lt" />
                                </span>
                              </Link>
                              <button
                                type="button"
                                onClick={this.goToCheckout}
                                disabled={isProceedDisabled}
                                className="btn btn--high btn--lg btn--icon-right">
                                <span className="btn__text btn__text--black">
                                  {t('Proceed')}
                                  <i className="btn__icon icon-pix-arrow-rt" />
                                </span>
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>   {/* cart__footer */}

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedCart = inject(
  'router',
  'queueStore',
  'savedGamesStore',
  'cartStore',
  'appStore',
  'modalsStore'
)(observer(Cart));
const TranslateCart = translate()(WrappedCart);
TranslateCart.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslateCart, {title: TITLE});
