import {
  extendObservable,
  action,
} from 'mobx';
import uuid from 'uuid-v4';
import {
  mergeWith,
} from 'lodash';

import {
  gradesFull,
  sealGrades,
} from '../constants/grades';

import GraderModel from './GraderModel';


class GradeItemModel {
  constructor(data) {
    extendObservable(this, {
      data: mergeWith({}, this.gradeItemInitialState),

      get boxNumber() {
        const box = parseFloat(this.data.box);
        return !Number.isNaN(box) ? box : 0;
      },
  
      get instructionNumber() {
        const instruction = parseFloat(this.data.instruction);
        return !Number.isNaN(instruction) ? instruction : 0;
      },
  
      get cartridgeNumber() {
        const cartridge = parseFloat(this.data.cartridge);
        return !Number.isNaN(cartridge) ? cartridge : 0;
      },

      get boxName() {
        const box = gradesFull.find(i => i.id === parseFloat(this.data.box));
        return box ? box.name : '-';
      },
  
      get instructionName() {
        const instruction = gradesFull.find(i => i.id === parseFloat(this.data.instruction));
        return instruction ? instruction.name : '-';
      },
  
      get cartridgeName() {
        const cartridge = gradesFull.find(i => i.id === parseFloat(this.data.cartridge));
        return cartridge ? cartridge.name : '-';
      },

      get sealName() {
        const seal = sealGrades.find(i => i.id === parseFloat(this.data.seal));
        return seal ? seal.name : '';
      },

      get isPro() {
        const self = this.data;
        return [self.cartridge, self.box, self.instruction]
          .map(i => parseFloat(i)).some(i => i === 11);
      },
  
      get isGenAll() {
        const self = this.data;
        return [self.cartridge, self.box, self.instruction]
          .map(i => parseFloat(i)).every(i => i === 12);
      },
    });

    if (data) {
      this.updateGradeItem(data);
    }
  }

  id = uuid();
  gradeItemInitialState = {
    id: '',
    instruction: '',
    seal: 0,
    cartridge: 0,
    notes: '',
    grader: new GraderModel(),
    details: [],
    standart: [],
    specific: [],
    variants: [],
  };

  clearGradeItem = action(
    'clear grade item', () => {
    this.updateGradeItem(this.gradeItemInitialState);
  });

  updateGradeItem = action('update grade item', newData => {
    const keys = Object.keys(newData);

    keys.forEach(key => {
      if (key === 'grader') {
        this.data.grader = new GraderModel(newData[key]);
      } else {
        this.data[key] = newData[key];
      }
    });
  });
};

export default GradeItemModel;
