import React from "react";
import { I18n, Trans } from "react-i18next";

import "./styles/Footer.css";
import "./styles/Copyright.css";

import Helpers from "../../utils/Helpers";

import Logo from "../shared/Logo/Logo";

import FooterMenu from "./FooterMenu/FooterMenu";
import FooterContacts from "./FooterContacts/FooterContacts";
import Socials from "./Socials/Socials";

const Footer = (props) => {
  return (
    <I18n ns="translations">
      {(t, { i18n }) => (
        <footer className="footer">
          <div className="footer__top">
            <div className="container-fluid">
              <div className="footer__top-content">
                <div className="footer__info">
                  <div className="footer__logo-wrapper">
                    <Logo
                      src="logo-white.png"
                      srcSet="logo-white@2x.png"
                      linkClassName="footer__logo"
                      imageClassName="footer__logo-img"
                    />

                    <p className="footer__be-social">
                      <Trans i18nKey={"Be social"}>
                        Be <b>social.</b>
                      </Trans>
                    </p>
                  </div>
                  <div className="footer__text-wrapper">
                    <Trans
                      i18nKey={
                        "8 generations. 41 consoles. Thousands of games. GRADE THEM ALL."
                      }
                    >
                      <p className="footer__text">
                        8 generations. 41 consoles. Thousands of games. GRADE
                        THEM ALL.
                      </p>
                      <p className="footer__text">
                      WATA is the industry leading video game and media authentication and grading company built by collectors, for collectors. We grade Sealed and Complete-In-Box (CIB) media for most consoles and platforms, and cartridges for NES and Super NES. We help you preserve your most important gaming and media memories, recognizing them for the art and history that they are. Whether you’re looking to continue building your high value media collection or are just getting started, we can help. Founded and operated by experts and collectors in the collectible industry, WATA Games offers the true confidence in collecting video games and media that is recognized and respected by serious collectors and gamers alike.
                      </p>
                    </Trans>
                  </div>
                </div>
                <div className="footer__nav">
                  <FooterMenu />
                  <FooterContacts />
                </div>
              </div>
            </div>
          </div>
          <div className="footer__bottom">
            <div className="container-fluid">
              <div className="footer__bottom-content">
                <div className="footer__social">
                  <p className="footer__social-text">
                    <Trans i18nKey={"Be social"}>
                      Be <b>social.</b>
                    </Trans>
                  </p>
                  <Socials />
                </div>
                <div className="footer__copyright">
                  <div className="footer__address">
                    CU/WATA, 1610 E St. Andrew Pl. STE 150, Santa Ana, CA 92705
                  </div>
                  <ul className="copyright">
                    <li className="copyright__item">
                      &copy;{" "}
                      <b>{Helpers.setCopyrightYear("2022")} WATA Games,</b>{" "}
                      {t("Inc.")}
                    </li>
                    <li className="copyright__item">
                      {t("All Rights Reserved")}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </I18n>
  );
};

export default Footer;
