import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-i18next';


const propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
};

const ConfirmationResolved = (props) => {
  return (
    <I18n ns="translations">
      {
        (t, {i18n}) => (
          <div>
            {
              props.isConfirmed ? (
                <div>
                  <h1 className="confirmation__header">
                    {t('Congratulations!')}
                  </h1>
                  <h2 className="confirmation__header">
                    {t('Now you can sign in')}
                  </h2>
                  <button
                    className="btn"
                    onClick={props.toggleLogInModal}
                    type="button">
                    <span className="btn__text btn__text--black">
                      {t('Go ahead!')}
                    </span>
                  </button>
                </div>
              ) : (
                <div>
                  <h2 className="confirmation__header">
                    {t("Sorry, we can't confirm your account. Bye.")}
                  </h2>
                  <div className="confirmation__icon-container">
                    <i className="icon-compare-them" />
                  </div>
                </div>
              )
            }
          </div>
        )
      }
    </I18n>
  );
}

ConfirmationResolved.propTypes = propTypes;

export default ConfirmationResolved;
