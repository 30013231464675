import {
  extendObservable,
  action,
} from 'mobx';

import GameItemModel from '../models/GameItemModel';


class SubmitGameStore {
  constructor() {
    extendObservable(this, {
      currentStep: 0,
      cartStep: 0,
      gameItem: new GameItemModel(),
      loginLinkStatus: false,
    });

    this.updateCurrentStep = action(
      'update current step of a game submission', (nextStep) => {
      if (this.currentStep !== nextStep) {
        this.currentStep = nextStep;
        if (this.currentStep === 1 && this.gameItem.submissionType === 'prototype'){
          this.gameItem.gameState = 2;
        }
      }
    });

    this.updateLoginLinkData = action(
      'update status of a login link', (status) => {
      if (this.loginLinkStatus !== status) {
        this.loginLinkStatus = status;
      }
    });
  }
}

const store = new SubmitGameStore();

export default store;
