import React from 'react';
import Slider from 'react-slick';


import '../../shared/styles/slick-slider.css';
import './styles/TopSlider.css';

import {
  sliderSettings,
} from '../../../constants/sliderSettings';

// const propTypes = {
//   t: PropTypes.func.isRequired,
// };

class TopSlider extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    const sliderList = data.map(item => (
      <div
        key={item.imgs.normal}
        className="top-slider__item"
      >
        <div className="top-slider__content">
          {
            item.content && (
              <div
                className="top-slider__content-wrapper"
                dangerouslySetInnerHTML={{__html: item.content}}
              />
            )
          }
          {
            !!item.btn.link && (
              <div className="top-slider__btn-wrapper">
                <a
                  className="btn btn--high btn--lg btn--white"
                  target={item.btn.targetBlank ? '_blank' : null}
                  href={item.btn.link}>
                  <span className="btn__text">
                    {item.btn.text}
                  </span>
                </a>
              </div>
            )
          }
        </div>
        <div className="top-slider__img-wrapper">
          <img
            src={item.imgs.normal}
            alt={item.imgs.alt}
            className="top-slider__img"
          />
        </div>
      </div>
    ));

    return (
      <section className="top-slider__section">
        <div className="container-fluid">
          <div className="top-slider__wrapper section--top">
          {
            !!data && !!data.length && (
              <Slider
                className="top-slider"
                {...sliderSettings.topSlider}
              >
                {sliderList}
              </Slider>
            )
          }
          </div>
        </div>
      </section>
    );
  }
}


// TopSlider.propTypes = propTypes;

// const TranslateTopSlider = translate()(TopSlider);

export default TopSlider;


// export default TranslateTopSlider;
