export const companies = [
  {
    id: 0,
    name: 'Atari',
    icon: 'icon-atari',
    acronym: 'ATARI',
  },
  {
    id: 1,
    name: 'Coleco',
    icon: 'icon-coleco',
    acronym: 'COLECO',
  },
  {
    id: 2,
    name: 'GCE',
    icon: 'icon-gce',
    acronym: 'GCE',
  },
  {
    id: 3,
    name: 'Magnavox',
    icon: 'icon-magnavox',
    acronym: 'MAGNAVOX',
  },
  {
    id: 4,
    name: 'Mattel',
    icon: 'icon-mattel',
    acronym: 'MATTEL',
  },
  {
    id: 5,
    name: 'Memorex',
    icon: 'icon-memorex',
    acronym: 'MEMOREX',
  },
  {
    id: 6,
    name: 'Microsoft',
    icon: 'icon-microsoft',
    acronym: 'MICROSOFT',
  },
  {
    id: 7,
    name: 'NEC',
    icon: 'icon-nec',
    acronym: 'NEC',
  },
  {
    id: 8,
    name: 'Nintendo',
    icon: 'icon-nintendo',
    acronym: 'NINTENDO',
  },
  {
    id: 9,
    name: 'Panasonic',
    icon: 'icon-panasonic',
    acronym: 'PANASONIC',
  },
  {
    id: 10,
    name: 'Philips',
    icon: 'icon-philips',
    acronym: 'PHILLIPS',
  },
  {
    id: 11,
    name: 'Sega',
    icon: 'icon-sega',
    acronym: 'SEGA',
  },
  {
    id: 12,
    name: 'SNK',
    icon: 'icon-snk',
    acronym: 'SNK',
  },
  {
    id: 13,
    name: 'Sony',
    icon: 'icon-sony',
    acronym: 'SONY',
  },
];
