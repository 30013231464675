import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import classnames from "classnames";
import { Link } from "react-router-dom";

import { translate } from "react-i18next";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import "./styles/Glossary.css";

import withTracker from "../shared/AnalyticsTracker/withTracker";

const propTypes = {
  t: PropTypes.func.isRequired,
};

const TITLE = "Glossary of Terms";

class Glossary extends React.Component {
  render() {
    const { props } = this;
    const { hash } = props.location;
    const { t } = this.props;

    return (
      <div className="page-wrapper">
        <Helmet
          title={t(TITLE)}
          meta={[
            {
              name: "description",
              content: "",
            },
          ]}
        />

        <Header className="header--gradient-btp" />

        <main className="main">
          <div className="glossary info-page">
            <div className="container-fluid">
              <div className="row-fluid">
                <div className="glossary__wrapper info-page__wrapper section section--top section--gradient section--btp">
                  <div className="glossary__content info-page__content">
                    <h2 className="section__title">{t("Glossary of Terms")}</h2>
                    <p className="section__subtitle">
                      {t(
                        "There tends to be a lot of lingo in niche markets, and video game collecting is no exception. We’re not out to make anyone feel behind the 8-ball. If you’re reading something on the site (or forum) and cross paths with a term you’re not yet familiar with, it’s probably below! If for some reason we’ve missed something, we genuinely hope you’ll bring it to our attention!"
                      )}
                    </p>

                    <div className="glossary__nav">
                      <ul className="glossary__nav-list">
                        <li className="glossary__nav-item">
                          <Link
                            to="#a"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#a",
                            })}
                          >
                            A
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#b"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#b",
                            })}
                          >
                            B
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#c"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#c",
                            })}
                          >
                            C
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#d"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#d",
                            })}
                          >
                            D
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#e"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#e",
                            })}
                          >
                            E
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#f"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#f",
                            })}
                          >
                            F
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#g"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#g",
                            })}
                          >
                            G
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#h"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#h",
                            })}
                          >
                            H
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#i"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#i",
                            })}
                          >
                            I
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#j"
                            className={classnames("glossary__nav-link", {
                              disabled: true,
                              active: hash === "#j",
                            })}
                          >
                            J
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#k"
                            className={classnames("glossary__nav-link", {
                              disabled: true,
                              active: hash === "#k",
                            })}
                          >
                            K
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#l"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#l",
                            })}
                          >
                            L
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#m"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#m",
                            })}
                          >
                            M
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#n"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#n",
                            })}
                          >
                            N
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#o"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#o",
                            })}
                          >
                            O
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#p"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#p",
                            })}
                          >
                            P
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#q"
                            className={classnames("glossary__nav-link", {
                              disabled: true,
                              active: hash === "#q",
                            })}
                          >
                            Q
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#r"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#r",
                            })}
                          >
                            R
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#s"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#s",
                            })}
                          >
                            S
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#t"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#t",
                            })}
                          >
                            T
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#u"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#u",
                            })}
                          >
                            U
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#v"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#v",
                            })}
                          >
                            V
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#w"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#w",
                            })}
                          >
                            W
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#x"
                            className={classnames("glossary__nav-link", {
                              disabled: true,
                              active: hash === "#x",
                            })}
                          >
                            X
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#y"
                            className={classnames("glossary__nav-link", {
                              disabled: true,
                              active: hash === "#y",
                            })}
                          >
                            Y
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#z"
                            className={classnames("glossary__nav-link", {
                              disabled: true,
                              active: hash === "#z",
                            })}
                          >
                            Z
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#0"
                            className={classnames("glossary__nav-link", {
                              disabled: true,
                              active: hash === "#0",
                            })}
                          >
                            0
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#n1"
                            className={classnames("glossary__nav-link", {
                              disabled: true,
                              active: hash === "#n1",
                            })}
                          >
                            1
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#n2"
                            className={classnames("glossary__nav-link", {
                              disabled: true,
                              active: hash === "#n2",
                            })}
                          >
                            2
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#n3"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#n3",
                            })}
                          >
                            3
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#n4"
                            className={classnames("glossary__nav-link", {
                              disabled: true,
                              active: hash === "#n4",
                            })}
                          >
                            4
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#n5"
                            className={classnames("glossary__nav-link", {
                              disabled: false,
                              active: hash === "#n5",
                            })}
                          >
                            5
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#n6"
                            className={classnames("glossary__nav-link", {
                              disabled: true,
                              active: hash === "#n6",
                            })}
                          >
                            6
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#n7"
                            className={classnames("glossary__nav-link", {
                              disabled: true,
                              active: hash === "#n7",
                            })}
                          >
                            7
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#n8"
                            className={classnames("glossary__nav-link", {
                              disabled: true,
                              active: hash === "#n8",
                            })}
                          >
                            8
                          </Link>
                        </li>
                        <li className="glossary__nav-item">
                          <Link
                            to="#n9"
                            className={classnames("glossary__nav-link", {
                              disabled: true,
                              active: hash === "#n9",
                            })}
                          >
                            9
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="glossary__terms">
                      <ul id="a" title="A">
                        <li>
                          <b>Altered:</b> Some game components have been
                          modified to hide condition flaws, customize their
                          appearance, or change the game’s actual variant
                          designation. WATA will certify such a game as the
                          least expensive of the possible variants. (e.g. a game
                          is submitted as the rarer “TM” version, but it is
                          really the common version with the “C” obscured by
                          damage.)
                        </li>
                        <li>
                          <b>Ancillary Product:</b> Product such as a jump pad,
                          gun, robot, etc. made by a video game publisher to
                          play a video game, excluding the console/controller
                          and video game
                        </li>
                        <li>
                          <b>Auction House:</b> Business that auctions certified
                          collectibles to the highest bidder
                        </li>
                        <li>
                          <b>Authorized Distributor:</b> High volume video game
                          distributor who submits games for grading and
                          certification from store inventory or on behalf of
                          their customers
                        </li>
                      </ul>

                      <ul id="b" title="B">
                        <li>
                          <b>Baggie:</b> Clear plastic bag included inside the
                          box when it was originally sold
                        </li>
                        <li>
                          <b>Blockbuster sticker:</b> Sticker placed on boxes or
                          cartridges by the now defunct Blockbuster Video chain
                        </li>
                        <li>
                          <b>Board:</b> Circuit board inside the cartridge that
                          runs the video game’s program
                        </li>
                        <li>
                          <b>Box:</b> Box in which the video game was originally
                          packaged
                        </li>
                        <li>
                          <b>Brick & Mortar:</b> Physical store that sells
                          vintage or modern video games
                        </li>
                        <li>
                          <b>Bump Up:</b> Moving a video game up from the
                          Service Level submitted to the accurate Service Level,
                          based on the game’s fair market value. The cost is
                          changed to reflect the appropriate Service Level.
                          (e.g. A game submitted with a declared value of $100
                          turns out to be a rare variant with a fair market
                          value of $1,500. The game is bumped up to the higher
                          Service Level to reflect its fair market value)
                        </li>
                      </ul>

                      <ul id="c" title="C">
                        <li>
                          <b>Cart:</b> Game cartridge
                        </li>
                        <li>
                          <b>Certification:</b> Process of impartially and
                          professionally assessing a game’s authenticity,
                          contents and condition, assigning a grade using fair,
                          objective standards, encasing it in a tamper-evident
                          case, and displaying all relevant information
                          regarding its condition on a label within the holder.
                          Certification creates a universal standard and
                          acceptance of the grade assigned
                        </li>
                        <li>
                          <b>Certification ID:</b> Number that corresponds to a
                          specific game certified by Wata
                        </li>
                        <li>
                          <b>Character Set:</b> Refers to a collection of all
                          games across all platforms that feature a specific
                          character (e.g. a Zelda or Super Mario character set)
                        </li>
                        <li>
                          <b>CIB:</b> Complete in box or cart, instructions
                          (manual), box. GSIs are not required but are
                          desirable, as they add value to a CIB
                        </li>
                        <li>
                          <b>Color touch:</b> Ink applied to a video game box or
                          manual post manufacturing to cover up defects
                        </li>
                        <li>
                          <b>Complete Set:</b> A collection comprised of one of
                          every game made for a particular console, such as
                          Atari, PlayStation or Nintendo
                        </li>
                        <li>
                          <b>Component grades:</b> In the case of CIBs, each
                          component is assigned a grade which is disclosed on
                          the WATA label. The CIB’s overall grade is weighted as
                          follows: Cartridge (30%), manual (20%) and box (50%)
                        </li>
                        <li>
                          <b>Console:</b> Game system in which the cartridge is
                          inserted to play the game
                        </li>
                        <li>
                          <b>Counterfeit:</b> Cartridge with either a fake
                          label, non-original guts, or made entirely from
                          aftermarket parts. May also be a video game box or
                          manual created by someone other than the original
                          video game publisher to closely resemble the original
                          with the intent of selling the counterfeit as an
                          original
                        </li>
                        <li>
                          <b>Crease:</b> Fold in the manual or box that may or
                          may not break the color printed on the fold
                        </li>
                        <li>
                          <b>Cross-Collectible:</b> Item considered desirable
                          across 2 or more markets (e.g. Star Wars video games
                          are collected by both video game collectors and Star
                          Wars collectors)
                        </li>
                        <li>
                          <b>Crunch:</b> Damage to a video game box from being
                          dropped on its corner(s). Also see crushed
                        </li>
                        <li>
                          <b>Crushed:</b> Damage to a video game box from weight
                          set on it while lying flat. Also see crunc
                        </li>
                      </ul>

                      <ul id="d" title="D">
                        <li>
                          <b>Database ID:</b> Number that corresponds to a
                          particular variant of a game in the WATA database
                        </li>
                        <li>
                          <b>Declared Value:</b> Dollar value of a video game
                          the customer enters when submitting a game for grading
                          and certification. The video game is insured for that
                          value while at Wata. Same as insured value
                        </li>
                      </ul>

                      <ul id="e" title="E">
                        <li>
                          <b>Ears:</b> Term used for the 2 small flaps just
                          under the main flap at the top of the video game box
                        </li>
                        <li>
                          <b>eBay:</b> Online auction website
                        </li>
                        <li>
                          <b>Estimated Turnaround Time:</b> Number of business
                          days it takes to grade and certify a game within a
                          specific Service Level. Same as turnaround time
                        </li>
                      </ul>

                      <ul id="f" title="F">
                        <li>
                          <b>Factory Sealed:</b> Video game which remains in the
                          same sealed condition as it was when it left the
                          factory
                        </li>
                        <li>
                          <b>Fair Market Value (FMV):</b> An estimate of the
                          market value of an item (such as a video game), based
                          on what a knowledgeable, willing, and unpressured
                          buyer would probably pay to a knowledgeable, willing,
                          and unpressured seller
                        </li>
                        <li>
                          <b>Flap:</b> Top of the video game box where the box
                          is intended to be opened. The main flap
                        </li>
                      </ul>

                      <ul id="g" title="G">
                        <li>
                          <b>Game Boy:</b> Portable game system by Nintendo.
                          Small because it was made to be played during travel
                        </li>
                        <li>
                          <b>Game Play:</b> Actual playing of a video game and
                          characteristics that positively or negatively affect
                          that experience
                        </li>
                        <li>
                          <b>Game Publisher:</b> Company that releases video
                          games
                        </li>
                        <li>
                          <b>Game Specific Insert (GSI):</b> Insert, such as a
                          poster or map, that is specific to only one game. GSIs
                          are not required but are desirable, as they add value
                          to a CIB
                        </li>
                        <li>
                          <b>Game Specific Sticker:</b> Sticker which identifies
                          a game that was placed on the shrink-wrap or box by
                          the original store in which it was sold
                        </li>
                        <li>
                          <b>Game System:</b> Different platforms for video
                          games, such as Atari, Nintendo, PlayStation, Xbox,
                          etc.
                        </li>
                        <li>
                          <b>Grader:</b> Person who impartially and
                          professionally assesses authenticity, contents,
                          condition, etc. and assigns a grade to a game. At
                          Wata, two graders independently grade each game. Their
                          work is reviewed by a final grader who ultimately
                          determines the grade assigned
                        </li>
                        <li>
                          <b>Grading:</b> Process of impartially and
                          professionally assessing a game’s authenticity,
                          contents and condition and assigning a grade using
                          fair, objective standards. Grading includes component
                          grades for CIB game submissions
                        </li>
                        <li>
                          <b>Grading Scale:</b> Numerical scale used to quantify
                          the condition of a video game. The scale goes from 0.0
                          to 10.0 with 23 levels
                        </li>
                      </ul>

                      <ul id="h" title="H">
                        <li>
                          <b>Hang Tab:</b> Video game box manufactured with
                          scoring (indentations) on the back to hang the game on
                          a hook for display/sale. Also refers to a thick
                          plastic sticker placed on the back of the game for the
                          same purpose
                        </li>
                        <li>
                          <b>Hang Tab:</b> Video game box manufactured with die
                          cuts on the back to hang the game on a hook for
                          display or sale, characteristic of early NES boxes.
                          Also refers to a thick plastic sticker placed on the
                          back of a game for the same purpose
                        </li>
                        <li>
                          <b>Hang Tab Tear:</b> Tear in shrink-wrap on the top
                          back of the box where a plastic hang tab was placed
                        </li>
                        <li>
                          <b>Homebrew:</b> Video game made by an individual or
                          small group as a passion-driven endeavor. Homebrew
                          games are released in limited quantities through
                          non-commercial/retail channels
                        </li>
                        <li>
                          <b>H-Seam:</b> Most video games are shrink-wrapped
                          using an H-Seam. The front of the shrink-wrap is
                          seamless, and the back has one horizontal seam across
                          the center. Each side also has a seam from top to
                          bottom, creating an “H”
                        </li>
                      </ul>

                      <ul id="i" title="I">
                        <li>
                          <b>Incorrect Married Parts (IMP):</b> CIB game with
                          one or more incompatible components such as a CIB
                          where the box variant was only manufactured with a 5
                          screw cart, but it is submitted for grading with a 3
                          screw cart. The 3 screw cart was “married” into the
                          CIB, but is incompatible/not original to this box
                          variant
                        </li>
                        <li>
                          <b>Innie:</b> Area(s) of a video game box that has
                          been crushed, making the current shape of that section
                          “inside” the original shape of the box. Opposite of
                          outie. Also called dent
                        </li>
                        <li>
                          <b>Insert:</b> Promotional material included inside
                          the video game box. Not necessary to be considered
                          CIB. Any inserts will be listed on the label or on the
                          Matrix
                        </li>
                        <li>
                          <b>Insured Value:</b> Dollar value of a video game the
                          customer enters when submitting a game for grading and
                          certification. The video game is insured for that
                          value while at Wata. Same as declared value
                        </li>
                        <li>
                          <b>Invoice Number:</b> Unique number that corresponds
                          with a particular game submission
                        </li>
                      </ul>

                      <ul id="l" title="L">
                        <li>
                          <b>Label:</b> Sticker placed on a cartridge to
                          identify a game. Also describes the information label
                          of a certified game in a WATA case
                        </li>
                        <li>
                          <b>Licensed Game:</b> Game made under agreement
                          between a game publisher and the manufacturer of the
                          console
                        </li>
                        <li>
                          <b>Loose:</b> Cartridge only; without other game
                          components
                        </li>
                      </ul>

                      <ul id="m" title="M">
                        <li>
                          <b>Manual:</b> Small booklet included with a game
                          which explains how the game is played. Also called
                          instructions
                        </li>
                        <li>
                          <b>Married:</b> CIB game with one or more components
                          which came from different copies of the same game
                        </li>
                        <li>
                          <b>Miscut Label:</b> Label on cartridge that was
                          incorrectly manufactured off-center
                        </li>
                        <li>
                          <b>Mock-up:</b> Item made by a game publisher to
                          preview the finished product for internal review or
                          promotional purposes. Typically refers to a box
                        </li>
                        <li>
                          <b>Modern:</b> Modern video games were first released
                          in 2006 and were developed for play on modern systems,
                          such as PlayStation and Xbox. For modern game
                          submission discounts, modern is defined as a sealed
                          game made from 2006 to present day
                        </li>
                      </ul>

                      <ul id="n" title="N">
                        <li>
                          <b>N64:</b> Nintendo 64 games, which set new standards
                          in realistic 3D gaming when they came out in 1996
                        </li>
                        <li>
                          <b>NES:</b> Nintendo Entertainment System, introduced
                          in 1985
                        </li>
                        <li>
                          <b>NintendoAge:</b> Popular chat board which promotes
                          interaction among the video game collecting community
                        </li>
                        <li>
                          <b>Nintendo Power:</b> Magazine about video games
                          which Nintendo began publishing in 1988
                        </li>
                      </ul>

                      <ul id="o" title="O">
                        <li>
                          <b>Original Art:</b> Actual artwork created to
                          manufacture a game box, cartridge label, or manual
                        </li>
                        <li>
                          <b>Outie:</b> Area(s) of a video game box that has
                          been crushed, making the current shape of that section
                          “outside” the original shape of the box. Opposite of
                          innie
                        </li>
                      </ul>

                      <ul id="p" title="P">
                        <li>
                          <b>Prescreen:</b> Service which allows the customer to
                          set a minimum acceptable grade for a game submission.*
                          <ul className="glossary__footnote">
                            <li>
                              *If the game’s grade is lower than the prescreen
                              level, the game is designated a reject and it will
                              not be encapsulated. A reject fee is charged for
                              this service.
                            </li>
                            <li>
                              *If the game’s grade meets or exceeds the
                              prescreen level, the game is awarded a grade and
                              is certified
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>Price Sticker:</b> Sticker indicating the price of
                          a game that was placed on the shrink-wrap or box by
                          the original store
                        </li>
                        <li>
                          <b>Prototype:</b> Cartridge made by a game publisher
                          for internal review or promotional purposes
                        </li>
                        <li>
                          <b>Punched Out:</b> Part of a game box that is
                          missing, often made by a hole punch. Part of box may
                          have been punched out when a game was resold
                        </li>
                      </ul>

                      <ul id="r" title="R">
                        <li>
                          <b>Raw:</b> Video game that has not been certified
                        </li>
                        <li>
                          <b>Region Code:</b> Different versions of games were
                          produced for compatibility with video game consoles in
                          different countries. These game versions are
                          designated by region codes such as PAL, NTSC, etc.
                        </li>
                        <li>
                          <b>Reject:</b> Game that did not meet the prescreen
                          level set by the submitter
                        </li>
                        <li>
                          <b>Replacement Value:</b> Dollar amount required to
                          replace a specific video game with a specific grade in
                          the open market
                        </li>
                        <li>
                          <b>Reproduction (Repro):</b> Game boxes, cartridges,
                          or manuals created by someone other than the game
                          publisher to offer a cheaper substitute for the
                          original. All have minor differences from the
                          original. Also see counterfeit. Repro also refers to
                          games that are fantasy pieces, such as Final Fantasy 2
                          in a US cartridge. This game was only released in
                          Japan on Super Famicom
                        </li>
                        <li>
                          <b>Restoration:</b> Video game component with material
                          added (including color) to improve its appearance or
                          repair damage
                        </li>
                      </ul>

                      <ul id="s" title="S">
                        <li>
                          <b>Sealed:</b> See factory sealed and sealed post
                          manufacturing
                        </li>
                        <li>
                          <b>Sealed Post Manufacturing (SPM):</b> Video game
                          which has been removed from its original factory
                          sealed shrink-wrap and has subsequently been resealed.
                          WATA does not certify games which are sealed post
                          manufacturing, as the contents could be incorrect and
                          their condition cannot be assessed (also called
                          “reseal”).*
                          <ul className="glossary__footnote">
                            <li>
                              *If WATA determines that the game you have
                              submitted was sealed post manufacturing, we will
                              contact you to ask if you wish to have the game
                              returned to you uncertified, or if you want WATA
                              to open the game and certify it as CIB
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>Service Level:</b> Varieties of service offered by
                          WATA that are determined by the game’s declared value,
                          speed of service, game state, and date of game release
                          (date of release only applies to modern games)
                        </li>
                        <li>
                          <b>Shell:</b> Outside casing of the cartridge which
                          houses the circuit board that runs the video game’s
                          program
                        </li>
                        <li>
                          <b>Slab:</b> Certified video game in a case or holder
                        </li>
                        <li>
                          <b>Sleeve:</b> Black storage sleeve that protects a
                          cartridge in the box
                        </li>
                        <li>
                          <b>Slice:</b> Damage caused to a video game when the
                          original case was carelessly opened with a box cutter
                          or other sharp tool
                        </li>
                        <li>
                          <b>SNES:</b> Super Nintendo Entertainment System
                          games. SNES was released in 1991 and featured 16-bit
                          technology
                        </li>
                        <li>
                          <b>Soot:</b> Smoke damage
                        </li>
                        <li>
                          <b>SOQ:</b> Seal of Quality
                        </li>
                        <li>
                          <b>State: WATA certifies games in 3 states:</b>{" "}
                          factory sealed, complete-in-box (CIB), or loose
                          (cartridge only)
                        </li>
                        <li>
                          <b>Store Sticker:</b> Sticker indicating the name of
                          the store in which it was sold that was placed on the
                          shrink-wrap or box by the original store
                        </li>
                        <li>
                          <b>Styrofoam Block:</b> Piece of Styrofoam inside the
                          video game box that ensures the contents do not shift
                        </li>
                      </ul>

                      <ul id="t" title="T">
                        <li>
                          <b>Tape Repair:</b> Tape that has been applied to a
                          box or manual. Tape is considered a grading issue, not
                          restoration by the game’s owner
                        </li>
                        <li>
                          <b>Tear Seal:</b> A tear mended by adding material to
                          the box or manual
                        </li>
                        <li>
                          <b>Turnaround Time:</b> Average number of business
                          days it takes to grade and certify a game within a
                          specific Service Level. Same as estimated turnaround
                          time
                        </li>
                      </ul>

                      <ul id="u" title="U">
                        <li>
                          <b>Unlicensed Game:</b> Game made without the approval
                          of the console’s manufacturer
                        </li>
                      </ul>

                      <ul id="v" title="V">
                        <li>
                          <b>Variant:</b> Many games were produced over several
                          years. Minor and even major changes were made to game
                          components over time. Each one of these game versions
                          is a variant. Some games have no variants and others
                          can have 10 or more known variants.
                        </li>
                        <li>
                          <b>V Tear:</b> Tear in the original manufacturer’s
                          shrink-wrap in the shape of a “V”
                        </li>
                      </ul>

                      <ul id="w" title="W">
                        <li>
                          <b>Warehouse Copy:</b> Video game from a warehouse of
                          unsold stock from the game’s manufacturer or
                          distributor
                        </li>
                      </ul>

                      <ul id="n3" title="3">
                        <li>
                          <b>3 Screw:</b> Cartridge assembled using 3 screws
                        </li>
                      </ul>

                      <ul id="n5" title="5">
                        <li>
                          <b>5 Screw:</b> Cartridge assembled using 5 screws
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    );
  }
}

Glossary.propTypes = propTypes;

const TranslateGlossary = translate()(Glossary);

export default withTracker(TranslateGlossary, { title: TITLE });
