import React from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';

import Modal from 'react-modal';

import {
  modalOptions,
} from '../../constants/common';

import LogIn from '../shared/LogIn/LogIn';
import ForgotPass from '../shared/ForgotPass/ForgotPass';
import LogOut from '../shared/LogOut/LogOut';
import PasswordReset from '../shared/PasswordReset/PasswordReset';

import AuthSession from '../../utils/AuthSession';

const wrappedPropTypes = {
  modalsStore: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
};


class Modals extends React.Component {
  toggleLogInModal = () => {
    this.props.modalsStore.toggleModal('logIn');
  }

  toggleForgotModal = () => {
    this.props.modalsStore.toggleModal('forgot');
  }

  toggleLogOutModal = () => {
    this.props.modalsStore.toggleModal('logOut');
  }

  toggleCompletePasswordResetModal = () => {
    this.props.modalsStore.toggleModal('completePasswordReset');
  }

  render() {
    const {
      modalsStore,
      appStore,
    } = this.props;

    const d = document;

    return (
      <div>
        {
          (!AuthSession.isTokenSet() && !appStore.isUserLoggedIn) ? (
            [
              <Modal
                key="logIn"
                onRequestClose={this.toggleLogInModal}
                shouldCloseOnOverlayClick={true}
                bodyOpenClassName={modalOptions.modalBodyOpenClassName}
                className={modalOptions.modalContainer()}
                overlayClassName={modalOptions.modalOverlayContainer()}
                parentSelector={() => d.body}
                appElement={d.getElementById('root')}
                shouldCloseOnEsc={true}
                isOpen={modalsStore.modals.logIn}>
                <LogIn />
              </Modal>,
              <Modal
                key="forgot"
                onRequestClose={this.toggleForgotModal}
                bodyOpenClassName={modalOptions.modalBodyOpenClassName}
                className={modalOptions.modalContainer()}
                overlayClassName={modalOptions.modalOverlayContainer()}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                parentSelector={() => d.body}
                appElement={d.getElementById('root')}
                isOpen={modalsStore.modals.forgot}>
                <ForgotPass />
              </Modal>,
              <Modal
                key="completePasswordReset"
                onRequestClose={this.toggleCompletePasswordResetModal}
                bodyOpenClassName={modalOptions.modalBodyOpenClassName}
                className={modalOptions.modalContainer()}
                overlayClassName={modalOptions.modalOverlayContainer()}
                shouldCloseOnEsc={false}
                shouldCloseOnOverlayClick={false}
                parentSelector={() => d.body}
                appElement={d.getElementById('root')}
                isOpen={modalsStore.modals.completePasswordReset}>
                <PasswordReset />
              </Modal>
            ]
          ) : (
            <Modal
              onRequestClose={this.toggleLogOutModal}
              bodyOpenClassName={modalOptions.modalBodyOpenClassName}
              className={modalOptions.modalContainer()}
              overlayClassName={modalOptions.modalOverlayContainer()}
              shouldCloseOnEsc={true}
              shouldCloseOnOverlayClick={true}
              parentSelector={() => d.body}
              appElement={d.getElementById('root')}
              isOpen={modalsStore.modals.logOut}>
              <LogOut />
            </Modal>
          )
        }
      </div>
    );
  }
}

const WrappedModals = inject('appStore', 'modalsStore')(observer(Modals));
WrappedModals.wrappedComponent.propTypes = wrappedPropTypes;

export default WrappedModals;
