import React from "react";

import { Link } from "react-router-dom";

import { routeURLs as URL } from "../../../../configs/routeURLs";
import { prices } from "../../../../constants/prices";

import { BrowserLink } from "../../../shared/BrowserLink";

import "./styles/Ons.css";

const pricing = prices.addOns;

const Ons = () => (
  <div className="ons">
    <div className="container-fluid">
      <div className="row-fluid">
        <div className="ons__wrapper section section--gradient section--ytr">
          <div className="ons__content">
            <h2 className="section__line-through section__line-through--white">
              <span>
                <b>Add-ons</b>
              </span>
            </h2>

            <div className="ons__items">
              <div className="ons__item">
                <div className="ons__info-wrapper">
                  <div className="ons__icon-wrapper">
                    <span className="ons__icon text-gradient icon-process-qc" />
                  </div>
                  <div className="ons__info">
                    <h3 className="ons__title">PSA/DNA Autograph Authentication</h3>
                    <span className="ons__price">
                      $<b>{pricing.autographAuth}</b>
                    </span>
                  </div>
                </div>

                <div className="ons__text-wrapper">
                  <div className="ons__text">
                  For those special items which feature signature(s) from someone involved with the media's creation, our PSA/DNA Autograph Authentication add-on has you covered. Your media will be evaluated by PSA/DNA's industry leading autograph authentication process. If both your media and signature(s) are deemed authentic, your item's label will feature a separate, valid PSA/DNA certification number confirming its authenticity. Limit 5 signatures per item.
                  </div>
                </div>
              </div>

              <div className="ons__item">
                <div className="ons__info-wrapper">
                  <div className="ons__icon-wrapper">
                    <span className="ons__icon text-gradient icon-graders" />
                  </div>
                  <div className="ons__info">
                    <h3 className="ons__title">Light Cleaning</h3>
                    <span className="ons__price">$<b>{prices.serviceLevels.sealedLoose.addOns.cleaning}</b>{" "}</span>
                    <span className="ons__price-extras">{" "}for sealed/cart</span>
                    <br />
                    <span className="ons__price">$<b>{prices.serviceLevels.cib.addOns.cleaning}{" "}</b></span>
                    <span className="ons__price-extras">{" "}for CIB</span>
                  </div>
                </div>

                <div className="ons__text-wrapper">
                  <div className="ons__text">
                    Most media have superficial dust, dirt, and/or debris that
                    can be gently removed to increase the media’s appearance and
                    grade. Soil, dust, and residue are expertly removed to avoid
                    damage to your media while also making it more likely to
                    receive a higher grade.
                  </div>
                </div>
              </div>

              <div className="ons__item">
                <div className="ons__info-wrapper">
                  <div className="ons__icon-wrapper">
                    <span className="ons__icon text-gradient icon-add-reholder" />
                  </div>
                  <div className="ons__info">
                    <h3 className="ons__title">Reholder</h3>
                    <span className="ons__price">
                      $<b>{pricing.reholder}</b>
                    </span>
                  </div>
                </div>

                <div className="ons__text-wrapper">
                  <div className="ons__text">
                    You always want your media to be encapsulated in perfect
                    cases but sometimes accidents happen. Chipped or broke your
                    case? Make sure your collection is always looking sharp in
                    new, clean, WATA cases for a nominal upgrade cost.
                  </div>
                </div>
              </div>

              <div className="ons__item">
                <div className="ons__info-wrapper">
                  <div className="ons__icon-wrapper">
                    <span className="ons__icon text-gradient icon-camera" />
                  </div>
                  <div className="ons__info">
                    <h3 className="ons__title">Post-Grade Photos</h3>
                    <span className="ons__price">
                      $<b>{pricing.photoServices}</b>
                    </span>
                  </div>
                </div>

                <div className="ons__text-wrapper">
                  <div className="ons__text">
                    Show off your newly graded media with high-quality studio
                    digital photos. You’ll receive three professional quality
                    photos right on your dashboard after encapsulation - perfect
                    for sharing online! Note: These photos are separate from the
                    high-resolution photos taken of your media before the grading
                    process.
                  </div>
                </div>
              </div>

              <div className="ons__item">
                  <div className="ons__info-wrapper">
                    <div className="ons__icon-wrapper">
                      <span className="ons__icon text-gradient icon-cib" />
                    </div>
                    <div className="ons__info">
                      <h3 className="ons__title">CIB Plus</h3>
                      <span className="ons__price">
                        $<b>{pricing.cibPlus}</b>
                      </span>
                    </div>
                  </div>

                  <div className="ons__text-wrapper">
                    <div className="ons__text">
                    This premium offer includes extra features on top of our CIB service.
                    With CIB Plus, you get the basic service plus pre-grading photos of all
                    the components, cartridge and manual variants listed on the label,
                    and Game Specific Inserts (GSIs) graded and listed on the label. Plus
                    all additional inserts are tracked and listed on your dashboard!
                    You can read more about our new CIB service&nbsp;
                    <a href="https://blog.watagames.com/2022/12/15/cib-grading-service-changes/" target="_blank">here</a>.
                    </div>
                  </div>
              </div>

              <div className="ons__item">
                  <div className="ons__info-wrapper">
                    <div className="ons__icon-wrapper">
                      <span className="ons__icon text-gradient icon-cleaning" />
                    </div>
                    <div className="ons__info">
                      <h3 className="ons__title">Sticker Removal</h3>
                      <span className="ons__price">
                        $<b>{pricing.stickerRemoval}</b>
                      </span>
                    </div>
                  </div>

                  <div className="ons__text-wrapper">
                    <div className="ons__text">
                    Many examples of media
                    have stickers on the plastic seal that you may want removed before encapsulation.
                    With our Sticker Removal service, the WATA team will evaluate the seal and sticker
                    before attempting to remove it. If we believe we can remove it safely, we use
                    our proprietary methods to safely remove the sticker from your media. 
                    <br></br>
                    <br></br>
                    Note: Selecting this add-on doesn't guarantee sticker removal. If the WATA team
                    determines removal may risk damages to your media, we'll let you know and refund
                    the fee. For more information check out our <Link to={URL.faq.link} target="_blank">FAQs</Link>.
                    </div>
                  </div>
              </div>

              <div className="ons__item">
                  <div className="ons__info-wrapper">
                    <div className="ons__icon-wrapper">
                      <span className="ons__icon text-gradient icon-process-grade" />
                    </div>
                    <div className="ons__info">
                      <h3 className="ons__title">Genuine “GEN” Designation</h3>
                      <span className="ons__price">
                        $<b>{pricing.genDesignation}</b>
                      </span>
                    </div>
                  </div>

                  <div className="ons__text-wrapper">
                    <div className="ons__text">
                    For the media that mean the most to you, get them authenticated and encapsulated without
                    a grade on the label. This is the perfect option for displaying your favorite well-played
                    media without the condition showing on the label.
                    </div>
                  </div>
              </div>

              {false &&
              <div className="ons__item">
                  <div className="ons__info-wrapper">
                    <div className="ons__icon-wrapper">
                      <span className="ons__icon text-gradient icon-case-3d" />
                    </div>
                    <div className="ons__info">
                      <h3 className="ons__title">WATA Legacy Holder</h3>
                      <span className="ons__price">
                        $<b>{pricing.legacyHolder}</b>
                      </span>
                    </div>
                  </div>

                  <div className="ons__text-wrapper">
                    <div className="ons__text">
                    Back by popular demand, and for a limited time only, get your media in our Legacy Holders. Perfect for 100%-ing that perfect set, or for matching your newest addition to that retro collection. Quantities are limited, so Press Start to Play!
                    </div>
                  </div>
              </div>}
            </div>

            <div className="text-center">
              <BrowserLink className="btn btn--high btn--lg" to={URL.submitGame.link}>
                <span className="btn__text">Submit Here</span>
              </BrowserLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Ons;
