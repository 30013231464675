import React from 'react';
import PropTypes from 'prop-types';

import {inject, observer} from 'mobx-react';

import ReactGA from 'react-ga';

import {
  withRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import AuthRoute from '../shared/routes/AuthRoute';

import Helmet from 'react-helmet';
import ReactAlert from 'react-s-alert';

import Modals from '../Modals/Modals';
import Sidebar from '../Sidebar/Sidebar';

import Spinner from '../shared/Spinner/Spinner';

import Progress from '../shared/ProgressSpinner/ProgressSpinner';

import HomePage from '../HomePage/HomePage';
import WhoWeAre from '../WhoWeAre/WhoWeAre';
import WhatWeDo from '../WhatWeDo/WhatWeDo';
import Join from '../Join/Join';
import Dealer from '../Dealer/Dealer';
import SubmitGame from '../SubmitGame/SubmitGame';
import Dashboard from '../Dashboard/Dashboard';
import Confirmation from '../Confirmation/Confirmation';
import Payment from '../Payment/Payment';
import Learn from '../Learn/Learn';
import ContactUs from '../ContactUs/ContactUs';
import Legal from '../Legal/Legal';
import Services from '../Services/Services';
import Glossary from '../Glossary/Glossary';
import Faq from '../Faq/Faq';
import Login from '../Login/Login';
import Logout from '../Logout/Logout';
import Certs from '../Certs/Certs';

import ErrorPage from '../ErrorPage/ErrorPage';

import {
  alertConfig,
  analyticsConfig,
  errorMessages as err,
} from '../../constants/common';

import { routeURLs as URL } from '../../configs/routeURLs';
import { apiURLs } from '../../configs/apiURLs';

import API from '../../utils/API';
import Storage from '../../utils/Storage';
import AuthSession from '../../utils/AuthSession';
import Logger from '../../utils/Logger';

import '../shared/styles/base/reset.css';
import '../shared/styles/base/base.css';
import '../shared/styles/base/grid.css';
import '../shared/styles/base/icons-font.css';
import '../shared/styles/base/sidebar-actions.css';
import '../shared/styles/common/section.css';
import '../shared/styles/common/buttons.css';
import '../shared/styles/common/utils.css';
import '../shared/styles/common/arrow-list.css';
import '../shared/styles/common/price-list.css';
import '../shared/styles/common/drop-list.css';
import '../shared/styles/common/info-page.css';
import '../shared/styles/common/info-block.css';
import '../shared/styles/common/editable.css';
import '../shared/styles/common/square.css';
import '../shared/styles/common/apply.css';
import '../shared/styles/common/article.css';
import '../shared/styles/common/event.css';
import '../shared/styles/common/box.css';
import '../shared/styles/common/banner.css';
import '../shared/styles/modals.css';
import '../shared/styles/react-s-alert.css';
import '../shared/styles/floater.css';
import '../shared/styles/tippy.css';
import CompletePasswordReset from '../CompletePasswordReset/CompletePasswordReset';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  cartStore: PropTypes.object.isRequired,
  savedGamesStore: PropTypes.object.isRequired,
  queueStore: PropTypes.object.isRequired,
};

class App extends React.Component {
  componentDidMount() {
    this.getUserData(this.initReactGA,this.props.location.pathname);
  }

  filteredConfigs = () => {
    return [analyticsConfig].filter(({ trackingId: id }) => id);
  };

  initReactGA = () => {
    if (this.filteredConfigs().length === 0) return;

    ReactGA.initialize([analyticsConfig]);
    // Send initial test view
    ReactGA.pageview(this.props.location.pathname);
    this.props.appStore.updateAnalyticsStatus(true);
  };

  toggleSidebar = () => {
    const {appStore} = this.props;
    appStore.updateSidebarStatus(!appStore.sidebarStatus);
  }

  getUserData = async (afterInit = ()=>true,initLocation=false) => {
    const {
      router,
      appStore,
      cartStore,
      savedGamesStore,
      queueStore,
    } = this.props;

    const queueListFromStorage = Storage.get('watagamesQueueList') || {};

    const updateQueueStore = (userId) => {
      !!queueListFromStorage &&
      !!queueListFromStorage[userId] &&
      queueListFromStorage[userId].forEach(queueItem => {
        queueStore.addItemToGameList(queueItem, 'queueId');
      });
    };

    if (!AuthSession.isTokenSet()) {
      updateQueueStore('unauth');
    } else if (!appStore.isUserLoggedIn) {
      // Storage has a token but it has not been used this session
      try {
        AuthSession.setHeader();
        const response = await API.getData(apiURLs.user.data);
        const {data} = response;

        const updateStore = (responseData, store, keyID) => {
          responseData.length && responseData.forEach(item => {
            item.platformType = item.game && item.game.platforms[0].type;
            item.platform = item.game && item.game.platforms[0];
            item.certificationNumber = item.crateItemPrototypeLabel;

            store.addItemToGameList(item, keyID);
          });
        };

        updateStore(data.shoppingCart, cartStore, 'id');
        updateStore(data.savedGames, savedGamesStore, 'id');

        delete data.shoppingCart;
        delete data.savedGames;

        // update queue store

        updateQueueStore('unauth');
        updateQueueStore(data.id);

        const queueDataToStorage = queueStore.gameList.ids.map(id => {
          return queueStore.gameList.dataMap[id].data
        });

        queueListFromStorage[data.id] = queueDataToStorage;

        if (!!queueListFromStorage.unauth) {
          delete queueListFromStorage.unauth;
        }

        Storage.set('watagamesQueueList', queueListFromStorage);

        // update user store
        appStore.updateUserData(response.data);

        let country = '';

        const {
          profile: {
            shippingAddress,
            address,
          }
        } = appStore;

        if (
          !!shippingAddress.country.id
        ) {
          country = shippingAddress.country.id;
        } else if (
          !!address.country.id &&
          getCountryIndex(address.country.id) >= 0
        ) {
          country = address.country.id;
        }

        cartStore.updateShippingCountry(country);

      } catch (error) {
        Logger.error(error);
        let msg = err.somethingWentWrong; // a default
        if (!!error.response && error.response.status === 403) {
          Storage.set('watagamesNextRedirect', initLocation);
          if ( AuthSession.isTokenSet() ){
            msg = err.tokenExpired;
            AuthSession.remove();
            cartStore.clearGameList();
            savedGamesStore.clearGameList();
            appStore.logOut();
          } else {
            msg = error.response.data.message;
          }
          router.push(URL.home.link);
        } else if (!!error.response) {
          msg = error.response.data.message;
        }
        ReactAlert.error(msg, alertConfig);
      }
    }
    if ( typeof afterInit === "function"){
      // delay afterInit just enough to allow things like authroutes and routes to resolve,
      //   although if it triggers before all that happens is an extra page view
      setTimeout(afterInit,50);
    }
  }

  render() {
    const {
      appStore,
    } = this.props;

    const { location } = this.props;
    const isError = !!(location.state && location.state.error);

    return (
      <div className="application-wrapper">
        <Helmet
          title="Home"
          htmlAttributes={{lang: "en"}}
          titleAttributes={{itemprop: "name", lang: "en"}}
          defaultTitle="WataGames"
          titleTemplate="WataGames - %s" />

        <input
          type="checkbox"
          id="nav-trigger"
          checked={appStore.sidebarStatus}
          onChange={this.toggleSidebar}
          className="nav-trigger closed-check" />
        <label
          htmlFor="nav-trigger"
          className="btn-burger" />

        <Sidebar />
        <div className="page-wrapper">
          {
            !!isError ? <Route component={ErrorPage} /> : (
              <Switch>
                <Route
                  path={URL.home.link}
                  exact
                  render={props => {
                    return <HomePage {...props} />
                  }} />

                <Route
                  path={URL.whoWeAre.link}
                  exact
                  render={props => {
                    return <WhoWeAre {...props} />
                  }} />

                <Route
                  path={URL.whatWeDo.link}
                  render={props => {
                    return <WhatWeDo {...props} />
                  }} />

                <Route
                  path={URL.join.link}
                  render={props => {
                    return <Join {...props} />
                  }} />

                <Route
                  path={URL.dealer.link}
                  exact
                  render={props => {
                    return <Dealer {...props} />
                  }} />

                <Route
                  path={URL.learn.link}
                  render={props => {
                    return <Learn {...props} />
                  }} />

                <Route
                  path={URL.submitGame.link}
                  render={props => {
                    return <SubmitGame {...props} />
                  }} />

                <Route
                  path={URL.confirmation.route}
                  exact
                  render={props => {
                    return <Confirmation {...props} />
                  }} />

                <Route
                  path={URL.reset.route}
                  exact
                  render={props => {
                    return <CompletePasswordReset {...props} />
                  }} />

                <Route
                  path={URL.contactUs.link}
                  exact
                  render={props => {
                    return <ContactUs {...props} />
                  }} />

                <Route
                  path={URL.legal.link}
                  exact
                  render={props => {
                    return <Legal {...props} />
                  }} />

                <Route
                  path={URL.services.link}
                  exact
                  render={props => {
                    return <Services {...props} />
                  }} />

                <Route
                  path={URL.glossary.link}
                  exact
                  render={props => {
                    return <Glossary {...props} />
                  }} />

                <Route
                  path={URL.faq.link}
                  exact
                  render={props => {
                    return <Faq {...props} />
                  }} />

                <AuthRoute
                  redirectTo={URL.home.link}
                  authenticated={AuthSession.isTokenSet()}
                  component={Dashboard}
                  path={URL.dashboard.link}
                />

                <Route
                  path={URL.cert.link}
                  render={props => {
                    return <Certs {...props} />
                  }} />


                {/* Emails send links to the following */}
                <AuthRoute
                  exact
                  redirectTo={URL.join.link}
                  authenticated={AuthSession.isTokenSet()}
                  component={Payment}
                  path={URL.payment.link}
                />

                <Route
                  path={URL.login.link}
                  render={props => {
                    return <Login {...props} />
                  }} />

                <Route
                  path={URL.logout.link}
                  render={props => {
                    return <Logout {...props} />
                  }} />


                {/* 404 */}
                <Redirect to={{ pathname: location.pathname, state: { error: true } }} />
              </Switch>
            )
          }
        </div>

        <Modals />

        <div className="page-blocker" />

        <Spinner spinnerStatus={appStore.spinnerStatus} />
        <Progress progressStatus={appStore.progressStatus} />
        <ReactAlert stack={true} />
      </div>
    );
  }
}

const WrappedApp = inject(
  'router',
  'appStore',
  'savedGamesStore',
  'cartStore',
  'queueStore'
)(observer(App));
const RouterApp = withRouter(WrappedApp);

RouterApp.wrappedComponent.propTypes = wrappedPropTypes;

export default RouterApp;
