import React from 'react';
import PropTypes from 'prop-types';
import ReactAlert from 'react-s-alert';
import {inject, observer} from 'mobx-react';

import { translate } from 'react-i18next';

import Formsy from 'formsy-react';

import FormModel from '../../../models/FormModel';

import Input from '../form-elements/Input';

import Logger from '../../../utils/Logger';

import API from '../../../utils/API';

import {
  errorMessages as err,
  alertConfig,
} from '../../../constants/common';

import { apiURLs } from '../../../configs/apiURLs';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  modalsStore: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

class ForgotPass extends React.Component {
  constructor(props) {
    super(props);
    this.store = new FormModel({
      email: '',
    });
  }

  onValidSubmit = async (model, resetForm, invalidateForm) => {
    const {
      appStore,
      t,
    } = this.props;

    const {store} = this;

    appStore.updateProgressData();
    store.updateLoadingStatus();

    try {
      await API.postData(apiURLs.reset, store.convertToJS());

      appStore.updateProgressData(false);
      store.updateLoadingStatus(false);

      store.resetForm();

      ReactAlert.success(t('Please, check your email for a password reset link'), alertConfig);
      this.toggleModal();

    } catch (error) {
      Logger.error(error);
      if (!!error.response) {
        const {data} = error.response;

        // invalidateForm or formRef.updateInputsWithError
        Object.keys(data).forEach(key => {
          const value = data[key];

          if (!(key in model)) return;
          // invalidate if key exist in current form model
          invalidateForm({
            [key]: Array.isArray(value) ? value.join(' ') : value,
          });
        });
      } else {
        ReactAlert.error(t(err.somethingWentWrong), alertConfig);
      }

      appStore.updateProgressData(false);
      store.updateLoadingStatus(false);
    }
  }

  toggleModal = () => {
    this.props.modalsStore.toggleModal('forgot');
  }

  render() {
    const {t} = this.props;

    const {store} = this;

    return (
      <div className="modal">
        <Formsy
          className="modal-content"
          ref={(component) => {
            this.refToForm = component
          }}
          onValidSubmit={this.onValidSubmit}
        >
          <div className="modal-header">
            <h2 className="section__title section__title--white">
              {t('Forgot something?')}
            </h2>
          </div>
          <div className="modal-body modal-form">
            <Input
              name="message"
              type="hidden"
              className="form-field--no-margin"
            />

            <Input
              name="email"
              type="email"
              value={store.elements.get('email')}
              updateStateData={store.updateFormItem}
              id="email"
              placeholder={t('Email')}
              required
              iconClassName="icon-envelope"

              validations={{
                isEmail: true
              }}
              validationErrors={{
                isEmail: err.isEmail,
                isDefaultRequiredValue: err.isDefaultRequiredValue,
              }}
            >
              <label className="form-label" htmlFor="email">{t('Email')}:</label>
            </Input>

          </div>

          <div className="modal-footer modal-form">
            <button
              type="submit"
              disabled={store.isLoading.get()}
              className="btn btn--high btn--lg btn--white">
              <span className="btn__text">
                {t('Submit')}
              </span>
            </button>
          </div>
        </Formsy>
      </div>
    );
  }
}


const WrappedForgotPass = inject('appStore', 'modalsStore')(observer(ForgotPass));
const TranslateForgotPass = translate()(WrappedForgotPass);
TranslateForgotPass.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateForgotPass;
