import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {withFormsy} from 'formsy-react';
import uuid from 'uuid-v4';

import './styles/form.css';

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  wrappedClassName: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  updateStateData: PropTypes.func,
  isPristine: PropTypes.func,
  getErrorMessage: PropTypes.func,
  inputClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  autoFocus: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  showButton: PropTypes.bool,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  onToggle: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  type: 'text',
  updateStateData: () => {},
  value: '',
  className: '',
  showButton: true,
  buttonClassName: '',
  placeholder: '',
  onBlur: () => {},
  onKeyDown: () => {},
};

class Password extends React.Component {
  constructor() {
    super();
    this.uuid = uuid();
  }

  state = {
    passwordShown: false,
    hasBeenFocused: false,
  }

  invokeCallbacks = (value, passwordShown) => {
    const {
      onShow,
      onHide,
      onToggle
    } = this.props;

    if (onToggle) {
      onToggle(value);
    }

    if (onShow && passwordShown) {
      onShow(value);
    }

    if (onHide && !passwordShown) {
      onHide(value);
    }
  }

  focusVisibleInput = () => {
    const {passwordShown} = this.state;
    const visibleInput = passwordShown ? this.textInput : this.passwordInput;

    visibleInput.focus();
  }

  componentWillUpdate(nextProps, nextState) {
    const {passwordShown} = this.state;

    if (nextState.passwordShown !== passwordShown) {
      this.invokeCallbacks(nextProps.value, nextState.passwordShown);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      passwordShown,
      hasBeenFocused
    } = this.state;

    if (hasBeenFocused && prevState.passwordShown !== passwordShown) {
      this.focusVisibleInput();
    }
  }

  togglePasswordMask = () => {
    this.setState({ passwordShown: !this.state.passwordShown });
  }

  onChange = (e) => {
    const {props} = this;
    const {name} = this.props;
    let {value} = e.currentTarget;

    props.setValue(value);
    props.updateStateData(name, value);
  }

  render() {
    const {props} = this;
    const {
      isPristine,
      getErrorMessage,
    } = props;

    const {passwordShown} = this.state;

    return (
      <div
        className={classnames({
            "form-field": true,
            "has-error": !isPristine() ? !!getErrorMessage() : false,
          },
          props.className
        )}>
        {props.children}
        <div className={classnames(
          'form-field__container',
          'form-field__container--password',
        )}>
          <input
            type="password"
            ref={input => this.passwordInput = input}
            value={props.getValue()}
            id={!passwordShown ? props.id : this.uuid}
            name={!passwordShown ? props.name : ''}
            placeholder={props.placeholder}
            autoFocus={props.autoFocus}
            minLength={props.minLength}
            maxLength={props.maxLength}
            readOnly={props.readOnly}
            disabled={props.disabled}
            style={{
              display: !passwordShown ? 'block' : 'none'
            }}
            onBlur={props.onBlur}
            onKeyDown={props.onKeyDown}
            onFocus={() => this.setState({ hasBeenFocused: true })}

            onChange={this.onChange}
            className={classnames(
              'form-field__item',
              props.inputClassName
            )}
          />

          {
            props.showButton && <input
              type="text"
              ref={input => this.textInput = input}
              value={props.getValue()}
              id={passwordShown ? props.id : this.uuid}
              name={passwordShown ? props.name : ''}
              className={classnames(
                'form-field__item',
                props.inputClassName
              )}
              placeholder={props.placeholder}
              minLength={props.minLength}
              maxLength={props.maxLength}
              readOnly={props.readOnly}
              disabled={props.disabled}
              style={{
                display: passwordShown ? 'block' : 'none'
              }}
              onChange={this.onChange}
              onBlur={props.onBlur}
              onKeyDown={props.onKeyDown}
              onFocus={() => this.setState({ hasBeenFocused: true })}
            />
          }

          {
            props.showButton && <button
              type="button"
              className={classnames(
                'form-field__icon',
                props.buttonClassName
              )}
              onMouseDown={e => e.preventDefault()}
              onClick={e => {
                e.preventDefault();
                this.togglePasswordMask();
              }}
              tabIndex={-1}
            >
              <i className={classnames({
                  "icon-eye": !passwordShown,
                  "icon-eye-slash": passwordShown,
                })}
                aria-hidden="true" />
            </button>
          }
        </div>
        {(!isPristine() && getErrorMessage())
          ? <div className="help-block text-danger">{getErrorMessage()}</div>
          : null
        }
      </div>
    );
  }
}

Password.propTypes = propTypes;

Password.defaultProps = defaultProps;

export default withFormsy(Password);
