import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import classnames from 'classnames';

import {inject, observer} from 'mobx-react';
import { translate, Trans } from 'react-i18next';

import Formsy from 'formsy-react';
import ReactAlert from 'react-s-alert';

import {
  errorMessages as err,
  alertConfig,
  debounceTimeout,
  searchLabels,
} from '../../../constants/common';

import { apiURLs } from '../../../configs/apiURLs';
import { routeURLs as URL } from '../../../configs/routeURLs';

import { platforms } from '../../../constants/platforms';

import API from '../../../utils/API';
import Logger from '../../../utils/Logger';

import FormModel from '../../../models/FormModel';

import Radio from '../../shared/form-elements/Radio';
import Select from '../../shared/form-elements/Select';

import Queue from '../Queue/Queue';

import './styles/SelectGame.css';

import withTracker from '../../shared/AnalyticsTracker/withTracker';

import Storage from '../../../utils/Storage';

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  submitGameStore: PropTypes.object.isRequired,
  queueStore: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const TITLE = 'Select A Game';

class SelectGameFormModel extends FormModel { // <- !!!
  updatePlatformFormItem = (key, value) => {
    const newData = {
      [key]: value,
      game: '',
    };

    // If the platform is changed after submission type was set, make sure submission type is valid
    if (this.elements.get('submissionType') === 'prototype'
        && platforms.hasOwnProperty(value.id.toString())
        && (!platforms[value.id.toString()].hasOwnProperty("allowPrototype")
            || !platforms[value.id.toString()].allowPrototype))
    {
      newData['submissionType']='standard';
    }

    this.update(newData);
  };
}

class SelectGame extends React.Component {
  constructor(props) {
    super(props);

    this.store = new SelectGameFormModel({
      submissionType: 'standard',
      game: '',
      platform: '',
    });
    /*

    // Force a page load when going to submit so that we can control access from Cloudflare
    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const reloaded = urlParams.get('r');

    if (!reloaded) {
      urlParams.set('r', 'true');
      window.location.href = `${URL.submitGame.child.stepOne.link}?${urlParams.toString()}`;
    }
      */

    const storedItem = Storage.get('watagamesGameItem');
    if (storedItem) {
      this.props.submitGameStore.gameItem.updateGameItem(storedItem);
      this.store.update(storedItem);
      Storage.remove('watagamesGameItem');
    }
  }

  state = {
    optionsPlatform: [],
  }

  componentDidMount() {
    this.getPlatformList();
    this.updateFormState();
  }

  componentWillReceiveProps(props) {
    this.updateFormState();
  }

  updateFormState = () => {
    const {gameItem} = this.props.submitGameStore;

    this.store.update({
      submissionType: gameItem.data.submissionType,
      game: gameItem.data.game,
      platform: gameItem.data.platform,
    });
  }

  promptTextCreator = (label) => {
    return `Press Enter for searching "${label}"`;
  }

  getPlatformList = async () => {
    try {
      let result = await API.getData(apiURLs.platform.list);
      let platforms = result.data.data;
      platforms.sort((a, b) => { return a.name.localeCompare(b.name); });
      this.setState({
        optionsPlatform: platforms,
      });

    } catch(error) {
      Logger.error(error);
      ReactAlert.error(
        error.hasOwnProperty('message') ? error.message : error,
        alertConfig
      );
    }
  }

  getSelectData = async (value) => {
    if (value.length <= 2) {
      return {
        options: [],
      };
    }

    try {
      let result = await API.getData(apiURLs.game.list, {
        name: value,
        page: 1,
        size: 10,
        platformId: this.store.elements.get('platform').id,
      });

      return {
        options: result.data.data,
      };

    } catch(error) {
      Logger.error(error);
      ReactAlert.error(
        error.hasOwnProperty('message') ? error.message : error,
        alertConfig
      );

      return {
        options: [],
      };
    }
  }

  onValidSubmit = (model, resetForm, invalidateForm) => {
    const {elements} = this.store;
    const {
      submitGameStore,
      router,
    } = this.props;

    submitGameStore.gameItem.updateGameItem({
      submissionType: elements.get('submissionType'),
      game: elements.get('game'),
      platform: elements.get('platform'),
    });
    submitGameStore.updateCurrentStep(1);
    router.push(URL.submitGame.child.stepTwo.link);
  }

  render() {
    const {t} = this.props;
    const {
      state,
      props,
      store,
    } = this;
    const {elements} = store;

    const platformClass = () => {
      let res = 'sl-select';
      const platform = elements.get('platform');
      if (!!platform && platforms.hasOwnProperty(platform.id) && !!platforms[platform.id].icon) {
        res = platforms[platform.id].icon;
      } else {
        res = 'unassigned';
      }
      return res;
    };

    return (
      <div className="submit-game">
        <div className="container-fluid">

          <div className="row-fluid">
            <div className="submit-game__content">
              <Helmet
                title={`${t('Submit Here')} - ${t(TITLE)}`}
                meta={[
                  {
                    name: "description",
                    content: ""
                  },
                ]}
              />
              <div className="submit-game__section section section--top section--gradient section--ytr">
                <div className="select-game">
                  <div className="container-fluid">
                    <h1 className="section__title">
                      {t('Tell us what you’ve got:')}
                    </h1>
                    <p className="section__subtitle">
                      <b>{t('We. Grade. Everything.')}</b>
                      {' '}
                      <Trans i18nKey={"We. Grade. Everything. Well, that’s our mission, anyhow. We’re adding new platforms all the time; if you see yours below, let’s get your media graded! If not, please select the media title of \"placeholder\" for your platform."}>
                      We. Grade. Everything. Well, that’s our mission, anyhow. We’re adding new platforms all the time; if you see yours below, let’s get your media graded! If not, please select the media title of "placeholder" for your platform.
                      </Trans>
                    </p>
                  </div>

                  <Formsy
                    className="container-fluid"
                    ref={(component) => {
                      store.updateRefToForm(component);
                    }}
                    onValidSubmit={this.onValidSubmit}>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 col-md-4 submit-game__item">
                        <div className="select-game__label">
                          <label htmlFor="selectGame_platform">
                            {t('Platform')}:
                          </label>
                        </div>

                        <Select
                          name="platform"
                          id="selectGame_platform"
                          updateStateData={store.updatePlatformFormItem}
                          value={elements.get('platform')}
                          placeholder={t('Select a platform')}
                          options={state.optionsPlatform}
                          clearable={true}
                          disabled={!state.optionsPlatform.length}
                          labelKey="name"
                          valueKey="id"
                          validationErrors={{
                            isDefaultRequiredValue: err.isDefaultRequiredValue,
                          }}
                          className="form-field--no-margin"
                          required />

                        <div className={classnames(
                            'select-game__presentation',
                            {
                              'select-game__presentation--gray': !elements.get('platform'),
                            }
                          )}>
                          <span className={`select-game__platform icon-${platformClass()}`} />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-6 col-md-6 submit-game__item">
                        <div className="select-game__label">
                          <label htmlFor="selectGame_game">
                            {t('Media Title')}:
                          </label>
                        </div>
                        <Select
                          name="game"
                          id="selectGame_game"
                          async={true}
                          autoload={false}
                          searchPromptText={t(searchLabels.searchPromptText)}
                          noResultsText={t(searchLabels.noResultsText)}
                          placeholder={t('Select media')}
                          className="form-field--no-margin"
                          searchable={true}
                          clearable={true}
                          debounceTimeout={debounceTimeout}
                          disabled={!elements.get('platform')}
                          labelKey="name"
                          valueKey="id"
                          value={elements.get('game')}
                          getData={this.getSelectData}
                          updateStateData={store.updateFormItem}
                          promptTextCreator={this.promptTextCreator}
                          validationErrors={{
                            isDefaultRequiredValue: err.isDefaultRequiredValue,
                          }}
                          required
                        />

                        <div className={classnames(
                            'select-game__presentation',
                            {
                              'select-game__presentation--gray': !elements.get('game') || !elements.get('game').imgUrl,
                            }
                          )} style={{minHeight:"260px"}}>
                          {
                            (!!elements.get('game') && !!elements.get('game').imgUrl) ? (
                              <img
                                className="select-game__img"
                                src={elements.get('game').imgUrl}
                                alt={elements.get('game').name} />
                            ) : (
                              <span className="icon-unassigned" />
                            )
                          }

                        </div>
                      </div>
                      <div className="col-xs-12 col-md-2 submit-game__item">
                        <div className="select-game__label">
                          {t('Submission Type')}:
                        </div>
                        <ul style={{position:"relative", flexGrow:"1", flexDirection:"column", display:"flex"}}>
                          <li className="choice-option__item">
                            <Radio
                              id="submission_standard"
                              checked={elements.get('submissionType') === 'standard'}
                              value="standard"
                              updateStateData={store.updateFormItem}
                              className="choice-option__radio"
                              isIndicatorIcon={false}
                              name="submissionType" />
                            <label
                              htmlFor="submission_standard"
                              className={classnames(
                                'choice-option__content',
                                {
                                  'checked': elements.get('submissionType') === 'standard',
                                },
                              )}>
                              <span className="choice-option__icon icon-seal" />
                              <p className="choice-option__text">{t('Standard')}</p>
                            </label>
                          </li>
                          <li className="choice-option__item">
                            <Radio
                              id="submission_prototype"
                              checked={elements.get('submissionType') === 'prototype'}
                              disabled={
                                (elements.get('platform').hasOwnProperty('id')
                                  && platforms.hasOwnProperty(elements.get('platform').id.toString())
                                  && (!platforms[elements.get('platform').id.toString()].hasOwnProperty("allowPrototype")
                                      || !platforms[elements.get('platform').id.toString()].allowPrototype)
                                )
                              }
                              value="prototype"
                              updateStateData={store.updateFormItem}
                              className="choice-option__radio"
                              isIndicatorIcon={false}
                              name="submissionType" />
                            <label
                              className={classnames(
                                'choice-option__content',
                                {
                                  'checked': elements.get('submissionType') === 'prototype',
                                },
                                {
                                  'disabled': elements.get('platform').hasOwnProperty('id')
                                && platforms.hasOwnProperty(elements.get('platform').id.toString())
                                && (!platforms[elements.get('platform').id.toString()].hasOwnProperty("allowPrototype")
                                    || !platforms[elements.get('platform').id.toString()].allowPrototype)
                                }
                              )}
                              htmlFor="submission_prototype">
                              <span className="choice-option__icon icon-prototype" />
                              <p className="choice-option__text">{t('Prototype')}</p>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="submit-game__footer horizontal-end">
                      <button
                        type="submit"
                        disabled={!state.optionsPlatform.length || !elements.get('game')}
                        className="btn btn--high btn--lg btn--black btn--icon-right">
                        <span className="btn__text btn__text--black">
                          {t('This is my media')}
                          <i className="btn__icon icon-pix-arrow-rt" />
                        </span>
                      </button>
                    </div>
                  </Formsy>
                </div>
              </div>
            </div>
          </div>

        </div>
        {
          !!props.queueStore.gameList.ids.length && (
            <Queue data={props.queueStore.gameList} />
          )
        }
      </div>
    );
  }
}

const WrappedSelectGame = inject(
  'router',
  'appStore',
  'queueStore',
  'submitGameStore'
)(observer(SelectGame));
const TranslateSelectGame = translate()(WrappedSelectGame);
TranslateSelectGame.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslateSelectGame, {title: TITLE});
