import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import ReactAlert from 'react-s-alert';

import {
  Route,
  Switch,
  Redirect,
  NavLink,
} from 'react-router-dom';

import { mergeWith } from 'lodash';

import {
  alertConfig,
  // errorMessages as err,
} from '../../../constants/common';

import { apiURLs } from '../../../configs/apiURLs';
import { routeURLs as URL } from '../../../configs/routeURLs';


import API from '../../../utils/API';

import Logger from '../../../utils/Logger';

import OpenOrders from './OpenOrders/OpenOrders';
import CompletedOrders from './CompletedOrders/CompletedOrders';
import OrderGrades from './OrderGrades/OrderGrades';

import './styles/Orders.css';

import withTracker from '../../shared/AnalyticsTracker/withTracker';

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
};

const TITLE = 'Orders';

class Orders extends React.Component {
  getOrdersData = async ({page = 1, size = 10, orderType = 0} = {}, successCb) => {
    const {
      appStore,
    } = this.props;

    appStore.updateProgressData();

    try {
      const response = await API.getData(apiURLs.queue.orders, {
        page,
        size,
        orderType,
      });
      const {data} = response;

      /* Update orders with fullName and country */

      data.data.map(order => {
        order.address = mergeWith({}, order.address,
          {
            fullName: appStore.user.fullName,
          });

        return order;
      });

      successCb(data);

      appStore.updateProgressData(false);
    } catch (error) {
      Logger.error(error);
      ReactAlert.error(error.message, alertConfig);

      appStore.updateProgressData(false);
    }
  }

  render() {
    const {t} = this.props;

    /* It makes redirect from /dashboard/orders/ to /dashboard/orders/open */
    const redirectToOpenOrders = props => {
      const {from} = this.props.location.state || {
        from: { pathname: URL.dashboard.child.orders.child.open.link }
      }
      return (
        <Redirect to={from} />
      )
    };

    return (
      <div className="dash">

        <div className="container-fluid">
          <div className="row">

            <div className="dash__content">
              <div className="dash__section section section--top section--gradient section--btp">

                <div className="orders">

                  <ul className="orders__nav">
                    <li className="orders__nav-item">
                      <NavLink
                        to={URL.dashboard.child.orders.child.open.link}
                        className="orders__nav-link">
                        <span className="orders__nav-icon icon-orders" />
                        {t('OPEN ORDERS')}
                      </NavLink>
                    </li>
                    <li className="orders__nav-item">
                      <NavLink
                        to={URL.dashboard.child.orders.child.completed.link}
                        className="orders__nav-link">
                        <span className="orders__nav-icon icon-completed" />
                        {t('COMPLETED ORDERS')}
                      </NavLink>
                    </li>
                  </ul>

                  <div className="orders__content">
                    <div className="container-fluid">
                      <Switch>
                        <Route
                          exact
                          path={URL.dashboard.child.orders.link}
                          render={redirectToOpenOrders}
                        />

                        <Route
                          exact
                          path={URL.dashboard.child.orders.child.open.link}
                          render={props => {
                            return <OpenOrders
                              {...props}
                              getOrdersData={this.getOrdersData} />
                          }}
                        />

                        <Route
                          exact
                          path={URL.dashboard.child.orders.child.completed.link}
                          render={props => {
                            return <CompletedOrders
                              {...props}
                              getOrdersData={this.getOrdersData} />
                          }}
                        />

                        <Route
                          exact
                          path={URL.dashboard.child.orders.child.grades.route}
                          render={props => {
                            return <OrderGrades
                              {...props}
                            />
                          }}
                        />

                        {/* 404 */}
                        <Redirect to={{ state: { error: true } }} />
                      </Switch>
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>

      </div>
    );
  }
}

const WrappedOrders = inject('appStore')(observer(Orders));
const TranslateOrders = translate()(WrappedOrders);
TranslateOrders.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslateOrders, {title: TITLE});
