import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {withFormsy} from 'formsy-react';

import './styles/form.css';

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  checkedKey: PropTypes.string,
  inputClassName: PropTypes.string,
  className: PropTypes.string,
  isIndicatorIcon: PropTypes.bool,
  checkboxClassName: PropTypes.string,
  id: PropTypes.string,
  updateStateData: PropTypes.func,
  isPristine: PropTypes.func,
  getErrorMessage: PropTypes.func,
};

const defaultProps = {
  className: '',
  checkboxClassName: 'form-checkbox--white',
  updateStateData: () => {},
  isIndicatorIcon: true,
  value: '',
  checkedKey: 'checked', // property as a value: 'checked' or 'value'
};

class Checkbox extends React.Component {
  onChange = (e) => {
    const {
      name,
      checkedKey,
    } = this.props;

    let value = e.target[checkedKey];
    const val = parseFloat(value, 10);

    value = !isNaN(val) ? val : value;

    this.props.updateStateData(name, value);
  }

  render() {
    const {props} = this;
    const {isPristine, getErrorMessage} = props;

    return (
      <div
        className={classnames({
            'form-field': true,
            'form-field--checkbox': true,
            'has-error': !isPristine() ? !!getErrorMessage() : false,
          },
          props.className
        )}
      >
        <label
          className={classnames(
            'form-checkbox',
            {
              'disabled': !!props.disabled
            },
            props.checkboxClassName
          )}>
          <input
            name={props.name}
            id={props.id}
            onChange={this.onChange}
            value={props.getValue()}
            checked={props.checked}
            type="checkbox"
            className={props.inputClassName}
            defaultChecked={props.defaultChecked}
            disabled={props.disabled} />
            {
              props.isIndicatorIcon && <i className="icon-check" aria-hidden="true" />
            }
        </label>
        {
          !!props.children && (
            <div className="form-checkbox__label">
              {props.children}
            </div>
          )
        }
        {(!isPristine() && getErrorMessage())
          ? <div className="help-block text-danger">{getErrorMessage()}</div>
          : null
        }
      </div>
    );
  }
}

Checkbox.propTypes = propTypes;

Checkbox.defaultProps = defaultProps;

export default withFormsy(Checkbox);
