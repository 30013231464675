import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';

import {translate} from 'react-i18next';

import '../PrintSummary/styles/PrintSummary.css';

import Helpers from '../../../utils/Helpers';

import { serviceLevels } from '../../../constants/serviceLevels';
import { displayTypes } from '../../../constants/displayTypes';

const propTypes = {
  item: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


class PrintSummaryGameItem extends React.Component {
  render() {
    const {
      t,
      item,
    } = this.props;

    const addOns = [
      item.data.badge,
      item.data.cleaning,
      item.data.heavyCleaning,
      item.data.photoServices,
      item.data.report,
      item.data.boardPhotos,
      item.data.testing,
      item.data.reholder,
      item.data.cibPlus,
      item.data.legacyHolder,
      item.data.stickerRemoval,
      item.data.genDesignation,
      item.data.autographAuth,
    ];

    const isAddOns = !!addOns.filter(item => !!item).length;

    return (
      <tr>
        <td>
          {item.data.count}
        </td>
        <td>
          <div>
            <h3>{item.data.game.name}</h3>
            <ul className="summary-printable__game-info">
              <li>{item.data.platform && item.data.platform.name}</li>
              <li>{item.data.game && item.data.game.publisher}</li>
              <li>{item.data.game && item.data.game.year}</li>
            </ul>
          </div>
        </td>
        <td>
          {t(displayTypes[item.data.displayType].name)}
        </td>
        <td>
          {t(serviceLevels[item.data.serviceLevel].name)}
        </td>
        <td>
          {
            isAddOns ? (
              <ul className="summary-printable__addons">
                {item.data.badge && (
                  <li>{t('Deep “Badge” upgrade')}</li>
                )}
                {item.data.cleaning && (
                  <li>{t('Light Cleaning')}</li>
                )}
                {item.data.photoServices && (
                  <li>{t('Post-Grade Photos')}</li>
                )}
                {item.data.report && (
                  <li>{t('Grader’s Report')}</li>
                )}
                {item.data.boardPhotos && (
                  <li>{t('Board Photos')}</li>
                )}
                {item.data.testing && (
                  <li>{t('Testing')}</li>
                )}
                {item.data.reholder && (
                  <li>{t('Reholder')}</li>
                )}
                {item.data.cibPlus && (
                  <li>CIB Plus</li>
                )}
                {item.data.stickerRemoval && (
                  <li>Sticker Removal</li>
                )}
                {item.data.genDesignation && (
                  <li>Genuine “GEN” Designation</li>
                )}
                {item.data.legacyHolder && (
                  <li>WATA Legacy Holder</li>
                )}
                {item.data.autographAuth && (
                  <li>PSA/DNA Autograph Authentication</li>
                )}
              </ul>
            ) : (
              <p>-</p>
            )
          }

        </td>
        <td>
          <div>${Helpers.round(item.data.normalPrice).toFixed(2)}</div>
          <small>Dec {item.decValueEa}{(item.data.count < 1) && " ea"}</small>
        </td>
      </tr>
    )
  }

}

PrintSummaryGameItem.propTypes = propTypes;

const ObserverPrintSummaryGameItem = observer(PrintSummaryGameItem);
const TranslatePrintSummaryGameItem = translate()(ObserverPrintSummaryGameItem);

export default TranslatePrintSummaryGameItem;
