import {
  extendObservable,
  action,
} from 'mobx';

import OrderItemModel from '../models/OrderItemModel';
import CrateItemModel from '../models/CrateItemModel';


class OrderDetailsStore {
  constructor() {
    extendObservable(this, {
      orderItem: false,
      crateItems: [],
      lastOrderNum: '',
      orderLabelGroups: new Map(),
      imageToModal: null,
      imageToModalAlt: '',
    });
  }

  addOrderItem = action(
    (orderItem) => {
      this.orderItem = new OrderItemModel(orderItem);
    }
  );

  addLabels = action(
    'add a set of labels from an order to the label store',
    (orderNum,labels) => {
      if (!this.orderLabelGroups.has(orderNum)){
        // Convert to array if response was a single label
        const addLabels = (labels.hasOwnProperty("length")) ? labels : [labels];
        this.orderLabelGroups.set(orderNum,addLabels);
      }
      this.lastOrderNum=orderNum;
    }
  );

  getLabels = action(
    'provide an array that represents label store access -- an empty array is provided if there are no relevant labels in the store',
    (orderNum) => {
      if (this.orderLabelGroups.has(orderNum)){
        return this.orderLabelGroups.get(orderNum);
      } else {
        return [];
      }
    }
  );

  addItem = action(
    'add item to game list', (data) => {
    this.crateItems.push(new CrateItemModel(data));
  });

  clearCrateItems = action(
    'clear game list', () => {
    this.crateItems.clear();
  });

  updateImageToModalProps = action(
    'update image to modal props', (img, alt) => {
      this.imageToModal = img;
      this.imageToModalAlt = alt;
  });
}

const store = new OrderDetailsStore();

export default store;
