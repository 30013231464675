import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import OrderItem from '../OrderItem/OrderItem';
import LoadMoreButton from '../../../shared/LoadMoreButton/LoadMoreButton';

import PrintSummary from '../../../shared/PrintSummary/PrintSummary';

import Invader from '../../../shared/Invader/Invader';

import withTracker from '../../../shared/AnalyticsTracker/withTracker';

const TITLE = 'Open Orders';

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  getOrdersData: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
  openOrdersStore: PropTypes.object.isRequired,
};

class OpenOrders extends React.Component {
  componentDidMount() {
    const {
      openOrdersStore: {
        clearOrderList,
        updateCount,
        clearPage,
      },
    } = this.props;

    clearOrderList();
    updateCount(0);
    clearPage();
    this.getNextPage();
  }

  getNextPage = () => {
    const {openOrdersStore} = this.props;
    const query = {
      page: openOrdersStore.page,
      orderType: openOrdersStore.orderType,
    };

    this.props.getOrdersData(query, openOrdersStore.updateOrderList);
  }

  render() {
    const {
      t,
      openOrdersStore,
    } = this.props;

    const orderList = openOrdersStore.orderList.map(item => {
      return (
        <li
          key={item.data.id}
          className="orders__list-item"
        >
          <OrderItem
            item={item}
            updateOrderIndex={openOrdersStore.updateOrderIndex}
            toPrint={this.toPrint}
          />
        </li>
      );
    });

    return (
      <div className="open-orders">
        <h2 className="section__title">
          {t('We’re hard at work.')}
        </h2>
        <p className="section__subtitle">
          {t('Below you’ll find a list of open orders, along with up-to-date process tracking information:')}
        </p>

        <div className="open-orders__content">

          <ul className="orders__list">
            {orderList}
          </ul>

          {
            !openOrdersStore.orderList.length && !this.props.appStore.progressStatus && (
              <h1 className="confirmation__header text-center">
                {t('You have no open orders')}
              </h1>
            )
          }

          {
            this.props.appStore.progressStatus && (
              <div className="loader-container">
                <h1 className="confirmation__header text-center">
                  {t('Please wait. Loading next level...')}
                </h1>
                <Invader />
              </div>
            )
          }

          {
            (openOrdersStore.orderList.length < openOrdersStore.count) && (
              <LoadMoreButton
                isLoading={this.props.appStore.progressStatus}
                getNextPage={this.getNextPage}
              />
            )
          }

          {
            !!openOrdersStore.orderList.length && (
              <div className="summary-printable">
                <PrintSummary
                  orderAmount={openOrdersStore.orderList[openOrdersStore.orderIndex].data.amount}
                  orderTotalDiscount={openOrdersStore.orderList[openOrdersStore.orderIndex].data.totalDiscount}
                  orderDeliveryServicePrice={openOrdersStore.orderList[openOrdersStore.orderIndex].data.deliveryServicePrice}
                  box={openOrdersStore.orderList[openOrdersStore.orderIndex].box}
                  address={openOrdersStore.orderList[openOrdersStore.orderIndex].address}
                  isSendTogether={openOrdersStore.orderList[openOrdersStore.orderIndex].data.isSendTogether}
                  orderNumber={openOrdersStore.orderList[openOrdersStore.orderIndex].data.orderNumber}
                  ref={el => this.toPrint = el}
                />
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

const WrappedOpenOrders = inject('appStore', 'openOrdersStore')(observer(OpenOrders));
const TranslateOpenOrders = translate()(WrappedOpenOrders);
TranslateOpenOrders.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslateOpenOrders, {title: TITLE});
