import React from 'react';
import PropTypes from 'prop-types';
import ReactAlert from 'react-s-alert';

import {
  mergeWith,
} from 'lodash';

import Helpers from '../../../utils/Helpers';

import Formsy from 'formsy-react';

import {inject, observer} from 'mobx-react';

import {
  translate,
  Trans,
} from 'react-i18next';

import FormModel from '../../../models/FormModel';

import Input from '../../shared/form-elements/Input';
import Select from '../../shared/form-elements/Select';
import Textarea from '../../shared/form-elements/Textarea';

import '../../../utils/FormsyCustomValidationRules';

import {
  errorMessages as err,
  searchLabels,
  alertConfig,
} from '../../../constants/common';

import { apiURLs } from '../../../configs/apiURLs';

import API from '../../../utils/API';
import Logger from '../../../utils/Logger';

import './styles/ProfileForm.css';

const wrappedPropTypes = {
  toggleEditable: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
};

class ProfileForm extends React.Component {
  constructor(props) {
    super(props);

    this.store = new FormModel({
      fullName: '',
      facebook: '',
      instagram: '',
      twitter: '',
      linkedin: '',
      bio: '',
      businessName: '',
      phone: '',
      countryId: '',
      zip: '',
      city: '',
      state: '',
      address: '',
    });
  }

  componentWillMount() {
    this.updateFormState();
  }

  updateFormState = () => {
    const {
      user,
      profile,
    } = this.props.appStore;

    this.store.update({
      fullName: user.fullName,
      facebook: profile.social.facebook,
      instagram: profile.social.instagram,
      twitter: profile.social.twitter,
      linkedin: profile.social.linkedin,
      bio: profile.bio,
      businessName: profile.address.businessName,
      phone: profile.address.phone,
      countryId: !!profile.address.country ? profile.address.country.id : '',
      zip: profile.address.zip,
      city: profile.address.city,
      state: profile.address.state,
      address: profile.address.address,
    });
  }

  cancelChanges = () => {
    const {refToForm} = this.store;

    // clean form errors
    refToForm.inputs.forEach(item => {
      refToForm.updateInputsWithError({
        [item.props.name]: ''
      });
    });
    this.props.toggleEditable();
  }

  onValidSubmit = async (model, resetForm, invalidateForm) => {
    const {
      appStore,
      cartStore,
      t,
    } = this.props;

    const {store} = this;

    const form = mergeWith({},
      store.convertToJS(),
      {
        countryId: parseInt(store.convertToJS().countryId, 10)
      },
      Helpers.customizer);

    appStore.updateProgressData();
    store.updateLoadingStatus();

    try {
      const response = await API.postData(apiURLs.user.data, form);
      const {data} = response;

      const profileData = {
        fullName: data.fullName,
        profile: data.profile,
      }

      store.updateLoadingStatus(false);

      appStore.updateUserData(profileData);
      appStore.updateProgressData(false);

      let country = '';

      const {
        profile: {
          shippingAddress,
          address,
        }
      } = appStore;

      if (
        !!shippingAddress.country.id
      ) {
        country = shippingAddress.country.id;
      } else if (
        !!address.country.id &&
        getCountryIndex(address.country.id) >= 0
      ) {
        country = address.country.id;
      }

      cartStore.updateShippingCountry(country);

      ReactAlert.success(t('Profile data was successfully saved'), alertConfig);

      this.props.toggleEditable();

    } catch (error) {
      Logger.error(error);
      if (!!error.response) {
        const {data} = error.response;

        // invalidateForm or formRef.updateInputsWithError
        Object.keys(data).forEach(key => {
          const value = data[key];

          if (!(key in model)) return;
          // invalidate if key exist in current form model
          invalidateForm({
            [key]: Array.isArray(value) ? value.join(' ') : value,
          });
        });
      } else {
        ReactAlert.error(t(err.somethingWentWrong), alertConfig);
      }

      appStore.updateProgressData(false);
      store.updateLoadingStatus(false);
    }
  }

  render() {
    const {
      t,
      appStore,
    } = this.props;

    const {store} = this;

    return (
      <Formsy
        ref={(component) => {
          store.updateRefToForm(component);
        }}
        className="profile-form-wrapper"
        onValidSubmit={this.onValidSubmit}>

        <div className="profile-form">
          <Input
            name="message"
            type="hidden"
            className="form-field--no-margin"
          />

          <Input
            name="fullName"
            type="text"
            value={store.elements.get('fullName')}
            updateStateData={store.updateFormItem}
            id="profile_fullName"
            iconClassName="icon-user"
            placeholder={t('Your Full Name')}

            required
            className="profile-form__item"
            validations={{
              isWhitespace: true,
            }}
            validationErrors={{
              isWhitespace: err.isWhitespace,
              isDefaultRequiredValue: err.isDefaultRequiredValue,
            }}>
            <label htmlFor="profile_fullName"
              className="profile__label">
              <Trans i18nKey={"Your Full Name (or name of recipient)"}>
                Your Full Name <span>(or name of recipient)</span>
              </Trans>:
            </label>
          </Input>

          <Input
            name="facebook"
            type="url"
            className="profile-form__item"
            value={store.elements.get('facebook')}
            updateStateData={store.updateFormItem}
            id="profile_facebook"
            iconClassName="icon-facebook-square"
            placeholder="Facebook (URL)"

            validations={{
              isWhitespace: true,
              isUrl: true,
              isFacebookUrl: true,
            }}
            validationErrors={{
              isWhitespace: err.isWhitespace,
              isUrl: err.isUrl,
              isFacebookUrl: err.isFacebookUrl,
            }}
          >
            <label htmlFor="profile_facebook"
              className="profile__label">
                Facebook <span>(URL)</span>:
            </label>
          </Input>

          <Input
            name="instagram"
            type="url"
            className="profile-form__item"
            value={store.elements.get('instagram')}
            updateStateData={store.updateFormItem}
            id="profile_instagram"
            iconClassName="icon-instagram"
            placeholder="Instagram (URL)"

            validations={{
              isWhitespace: true,
              isUrl: true,
              isInstagramUrl: true,
            }}
            validationErrors={{
              isWhitespace: err.isWhitespace,
              isUrl: err.isUrl,
              isInstagramUrl: err.isInstagramUrl,
            }}
          >
            <label htmlFor="profile_instagram"
              className="profile__label">
              Instagram <span>(URL)</span>:
            </label>
          </Input>

          <Input
            name="twitter"
            type="url"
            value={store.elements.get('twitter')}
            updateStateData={store.updateFormItem}
            id="profile_twitter"
            className="profile-form__item"
            iconClassName="icon-twitter-square"
            placeholder="Twitter (URL)"

            validations={{
              isWhitespace: true,
              isUrl: true,
              isTwitterUrl: true,
            }}
            validationErrors={{
              isWhitespace: err.isWhitespace,
              isUrl: err.isUrl,
              isTwitterUrl: err.isTwitterUrl,
            }}
          >
            <label htmlFor="profile_twitter"
              className="profile__label">
              Twitter <span>(URL)</span>:
            </label>
          </Input>

          <Input
            name="linkedin"
            type="url"
            className="profile-form__item"
            value={store.elements.get('linkedin')}
            updateStateData={store.updateFormItem}
            id="profile_linkedin"
            iconClassName="icon-linkedin-square"
            placeholder="LinkedIn (URL)"

            validations={{
              isWhitespace: true,
              isUrl: true,
              isLinkedInUrl: true,
            }}
            validationErrors={{
              isWhitespace: err.isWhitespace,
              isUrl: err.isUrl,
              isLinkedInUrl: err.isLinkedInUrl,
            }}
          >
            <label htmlFor="profile_linkedin"
              className="profile__label">
              LinkedIn <span>(URL)</span>:
            </label>
          </Input>

          <Textarea
            name="bio"
            className="profile-form__item profile-form__address"
            value={store.elements.get('bio')}
            updateStateData={store.updateFormItem}
            id="profile_bio"
            maxLength={500}
            placeholder={t('Short Bio')}
            // required
            isCharsLeft={true}
            validations={{
              maxLength: 500,
            }}
            validationErrors={{
              maxLength: err.maxLength(500),
              // isDefaultRequiredValue: err.isDefaultRequiredValue,
            }}>
            <label
              htmlFor="profile_bio"
              className="profile__label">
              <Trans
                i18nKey={"Short Bio (500 characters max)"}>
                Short Bio <span>(500 characters max)</span>
              </Trans>:
            </label>
          </Textarea>

          <Input
            name="businessName"
            type="text"
            className="profile-form__item"
            value={store.elements.get('businessName')}
            updateStateData={store.updateFormItem}
            id="profile_businessName"
            iconClassName="icon-building"
            placeholder={t('Business Name')}

            validations={{
              isWhitespace: true,
            }}
            validationErrors={{
              isWhitespace: err.isWhitespace,
            }}
          >
            <label htmlFor="profile_businessName"
              className="profile__label">
              <Trans i18nKey={"Business Name"}>
                Business Name
              </Trans>
              {' '}
              <span>{t('(optional)')}:</span>
            </label>
          </Input>

          <div className="profile-form__item row">
            <div className="col-xs-12 col-md-9 col-lg-6">
              <Input
                name="phone"
                type="tel"
                value={store.elements.get('phone')}
                updateStateData={store.updateFormItem}
                id="profile_phone"
                iconClassName="icon-phone"
                placeholder={t('Phone number')}

                validations={{
                  isWhitespace: true,
                }}
                validationErrors={{
                  isWhitespace: err.isWhitespace,
                }}>
                <label htmlFor="profile_phone"
                  className="profile__label">
                  <Trans i18nKey={"Phone number"}>
                    Phone number
                  </Trans>:
                </label>
              </Input>
            </div>
          </div>

          <Select
            name="countryId"
            id="profile_country"
            className="profile-form__item"
            autoload={false}
            searchPromptText={t(searchLabels.searchPromptText)}
            noResultsText={t(searchLabels.noResultsText)}
            placeholder={t('Country')}
            multi={false}
            searchable={true}
            clearable={true}
            simpleValue={true}
            labelKey="name"
            valueKey="id"
            disabled={!appStore.countries.length}
            value={!!store.elements.get('countryId') ? store.elements.get('countryId').toString() : store.elements.get('countryId')}
            updateStateData={store.updateCountryFormItem}
            options={appStore.convertCountriesToJS()}>
            <label
              htmlFor="profile_country"
              className="profile__label">
              <Trans i18nKey={"Country"}>
                Country
              </Trans>:
            </label>
          </Select>

          <Input
            name="state"
            type="text"
            className="profile-form__item"
            value={store.elements.get('state')}
            updateStateData={store.updateStateFormItem}
            disabled={!store.elements.get('countryId')}
            id="profile_state"
            placeholder={t('State')}
            validations={{
              isWhitespace: true,
            }}
            validationErrors={{
              isWhitespace: err.isWhitespace,
            }}>
            <label
              className="profile__label"
              htmlFor="profile_state">
              <Trans i18nKey={"State"}>
                State
              </Trans>:
            </label>
          </Input>

          <Input
            name="city"
            type="text"
            className="profile-form__item"
            value={store.elements.get('city')}
            updateStateData={store.updateFormItem}
            disabled={!store.elements.get('state')}
            id="profile_city"
            placeholder={t('City')}
            validations={{
              isWhitespace: true,
            }}
            validationErrors={{
              isWhitespace: err.isWhitespace,
            }}>
            <label
              className="profile__label"
              htmlFor="profile_city">
              <Trans i18nKey={"City"}>
                City
              </Trans>:
            </label>
          </Input>


          <div className="row">
            <Input
              name="zip"
              type="text"
              value={store.elements.get('zip')}
              updateStateData={store.updateFormItem}
              className="col-xs-12 col-md-9 col-lg-6 profile-form__item"
              id="profile_zip"
              placeholder={t('Zip code')}

              validations={{
                isWhitespace: true,
              }}
              validationErrors={{
                isWhitespace: err.isWhitespace,
              }}
              >
              <label htmlFor="profile_zip"
                className="profile__label">
                <Trans i18nKey={"Zip code"}>
                  Zip code
                </Trans>:
              </label>
            </Input>
          </div>

          <Textarea
            className="profile-form__item profile-form__address"
            name="address"
            value={store.elements.get('address')}
            updateStateData={store.updateFormItem}
            id="profile_address"
            placeholder={t('Address')}>
            <label
              className="profile__label">
              <Trans
                htmlFor="profile_address"
                i18nKey={"Address"}>
                Address
              </Trans>:
            </label>
          </Textarea>

        </div>

        <div className="profile__buttons-wrapper">
          <div className="profile__buttons">
            <button
              type="submit"
              disabled={store.isLoading.get()}
              className="btn btn--high btn--lg btn--icon-right">
              <span className="btn__text btn__text--black">
                {t('Save changes')}
                <i className="btn__icon icon-pix-arrow-rt" />
              </span>
            </button>

            <button
              type="button"
              disabled={store.isLoading.get()}
              onClick={this.cancelChanges}
              className="btn btn--high btn--black btn--lg">
              <span className="btn__text btn__text--black">
                {t('Cancel')}
              </span>
            </button>
          </div>
        </div>
      </Formsy>
    );
  }

}

const WrappedProfileForm = inject('appStore', 'cartStore')(observer(ProfileForm));
const TranslateProfileForm = translate()(WrappedProfileForm);
TranslateProfileForm.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateProfileForm;
