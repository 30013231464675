import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles/ProgressSpinner.css';

const propTypes = {
  progressStatus: PropTypes.bool.isRequired,
};

const ProgressSpinner = (props) => {
  const wrapperClasses = classnames(
    'progress-loader',
    {
      'progress-loader--stop': !props.progressStatus,
    }
  );
  const barClasses = classnames(
    'progress-loader__bar',
    {
      'progress-loader__bar--animating': props.progressStatus,
      'progress-loader__bar--stop': !props.progressStatus,
    }
  );

  return (
    <div id="seconds" className={wrapperClasses}>
      <div id="bar" className={barClasses}></div>
    </div>
  );
}

ProgressSpinner.propTypes = propTypes;


export default ProgressSpinner;
