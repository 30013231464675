import React from 'react';
import { inject, observer } from 'mobx-react';
import Helmet from 'react-helmet';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import ReactAlert from 'react-s-alert';
import API from '../../utils/API';
import Logger from '../../utils/Logger';
import { apiURLs } from '../../configs/apiURLs';
import { alertConfig } from '../../constants/common';


import './styles/Legal.css';

import withTracker from '../shared/AnalyticsTracker/withTracker';

class Legal extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    this.fetchLegalData();
  }

  fetchLegalData = async () => {
    const {appStore} = this.props;
    appStore.updateProgressData();
    try {
      const response = await API.getData(apiURLs.cms.any('termsOfUse'));

      this.setState({
        data: response.data.value.content,
      });

      appStore.updateProgressData(false);

    } catch (error) {
      Logger.error(error);
      ReactAlert.error(error.message, alertConfig);
      appStore.updateProgressData(false);
    }
  }

  render() {
    return (
      <div className="page-wrapper">
        <Helmet
          title="Terms Of Use"
          meta={[
            {
              name: "description",
              content: ""
            },
          ]}
        />

        <Header className="header--gradient-btp" />

        <main className="main">
          <div className="legal info-page">
            <div className="container-fluid">
              <div className="row-fluid">
                <div className="legal__wrapper info-page__wrapper section section--top section--gradient section--btp">

                  <div className="legal__content info-page__content">
                    <h2 className="section__title">TERMS OF USE</h2>
                    <div
                      className="legal__content-container"
                      dangerouslySetInnerHTML={{__html: this.state.data}}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    );
  }
}

const WrappedLegal = inject(
  'appStore',
)(observer(Legal));

export default withTracker(WrappedLegal);
