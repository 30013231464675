export const scaleConfig = {
  min: 0,
  max: 10,
  marks: {
    0: '',
    '0.5': {
      label: '0.5',
    },
    1: {
      label: '1.0',
    },
    '1.5': {
      label: '1.5',
    },
    2: {
      label: '2.0',
    },
    '2.5': {
      label: '2.5',
    },
    3: {
      label: '3.0',
    },
    '3.5': {
      label: '3.5',
    },
    4: {
      label: '4.0',
    },
    '4.5': {
      label: '4.5',
    },
    5: {
      label: '5.0',
    },
    '5.5': {
      label: '5.5',
    },
    6: {
      label: '6.0',
    },
    '6.5': {
      label: '6.5',
    },
    7: {
      label: '7.0',
    },
    '7.5': {
      label: '7.5',
    },
    8: {
      label: '8.0',
    },
    '8.5': {
      label: '8.5',
    },
    9: {
      label: '9.0',
    },
    '9.2': {
      label: '9.2',
    },
    '9.4': {
      label: '9.4',
    },
    '9.6': {
      label: '9.6',
    },
    '9.8': {
      label: '9.8',
    },
    10: {
      label: '10',
    },
  },
};