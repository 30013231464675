import React from 'react';
import Helmet from 'react-helmet';

import { I18n } from 'react-i18next';

import { Link } from 'react-router-dom';

import { routeURLs as URL } from '../../configs/routeURLs';

import withTracker from '../shared/AnalyticsTracker/withTracker';

import './styles/ErrorPage.css';

const TITLE = 'Error';

const ErrorPage = (props) => {
  return (
    <I18n ns="translations">
      {
        (t, {i18n}) => (
          <div className="page-wrapper">
            <Helmet
              title={t(TITLE)}
              meta={[
                {
                  name: "description",
                  content: ""
                },
              ]}
            />


            <main className="error-404">
              <div className="error-404__content">
                <div className="error-404__info">
                  <h1>404</h1>
                  <h2>{t('The Page Does Not Exist')}</h2>
                  <p>
                    <Link className="btn" to={URL.home.link}>
                      <span className="btn__text">{t('Home page')}</span>
                    </Link>
                  </p>
                </div>
                <div className="error-404__mario-wrapper">
                  <div className="error-404__mario"></div>
                </div>
                <div className="error-404__helper">
                  <div className="error-404__message">
                    <h2>{t('Thank you Mario!')}</h2>
                    <p>{t('But our princess is in another castle!')}</p>
                  </div>
                </div>
              </div>
            </main>

          </div>
        )
      }
    </I18n>
  );
}

export default withTracker(ErrorPage, {title: TITLE});
