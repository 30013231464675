import React from 'react';
import PropTypes from 'prop-types';

import Rating from '../Rating/Rating';

import {
  maxRating,
} from '../../../constants/common';


const propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rating: PropTypes.string.isRequired,
};

const defaultProps = {
  text: '',
  name: '',
  rating: 5,
};

const MembersItem = (props) => {
  return (
    <div className="members__item">
      <Rating
        rating={props.rating}
        maxRating={maxRating} />
      <p className="members__comment">
        “{props.text}”
      </p>
      <p className="members__author">
        --{props.name}
      </p>
    </div>
  );
}

MembersItem.propTypes = propTypes;
MembersItem.defaultProps = defaultProps;

export default MembersItem;
