import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {translate} from 'react-i18next';

import EventsItem from '../EventsItem/EventsItem';

import './styles/EventsList.css';


const propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  emptyClassName: PropTypes.string,
  white: PropTypes.bool.isRequired
};

class EventsList extends React.Component {
  render() {
    const {props} = this;

    const {t, white} = props;
    console.log('%cEventsList.js line:24 white', 'color: #007acc;', white);

    const eventsList = props.data.filter(item => !!item.eventName).map(item => {
      return (
        <EventsItem
          key={item.id}
          item={item}
          white={white} />
      )
    });

    return (
      <div className="events__items">
        {
          !!eventsList.length ? eventsList : (
            <h3 className={classnames(
                'text-center',
                props.emptyClassName,
              )}>
              {t('There are no events.')}
            </h3>
          )
        }
      </div>
    );
  }

}


EventsList.propTypes = propTypes;

const TranslateEventsList = translate()(EventsList);

export default TranslateEventsList;
