import {
  extendObservable,
  action,
} from 'mobx';
import uuid from 'uuid-v4';
import BigNumber from 'bignumber.js';
import {
  mergeWith,
} from 'lodash';

import { platforms } from '../constants/platforms';
import { prices } from '../constants/prices';


class GameItemModel {
  constructor(data) {
    extendObservable(this, {
      data: mergeWith({}, this.gameItemInitialState),

      // Additional services
      get addOnsPrice() {
        const arrAddOns = [
          'cleaning',
          'heavyCleaning',
          'testing',
          'reholder',
          'boardPhotos',
          'photoServices',
          'report',
          // 'badge', -- left out by design. The charge for this comes later
          'cibPlus',
          'legacyHolder',
          'stickerRemoval',
          'genDesignation',
          'autographAuth',
        ];

        return arrAddOns.reduce((previousValue, currentValue) => {
          let priceToRes;

          const { gameState } = this.data;

          switch (true) {
            case (
              currentValue === 'cleaning'
              && gameState === 1
            ):
              priceToRes = prices.serviceLevels.cib.addOns[currentValue];
              break;

            case (
              currentValue === 'cleaning'
              && (gameState === 0 || gameState === 2)
            ):
              priceToRes = prices.serviceLevels.sealedLoose.addOns[currentValue];
              break;

            default:
              priceToRes = prices.addOns[currentValue];
              break;
          }

          const withAddOnValue = BigNumber(previousValue)
            .plus(priceToRes)
            .toNumber();

          return this.data[currentValue] ? withAddOnValue : previousValue
        }, 0);
      },

      get totalDecValue() {
        return BigNumber(this.decValueEa).times(this.data.count).toNumber();
      },

      get decValueEa() {
        const {data} = this;
        return BigNumber(
          (data.declaredValueType === 6) ? data.declaredValue : prices.getDeclaredValueOptionsArray()[data.declaredValueType])
        .toNumber();
      },

      get gameStateAvailability() {
        const initial = [true, true, false]; // [sealed, cib, looseCart]
        let res = initial.slice(0);
        const {platform,submissionType} = this.data;
        const platformData = platforms[platform.id];

        if (!!platformData && !!platformData.gameStates && !!platformData.gameStates.length) {
          res = initial.map((item, i) => platformData.gameStates.indexOf(i) > -1);
        }

        if (submissionType === 'prototype'){
          res[0] = res[1] = false;
          res[2] = true;
        }

        return res;
      },

      get serviceLevelPricesForPrototypes() {
        let ret = [];

        Object.keys(prices.serviceLevelsForPrototypes).forEach( sl => { // "basic" and "extended"
          ret.push({
            enabled: prices.serviceLevelsForPrototypes[sl].enabled,
            price: BigNumber(prices.serviceLevelsForPrototypes[sl].cost)
              .plus(prices.gameLiability.getLiabilityCost(this.decValueEa))
              .toNumber()
          });
        });

        return ret;
      },

      get serviceLevelPrices() {
        const {data} = this;

        // The prices object has the prices by gameStates if you look
        //   in an array called gameStates -- there should be one and only
        //   one matching child of prices.serviceLevels
        const sl_prices = prices.serviceLevels[
          Object.keys(prices.serviceLevels)
          .filter( // not all pricing by service level applies -- game state changes pricing
            key =>(
              prices.serviceLevels[key].hasOwnProperty("gameStates")
              && prices.serviceLevels[key].gameStates
                .some(state => (state === data.gameState))
          ))[0] // get the one and only one resulting key
        ];

        let my_prices = sl_prices.base;

        let ret = [];

        ["select", "turbo", "speedrun", "warpzone"].forEach( sl => {
          let price = my_prices[sl];
          if (price === 0 && my_prices.hasOwnProperty(sl + "DisabledPrice")){
            price = my_prices[sl + "DisabledPrice"];
          }

          ret.push({
            enabled: (my_prices[sl] > 0) ? true : false,
            price: BigNumber(price)
              .plus(prices.gameLiability.getLiabilityCost(this.decValueEa))
              .toNumber()
          });
        });

        // Add relevant fields if we are dealing with prototype pricing
        if (data.submissionType === 'prototype'){
          this.serviceLevelPricesForPrototypes.forEach(item => ret.push(item));
        }

        return ret;
      },

      get price() {
        let price = 0;
        const {data} = this;

        const serviceLevelSubmissionPrice = this.data.reholder
          ? 0
          : this.serviceLevelPrices[data.serviceLevel].price;

        let price_bn = BigNumber(price).plus(serviceLevelSubmissionPrice);

        // Additional services
        if (!!this.addOnsPrice) {
          price_bn = price_bn.plus(
            this.data.reholder
              ? prices.addOns.reholder
              : this.addOnsPrice
          );
        }

        return price_bn.times(this.data.count).toNumber();
      }
    });

    if (data) {
      this.updateGameItem(data);
    }
  }

  id = uuid();
  gameItemInitialState = {
    id: '',
    queueId: '',
    submissionType: 'standard',
    platformType: 1,
    game: '',
    platform: '',

    region: {
      id: 230,
      name: "United States"
    },
    count: 1,
    notes: '',
    gameState: 0,
    declaredValue: '',
    declaredValueType: 0,
    certificationNumber: '',

    displayType: 0,
    serviceLevel: '',
    checked: false,
    badge: false,
    cleaning: false,
    heavyCleaning: false,
    photoServices: false,
    cibPlus: false,
    legacyHolder: false,
    stickerRemoval: false,
    genDesignation: false,
    report: false,
    testing: false,
    boardPhotos: false,
    reholder: false,
  };

  clearGameItem = action(
    'clear game item', () => {
    this.updateGameItem(this.gameItemInitialState);
  });

  updateGameItem = action('update game to submit', newData => {
    const keys = Object.keys(newData);

    keys.forEach(key => {
      if (this.data[key] !== newData[key]) {
        this.data[key] = newData[key];
        if (key === 'displayType' && newData[key] === 2){
          this.data.submissionType = 'prototype';
        }
      }
    });
  });
};

export default GameItemModel;
