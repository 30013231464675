import React from 'react';
import PropTypes from 'prop-types';
import ReactAlert from 'react-s-alert';

import Formsy from 'formsy-react';

import {inject, observer} from 'mobx-react';
import {translate, Trans} from 'react-i18next';

import FormModel from '../../../models/FormModel';

import Input from '../form-elements/Input';
//import Select from '../form-elements/Select';
//import Checkbox from '../form-elements/Checkbox';
import Textarea from '../form-elements/Textarea';

import {
  errorMessages as err,
//  searchLabels,
  alertConfig,
} from '../../../constants/common';

import { apiURLs } from '../../../configs/apiURLs';

import API from '../../../utils/API';
import Logger from '../../../utils/Logger';

import '../../../utils/FormsyCustomValidationRules';

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
};

class AddressForm extends React.Component {
  constructor(props) {
    super(props);

    const altCountryPrefill = !!this.props.prefill ? this.props.prefill.country.id : '';

    this.store = new FormModel({
      country: !!this.props.countryLock ? this.props.countryLock.id : altCountryPrefill,
      businessName: !!this.props.prefill ? this.props.prefill.businessName : '',
      phone: !!this.props.prefill ? this.props.prefill.phone : '',
      zip: !!this.props.prefill ? this.props.prefill.zip : '',
      city: !!this.props.prefill ? this.props.prefill.city : '',
      state: !!this.props.prefill ? this.props.prefill.state : '',
      street: !!this.props.prefill ? this.props.prefill.address : '',
    });
  }

  componentDidMount() {
    this.getSelectData();
  }

  getSelectData = async () => {
    const {appStore} = this.props;

    if (!!appStore.countries.length || !!this.props.countryLock) return;

    try {
      let response = await API.getData(apiURLs.countries);

      appStore.updateCountries(response.data.items);
    } catch(error) {
      Logger.error(error);
      ReactAlert.error(
        error.hasOwnProperty('message') ? error.message : error,
        alertConfig
      );
    }
  }

  onValidSubmit = async (model, resetForm, invalidateForm) => {
    const {
      orderNumber
    } = this.props;

    const { store } = this;

    try {
      const sub = {
        saveAddress: false,
        shippingAddress: {
          businessName: store.elements.get('businessName'),
          phone: store.elements.get('phone'),
          country: store.elements.get('country'),
          zip: store.elements.get('zip'),
          city: store.elements.get('city'),
          state: store.elements.get('state'),
          address: store.elements.get('street'),
        }
      }
      let response = await API.postData(apiURLs.order.updateAddress(orderNumber),sub);
      if(response.status === 201){
        ReactAlert.success("Address changed!",alertConfig);
        this.props.updateItemAddress(response.data.address);
        if(typeof this.props.afterUpdate === "function"){
           this.props.afterUpdate();
        }
      }
    } catch(error) {
      Logger.error(error);
      ReactAlert.error(
        error.hasOwnProperty('message') ? error.message : error,
        alertConfig
      );
    }
  }

  render() {
    const {
      t,
//      appStore,
    } = this.props;

    const {store} = this;

    return (
      <div className="shipping-form">
        <Formsy
          ref={(component) => {
            store.updateRefToForm(component);
          }}
          onValidSubmit={this.onValidSubmit}>

          <div className="row">

            <div className="col-xs-12">
              <Input
                id="message"
                name="message"
                type="hidden"
                className="form-field--no-margin"
              />
            </div>
            <div className="col-xs-12 shipping-form__item">
              <Input
                name="businessName"
                type="text"
                value={store.elements.get('businessName')}
                updateStateData={store.updateFormItem}
                id="shipping_businessName"
                iconClassName="icon-building"
                placeholder={t('Business Name')}
                validations={{
                  isWhitespace: true,
                }}
                validationErrors={{
                  isWhitespace: err.isWhitespace,
                  isDefaultRequiredValue: err.isDefaultRequiredValue,
                }}
              >
                <label htmlFor="shipping_businessName"
                  className="shipping-form__label">
                  <Trans i18nKey={"Business Name"}>
                    Business Name
                  </Trans>
                  {' '}
                  <span>{t('(optional)')}:</span>
                </label>
              </Input>
            </div>

            <div className="col-xs-12 shipping-form__item">
              <div className="row">

                <div className="col-xs-12 col-md-7">
                  <Input
                    name="phone"
                    type="tel"
                    value={store.elements.get('phone')}
                    updateStateData={store.updateFormItem}
                    id="shipping_phone"
                    iconClassName="icon-phone"
                    placeholder={t('Phone number')}
                    required
                    validations={{
                      isWhitespace: true,
                    }}
                    validationErrors={{
                      isWhitespace: err.isWhitespace,
                      isDefaultRequiredValue: err.isDefaultRequiredValue,
                    }}
                  >
                    <label htmlFor="shipping_phone"
                      className="shipping-form__label">
                      <Trans i18nKey={"Phone number"}>
                        Phone number
                      </Trans>:
                    </label>
                  </Input>
                </div>

              </div>
            </div>

            <div className="col-xs-12 shipping-form__item">
              <div className="row">

                <div className="col-xs-12 col-md-6">
                  <Input
                    name="city"
                    type="text"
                    value={store.elements.get('city')}
                    updateStateData={store.updateFormItem}
                    id="shipping_city"
                    placeholder={t('City')}
                    required
                    validations={{
                      isWhitespace: true,
                    }}
                    validationErrors={{
                      isWhitespace: err.isWhitespace,
                      isDefaultRequiredValue: err.isDefaultRequiredValue,
                    }}>
                    <label
                      className="shipping-form__label"
                      htmlFor="shipping_city">
                      <Trans i18nKey={"City"}>
                        City
                      </Trans>:
                    </label>
                  </Input>
                </div>

                <div className="col-xs-12 col-md-6 shipping-form__item">
                  <Input
                    name="state"
                    type="text"
                    value={store.elements.get('state')}
                    updateStateData={store.updateFormItem}
                    id="shipping_state"
                    placeholder={t('State')}
                    required
                    validations={{
                      isWhitespace: true,
                    }}
                    validationErrors={{
                      isWhitespace: err.isWhitespace,
                      isDefaultRequiredValue: err.isDefaultRequiredValue,
                    }}>
                    <label
                      htmlFor="shipping_state"
                      className="shipping-form__label">
                      <Trans i18nKey={"State"}>
                        State
                      </Trans>:
                    </label>
                  </Input>
                </div>

                <div className="col-xs-12 col-md-6">
                  <Input
                    name="zip"
                    type="text"
                    value={store.elements.get('zip')}
                    updateStateData={store.updateFormItem}
                    id="shipping_zip"
                    placeholder={t('Zip code')}
                    required
                    validations={{
                      isWhitespace: true,
                    }}
                    validationErrors={{
                      isWhitespace: err.isWhitespace,
                      isDefaultRequiredValue: err.isDefaultRequiredValue,
                    }}
                  >
                    <label htmlFor="shipping_zip"
                      className="shipping-form__label">
                      <Trans i18nKey={"Zip code"}>
                        Zip code
                      </Trans>:
                    </label>
                  </Input>
                </div>
                { !!this.props.countryLock && (
                  <div className="col-xs-12 col-md-6">
                      <label
                        className="shipping-form__label">
                        <Trans i18nKey={"Country"}>
                          Country
                        </Trans>:
                      </label>
                      <div>{this.props.countryLock.name}</div>
                  </div>
                )}
                <div className="col-xs-12">
                  <label
                    className="shipping-form__label">
                    <Trans
                      htmlFor="shipping_address"
                      i18nKey={"Address"}>
                      Street/Other
                    </Trans>:
                  </label>
                  <Textarea
                    name="street"
                    className="shipping-form__address"
                    value={store.elements.get('street')}
                    updateStateData={store.updateFormItem}
                    id="shipping_address"
                    placeholder={t('Street Address/Other')}
                    required
                    validationErrors={{
                      isDefaultRequiredValue: err.isDefaultRequiredValue,
                    }}/>
                </div>

                <div className="col-xs-12">
                  <div className="shipping-form__btn-wrapper">
                    <button
                      type="submit"
                      className="btn btn--high btn--md btn--icon-right shipping-form__btn">
                      <span className="btn__text btn__text--black">
                        {t('Proceed')}
                        <i className="btn__icon icon-pix-arrow-rt" />
                      </span>
                    </button>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </Formsy>
      </div>
    );
  }

}

const WrappedAddressForm = inject(
  'appStore',
)(observer(AddressForm));

const TranslateAddressForm = translate()(WrappedAddressForm);
TranslateAddressForm.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateAddressForm;
