import React from 'react';
import ReactAlert from 'react-s-alert';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import AuthSession from '../../../utils/AuthSession';

import { translate } from 'react-i18next';

import {
  alertConfig,
  userSessionRoleKey,
} from '../../../constants/common';

import { routeURLs as URL } from '../../../configs/routeURLs';
import Storage from '../../../utils/Storage';

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  modalsStore: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
  cartStore: PropTypes.object.isRequired,
  queueStore: PropTypes.object.isRequired,
  savedGamesStore: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};


class LogOut extends React.Component {
  toggleModal = () => {
    this.props.modalsStore.toggleModal('logOut');
  }

  logOut = () => {
    const {
      t,
      cartStore: {
        clearGameList,
        resetShippingFormData,
      },
      savedGamesStore,
      queueStore,
      router,
      appStore: {
        logOut,
        updateSidebarStatus,
      },
    } = this.props;

    clearGameList();
    savedGamesStore.clearGameList();
    resetShippingFormData();
    queueStore.clearGameList();
    logOut();

    AuthSession.remove();
    Storage.remove(userSessionRoleKey);

    ReactAlert.success(t('Bye, friend! :('), alertConfig);

    this.toggleModal();

    updateSidebarStatus(false);

    router.push(URL.home.link);
  }

  render() {
    const {t} = this.props;

    return (
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="section__title section__title--white">
              {t('Are you sure you want to logout?')}
            </h2>
          </div>
          <div className="modal-body modal-form">
            <div className="form-field">
              <button
                type="button"
                onClick={this.toggleModal}
                className="btn btn--high btn--lg">
                <span>
                  {t('Nope, take me back!')}
                </span>
              </button>
            </div>
            <div className="form-field">
              <button
                onClick={this.logOut}
                type="button"
                className="btn btn--high btn--lg btn--white">
                <span>
                  {t('Yep, I’m leaving!')}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const WrappedLogOut = inject(
  'router',
  'appStore',
  'cartStore',
  'savedGamesStore',
  'queueStore',
  'modalsStore'
)(observer(LogOut));
const TranslateLogOut = translate()(WrappedLogOut);
TranslateLogOut.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateLogOut;
