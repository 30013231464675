import React from 'react';
import PropTypes from 'prop-types';

import {inject, observer} from 'mobx-react';
import {translate, Trans} from 'react-i18next';
import ReactAlert from 'react-s-alert';

import Helpers from '../../../utils/Helpers';
import AuthSession from '../../../utils/AuthSession';
import Storage from '../../../utils/Storage';

import { alertConfig  } from '../../../constants/common';

import { routeURLs as URL } from '../../../configs/routeURLs';

import GamesList from '../../shared/GamesList/GamesList';


import './styles/Queue.css';


const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  queueStore: PropTypes.object.isRequired,
  modalsStore: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

class Queue extends React.Component {
  onCheckout = () => {
    const {
      router,
      appStore,
      modalsStore,
    } = this.props;

    if (AuthSession.isTokenSet() && appStore.isUserLoggedIn) {
      router.push(URL.submitGame.child.cart.link);
    } else {
      Storage.set('watagamesNextRedirect', URL.submitGame.child.cart.link);
      Storage.set('watagamesDirectNavigate', true);

      modalsStore.toggleModal('logIn');
    }
  }

  updateLocalStorage = () => {
    const {
      appStore,
      queueStore: { gameList },
    } = this.props;
  
    const userId = appStore.user.id;
    const queueDataToStorage = gameList.ids.map(id => {
      return gameList.dataMap[id].data
    });

    const queueListFromStorage = Storage.get('watagamesQueueList') || {};

    if (!!userId) {
      queueListFromStorage[userId] = queueDataToStorage;
    } else {
      queueListFromStorage.unauth = queueDataToStorage;
    }

    Storage.set('watagamesQueueList', queueListFromStorage);
  }

  removeItem = () => {
    const {props} = this;
    const {queueStore} = props;
    const userId = props.appStore.user.id;


    const queueListFromStorage = Storage.get('watagamesQueueList') || {};

    queueStore.removeItemFromGameList(queueStore.activeGameListItemID);
    queueStore.updateActiveGameListItemID();

    const queueDataToStorage = queueStore.gameList.ids.map(id => {
      return queueStore.gameList.dataMap[id].data
    });

    if (!!userId) {
      queueListFromStorage[userId] = queueDataToStorage;
    } else {
      queueListFromStorage.unauth = queueDataToStorage;
    }

    Storage.set('watagamesQueueList', queueDataToStorage);

    ReactAlert.success(props.t('The game was successfully deleted from the queue'), alertConfig);
    props.modalsStore.toggleModal('deleteItem');
  }

  render() {
    const {props} = this;
    const {
      t,
      queueStore,
    } = props;

    return (
      <div className="queue section section--gradient section--btp container-fluid">
        <h2 className="section__title section__title--white">
          <Trans i18nKey={"Your Queue (that’s a solid start!)"}>
            Your Queue <span>(that’s a solid start!)</span>
          </Trans>
        </h2>

        <div className="queue__wrapper">
          <div className="games queue__games">
            <GamesList
              removeItem={this.removeItem}
              updateData={this.updateLocalStorage}
              updateActiveItemID={queueStore.updateActiveGameListItemID}
              data={queueStore.gameList}
              emptyClassName="games__empty--white"
              emptyListText={t('You haven’t any game in the queue yet.')}
            />

            <div className="games__total">
              <span className="games__total-text">
                {t('Total')}:
              </span>
              <span className="games__total-price">
                ${Helpers.round(queueStore.totalPrice).toFixed(2)}
              </span>
            </div>

            <div className="games__button horizontal-end">
              <button
                disabled={!queueStore.gameList.ids.length}
                type="button"
                onClick={this.onCheckout}
                className="btn btn--white btn--high btn--lg btn--icon-right">
                <span className="btn__text">
                  {t('Skip to Checkout')}
                  <i className="btn__icon icon-pix-arrow-rt"></i>
                </span>
              </button>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

const WrappedQueue = inject(
  'router',
  'queueStore',
  'modalsStore',
  'appStore'
)(observer(Queue));
const TranslateQueue = translate()(WrappedQueue);
TranslateQueue.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateQueue;
