import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';

import {inject, observer} from 'mobx-react';


import {
  Redirect,
} from 'react-router-dom';

import { routeURLs as URL } from '../../../configs/routeURLs';

import AddOnsForm from '../AddOnsForm/AddOnsForm';
import AddOnsFormForPrototypes from '../AddOnsForm/AddOnsFormForPrototypes';
import Success from '../Success/Success';

import Queue from '../Queue/Queue';

import withTracker from '../../shared/AnalyticsTracker/withTracker';


const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  submitGameStore: PropTypes.object.isRequired,
  queueStore: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const TITLE = 'AddOns';


class AddOns extends React.Component {
  state = {
    isSucceed: false,
  }

  updateSucceed = () => {
    this.setState({
      isSucceed: !this.state.isSucceed,
    });
  }

  render() {
    const {props} = this;
    const {from} = props.location.state || {
      from: {
        pathname: URL.submitGame.child.stepOne.link
      }
    }

    if (props.submitGameStore.currentStep !== 2) {
      return (
        <Redirect to={from} />
      )
    }
    const {gameItem} = this.props.submitGameStore;

    let useForm;
    switch (true){
      case (this.state.isSucceed):
        useForm = (
          <Success />
        );
        break;
      case (gameItem.data.submissionType === 'prototype'):
        useForm = (
          <AddOnsFormForPrototypes updateSucceed={this.updateSucceed} />
        );
        break;
      default:
        useForm = (
          <AddOnsForm updateSucceed={this.updateSucceed} />
        );
    }

    return (
      <div className="submit-game">
        <div className="container-fluid">
          <div className="row-fluid">
            <div className="submit-game__content">
              <div className="submit-game__section section section--top section--gradient section--ytr">
                {useForm}
              </div>
            </div>
          </div>
        </div>
        {
          !!props.queueStore.gameList.ids.length && (
            <Queue data={props.queueStore.gameList} />
          )
        }
      </div>
    );
  }
}


const WrappedAddOns = inject('submitGameStore', 'queueStore')(observer(AddOns));
const TranslateAddOns = translate()(WrappedAddOns);
TranslateAddOns.wrappedComponent.propTypes = wrappedPropTypes;

export default withTracker(TranslateAddOns, {title: TITLE});
