import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {translate} from 'react-i18next';

import {inject, observer} from 'mobx-react';

import { Link } from 'react-router-dom';

import {
  debounce,
} from 'lodash';

import { placeholders } from '../../../constants/common';

import { serviceLevels } from '../../../constants/serviceLevels';

import { displayTypes } from '../../../constants/displayTypes';

import Helpers from '../../../utils/Helpers';
import Switcher from '../Switcher/Switcher';
import { routeURLs as URL } from '../../../configs/routeURLs';

import GameInfoBlock from './GameInfoBlock/GameInfoBlock';


const wrappedPropTypes = {
  verboseInfo: PropTypes.bool,
  t: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  orderId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  item: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  updateActiveItemID: PropTypes.func.isRequired,
  isCheckboxVisible: PropTypes.bool,
  isEditable: PropTypes.bool,
};

const defaultProps = {
  verboseInfo: false,
  orderId: '',
  isCheckboxVisible: false,
  isEditable: true,
  isAddLink: false,
};


class GameListItem extends React.Component {
  constructor(props) {
    super(props);

    this.debounceUpdateData = debounce(id => {
      this.props.updateData(id)
    }, 300);
  }

  removeItemFromGameList = () => {
    const {props} = this;
    props.modalsStore.toggleModal('deleteItem');

    props.updateActiveItemID(props.id);
  }

  updateValue = (name, value) => {
    this.props.item.updateGameItem({
      [name]: value,
    });

    this.debounceUpdateData(this.props.id);
  }

  onCheck = (e) => {
    const {target} = e;
    const name = target.getAttribute('name');

    this.updateValue(name, target.checked);
  }

  render() {
    const {props} = this;
    const {
      t,
      item,
      orderId,
      verboseInfo,
      isEditable
    } = props;

    const decValueLine = (!!verboseInfo) ? (
      <span style={{fontSize: "1rem"}}><br />Dec: {item.totalDecValue || "<1000"}</span>
    ) : "";

    let addOnKeysAvailable, labelsAvailable;
    let serviceLevelsMapped = serviceLevels
    .filter( sl => sl.submissionTypes.some(sType => sType === item.data.submissionType ) )
    .map(sl => {
        const res = sl;
        res.enabled = item.serviceLevelPrices[sl.id].enabled;
        return res;
    });

    if (isEditable){
      serviceLevelsMapped=serviceLevelsMapped.filter(levelItem => !!levelItem.enabled);
    }

    if (item.data.submissionType === 'standard'){
      addOnKeysAvailable = ["autographAuth","cleaning","photoServices","cibPlus",/*"legacyHolder",*/"stickerRemoval","genDesignation"];
      labelsAvailable = [0];// see  constants/displayTypes
    } else {
      // A prototype submission has different service levels,
      //   labels, and addOns
      addOnKeysAvailable = [];
      labelsAvailable = [2];// see  constants/displayTypes
    }

    if (serviceLevels[item.data.serviceLevel].disabled && isEditable){
      /* the selected service level was paused or disabled since the item was added to cart */
      /* use the updateValue function to make sure the server is told of the update */
      this.updateValue("serviceLevel",serviceLevelsMapped[0].id);
    }

    let serviceLevelItem = serviceLevels[item.data.serviceLevel];
    let altServiceText = 'No Additional Services';

    if (item.data.reholder){
      serviceLevelItem = {
        name: "Reholder",
        icon: "case"
      };
      altServiceText = item.data.crateItemPrototypeLabel;
      serviceLevelsMapped = []; // Reholders have no service level
      addOnKeysAvailable = []; // Reholders have no additional services
      // Leave labelsAvailable alone as a reholder might switch
    }

    const addOns = addOnKeysAvailable.map(key => item.data[key]);

    const isAddOns = !!addOns.filter(item => !!item).length;

    const addOnDetails = {
      autographAuth: {
        title: 'PSA/DNA',
        disabled: true,
        allowedGameStates: [0, 1, 2]
      },
      cleaning: {
        title: 'Light Cleaning',
        disabled: false,
        allowedGameStates: [0, 1, 2]
      },
      heavyCleaning: {
        title: 'Heavy Cleaning',
        disabled: true,
        allowedGameStates: [0, 1, 2]
      },
      photoServices: {
        title: 'Post-Grade Photos',
        disabled: false,
        allowedGameStates: [0, 1, 2]
      },
      report: {
        title: 'Grader’s Report',
        disabled: true,
        allowedGameStates: [0, 1, 2]
      },
      boardPhotos: {
        title: 'Board Photos',
        disabled: true,
        allowedGameStates: [0, 1, 2]
      },
      testing: {
        title: 'Testing',
        disabled: true,
        allowedGameStates: [0, 1, 2]
      },
      cibPlus: {
        title: 'CIB Plus',
        disabled: false,
        allowedGameStates: [1]
      },
      /*
      legacyHolder: {
        title: 'WATA Legacy Holder',
        disabled: false,
        allowedGameStates: [0, 1, 2]
      },
      */
      stickerRemoval: {
        title: 'Sticker Removal',
        disabled: true,
        allowedGameStates: [0]
      },
      genDesignation: {
        title: 'Genuine “GEN” Designation',
        disabled: false,
        allowedGameStates: [0, 1, 2]
      },
    }

    const makeAddOnInputMaker = (onchange) => (datakey, gameState) => {
      return (
        <React.Fragment>
          <label className={
            classnames(
              'form-checkbox form-checkbox--black',
              {
                'disabled': addOnDetails[datakey] === undefined || addOnDetails[datakey].disabled || !addOnDetails[datakey].allowedGameStates.includes(gameState),
              },
            )
          }>
            <input
              type="checkbox"
              name={datakey}
              checked={item.data[datakey]}
              disabled={addOnDetails[datakey] === undefined ||addOnDetails[datakey].disabled || !addOnDetails[datakey].allowedGameStates.includes(gameState)}
              onChange={onchange}
              id={`${props.id}_${datakey}`} />
            <i className="icon-check" />
          </label>
          <label
            htmlFor={`${props.id}_${datakey}`}
            className="form-checkbox__label">
            <p className="games__addons-text">
              {addOnDetails[datakey] === undefined ? 'n/a' : t(addOnDetails[datakey].title)}
            </p>
          </label>
        </React.Fragment>
      );
    };

    const addOnCheckboxLister = (datakey) => {
      return (isAddOns && item.data[datakey]) ? (
        <div className="form-field form-field--checkbox games__addons-checkbox" key={"addOnCheckboxList_" + datakey}>
          <i className="icon-check checked" />
          <div
            className="form-checkbox__label">
            <p className="games__addons-text">
              {t(addOnDetails[datakey].title)}
            </p>
          </div>
        </div>
      ) : "";
    };

    const makeAddOnCheckbox = props.isEditable ? makeAddOnInputMaker(this.onCheck) : addOnCheckboxLister;

    const addOnCheckboxes = (!!item.data.reholder || (!isAddOns && !props.isEditable)) ? altServiceText
    : addOnKeysAvailable.map( service => {
      return (
        <div key={'checkbox_for_' + service}
          className="form-field form-field--checkbox games__addons-checkbox">
          {makeAddOnCheckbox(service, item.data.gameState)}
        </div>
      );
    });

    const price = (props.isEditable || !item.data.normalPrice) ? item.price : item.data.normalPrice;

    return (
      <div className="games__row">
        <div className={classnames(
          'games__col',
          'games__img-wrapper',
          {
            'games__img-wrapper--unassigned': !item.data.game.imgUrl
          }
        )}>
          <img
            className="games__img"
            src={(item.data.game && item.data.game.imgUrl) || placeholders.image}
            alt={item.data.game && item.data.game.name} />
        </div>

        <div className="games__col games__content">
          <div className="games__count">
            <div className="games__select">
              {
                props.isEditable ? (
                  <Switcher
                    name="count"
                    min={1}
                    max={(item.data.reholder) ? 1 : 999}
                    value={item.data.count}
                    updateValue={this.updateValue}>
                    <div className="games__select-content">
                      {item.data.count}
                    </div>
                  </Switcher>
                ) : (
                  <div className="games__select-content">
                    {item.data.count}
                  </div>
                )
              }
            </div>
          </div>

          <input type="checkbox"
            className="games__toggle"
            id={`${props.id}_gamesToggle`}
          />
          <GameInfoBlock
            platform={item.data.platform}
            game={item.data.game}
            noteForTt={item.data.notes}
          />
          <div className="games__settings">
            <div className="games__label">
              <i className={classnames(
                'games__label-icon',
                `icon-${displayTypes[item.data.displayType].icon}`
              )} />

              <div className="games__select">
                {
                  (props.isEditable && labelsAvailable.length > 1) ? (
                    <Switcher
                      name="displayType"
                      min={labelsAvailable[0]}
                      max={labelsAvailable[labelsAvailable.length -1]}
                      value={item.data.displayType}
                      updateValue={this.updateValue}>
                      <div className="games__select-content">
                        {t(displayTypes[item.data.displayType].name)}
                      </div>
                    </Switcher>
                  ) : (
                    <div className="games__select-content">
                      {t(displayTypes[item.data.displayType].name)}
                    </div>
                  )
                }
              </div>
            </div>

            <div className="games__service">
              <i className={classnames(
                'games__service-icon',
                `icon-${serviceLevelItem.icon}`
              )} />

              <div className="games__select">
                {
                  (props.isEditable && !item.data.reholder) ? (
                    <Switcher
                      options={serviceLevels}
                      name="serviceLevel"
                      min={serviceLevelsMapped[0].id}
                      max={serviceLevelsMapped[serviceLevelsMapped.length -1].id}
                      value={item.data.serviceLevel}
                      updateValue={this.updateValue}>
                      <div className="games__select-content">
                        {t(serviceLevelItem.name)}
                      </div>
                    </Switcher>
                  ) : (
                    <div className="games__select-content">
                      {t(serviceLevelItem.name)}
                    </div>
                  )
                }
              </div>
            </div>
            <div className="games__addons">
              {addOnCheckboxes}
            </div>
          </div>
        </div>
        {
          (false && props.isAddLink) && (
            <div className="games__col games__link">
              <div>
                <Link to={URL.dashboard.child.orders.child.details.link(orderId, item.data.id)} className="btn">
                  <span className="btn__text btn__text--black">
                    {t('View')}
                  </span>
                </Link>
              </div>
            </div>
          )
        }

        {
          (props.isCheckboxVisible && props.isEditable) && (
            <div className="games__col games__checkbox">
              <div className="form-field form-field--checkbox">
                <label className="form-checkbox form-checkbox--black">
                  <input
                    type="checkbox"
                    name="checked"
                    checked={item.data.checked}
                    onChange={this.onCheck} />
                  <i className="icon-check" />
                </label>
              </div>
            </div>
          )
        }

        <div className="games__col games__price-wrapper">
          <span className="games__price">
            ${Helpers.round(price).toFixed(2)}
            {decValueLine}
          </span>
        </div>

        {
          props.isEditable && (
            <div className="games__col games__delete-wrapper">
              <button
                onClick={this.removeItemFromGameList}
                type="button"
                className="games__delete">
                <i className="icon-trash-alt" />
              </button>
            </div>
          )
        }

      </div>
    );
  }
}

const WrappedGameListItem = inject('modalsStore')(observer(GameListItem));
const TranslateGameListItem = translate()(WrappedGameListItem);
TranslateGameListItem.wrappedComponent.propTypes = wrappedPropTypes;
TranslateGameListItem.wrappedComponent.defaultProps = defaultProps;


export default TranslateGameListItem;
