//TODO merge things like "displayTypes.js" and "declaredValues.js" (and more) into here - anything
// that is just a way to define how the wata db works for wata's products (e.g. not companies and platforms)
// but may ultimately be centralized into the API

export const gameStates = [
  // use pricingKey within prices.serviceLevels
  {
    id: 0,
    name: "Sealed",
    acronym: "Sealed",
    pricingKey: "sealedLoose",
  },
  {
    id: 1,
    name: "CIB",
    acronym: "CIB",
    pricingKey: "cib",
  },
  {
    id: 2,
    name: "Loose Cart",
    altName: "Loose Disc",
    acronym: "Cart",
    altAcronym: "Disc",
    pricingKey: "sealedLoose",
  },
];

export const attachmentSets = [
  // use translationId within the format for "Photos.Board.Title" and "Photos.Board.Desc" as
  //   each kind of photo translation will start with "Photos" and have a "Title" and "Desc"
  {
    translationId: "PostGrading",
    attachmentTypeId: 15,
  },
  {
    translationId: "Board",
    attachmentTypeId: 10,
  },
  {
    translationId: "PreGrading",
    attachmentTypeId: 5,
  },
  /*
    {
    id: 90,
    name: 'VGHF Report',
    acronym: 'VGHF'
  },
  {
    id: 100,
    name: 'ROM/Backup',
    acronym: 'ROM'
  },
  {
    id: 255,
    name: 'Other/Misc',
    acronym: 'Misc'
  }
  */
];

export const gradeLists = [
  // use translationId within the format for "Grade.Inserts.Standard.Title" or "Grade.Variants.Title" as
  //   each translation will start with "Grade" and have a "Title" and "Desc"
  {
    translationId: "Inserts.Standard",
    jsonKey: "standardInserts",
    graded: false,
    suggestedCols: "col-xs-12 col-md-6",
  },
  {
    translationId: "Inserts.Specific",
    jsonKey: "specificInserts",
    graded: true,
    suggestedCols: "col-xs-12 col-md-6",
  },
  {
    translationId: "Variants",
    jsonKey: "variants",
    graded: false,
    suggestedCols: "col-xs-12",
  },
];
