import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy-ie11-fix";

import {
  translate,
  // Trans
} from "react-i18next";

import {
  tippyConfig,
  // alertConfig,
} from "../../../../constants/common";

import { routeURLs as URL } from "../../../../configs/routeURLs";

import "./styles/ScaleInfo.css";

const propTypes = {
  t: PropTypes.func.isRequired,
  onMount: PropTypes.func,
};

const defaultProps = {
  onMount: () => {},
};

class ScaleInfo extends React.Component {
  componentDidMount() {
    this.props.onMount();
  }

  render() {
    return (
      <div className="scale-info">
        <div className="editable">
          <div className="editable__content">
            <p>
              Retro video game grading just made a giant leap forward. As
              collectors ourselves, we searched far and wide for a grading
              service we could truly count on to get things right. Well... truth
              be told, we never did manage to find one. So we decided to{" "}
              create it ourselves. We love our video games, and we hold
              ourselves to the same exacting standards we demand for our own
              games.
            </p>
          </div>
        </div>
        <div className="scale-info__content">
          <div className="scale-info__item">
            <span className="scale-info__icon text-gradient icon-graph" />
            <h3 className="scale-info__title">
              INTUITIVE GRADING SCALE
              <Tooltip
                {...tippyConfig}
                html={
                  <div>
                    Our grading scale goes from 0 to 10. It’s easy to understand
                    across all levels of collecting experience, especially for
                    anyone who is new to collecting. Other scales can go from
                    0-100 with big gaps between some scores. These are hard to
                    follow and understand. WATA’s grading scale is way more
                    intuitive! Nice, right?
                  </div>
                }
              >
                <span className="icon-question-circle text-gradient scale__tooltip" />
              </Tooltip>
            </h3>
            <p className="scale-info__text">
              Our grading scale goes from 0 to 10 in 0.5 increments, up to 9.
              From 9 to 10, we go up in 0.2 increments. Our seal grades go up by
              +’s from C to C+, B to B+, and then A to A+ and A++. Pretty
              simple!
            </p>
          </div>

          <div className="scale-info__item">
            <span className="scale-info__icon text-gradient icon-piechart" />
            <h3 className="scale-info__title">
              FAIR, DETAILED, ACCURATE
              <Tooltip
                {...tippyConfig}
                html={
                  <div>
                    Grading retro video games just got better. Real grades that
                    mean something. Accurate listing linked to a unique database
                    ID that specifies game significance and whether the game is
                    a variant, prototype, demo, etc. Forget the
                    gold/silver/bronze categories, and descriptions that confuse
                    more than they clarify. We are certifying games, not medals.
                  </div>
                }
              >
                <span className="icon-question-circle text-gradient scale__tooltip" />
              </Tooltip>
            </h3>
            <p className="scale-info__text">
              Accurate media details, linked to a unique database ID that
              specifies media significance, and whether it is a variant,
              prototype, demo, etc.
            </p>
          </div>

          <div className="scale-info__item">
            <span className="scale-info__icon text-gradient icon-game" />
            <h3 className="scale-info__title">
              GRADE THEM ALL
              <Tooltip
                {...tippyConfig}
                html={
                  <div>
                    While we don’t grade for every system today, we’re not far
                    off. Keep checking back – we’re adding new systems all the
                    time. For a complete list of games and systems we currently
                    grade, click <a href={URL.submitGame.link}>here</a>.
                  </div>
                }
              >
                <span className="icon-question-circle text-gradient scale__tooltip" />
              </Tooltip>
            </h3>
            <p className="scale-info__text">
            Whatever your favorite platform is - Nintendo, Sega, Sony, Microsoft, Atari, or other media, we grade it. Can you grade an open, Complete-in-Box media? Yes! Can you grade modern media? Yes! Can you grade a retro cartridge? Also yes! While we don’t grade for every system today, we’re not far off. Keep checking back – we’re adding new ones all the time.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

ScaleInfo.propTypes = propTypes;

ScaleInfo.defaultProps = defaultProps;

const TranslateScaleInfo = translate()(ScaleInfo);
export default TranslateScaleInfo;
