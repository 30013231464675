import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {withFormsy} from 'formsy-react';

import './styles/form.css';

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  isIndicatorIcon: PropTypes.bool,
  checkedKey: PropTypes.string,
  inputClassName: PropTypes.string,
  className: PropTypes.string,
  checkboxClassName: PropTypes.string,
  id: PropTypes.string,
  updateStateData: PropTypes.func,
  isPristine: PropTypes.func,
  getErrorMessage: PropTypes.func,
};

const defaultProps = {
  className: '',
  checkboxClassName: 'form-checkbox--white',
  updateStateData: () => {},
  value: '',
  isIndicatorIcon: true,
  checkedKey: 'checked', // property as a value: 'checked' or 'value'
};

class Radio extends React.Component {
  onChange = (e) => {
    const {name} = this.props;

    let {value} = e.target;
    const val = parseFloat(value, 10);

    value = !isNaN(val) ? val : value;

    this.props.updateStateData(name, value);
  }

  render() {
    const {props} = this;
    const {isPristine, getErrorMessage} = props;

    return (
      <div
        className={classnames({
            'form-field': true,
            'form-field--radio': true,
            'has-error': !isPristine() ? !!getErrorMessage() : false,
          },
          props.className
        )}
      >
        <label
          className={classnames(
            'form-radio',
            {
              'disabled': !!props.disabled
            },
            props.checkboxClassName
          )}>
          <input
            name={props.name}
            id={props.id}
            onChange={this.onChange}
            value={props.getValue()}
            checked={props.checked}
            type="radio"
            className={props.inputClassName}
            defaultChecked={props.defaultChecked}
            disabled={props.disabled} />
          {
            props.isIndicatorIcon && <i className="icon-circle" aria-hidden="true" />
          }
        </label>
        {
          !!props.children && (
            <div className="form-radio__label">
              {props.children}
            </div>
          )
        }
        {(!isPristine() && getErrorMessage())
          ? <div className="help-block text-danger">{getErrorMessage()}</div>
          : null
        }
      </div>
    );
  }
}

Radio.propTypes = propTypes;

Radio.defaultProps = defaultProps;

export default withFormsy(Radio);
