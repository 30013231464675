import React from "react";
import PropTypes from "prop-types";

import ReactAlert from "react-s-alert";
import { inject, observer } from "mobx-react";
import { translate } from "react-i18next";

import Formsy from "formsy-react";

import { Trans } from "react-i18next";

import FormModel from "../../../models/FormModel";

import Input from "../../shared/form-elements/Input";
import Checkbox from "../../shared/form-elements/Checkbox";
import Password from "../../shared/form-elements/Password";

import Logger from "../../../utils/Logger";
import API from "../../../utils/API";
import "../../../utils/FormsyCustomValidationRules";

import { errorMessages as err, alertConfig } from "../../../constants/common";

import { apiURLs } from "../../../configs/apiURLs";

const wrappedPropTypes = {
  appStore: PropTypes.object.isRequired,
  modalsStore: PropTypes.object.isRequired,
};

class JoinForm extends React.Component {
  constructor(props) {
    super(props);

    this.store = new FormModel({
      email: "",
      password: "",
      fullName: "",
      newsletter: false,
    });
  }

  toggleLogInModal = () => {
    this.props.modalsStore.toggleModal("logIn");
  };

  onValidSubmit = async (model, resetForm, invalidateForm) => {
    const { appStore, t } = this.props;

    const { store } = this;

    const formData = store.convertToJS();

    formData.events = false; // TODO: remove it after BE changes
    formData.howToSeries = false; // TODO: remove it after BE changes

    appStore.updateProgressData();
    store.updateLoadingStatus();

    try {
      await API.postData(apiURLs.registration, formData);
      // await API.postData(apiURLs.registration, store.convertToJS());

      appStore.updateProgressData(false);
      store.updateLoadingStatus(false);

      store.resetForm();

      ReactAlert.success(
        t("Please, check your email for the magic activation link"),
        alertConfig
      );
    } catch (error) {
      Logger.error(error);
      if (!!error.response) {
        const { data } = error.response;

        // invalidateForm or formRef.updateInputsWithError
        Object.keys(data).forEach((key) => {
          const value = data[key];

          if (!(key in model)) return;
          // invalidate if key exist in current form model
          invalidateForm({
            [key]: Array.isArray(value) ? value.join(" ") : value,
          });
        });
      } else {
        ReactAlert.error(err.somethingWentWrong, alertConfig);
      }

      appStore.updateProgressData(false);
      store.updateLoadingStatus(false);
    }
  };

  render() {
    const { t } = this.props;
    const { store } = this;
    return (
      <section className="join section section--gradient section--top section--ytr">
        <Formsy
          className="container-fluid"
          ref={(component) => {
            store.updateRefToForm(component);
          }}
          onValidSubmit={this.onValidSubmit}
        >
          <h2 className="section__title section__title--white">
            {t("Start a New Game")}
          </h2>

          <div className="join__content">
            <div className="join__item">
              <div className="join__fields">
                <Input
                  id="message"
                  name="message"
                  type="hidden"
                  className="form-field--no-margin"
                />

                <Input
                  name="fullName"
                  type="text"
                  value={store.elements.get("fullName")}
                  updateStateData={store.updateFormItem}
                  id="join_fullName"
                  iconClassName="icon-user"
                  placeholder={t("Full Name")}
                  required
                  validations={{
                    isWhitespace: true,
                  }}
                  validationErrors={{
                    isWhitespace: err.isWhitespace,
                    isDefaultRequiredValue: err.isDefaultRequiredValue,
                  }}
                />

                <Input
                  name="email"
                  type="email"
                  value={store.elements.get("email")}
                  updateStateData={store.updateFormItem}
                  id="join_email"
                  placeholder={t("Email")}
                  iconClassName="icon-envelope"
                  required
                  validations={{
                    isEmail: true,
                  }}
                  validationErrors={{
                    isEmail: err.isEmail,
                    isDefaultRequiredValue: err.isDefaultRequiredValue,
                  }}
                />

                <Password
                  name="password"
                  value={store.elements.get("password")}
                  updateStateData={store.updateFormItem}
                  id="join_password"
                  placeholder={t("Password")}
                  required
                  validations={{
                    minLength: 8,
                    isLatinAndNumber: true,
                  }}
                  validationErrors={{
                    minLength: err.minLength(8),
                    isDefaultRequiredValue: err.isDefaultRequiredValue,
                    isLatinAndNumber: err.isLatinAndNumber,
                  }}
                />
              </div>{" "}
              {/* join__fields */}
            </div>
            <div className="join__item join__list-wrapper">
              <div className="join__list">
                <Checkbox
                  checked={store.elements.get("newsletter")}
                  id="join_newsletter"
                  updateStateData={store.updateFormItem}
                  name="newsletter"
                >
                  <p className="join__list-text join__list-item">
                    <b>WATA News:</b> Sign up for the latest news, exclusive
                    promo codes, market trends, rare finds, events, and much
                    more!
                  </p>
                </Checkbox>
              </div>
            </div>{" "}
            {/* join__list-wrapper */}
          </div>
          <div className="join__footer">
            <button
              disabled={store.isLoading.get()}
              type="submit"
              className="btn btn--high btn--lg btn--white"
            >
              <span>{t("Submit")}</span>
            </button>
            <p className="join__sing-in">
              {t("Already a member?")}{" "}
              <button onClick={this.toggleLogInModal} type="button">
                {t("Sign In")}
              </button>
              .
            </p>
          </div>
        </Formsy>
      </section>
    );
  }
}

const WrappedJoinForm = inject("appStore", "modalsStore")(observer(JoinForm));
const TranslateJoinForm = translate()(WrappedJoinForm);
TranslateJoinForm.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateJoinForm;
